import axios from '../../../axios/index.js'
import store from '../../../../store/store.js'
import has from 'lodash/has'
// Token Refresh
let isAlreadyFetchingAccessToken = false
const attempts = {}
async function rejected (error) {
  const { config, response } = error
  if (typeof attempts[config.data] === 'undefined') {
    attempts[config.data] = 0
  }
  if (!isAlreadyFetchingAccessToken && attempts[config.data] < 2) {
    attempts[config.data]++
    isAlreadyFetchingAccessToken = true
    const refreshToken = localStorage.getItem('refreshToken')
    if (refreshToken) {
      const payload = {
        refreshToken: localStorage.getItem('refreshToken')
      }
      let fetchSucceeded = false
      let secondResponse = response
      await store.dispatch('auth/refreshAccessToken', payload)
        .then(() => {
          if (
            new Date(Date.now()) < new Date(localStorage.getItem('tokenExpiry')) &&
            localStorage.getItem('loggedIn') === 'true'
          ) {
            isAlreadyFetchingAccessToken = false
            fetchSucceeded = true
            window.location.reload();
          } else {
            store.dispatch('auth/logout')
              .finally(() => {
                window.location.reload()
              });
          }
        })
        .catch(() => {
          isAlreadyFetchingAccessToken = false
          store.dispatch('auth/logout')
            .then(() => {
              window.location.reload()
            })
            .catch(() => {
              window.location.reload()
            })
        })
      if (fetchSucceeded) {
        try {
          await axios.request({
            method: config.method,
            url: config.url,
            data: {
              query: JSON.parse(config.data).query
            },
            withCredentials: true
          }).then((response) => {
            attempts[config.data] = 0
            isAlreadyFetchingAccessToken = false
            secondResponse = response
            Promise.resolve(response)
          }).catch(() => {
            isAlreadyFetchingAccessToken = false
            store.dispatch('auth/logout')
              .then(() => {
                window.location.reload()
              })
              .catch(() => {
                window.location.reload()
              })
          })
        } catch (error) {
          Promise.reject(false)
        }
      }
      return secondResponse
    } else {
      Promise.reject(false)
    }
  }
}
function processed (response) {
  if (has(response, ['data', 'errors']) && has(response.data.errors[0], ['debugMessage'])) {
    if (response.data.errors[0].debugMessage === 'Unauthenticated.') {
      return rejected(response)
    }
  }
  return response;
}
export default {
  init () {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
    }
    axios.interceptors.response.use(processed, rejected)
  }
}