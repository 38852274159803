import en from './languages/en.js';
import de from './languages/de.js';

// Additional Translates
import privacyPolicyEn from './languages/additional/privacy-policy/en.js';
import privacyPolicyDe from './languages/additional/privacy-policy/de.js';

export default {
  en: {...en, ...privacyPolicyEn},
  de: {...de, ...privacyPolicyDe}
}
