import has from 'lodash/has';

function translateTitle (title, key_name) {
	if(title) {
		const lang = JSON.parse(localStorage.getItem('userInfo')).language.code;
		let translatedTitle = {};
		try {
			translatedTitle = JSON.parse(title.substring(1, title.length - 1));
		} catch (e) {
			translatedTitle = JSON.parse(title);
		}
		if(typeof translatedTitle[lang] != 'undefined' && translatedTitle[lang].length > 0) {
			return translatedTitle[lang];
		}
		if(typeof translatedTitle.en != 'undefined' && translatedTitle.en.length > 0) {
			return translatedTitle.en;
		}
	}
	return key_name;
};

function translateRole (role) {
	let roleVar = JSON.parse(JSON.stringify(role));
	roleVar.translatedTitle = translateTitle(roleVar.title, roleVar.key_name);
	return roleVar;
};

export default {
	checkForApiErrors(data) {
		return data.hasOwnProperty('errors');
	},
	getApiErrorMessages(errors) {
		if (!Array.isArray(errors)) {
			return [];
		}

		let errorMessages = [];

		errors.forEach(error => {
			if (error.message) {
				errorMessages.push(error.message);
			}
		});

		return errorMessages;
	},
	makeFormDataIfFileExists(query, files = {}) {
		if (!Object.keys(files).length) {
			return { query };
		}

		let formData = new FormData();
		let operations = { query, variables: {} };
		let map = {};

		for (let key in files) {
			operations.variables[key] = null;
			map[key] = ['variables.' + key];
		}

		formData.append('operations', JSON.stringify(operations));
		formData.append('map', JSON.stringify(map));

		for (let key in files) {
			if (Array.isArray(files[key])) {
				let index = 0;

				files[key].forEach(file => {
					formData.append(key + '[' + index + ']', file);
					index++;
				});
			} else {
				formData.append(key, files[key]);
			}
		}

		return formData;
	},
	getUpdatedFields(oldData, updatedData) {
		let updatedFields = {};

		for (let key in updatedData) {
			if (typeof updatedData[key] != 'undefined' && oldData[key] != updatedData[key]) {
				updatedFields[key] = updatedData[key];
			}
		}

		return updatedFields;
	},
	convertFieldsToString(model, role = '', cleanNewLines = false) {
		let skipFields = [
			'model_name',
			'updated_at',
			'created_at',
			'fields_visibility',
			'fields_writeability'
		];
		if(role.length > 0) {
			if(typeof model.fields_writeability != 'undefined' && model.fields_writeability.length > 0) {
				model.fields_writeability.forEach(field => {
					let roles = field.roles.split(',');
					if (roles.length > 0) {
						if (roles.indexOf(role) === -1) {
							skipFields.push(field.field);
						}
					}
				});
			}
		}
		return Object.keys(model).filter(key => {
			return typeof model[key] !== 'object';
		}).map(key => {
			if (skipFields.indexOf(key) === -1) {
				return cleanNewLines === true && typeof(model[key].replace) === 'function' ? `${key}: "${model[key].replace(/(\r\n|\n|\r)/gm, "")}"` : `${key}: "${model[key]}"`;
			}
		}).join(', ');
	},
	displayProjectPriceRange($t, priceFrom, priceTo) {
		if (priceFrom && priceTo) {
			return `€ ${priceFrom} ${$t('dashboard.other.upTo')} € ${priceTo} ${$t('dashboard.other.perMeters')}`;
		} else {
			return `${$t('dashboard.other.moreThan')} € ${priceFrom} ${$t('dashboard.other.perMeters')}`;
		}
	},
	displayName(userData) {
		return userData.first_name + (userData.last_name ? ` ${userData.last_name}` : '');
	},
	parseUrlProtocol(url) {
		if (!url.match(/^https?:\/\//i)) {
			url = 'http://' + url;
		}

		return url;
	},
	canSee (moduleScope, to) {
		const gateAllowed = (has(moduleScope.$router.match(to).meta, 'model_name')
			&& has(moduleScope.$router.match(to).meta, 'permission')
			&& moduleScope.$Gate.allow(moduleScope.$router.match(to).meta.permission, moduleScope.$router.match(to).meta.model_name.toLowerCase()));

		return to ? (gateAllowed && moduleScope.$acl.check(moduleScope.$router.match(to).meta.rule)) : true;
	},
	processErrorMessage(message) {
		return message.split(' ').map(word => { return word.charAt(0).toUpperCase() + word.slice(1) }).join('')
	},
	parseGraphQLErrorValidatorMessages(errors) {
		if (!Array.isArray(errors)) {
			return [];
		}

		let errorMessages = [];

		errors.forEach(error => {
			if (error.extensions && error.extensions.validation) {
				let validationErrors = error.extensions.validation;

				for (let validationError in validationErrors) {
					validationErrors[validationError].forEach(message => {
						errorMessages.push(message);
					});
				}
			} else if (error.message) {
				errorMessages.push(error.message);
			}
		});

		return errorMessages;
	},

	parseGraphQLErrorDebugMessages(errors) {
		if (!Array.isArray(errors)) {
			return [];
		}

		let errorMessages = [];

		errors.forEach(error => {
			if (error.debugMessage) {
				errorMessages.push(error.debugMessage);
			}
		});

		return errorMessages;
	},

	translateRole,

	translateTitle,

	sortOptions (a, b){
		if (a.label.toLowerCase() < b.label.toLowerCase()) {
			return -1
		}
		if (a.label.toLowerCase() > b.label.toLowerCase()) {
			return 1
		}
		return 0;
	},

	switchDashboardLanguage(language, forceEvent = true)
	{
		let currentUser = JSON.parse(localStorage.getItem('userInfo'));

		if (currentUser) {
			currentUser.language = language;
			currentUser.language_id = typeof language.id != 'undefined' ? language.id : language.code;
		}

		localStorage.setItem('forcedLocale', JSON.stringify(language));
		localStorage.setItem('userInfo', JSON.stringify(currentUser));

		if (forceEvent) {
			window.dispatchEvent(new CustomEvent('userInfoChanged', {
				detail: {
					userInfo: currentUser
				}
			}));
		}
	},

	newsTranslate(phrase, language)
	{
		let translated = phrase;
		try {
			const parsedPhrase = JSON.parse("{" + phrase + '}');
			if (parsedPhrase[language]) {
				translated = parsedPhrase[language];
			} else if (parsedPhrase['en']) {
				translated = parsedPhrase['en'];
			} else if (parsedPhrase['de']) {
				translated = parsedPhrase['de'];
			} else {
				translated = phrase;
			}
		} catch($e) {
			translated = phrase;
		}
		return translated;
	}

}
