export default class NewsCategoryPolicy
{
    /**
     * Determine whether the user can view any models.
     *
     * @param  {object}  user
     * @return mixed
     */
    viewAny(user)
    {
        return (user.role.key_name === 'admin')
    }

    /**
     * Determine whether the user can view the model.
     *
     * @param  {object}  user
     * @param  {object}  newsCategory
     * @return {mixed}
     */
    view(user, newsCategory)
    {
        return true
    }

    /**
     * Determine whether the user can create models.
     *
     * @param  {object}  user
     * @return {mixed}
     */
    create(user)
    {
        return (user.role.key_name === 'admin')
    }

    /**
     * Determine whether the user can update the model.
     *
     * @param  {object}  user
     * @param  {object}  newsCategory
     * @return {mixed}
     */
    update(user, newsCategory)
    {
        return (user.role.key_name === 'admin')
    }

    /**
     * Determine whether the user can restore the model.
     *
     * @param  {object}  user
     * @param  {object}  newsCategory
     * @return {mixed}
     */
    restore(user, newsCategory)
    {
        return (user.role.key_name === 'admin')
    }

    /**
     * Determine whether the user can delete the model.
     *
     * @param  {object}  user
     * @param  {object}  newsCategory
     * @return {mixed}
     */
    delete(user, newsCategory)
    {
        return (user.role.key_name === 'admin')
    }

    /**
     * Determine whether the user can permanently delete the model.
     *
     * @param  {object}  user
     * @param  {object}  newsCategory
     * @return {mixed}
     */
    forceDelete(user, newsCategory)
    {
        return (user.role.key_name === 'admin')
    }

    /**
     * Determine whether the user can delete the model.
     *
     * @param  {object}  user
     * @return {mixed}
     */
    deleteAll(user)
    {
        return (user.role.key_name === 'admin')
    }
}
