/*=========================================================================================
  File Name: moduleSentEmailsActions.js
  Description: SentEmails Module Actions
==========================================================================================*/

import axios from '@/axios.js'
import helperFunctions from '@/helper/functions.js'
import apiQueries from './api/queries.js'

export default {
  fetchSentEmails ({commit}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getAllSentEmails()
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        const responseData = response.data.data.sentEmails
        commit('SET_SENT_EMAILS', responseData)
        resolve(responseData)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  fetchSentEmail ({commit}, projectId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getSentEmail(projectId)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        commit('SET_SENT_EMAIL', response.data.data.sentEmailById)
        resolve(response.data.data.sentEmailById)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  fetchFilteredSentEmails ({commit}, data) {
    const query = apiQueries.getFilteredSentEmails(data.page, data.search)

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors))
        }

        const responseData = response.data.data.filteredSentEmails
        resolve(responseData)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  fetchActivities ({commit}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getAllActivities()
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        const responseData = response.data.data.streamEvents
        commit('SET_ACTIVITIES', responseData)
        resolve(responseData)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  fetchFilteredActivities ({ commit }, data) {
    const query = apiQueries.getFilteredActivities(data.lastItem, data.search);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }

        const responseData = response.data.data.filteredStreamEvents;
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  },
}
