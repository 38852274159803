/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/auth/authService'
import has from 'lodash/has'
import store from './store/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        {
          path: '/',
          redirect: '/apps/dashboard'
        },
        {
          path: 'my-profile',
          name: 'app-my-profile',
          component: () => import('@/views/apps/user/MyProfile.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.user.editMyProfile',
            rule: 'staff',
            model_name: 'CompanyUser',
            permission: 'myProfile',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.user.editMyProfile', active: true }
            ]
          }
        },
        {
          path: 'callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'staff'
          }
        },
      ].map(route => {
        if (has(route, ['meta'])) {
          route.meta.authRequired = true
        }
        return route
      })
    },

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '/apps/',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: 'dashboard',
          name: 'page-dashboard',
          component: () => import('@/views/apps/dashboard/Dashboard.vue'),
          meta: {
            rule: 'staff',
            model_name: 'CompanyProject',
            permission: 'viewAny'
          }
        },
        {
          path: 'data-sync',
          name: 'page-data-sync',
          component: () => import('@/views/apps/data-sync/Sync.vue'),
          meta: {
            rule: 'admin',
            model_name: 'UserRole',
            permission: 'update'
          }
        },
        {
          path: 'email-notifications',
          name: 'page-email-notifications',
          component: () => import('@/views/apps/email-notifications/Notifications.vue'),
          meta: {
            rule: 'admin',
            model_name: 'UserRole',
            permission: 'update'
          }
        },
        {
          path: 'user/user-list',
          name: 'app-user-list',
          component: () => import('@/views/apps/user/user-list/UserList.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.user.userList',
            rule: 'staff',
            model_name: 'CompanyUser',
            permission: 'viewAny',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.user.userList', active: true }
            ]
          }
        },
        {
          path: 'user/user-view/:userId',
          name: 'app-user-view',
          component: () => import('@/views/apps/user/UserView.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.user.userView',
            rule: 'owner',
            model_name: 'CompanyUser',
            permission: 'view',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.Users', url: '/apps/user/user-list' },
              { title: 'dashboard.navmenu.titles.user.userView', active: true }
            ],
            parent: 'app-user-list'
          }
        },
        {
          path: 'user/user-edit/:userId',
          name: 'app-user-edit',
          component: () => import('@/views/apps/user/user-edit/UserEdit.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.user.userEdit',
            rule: 'owner',
            model_name: 'CompanyUser',
            permission: 'update',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.Users', url: '/apps/user/user-list' },
              { title: 'dashboard.navmenu.titles.user.userEdit', active: true }
            ],
            parent: 'app-user-list'
          }
        },
        {
          path: 'user/user-add',
          name: 'app-user-add',
          component: () => import('@/views/apps/user/UserAdd.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.user.addNewUser',
            rule: 'owner',
            model_name: 'CompanyUser',
            permission: 'create',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.Users', url: '/apps/user/user-list' },
              { title: 'dashboard.navmenu.titles.user.addNewUser', active: true }
            ],
            parent: 'app-user-list'
          }
        },
        {
          path: 'company/company-list',
          name: 'app-company-list',
          component: () => import('@/views/apps/company/company-list/CompanyList.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.company.companyList',
            rule: 'staff',
            model_name: 'Company',
            permission: 'viewAny',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.company.companyList', active: true }
            ]
          }
        },
        {
          path: 'company/company-edit/:companyId',
          name: 'app-company-edit',
          component: () => import('@/views/apps/company/CompanyEdit.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.company.companyEdit',
            rule: 'owner',
            model_name: 'Company',
            permission: 'update',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.Company', url: '/apps/company/company-list' },
              { title: 'dashboard.navmenu.titles.company.companyEdit', active: true }
            ],
            parent: 'app-company-list'
          }
        },
        {
          path: 'company/company-overview/:companyId',
          name: 'app-company-overview',
          component: () => import('@/views/apps/company/CompanyOverview.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.company.viewCompany',
            rule: 'staff',
            model_name: 'Company',
            permission: 'view',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.Company', url: '/apps/company/company-list' },
              { title: 'dashboard.navmenu.titles.company.viewCompany', active: true }
            ],
            parent: 'app-company-list'
          }
        },
        {
          path: 'company/company-add',
          name: 'app-company-add',
          component: () => import('@/views/apps/company/CompanyAdd.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.company.addNewCompany',
            rule: 'company_admin',
            model_name: 'Company',
            permission: 'create',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.Company', url: '/apps/company/company-list' },
              { title: 'dashboard.navmenu.titles.company.addNewCompany', active: true }
            ],
            parent: 'app-company-list'
          }
        },
        {
          path: 'service/service-list',
          name: 'app-service-list',
          component: () => import('@/views/apps/service/service-list/ServiceList.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.service.serviceList',
            rule: 'admin',
            model_name: 'Service',
            permission: 'viewAny',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.service.serviceList', active: true }
            ]
          }
        },
        {
          path: 'service/service-add',
          name: 'app-service-add',
          component: () => import('@/views/apps/service/ServiceAdd.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.service.addNewService',
            rule: 'admin',
            model_name: 'Service',
            permission: 'create',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.Services', url: '/apps/service/service-list' },
              { title: 'dashboard.navmenu.titles.service.addNewService', active: true }
            ],
            parent: 'app-service-list'
          }
        },
        {
          path: 'service/service-edit/:serviceId',
          name: 'app-service-edit',
          component: () => import('@/views/apps/service/ServiceEdit.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.service.editService',
            rule: 'admin',
            model_name: 'Service',
            permission: 'update',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.Services', url: '/apps/service/service-list' },
              { title: 'dashboard.navmenu.titles.service.editService', active: true }
            ],
            parent: 'app-service-list'
          }
        },
        {
          path: 'addon/addon-list',
          name: 'app-addon-list',
          component: () => import('@/views/apps/addon/addon-list/AddonList.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.addon.addonList',
            rule: 'admin',
            model_name: 'Addon',
            permission: 'viewAny',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.addon.addonList', active: true }
            ]
          }
        },
        {
          path: 'addon/addon-add',
          name: 'app-addon-add',
          component: () => import('@/views/apps/addon/AddonAdd.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.addon.addNewAddon',
            rule: 'admin',
            model_name: 'Addon',
            permission: 'create',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.Addons', url: '/apps/addon/addon-list' },
              { title: 'dashboard.navmenu.titles.addon.addNewAddon', active: true }
            ],
            parent: 'app-addon-list'
          }
        },
        {
          path: 'addon/addon-edit/:addonId',
          name: 'app-addon-edit',
          component: () => import('@/views/apps/addon/AddonEdit.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.addon.editAddon',
            rule: 'admin',
            model_name: 'Addon',
            permission: 'update',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.Addons', url: '/apps/addon/addon-list' },
              { title: 'dashboard.navmenu.titles.addon.editAddon', active: true }
            ],
            parent: 'app-addon-list'
          }
        },
        {
          path: 'marketplace/marketplace-list',
          name: 'app-marketplace-list',
          component: () => import('@/views/apps/marketplace/marketplace-list/MarketplaceList.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.marketplace.marketplaceList',
            rule: 'admin',
            model_name: 'Marketplace',
            permission: 'viewAny',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.marketplace.marketplaceList', active: true }
            ]
          }
        },
        {
          path: 'marketplace/marketplace-add',
          name: 'app-marketplace-add',
          component: () => import('@/views/apps/marketplace/MarketplaceAdd.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.marketplace.addNewMarketplace',
            rule: 'admin',
            model_name: 'Marketplace',
            permission: 'create',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.Marketplaces', url: '/apps/marketplace/marketplace-list' },
              { title: 'dashboard.navmenu.titles.marketplace.addNewMarketplace', active: true }
            ],
            parent: 'app-marketplace-list'
          }
        },
        {
          path: 'marketplace/marketplace-edit/:marketplaceId',
          name: 'app-marketplace-edit',
          component: () => import('@/views/apps/marketplace/marketplace-edit/MarketplaceEdit.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.marketplace.editMarketplace',
            rule: 'admin',
            model_name: 'Marketplace',
            permission: 'update',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.Marketplaces', url: '/apps/marketplace/marketplace-list' },
              { title: 'dashboard.navmenu.titles.marketplace.editMarketplace', active: true }
            ],
            parent: 'app-marketplace-list'
          }
        },
        {
          path: 'project/project-add',
          name: 'app-project-add',
          component: () => import('@/views/apps/project/project-wizard/ProjectWizard.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.project.addNewProject',
            rule: 'project_manager',
            model_name: 'CompanyProject',
            permission: 'create',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.Projects', url: '/apps/project/project-list' },
              { title: 'dashboard.navmenu.titles.project.addNewProject', active: true }
            ],
            parent: 'app-project-list'
          }
        },
        {
          path: 'project/project-list',
          name: 'app-project-list',
          component: () => import('@/views/apps/project/project-list/ProjectList.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.project.projectList',
            rule: 'project_manager',
            model_name: 'CompanyProject',
            permission: 'viewAny',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.project.projectList', active: true }
            ]
          }
        },
        {
          path: 'project/project-overview/:projectId',
          name: 'app-project-overview',
          component: () => import('@/views/apps/project/ProjectOverview.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.project.viewProject',
            rule: 'project_manager',
            model_name: 'CompanyProject',
            permission: 'view',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.Projects', url: '/apps/project/project-list' },
              { title: 'dashboard.navmenu.titles.project.viewProject', active: true }
            ],
            parent: 'app-project-list'
          }
        },
        {
          path: 'project/project-edit/:projectId',
          name: 'app-project-edit',
          component: () => import('@/views/apps/project/project-wizard/ProjectWizard.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.project.editProject',
            rule: 'project_manager',
            model_name: 'CompanyProject',
            permission: 'update',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.Projects', url: '/apps/project/project-list' },
              { title: 'dashboard.navmenu.titles.project.editProject', active: true }
            ],
            parent: 'app-project-list'
          }
        },
        // {
        //   path: '/apps/order/order-list',
        //   name: 'app-order-list',
        //   component: () => import('@/views/apps/billing/order/order-list/OrderList.vue'),
        //   meta: {
        //     pageTitle: 'dashboard.navmenu.titles.order.orderList',
        //     rule: 'staff',
        //     model_name: 'Order',
        //     permission: 'viewAny',
        //     breadcrumb: [
        //       { title: 'dashboard.navmenu.items.Home', url: '/' },
        //       { title: 'dashboard.navmenu.titles.order.orderList', active: true }
        //     ]
        //   }
        // },
        // {
        //   path: '/apps/order/order-overview/:orderId',
        //   name: 'app-order-overview',
        //   component: () => import('@/views/apps/billing/order/OrderOverview.vue'),
        //   meta: {
        //     pageTitle: 'dashboard.navmenu.titles.order.viewOrder',
        //     rule: 'staff',
        //     model_name: 'Order',
        //     permission: 'view',
        //     breadcrumb: [
        //       { title: 'dashboard.navmenu.items.Home', url: '/' },
        //       { title: 'dashboard.navmenu.items.Orders', url: '/apps/order/order-list' },
        //       { title: 'dashboard.navmenu.titles.order.viewOrder', active: true }
        //     ],
        //     parent: 'app-order-list'
        //   }
        // },
        // {
        //   path: '/apps/order/order-add',
        //   name: 'app-order-add',
        //   component: () => import('@/views/apps/billing/order/OrderAdd.vue'),
        //   meta: {
        //     pageTitle: 'dashboard.navmenu.titles.order.addNewOrder',
        //     rule: 'staff',
        //     model_name: 'Order',
        //     permission: 'create',
        //     breadcrumb: [
        //       { title: 'dashboard.navmenu.items.Home', url: '/' },
        //       { title: 'dashboard.navmenu.items.Orders', url: '/apps/order/order-list' },
        //       { title: 'dashboard.navmenu.titles.order.addNewOrder', active: true }
        //     ],
        //     parent: 'app-order-list'
        //   }
        // },
        // {
        //   path: '/apps/order/order-edit/:orderId',
        //   name: 'app-order-edit',
        //   component: () => import('@/views/apps/billing/order/OrderEdit.vue'),
        //   meta: {
        //     pageTitle: 'dashboard.navmenu.titles.order.editOrder',
        //     rule: 'staff',
        //     model_name: 'Order',
        //     permission: 'update',
        //     breadcrumb: [
        //       { title: 'dashboard.navmenu.items.Home', url: '/' },
        //       { title: 'dashboard.navmenu.items.Orders', url: '/apps/order/order-list' },
        //       { title: 'dashboard.navmenu.titles.order.editOrder', active: true }
        //     ],
        //     parent: 'app-order-list'
        //   }
        // },

        // =============================================================================
        // Pages Routes
        // =============================================================================
        // {
        //   path: '/pages/profile',
        //   name: 'page-profile',
        //   component: () => import('@/views/pages/Profile.vue'),
        //   meta: {
        //     pageTitle: 'dashboard.navmenu.titles.other.Profile',
        //     rule: 'staff'
        //   }
        // },
        {
          path: 'pages/user-settings',
          name: 'page-user-settings',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.other.Settings',
            rule: 'staff'
          }
        },
        {
          path: 'admin/roles-list',
          name: 'page-admin-roles',
          component: () => import('@/views/apps/admin/Roles.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.other.Roles',
            rule: 'admin',
            model_name: 'UserRole',
            permission: 'update',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.other.Roles', active: true }
            ]
          }
        },
        {
          path: 'admin/identity-providers',
          name: 'page-admin-identity-providers',
          component: () => import('@/views/apps/admin/IdentityProviders.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.other.IdentityProviders',
            rule: 'admin',
            model_name: 'IdentityProvider',
            permission: 'update',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.other.IdentityProviders', active: true }
            ]
          }
        },
        {
          path: 'admin/dictionaries',
          name: 'page-admin-dictionaries',
          component: () => import('@/views/apps/admin/Dictionaries.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.other.Dictionaries',
            rule: 'admin',
            model_name: 'Dictionary',
            permission: 'update',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.other.Dictionaries', active: true }
            ]
          }
        },
        {
          path: 'reports/overview',
          name: 'page-reports-overview',
          component: () => import('@/views/apps/reports/Overview.vue'),
          meta: {
            rule: 'staff',
            model_name: 'CompanyProject',
            permission: 'viewAny',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.Reports', active: true }
            ]
          }
        },
        {
          path: 'helpcenter/faq',
          name: 'page-helpcenter-faq',
          beforeEnter() {
            store.dispatch('events/logEvent', {
              app_key_name: 'cd',
              object_key_name: 'action',
              event_key_name: 'open_faq',
              context: {
                user_id: auth.getGate().user.id
              }
            });
            window.open('https://helpcenter.propster.tech/hc/de', '_blank');
          },
          meta: {
            rule: 'staff',
            model_name: 'CompanyUser',
            permission: 'view',
          }
        },
        {
          path: 'helpcenter/privacy-policy',
          name: 'page-helpcenter-privacy-policy',
          component: () => import('@/views/apps/helpcenter/PrivacyPolicy.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.other.PrivacyPolicy',
            rule: 'staff',
            model_name: 'CompanyUser',
            permission: 'view',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.other.PrivacyPolicy', active: true }
            ]
          }
        },
        {
          path: 'file/:fileId',
          name: 'page-view-file',
          component: () => import('@/views/apps/file/ViewFile.vue'),
          meta: {
            rule: 'staff',
            model_name: 'File',
            permission: 'view'
          }
        },
        {
          path: 'admin/email/log',
          name: 'page-admin-email-log',
          component: () => import('@/views/apps/stream/email/Log.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.other.EmailLog',
            rule: 'admin',
            model_name: 'SentEmail',
            permission: 'viewAny',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.other.EmailLog', active: true }
            ]
          }
        },
        {
          path: 'admin/activity/log',
          name: 'page-admin-activity-log',
          component: () => import('@/views/apps/stream/activity/Log.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.other.ActivityLog',
            rule: 'staff',
            model_name: 'Activity',
            permission: 'viewAny',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.other.ActivityLog', active: true }
            ]
          }
        },
        {
          path: 'admin/api-users',
          name: 'app-admin-api-user-list',
          component: () => import('@/views/apps/admin/api-user/user-list/UserList.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.admin.apiUsers',
            rule: 'admin',
            model_name: 'ApiUser',
            permission: 'viewAny',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.admin.apiUsers', active: true }
            ]
          }
        },
        {
          path: 'admin/api-users/edit/:userId',
          name: 'app-admin-api-user-edit',
          component: () => import('@/views/apps/admin/api-user/user-edit/UserEdit.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.admin.apiUserEdit',
            rule: 'admin',
            model_name: 'ApiUser',
            permission: 'update',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.admin.apiUsers', url: '/apps/admin/api-users' },
              { title: 'dashboard.navmenu.titles.admin.apiUserEdit', active: true }
            ],
            parent: 'app-admin-api-user-list'
          }
        },
        {
          path: 'admin/api-users/add',
          name: 'app-admin-api-user-add',
          component: () => import('@/views/apps/admin/api-user/UserAdd.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.admin.apiUserAdd',
            rule: 'admin',
            model_name: 'ApiUser',
            permission: 'create',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.admin.apiUsers', url: '/apps/admin/api-users' },
              { title: 'dashboard.navmenu.titles.admin.apiUserAdd', active: true }
            ],
            parent: 'app-admin-api-user-list'
          }
        },
        {
          path: 'news/news-add',
          name: 'app-news-add',
          component: () => import('@/views/apps/news/Add.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.news.addNews',
            rule: 'project_manager',
            model_name: 'News',
            permission: 'create',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.News', url: '/apps/news' },
              { title: 'dashboard.navmenu.titles.news.addNews', active: true }
            ],
            parent: 'app-news'
          }
        },
        {
          path: 'news',
          name: 'app-news',
          component: () => import('@/views/apps/news/News.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.news.viewNews',
            rule: 'staff',
            model_name: 'News',
            permission: 'viewAny',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.news.viewNews', active: true }
            ]
          }
        },
        {
          path: 'news/:newsRestriction',
          name: 'app-news-restricted',
          component: () => import('@/views/apps/news/News.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.news.viewNews',
            rule: 'project_manager',
            model_name: 'News',
            permission: 'viewAny',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.titles.news.viewNews', active: true }
            ],
            parent: 'app-news'
          }
        },
        {
          path: 'news/news-edit/:newsItemId',
          name: 'app-news-edit',
          component: () => import('@/views/apps/news/Edit.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.news.editNews',
            rule: 'project_manager',
            model_name: 'News',
            permission: 'update',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.News', url: '/apps/news' },
              { title: 'dashboard.navmenu.titles.news.editNews', active: true }
            ],
            parent: 'app-news'
          }
        },
        {
          path: 'news/:newsItemId',
          name: 'app-news-read',
          component: () => import('@/views/apps/news/Read.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.news.readNews',
            rule: 'staff',
            model_name: 'News',
            permission: 'view',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.News', url: '/apps/news' },
              { title: 'dashboard.navmenu.titles.news.readNews', active: true }
            ],
            parent: 'app-news'
          }
        },
        {
          path: 'news-categories',
          name: 'app-news-categories',
          component: () => import('@/views/apps/news/categories.vue'),
          meta: {
            pageTitle: 'dashboard.navmenu.titles.news.categories',
            rule: 'project_manager',
            model_name: 'NewsCategory',
            permission: 'viewAny',
            breadcrumb: [
              { title: 'dashboard.navmenu.items.Home', url: '/' },
              { title: 'dashboard.navmenu.items.News', url: '/apps/news' },
              { title: 'dashboard.navmenu.titles.news.categories', active: true }
            ],
            parent: 'app-news'
          }
        }
      ].map(route => {
        if (has(route, ['meta'])) {
          route.meta.authRequired = true
        }
        return route
      })
    },
    {
      // =============================================================================
      // CUSTOMER PLATFORM EDITOR ROUTES
      // =============================================================================
      path: '/pm/',
      component: () => import('./layouts/main/Project.vue'),
      children: [
        {
          path: '',
          name: 'pm-dashboard',
          component: () => import('@/views/pm/Dashboard.vue'),
          meta: {
            pageTitle: '',
            rule: 'project_manager',
            model_name: 'CompanyProject',
            permission: 'view'
          }
        },
        {
          path: ':projectId/overview',
          name: 'pm-project-overview',
          component: () => import('@/views/pm/project/Overview.vue'),
          meta: {
            pageTitle: '',
            rule: 'project_manager',
            model_name: 'CompanyProject',
            permission: 'view',
            // breadcrumb: [
            //   { title: 'pm.navmenu.items.overview', active: true }
            // ]
          }
        },
        {
          path: 'settings',
          name: 'pm-settings',
          component: () => import('@/views/pm/Dashboard.vue'),
          meta: {
            pageTitle: '',
            rule: 'project_manager',
            model_name: 'CompanyProject',
            permission: 'update',
            breadcrumb: [
              { title: 'pm.navmenu.items.home', url: '/pm/' },
              { title: 'pm.navmenu.items.settings' },
              { title: 'pm.navmenu.items.general', active: true }
            ]
          }
        },
        {
          path: ':projectId/settings/general',
          name: 'pm-settings-general',
          component: () => import('@/views/pm/settings/General.vue'),
          meta: {
            pageTitle: '',
            rule: 'project_manager',
            model_name: 'CompanyProject',
            permission: 'update',
            parent: 'pm-settings',
            breadcrumb: [
              { title: 'pm.navmenu.items.home', url: '/pm/' },
              { title: 'pm.navmenu.items.settings' },
              { title: 'pm.navmenu.items.general', active: true }
            ]
          }
        },
        {
          path: ':projectId/settings/home',
          name: 'pm-settings-homepage',
          component: () => import('@/views/pm/settings/Homepage.vue'),
          meta: {
            pageTitle: '',
            rule: 'project_manager',
            model_name: 'CompanyProject',
            permission: 'update',
            parent: 'pm-settings',
            canBeHidden: true,
            breadcrumb: [
              { title: 'pm.navmenu.items.home', url: '/pm/' },
              { title: 'pm.navmenu.items.settings', url: '/pm/settings' },
              { title: 'pm.navmenu.items.homepage', active: true }
            ]
          }
        },
        {
          path: ':projectId/settings/design',
          name: 'pm-settings-design',
          component: () => import('@/views/pm/settings/Design.vue'),
          meta: {
            pageTitle: '',
            rule: 'project_manager',
            model_name: 'CompanyProject',
            permission: 'update',
            parent: 'pm-settings',
            canBeHidden: true,
            breadcrumb: [
              { title: 'pm.navmenu.items.home', url: '/pm/' },
              { title: 'pm.navmenu.items.settings', url: '/pm/settings' },
              { title: 'pm.navmenu.items.design', active: true }
            ]
          }
        },
        {
          path: ':projectId/settings/features',
          name: 'pm-settings-features',
          component: () => import('@/views/pm/settings/Features.vue'),
          meta: {
            pageTitle: '',
            rule: 'project_manager',
            model_name: 'CompanyProject',
            permission: 'update',
            parent: 'pm-settings',
            canBeHidden: true,
            breadcrumb: [
              { title: 'pm.navmenu.items.home', url: '/pm/' },
              { title: 'pm.navmenu.items.settings', url: '/pm/settings' },
              { title: 'pm.navmenu.items.features', active: true }
            ]
          }
        }
      ].map(route => {
        if (has(route, ['meta'])) {
          route.meta.authRequired = true
        }
        return route
      })
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/auth/Login.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/logout',
          name: 'page-logout',
          component: () => import('@/views/pages/auth/Logout.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/register',
          name: 'page-register',
          component: () => import('@/views/pages/register/Register.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/set-password',
          name: 'page-set-password',
          component: () => import('@/views/pages/SetPassword.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/lock-screen',
          name: 'page-lock-screen',
          component: () => import('@/views/pages/LockScreen.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/comingsoon',
          name: 'page-coming-soon',
          component: () => import('@/views/pages/ComingSoon.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'staff'
          }
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'staff'
          }
        },
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.authRequired) {
    auth.isAuthenticatedPromise()
      .then(() => {
        if (auth.getGate().allow(to.meta.permission, to.meta.model_name.toLowerCase())) {
          return next()
        } else {
          router.push({ path: '/pages/not-authorized'})
        }
      })
      .catch((e) => {
        // console.log(e);
        router.push({ path: '/pages/login', query: { to: to.path } })
      })
  } else {
    return next()
  }
})

export default router
