/*=========================================================================================
  File Name: moduleProjects.js
  Description: Projects Module
==========================================================================================*/

import state from './moduleProjectsState.js'
import mutations from './moduleProjectsMutations.js'
import actions from './moduleProjectsActions.js'
import getters from './moduleProjectsGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

