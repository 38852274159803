import EventEmitter from 'events'
import Gate from '@/../policies/Gate'
import store from '@/store/store.js'
import has from 'lodash/has'

// 'loggedIn' is used in other parts of application. So, Don't forget to change there also
const localStorageKey = 'loggedIn'

const tokenExpiryKey = 'tokenExpiry'
const loginEvent = 'loginEvent'


class AuthService extends EventEmitter {
    idToken = null;
    profile = null;
    tokenExpiry = null;

    isAuthenticated () {
      return (
        new Date(Date.now()) < new Date(localStorage.getItem(tokenExpiryKey)) &&
            localStorage.getItem(localStorageKey) === 'true'
      )
    }

    isAuthenticatedPromise () {
      return new Promise((resolve, reject) => {
        const authenticated = (
          new Date(Date.now()) < new Date(localStorage.getItem(tokenExpiryKey)) &&
          localStorage.getItem(localStorageKey) === 'true'
        )
        if (!authenticated) {
          const refreshToken = localStorage.getItem('refreshToken')
          if (refreshToken) {
            const payload = {
              refreshToken: localStorage.getItem('refreshToken')
            }
            store.dispatch('auth/refreshAccessToken', payload)
              .then(() => {
                if (
                  new Date(Date.now()) < new Date(localStorage.getItem(tokenExpiryKey)) &&
                  localStorage.getItem(localStorageKey) === 'true'
                ) {
                  resolve(true)
                } else {
                  reject(false)
                }
              })
              .catch(error => {
                store.dispatch('auth/logout')
                reject(error)
              })
          } else {
            reject(false)
          }
        } else {
          resolve(true)
        }
      })
    }

    getAuth () {
      return JSON.parse(localStorage.getItem('userInfo'))
    }

    getGate (authData) {
      if (typeof authData === 'object' && has(authData, 'userRole')) {
        return new Gate(authData)
      }
      return new Gate(this.getAuth())
    }
}

export default new AuthService()
