/*=========================================================================================
  File Name: moduleProjects.js
  Description: News Module
==========================================================================================*/

import state from './moduleNewsState.js'
import mutations from './moduleNewsMutations.js'
import actions from './moduleNewsActions.js'
import getters from './moduleNewsGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

