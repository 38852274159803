/*=========================================================================================
  File Name: moduleCompaniesActions.js
  Description: Companies Module Actions
==========================================================================================*/

import axios from '@/axios.js';
import helperFunctions from '@/helper/functions.js';
import apiQueries from './api/queries.js';

export default {
  fetchCompanies ({ commit }) {
    const query = apiQueries.getAllCompanies();

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.companies;
          commit('SET_COMPANIES', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchCompaniesList ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getAllCompaniesList()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.companies;
          commit('SET_COMPANIES_LIST', responseData)
          resolve(responseData)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchFilteredCompanies ({ commit }, data) {
    const query = apiQueries.getFilteredCompanies(data.page, data.items_per_page, data.search);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.filteredCompanies;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchParentCompaniesWithChilds ({ commit }) {
    const query = apiQueries.getAllParentCompanies();

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.parentCompanies;
          commit('SET_PARENT_COMPANIES_WITH_CHILDS', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchParentCompanies ({ commit }) {
    const query = apiQueries.getAllCompanies();

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.companies;
          commit('SET_PARENT_COMPANIES', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchCompany (context, companyId) {
    const query = apiQueries.getCompanyById(companyId);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.companyById;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchAdminCompany (context, companyId) {
    const query = apiQueries.getAdminCompanyById(companyId);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.companyById;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchUserMarketplaceCompanies (context, userId) {
    const query = apiQueries.getUserMarketplaceCompanies(userId);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.userMarketplaceCompanies;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  createCompany({ commit }, companyData) {
    const query = apiQueries.createCompany(companyData);
    const files = companyData.logo ? {logo: companyData.logo} : {};
    const data = helperFunctions.makeFormDataIfFileExists(query, files);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', data)
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.parseGraphQLErrorValidatorMessages(response.data.errors));
          }

          const responseData = response.data.data.createCompany;
          commit('ADD_NEW_COMPANY', [responseData]);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  updateCompany({ commit }, companyData) {
    const query = apiQueries.updateCompany(companyData.id, companyData.updatedFields);
    const files = companyData.logo ? {logo: companyData.logo} : {};
    const data = helperFunctions.makeFormDataIfFileExists(query, files);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', data)
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.parseGraphQLErrorValidatorMessages(response.data.errors));
          }

          const responseData = response.data.data.updateCompany;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  pappApiUpdate ({ commit }, companyId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.pappApiUpdate(companyId)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }
        const responseData = response.data.data.response;
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  },
  deleteCompany ({ commit }, companyId) {
    const query = apiQueries.deleteCompany(companyId);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.deleteCompany;
          commit('DELETE_COMPANY', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  bulkDeleteCompanies ({ commit }, companyIds) {
    const query = apiQueries.bulkDeleteCompanies(companyIds);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.bulkDeleteCompanies;
          commit('BULK_DELETE_COMPANIES', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  associateCompaniesToMarketplaces ({ commit }, marketplaceData) {
    const query = apiQueries.associateCompaniesToMarketplaces(marketplaceData.ids, marketplaceData.companyIds, marketplaceData.parentCompanyID);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }

        const responseData = response.data.data.associateCompaniesToMarketplace;
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  }
}
