/*=========================================================================================
  File Name: moduleRolesPermissionsActions.js
  Description: RolesPermissions Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js';
import helperFunctions from '@/helper/functions.js';
import apiQueries from './api/queries.js';

export default {
  fetchRolesPermissions ({ commit }) {
    const query = apiQueries.getAllRolesPermissions();

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.userRoles;
          commit('SET_ROLES', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchMarketplaceRoles ({ commit }) {
    const query = apiQueries.getMarketplaceRoles();

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.marketplaceRoles;
          commit('SET_MARKETPLACE_ROLES', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchUserMarketplaceRolesStructure ({ commit }, userId) {
    const query = apiQueries.getUserMarketplaceRolesStructure(userId);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.userMarketplaceRolesStructure;
          commit('SET_USER_MARKETPLACE_ROLES_STRUCTURE', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    })
  },
  updateUserMarketplaceRolesStructure ({ commit }, data) {
    const query = apiQueries.updateUserMarketplaceRolesStructure(data.id, data.rolesStructure);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.updateUserMarketplaceRolesStructure;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  }
}
