export default class OrderPolicy
{
    
    orderPermission (user, order, permission) {
        if(user.role.key_name === 'admin') {
            return true
        }

        if (
         user.role.company_restricted
         && order
         && order.company_id
         && user.user_companies.indexOf(parseInt(order.company_id)) == -1
        ) {
            return false
        }

        if (!user.aclPermissions[permission]) {
            return false
        }

        return true;
    }
    
    /**
     * Determine whether the user can view any models.
     *
     * @param  {object}  user
     * @return mixed
     */
    viewAny(user)
    {
        if(user.role.key_name === 'admin') {
            return true
        }
        return user.aclPermissions.view_orders
    }

    /**
     * Determine whether the user can view the model.
     *
     * @param  {object}  user
     * @param  {object}  order
     * @return {mixed}
     */
    view(user, order)
    {
        return this.orderPermission(user, order, 'view_orders')
    }

    /**
     * Determine whether the user can create models.
     *
     * @param  {object}  user
     * @return {mixed}
     */
    create(user)
    {
        if(user.role.key_name === 'admin') {
            return true
        }
        return user.aclPermissions.add_orders
    }

    /**
     * Determine whether the user can update the model.
     *
     * @param  {object}  user
     * @param  {object}  order
     * @return {mixed}
     */
    update(user, order)
    {
        return this.orderPermission(user, order, 'edit_orders')
    }

    /**
     * Determine whether the user can restore the model.
     *
     * @param  {object}  user
     * @param  {object}  order
     * @return {mixed}
     */
    restore(user, order)
    {
        return this.orderPermission(user, order, 'delete_orders')
    }

    /**
     * Determine whether the user can delete the model.
     *
     * @param  {object}  user
     * @param  {object}  order
     * @return {mixed}
     */
    delete(user, order)
    {
        return this.orderPermission(user, order, 'delete_orders')
    }

    /**
     * Determine whether the user can permanently delete the model.
     *
     * @param  {object}  user
     * @param  {object}  order
     * @return {mixed}
     */
    forceDelete(user, order)
    {
        return this.orderPermission(user, order, 'delete_orders')
    }

    /**
     * Determine whether the user can delete the model.
     *
     * @param  {object}  user
     * @return {mixed}
     */
    deleteAll(user)
    {
        return (user.role.key_name === 'admin')
    }
}
