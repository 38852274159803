export default class ActivityPolicy
{
    /**
     * Determine whether the user can view any models.
     *
     * @param  {object}  user
     * @return mixed
     */
    viewAny(user)
    {
        if(user.role.key_name === 'admin') {
            return true
        }
        return user.aclPermissions.view_activity
    }

    /**
     * Determine whether the user can view the model.
     *
     * @param  {object}  user
     * @param  {object}  activity
     * @return {mixed}
     */
    view(user, activity)
    {
        return false
    }

    /**
     * Determine whether the user can create models.
     *
     * @param  {object}  user
     * @return {mixed}
     */
    create(user)
    {
        return false
    }

    /**
     * Determine whether the user can update the model.
     *
     * @param  {object}  user
     * @param  {object}  activity
     * @return {mixed}
     */
    update(user, activity)
    {
        return false
    }

    /**
     * Determine whether the user can restore the model.
     *
     * @param  {object}  user
     * @param  {object}  activity
     * @return {mixed}
     */
    restore(user, activity)
    {
        return false
    }

    /**
     * Determine whether the user can delete the model.
     *
     * @param  {object}  user
     * @param  {object}  activity
     * @return {mixed}
     */
    delete(user, activity)
    {
        return false
    }

    /**
     * Determine whether the user can permanently delete the model.
     *
     * @param  {object}  user
     * @param  {object}  activity
     * @return {mixed}
     */
    forceDelete(user, activity)
    {
        return false
    }
}
