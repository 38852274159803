/*=========================================================================================
  File Name: moduleCountriesMutations.js
  Description: Countries Module Mutations
==========================================================================================*/


export default {
  SET_COUNTRIES (state, countries) {
    state.countries = countries
  }
}
