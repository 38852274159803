/*=========================================================================================
  File Name: moduleUserManagementActions.js
  Description: User Management Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js';
import helperFunctions from '@/helper/functions.js';
import apiQueries from './api/queries.js';

export default {
  addNewUser ({ commit }, userData) {
    const query = apiQueries.addNewUser(userData);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors);
          }

          const responseData = response.data.data.createUser;
          commit('ADD_NEW_USER', [responseData]);
          resolve(responseData);
        })
        .catch((error) => {
            reject(error)
        })
    });
  },
  fetchFilteredUsers ({ commit }, data) {
    const query = apiQueries.getFilteredUsers(data.page, data.items_per_page, data.app_id, data.marketplace_id, data.company_id, data.project_id, data.role, data.is_dashboard, data.search);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.filteredUsers;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchAvailableUserFiltersData ({ commit }, data) {
    const query = apiQueries.fetchAvailableUserFiltersData(data.app_id, data.marketplace_id, data.company_id, data.project_id, data.role, data.is_dashboard, data.search);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.availableUserFiltersData;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  updateUser ({ commit }, userData) {
    const query = apiQueries.updateUser(userData.id, userData.updatedFields);
    const files = userData.updatedFields.avatar ? {avatar: userData.updatedFields.avatar} : {};
    const data = helperFunctions.makeFormDataIfFileExists(query, files);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', data)
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors);
          }

          const responseData = response.data.data.updateUser;
          //commit('ADD_NEW_USER', [responseData]);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  updateCurrentUser ({ commit }, userData) {
    const query = apiQueries.updateCurrentUser(userData.id, userData.updatedFields);
    const files = userData.updatedFields.avatar ? {avatar: userData.updatedFields.avatar} : {};
    const data = helperFunctions.makeFormDataIfFileExists(query, files);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', data)
        .then((response) => {
            if (helperFunctions.checkForApiErrors(response.data)) {
                return reject(response.data.errors);
            }

            const responseData = response.data.data.updateUser;
            //commit('ADD_NEW_USER', [responseData]);
            resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchUsers ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getUsers()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors))
          }

          commit('SET_USERS', response.data.data.users)
          resolve(response.data.data.users)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchUser (context, userId) {
    const query = apiQueries.getUserById(userId);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.userById;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchCurrentUser (context, userId) {
    return new Promise((resolve, reject) => {
        axios.post('/graphql', {
            query: apiQueries.getCurrentUserById(userId)
        })
            .then((response) => {
                if (helperFunctions.checkForApiErrors(response.data)) {
                    return reject(helperFunctions.getApiErrorMessages(response.data.errors));
                }

                const responseData = response.data.data.userById;
                resolve(responseData);
            })
            .catch((error) => { reject(error) })
    });
  },
  fetchFile (context, fileId) {
    return new Promise((resolve, reject) => {
      axios.get('/api/getFileById/' + fileId, {responseType: 'blob'})
        .then((response) => {
          resolve(response);
        })
        .catch((error) => { reject(error) })
    });
  },
  downloadFile (context, data) {
    let url = '/api/getFileById/' + data.id + '?token=' + data.token + '&download=1';

    return new Promise((resolve, reject) => {
      axios.get(url, {responseType: 'blob'})
        .then((response) => {
          resolve(response);
        })
        .catch((error) => { reject(error) })
    });
  },
  removeRecord ({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.deleteUser(userId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors))
          }
          commit('REMOVE_RECORD', response.data.data.deleteUser.id)
          resolve(response.data.data.deleteUser)
        })
        .catch((error) => { reject(error) })
    })
  }
}
