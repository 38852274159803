export default class CompanyUserPolicy
{
    userPermission (user, companyuser, permission) {
        if(user.role.key_name === 'admin') {
            return true;
        }
        if (
          companyuser !== null &&
          typeof companyuser.role !== 'undefined' &&
          typeof companyuser.user_companies !== 'undefined'
        ) {
            if (
             user.role.company_restricted &&
             !companyuser.role.company_restricted
            ) {
                return false;
            }
            if (
             user.role.company_restricted &&
             companyuser.user_companies.filter(function (n) {
                 return user.user_companies.indexOf(n) !== -1;
             }).length == 0
            ) {
                return false;
            }
        }
        return user.aclPermissions[permission];
    }
    /**
     * Determine whether the user can view any models.
     *
     * @param  {object}  user
     * @return mixed
     */
    viewAny(user)
    {
        return user.aclPermissions.view_users
    }

    /**
     * Determine whether the user can view the model.
     *
     * @param  {object}  user
     * @param  {object}  companyuser
     * @return {mixed}
     */
    view(user, companyuser)
    {
        return user.aclPermissions.view_users
    }

    /**
     * Determine whether the user can create models.
     *
     * @param  {object}  user
     * @return {mixed}
     */
    create(user)
    {
        if(user.role.key_name === 'admin') {
            return true
        }
        return user.aclPermissions.add_users
    }

    /**
     * Determine whether the user can update the model.
     *
     * @param  {object}  user
     * @param  {object}  companyuser
     * @return {mixed}
     */
    update(user, companyuser)
    {
        return this.userPermission(user, companyuser, 'edit_users')
    }

    /**
     * Determine whether the user can restore the model.
     *
     * @param  {object}  user
     * @param  {object}  companyuser
     * @return {mixed}
     */
    restore(user, companyuser)
    {
        return this.userPermission(user, companyuser, 'delete_users')
    }

    /**
     * Determine whether the user can delete the model.
     *
     * @param  {object}  user
     * @param  {object}  companyuser
     * @return {mixed}
     */
    delete(user, companyuser)
    {
        return this.userPermission(user, companyuser, 'delete_users')
    }

    /**
     * Determine whether the user can permanently delete the model.
     *
     * @param  {object}  user
     * @param  {object}  companyuser
     * @return {mixed}
     */
    forceDelete(user, companyuser)
    {
        return this.userPermission(user, companyuser, 'delete_users')
    }

    modifyAppAndMarketplaceLevelRoles (user, companyuser)
    {
        return this.update(user, companyuser);
    }

    editUserRole(user, companyuser)
    {
        if(user.role.key_name !== 'admin') {
            if(user.role.company_restricted) {
                if(!companyuser.role.company_restricted) {
                    return false;
                }
            }
        }
        return true;
    }

    /**
     * Determine whether the user can manage his profile
     *
     * @param  {object}  user
     * @param  {object}  companyuser
     * @return {boolean}
     */
    myProfile(user, companyuser)
    {
        return true;
    }
}
