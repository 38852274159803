/*=========================================================================================
  File Name: moduleProjectsState.js
  Description: Projects Module State
==========================================================================================*/

export default {
  newsItem: {},
  newsAuthor: {},
  newsItemTags: [],
  news: [],
  latestNews: [],
  newsCategories: [],
  newsStatuses: [],
  newTags: []
}
