const requestStatusFields = () => {
	return `
		id
		name
		key_name
		created_at
		updated_at
		entries: statsSwmRequests {
			id
			company_id
			project_id
			order_id
		}`;
};

const defectStatusFields = () => {
	return `
		id
		name
		key_name
		created_at
		updated_at
		entries: statsDefects {
			id
			company_id
			project_id
			order_id
		}`;
};

const defectDefectItemCategoryFields = () => {
	return `
		id
		name
		key_name
		created_at
		updated_at
		entries: statsDefectItemsDefectItemCategories {
			stats_defect_item_id
			stats_defect_category_id
		}`;
};

const swmProductCategoryFields = () => {
	return `
		id
		name
		key_name
		created_at
		updated_at
		entries: statsSwmProductsProductCategories {
			stats_swm_product_id
			stats_swm_product_category_id
		}`;
};

const ticketStatusFields = () => {
	return `
		id
		name
		key_name
		created_at
		updated_at
		entries: statsTickets {
			id
		}`;
};

export default {

	fetchFilteredRequestStatusList(page, search = '') {
		return `
			{
				filteredStatsRequestStatuses(page: ${page}, search: "${search}") {
					${requestStatusFields()}
				}
			}
		`;
	},
	
	getAllDetailedRequestStatuses() {
		return `
			{
				statsRequestStatuses{
					${requestStatusFields()}
				}
			}`;
	},

	createRequestStatus (data) {
		return `
		 mutation {
			createRequestStatus(key_name:"${data.key_name}", name:${data.name}) {
			 ${requestStatusFields()}
			}
		 }`;
	},

	updateRequestStatus (data) {
		let updateName = data.name ? `name:${data.name},` : ``;
		let updateKeyName = data.key_name ? `key_name:"${data.key_name}",` : ``;
		return `
		 mutation {
			updateRequestStatus(id:${data.id}, ${updateKeyName}, ${updateName}) {
			 ${requestStatusFields()}
			}
		 }`;
	},

	deleteRequestStatus (id) {
		return `
		 mutation {
			deleteRequestStatus(id: ${id}) {
			 ${requestStatusFields()}
			}
		 }`;
	},

	fetchFilteredDefectStatusList(page, search = '') {
		return `
			{
				filteredStatsDefectStatuses(page: ${page}, search: "${search}") {
					${defectStatusFields()}
				}
			}
		`;
	},

	getAllDetailedDefectStatuses() {
		return `
			{
				statsDefectStatuses{
					${defectStatusFields()}
				}
			}`;
	},

	createDefectStatus (data) {
		return `
		 mutation {
			createDefectStatus(key_name:"${data.key_name}", name:${data.name}) {
			 ${defectStatusFields()}
			}
		 }`;
	},

	updateDefectStatus (data) {
		let updateName = data.name ? `name:${data.name},` : ``;
		let updateKeyName = data.key_name ? `key_name:"${data.key_name}",` : ``;
		return `
		 mutation {
			updateDefectStatus(id:${data.id}, ${updateKeyName}, ${updateName}) {
			 ${defectStatusFields()}
			}
		 }`;
	},

	deleteDefectStatus (id) {
		return `
		 mutation {
			deleteDefectStatus(id: ${id}) {
			 ${defectStatusFields()}
			}
		 }`;
	},

	fetchFilteredDefectItemCategoryList(page, search = '') {
		return `
			{
				filteredStatsDefectItemCategories(page: ${page}, search: "${search}") {
					${defectDefectItemCategoryFields()}
				}
			}
		`;
	},

	getAllDetailedDefectItemCategories() {
		return `
			{
				statsDefectItemCategories{
					${defectDefectItemCategoryFields()}
				}
			}`;
	},

	createDefectItemCategory (data) {
		return `
		 mutation {
			createDefectItemCategory(key_name:"${data.key_name}", name:${data.name}) {
			 ${defectDefectItemCategoryFields()}
			}
		 }`;
	},

	updateDefectItemCategory (data) {
		let updateName = data.name ? `name:${data.name},` : ``;
		let updateKeyName = data.key_name ? `key_name:"${data.key_name}",` : ``;
		return `
		 mutation {
			updateDefectItemCategory(id:${data.id}, ${updateKeyName}, ${updateName}) {
			 ${defectDefectItemCategoryFields()}
			}
		 }`;
	},

	deleteDefectItemCategory (id) {
		return `
		 mutation {
			deleteDefectItemCategory(id: ${id}) {
			 ${defectDefectItemCategoryFields()}
			}
		 }`;
	},

	fetchFilteredSwmProductCategoryList(page, search = '') {
		return `
			{
				filteredStatsSwmProductCategories(page: ${page}, search: "${search}") {
					${swmProductCategoryFields()}
				}
			}
		`;
	},

	getAllDetailedSwmProductCategories() {
		return `
			{
				statsSwmProductCategories{
					${swmProductCategoryFields()}
				}
			}`;
	},

	createSwmProductCategory (data) {
		return `
		 mutation {
			createSwmProductCategory(key_name:"${data.key_name}", name:${data.name}) {
			 ${swmProductCategoryFields()}
			}
		 }`;
	},

	updateSwmProductCategory (data) {
		let updateName = data.name ? `name:${data.name},` : ``;
		let updateKeyName = data.key_name ? `key_name:"${data.key_name}",` : ``;
		return `
		 mutation {
			updateSwmProductCategory(id:${data.id}, ${updateKeyName}, ${updateName}) {
			 ${swmProductCategoryFields()}
			}
		 }`;
	},

	deleteSwmProductCategory (id) {
		return `
		 mutation {
			deleteSwmProductCategory(id: ${id}) {
			 ${swmProductCategoryFields()}
			}
		 }`;
	},

	fetchFilteredTicketStatusList(page, search = '') {
		return `
			{
				items: filteredStatsTicketStatuses(page: ${page}, search: "${search}") {
					${ticketStatusFields()}
				}
			}
		`;
	},

	getAllDetailedTicketStatuses() {
		return `
			{
				items: statsTicketStatuses {
					${ticketStatusFields()}
				}
			}`;
	},

	createTicketStatus (data) {
		return `
		 mutation {
			createTicketStatus(key_name:"${data.key_name}", name:${data.name}) {
			 ${ticketStatusFields()}
			}
		 }`;
	},

	updateTicketStatus (data) {
		let updateName = data.name ? `name:${data.name},` : ``;
		let updateKeyName = data.key_name ? `key_name:"${data.key_name}",` : ``;
		return `
		 mutation {
			updateTicketStatus(id:${data.id}, ${updateKeyName}, ${updateName}) {
			 ${ticketStatusFields()}
			}
		 }`;
	},

	deleteTicketStatus (id) {
		return `
		 mutation {
			deleteTicketStatus(id: ${id}) {
			 ${ticketStatusFields()}
			}
		 }`;
	},
}
