export default {
    dashboard: {
        pages: {
            dashboard: {
                welcomeHeader: 'Welcome {fullName}!',
                welcomeContent: 'On your personal dashboard you will immediately find all important information about all your projects!',
                welcomeButtonText: 'Project Overview',
                startNewProjectHeader: 'Start a new project',
                startNewProjectContent: 'Start a new project in a few steps',
                startNewProjectButtonText: 'Start Project',
                reportsHeader: 'View Report',
                reportsContent: 'Here you will find all the important facts and figures for your projects',
                reportsButtonText: 'Show Reports',
                projectsHeader: 'Projects',
                gbSpecificationLine1: 'Welcome to the GOLDBECK Specification-Tool,',
                gbSpecificationLine2: 'you have access to the following Databases and Features.',
                gbSpecificationLine3: 'Please select your target:',
                gbProjectList: 'Projects Master Data',
            },
            user: {
                add: {
                    userCreated: 'User successfully created!',
                    sendWelcomeEmailQuestion: 'Send welcome email to new user?',
                    welcomeEmailDoesntSent: 'Welcome email doesn\'t sent.',
                    validation: {
                        aUserWithSuchEmailAlreadyExists: 'A User with such email already exists.'
                    }
                },
                edit: {
                    userUpdated: 'User successfully updated!',
                    userNotFound: 'User Not Found',
                    userWithIdNotFound: 'User record with id: {userId} not found.',
                    allUsers: 'All Users',
                    changeAvatar: 'Change Avatar',
                    removeAvatar: 'Remove Avatar',
                    password: 'Password',
                    newPassword: 'New Password',
                    confirmPassword: 'Confirm Password',
                    passwordValidateMessage: 'The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. @ _ & ? etc)',
                    passwordConfirmValidateMessage: 'Passwords do not match',
                    personalInformation: 'Personal Information',
                    birthdayDate: 'Birth Date',
                    position: 'Position',
                    department: 'Department',
                    gender: 'Gender',
                    male: 'Male',
                    female: 'Female',
                    address: 'Address',
                    resendWelcomeEmail: 'Re-send Welcome Email',
                    areYouSureYouWantToResend: 'Are you sure you want to resend the Welcome email?',
                    assignedProjects: 'Assigned Projects',
                    marketplaces: {
                        Marketplaces: 'Marketplace-wide',
                        Company: 'Company-wide',
                        Project: 'Project',
                        NoProjectsMessage: 'At the moment, this Company has no linked Projects.<br/><br/>\n' +
                         '\n' +
                         'You can create a new Project in the Projects section.<br/><br/>\n' +
                         '\n' +
                         'Until then, you may just define default user Role for all future Projects in this Company, per Marketplace.',
                        NoCompaniesMessage: 'At the moment, this user is not associated with any Company.<br/><br/>\n' +
                         '\n' +
                         'Please go to “Account” tab, add Companies, save changes and return to this page.'
                    },
                    apps: {
                        Applications: 'App-wide',
                        Company: 'Company-wide',
                        Project: 'Project',
                        ssoAppServices: {
                            sso_configurator: 'Property Configurator & Defect Management',
                            sso_dashboard: 'PROPSTER Company Dashboard',
                        },
                        NoCompanies: 'No Companies',
                        NoProjects: 'No Projects',
                        NoRole: 'No Role',
                        NoProjectsMessage: 'At the moment, this Company has no linked Projects.<br/><br/>\n' +
                         '\n' +
                         'You can create a new Project in the Projects section.<br/><br/>\n' +
                         '\n' +
                         'Until then, you may just define a default user Role for all future Projects of the Company.',
                        NoCompaniesMessage: 'At the moment, this user is not associated with any Company.<br/><br/>\n' +
                         '\n' +
                         'Please go to “Account” tab, add Companies, save changes and return to this page.<br/><br/>\n' +
                         '\n' +
                         'Until then, you may only define default user Role for all their future Companies and Projects, per application.'
                    },
                    validation: {
                        aUserWithSuchEmailAlreadyExists: 'A User with such email already exists.'
                    }
                },
                myProfile: {
                    changeIcon: 'Change Icon',
                    removeIcon: 'Remove Icon',
                    generalInformation: 'General Information',
                    newPassword: 'New Password (please enter it twice)',
                    socialInformation: 'Social',
                    company: 'Company',
                    contactData: 'Contact Data',
                    addressLine: 'Address Line'
                },
                list: {
                    active: 'Active',
                    inactive: 'Inactive',
                    status: 'Status',
                    addUser: 'Add User',
                    AllAppServices: 'All Apps',
                    AllMpMarketplaces: 'All Marketplaces',
                    AllCompanies: 'All Companies',
                    AllProjects: 'All Projects',
                    AllRoles: 'All Roles',
                },
                other: {
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    email: 'Email',
                    phoneNumber: 'Phone Number',
                    company: 'Company',
                    role: 'Role',
                    language: 'Language',
                    noCompany: 'No Company',
                    noCompanies: 'No Companies',
                    noProjects: 'No Projects',
                    deleteAUser: 'Delete a User',
                    pleaseConfirmDeletingUser: 'Please confirm deleting user {firstName} {lastName}.',
                    youCanNotDeleteUser: 'You cannot delete user {firstName} {lastName} because it is either has one of Project roles, or has permissions for Apps or Marketplaces.',
                    roles: {
                        no_role: 'No Role',
                        super_administrator: 'Super Administrator',
                        administrator: 'Administrator',
                        admin: 'Admin',
                        company_admin: 'Company Admin',
                        owner: 'Owner',
                        project_manager: 'Project Manager',
                        staff: 'Staff',
                        propster_finance: 'PROPSTER finance',
                        subscriber: 'Subscriber',
                        shop_manager: 'Shop Manager',
                        pending_vendor: 'Pending Vendor',
                        vendor_admin: 'Vendor Admin',
                        vendor_manager: 'Vendor Manager',
                        translator: 'Translator',
                        project_admin: 'Project Admin',
                    }
                }
            },
            company: {
                add: {
                    companyCreated: 'Company Created',
                    companyNotCreated: 'Company Not Created',
                    companySuccessfullyCreated: 'New company was successfully created'
                },
                edit: {
                    companyNotFound: 'Company Not Found',
                    companyWithIdNotFound: 'Company record with id: {companyId} not found.',
                    allCompanies: 'All Companies',
                    companyUpdated: 'Company Updated',
                    companyNotUpdated: 'Company Not Updated',
                    companySuccessfullyUpdated: 'Company was successfully updated',
                    tabGeneral: 'General Information',
                    tabInvoicing: 'Invoicing Data',
                    tabMarketplaces: 'Marketplaces',
                    tabPaymentInformation: 'Payment Information',
                    hasProjectDashboard: 'Enable Customer Platform Editor',
                    identityProvider: 'Identity Provider',
                },
                list: {
                    of: 'of',
                    exportAsCsv: 'Export as CSV',
                    addNewCompany: 'Add New Company',
                    bulkDeleteCompaniesConfirmMessage: 'Do you really want to delete all selected companies?',
                    companiesDeleted: 'Companies Deleted',
                    companiesNotDeleted: 'Companies Not Deleted',
                    selectedCompaniesDeleted: 'All selected companies was successfully deleted',
                    companyDeleted: 'Company Deleted',
                    selectedCompanyDeleted: 'The selected company was successfully deleted',
                    companyNotDeleted: 'Company Not Deleted',
                    deleteACompany: 'Delete a Company',
                    confirmDelete: 'Please confirm deleting company "{name}".',
                    cantDeleteCompany: 'You cannot delete company "{name}" because it has connected Users and/or Projects:',
                },
                other: {
                    uidNumber: 'VAT / UID',
                    paymentModality: 'Payment Modality',
                    paymentCadence: 'Payment Cadence',
                    iban: 'IBAN',
                    bic: 'BIC/SWIFT',
                    bankInstitute: 'Bank institute',
                    sepa: 'SEPA',
                    bankTransfer: 'Bank transfer',
                    monthlyPayment: 'Monthly payment',
                    quarterlyPayment: 'Quarterly payment',
                    annualPayment: 'Annual payment',
                    street: 'Street',
                    houseNumber: 'House Number',
                    companyRegister: 'Company Register',
                    website: 'Website',
                    viewDetails: 'View Details',
                    responsibleSalesManager: 'Responsible Sales Manager',
                    parentCompany: 'Parent Company',
                    companyType: 'Company Type',
                    development: 'Client',
                    vendor: 'Product-Partner',
                    public_phone_number: 'Public Phone Number',
                    billingCompanyName: 'Company Billing Name',
                    billingPostCode: 'Post Code',
                    billingStreet: 'Street',
                    billingHouseNumber: 'House Number',
                    billingCity: 'City',
                    billingCountry: 'Country',
                    billingInvoicingEmail: 'Invoicing Email',
                    billingContactPerson: 'Contact Person',
                    billingPhone: 'Phone of Accounting Department',
                    privateMarketplaces: 'Private Marketplaces',
                    publicMarketplaces: 'Public Marketplaces',
                    parentCompanyWarning: 'Warning! This company has {childCompaniesLength} subsidiaries. If you will associate it with specific marketplaces, all child companies will be automatically associated as well. Please proceed only if this is your intention. Otherwise, please open accounts of child companies one by one and define required access individually.',
                    companyLogo: 'Company Logo',
                    changeLogo: 'Change Logo',
                    removeLogo: 'Remove Logo',
                }
            },
            marketplace: {
                add: {
                    name: 'Name',
                    marketplaceType: 'Marketplace Type',
                    websiteUrl: 'Website URL',
                    marketplaceId: 'Marketplace ID'
                },
                edit: {
                    generalInfo: 'General Info',
                    companies: 'Companies',
                    marketplaceNotFound: 'Marketplace Not Found',
                    marketplaceWithIdNotFound: 'Marketplace record with id: {marketplaceId} not found.',
                    allMarketplaces: 'All Marketplaces',
                    parentCompanySelectedWarning: 'Warning! This company has {childCompaniesLength} subsidiaries. If you will associate it with this marketplaces, all child companies will be automatically associated as well. Please proceed only if this is your intention. Otherwise, please select accounts of child companies one by one, to define required access individually.',
                    changeIcon: 'Change Icon',
                    removeIcon: 'Remove Icon'
                },
                list: {
                    addNewMarketplace: 'Add New Marketplace',
                    confirmDeactivation: 'Confirm Deactivation',
                    doYouWishToContinueDeactivate: 'Do you wish to continue deactivating this Marketplace?',
                    confirmActivation: 'Confirm Activation',
                    doYouWishToContinueActivate: 'Do you wish to continue activating this Marketplace?',
                    deleteMarketplace: 'Delete a Marketplace',
                    confirmDeleting: 'Please confirm deleting marketplace "{marketplaceName}".',
                    cantDeleteMarketplace: 'You cannot delete marketplace "{marketplaceName}" because it’s connected to one or more Companies.',
                    marketplaceDeleted: 'Marketplace Deleted',
                    selectedMarketplaceDeleted: 'The selected Marketplace was successfully deleted',
                    marketplaceNotDeleted: 'Marketplace Not Deleted'
                },
                other: {
                    name: 'Name',
                    edit: 'Edit',
                    numberOfCompanies: '# of connected Companies',
                    activate: 'Activate',
                    deactivate: 'Deactivate',
                    public: 'Public',
                    private: 'Private',
                    delete: 'Delete',
                    ok: 'OK'
                },
                errors: {
                    nameIsRequired: 'Marketplace name is required',
                    websiteUrlIsRequired: 'Website URL is required',
                    marketplaceIdIsRequired: 'Marketplace ID is required'
                }
            },
            service: {
                add: {},
                edit: {},
                list: {
                    addNewService: 'Add New Service',
                    deleteService: 'Delete a Service',
                    confirmDeleting: 'Please confirm deleting service "{serviceName}".',
                    cantDeleteService: 'You cannot delete service "{serviceName}" because it’s connected to one or more Projects.',
                    serviceDeleted: 'Service Deleted',
                    selectedServiceDeleted: 'The selected service was successfully deleted',
                    serviceNotDeleted: 'Service Not Deleted'
                },
                other: {
                    order: 'Order',
                    icon: 'Icon',
                    iconCode: 'Icon Code',
                    numberOfProjects: '# of Projects',
                    name: 'Name',
                    description: 'Description',
                    descriptionPlaceholder: 'Write description...',
                    delete: 'Delete',
                    ok: 'OK'
                },
                errors: {
                    valueIsRequired: 'The {field} is required',
                    shouldBeInteger: 'Order should be an Integer, 1 or bigger'
                }
            },
            addon: {
                add: {},
                edit: {},
                list: {
                    addNewAddon: 'Add New Add-On',
                    deleteAddon: 'Delete an Add-On',
                    confirmDeleting: 'Please confirm deleting Add-On "{addonName}".',
                    cantDeleteAddon: 'You cannot delete Add-On "{addonName}" because it’s connected to one or more Projects.',
                    addonDeleted: 'Add-On Deleted',
                    selectedAddonDeleted: 'The selected Add-On was successfully deleted',
                    addonNotDeleted: 'Add-On Not Deleted'
                },
                other: {
                    order: 'Order',
                    icon: 'Icon',
                    iconCode: 'Icon Code',
                    numberOfProjects: '# of Projects',
                    name: 'Name',
                    delete: 'Delete',
                    ok: 'OK',
                    keyName: 'Key Name',
                    description: 'Description',
                    availableDisclaimer: 'Available for a Project only if one of following services is selected:'
                },
                errors: {
                    valueIsRequired: 'The {field} is required',
                    shouldBeInteger: 'Order should be an Integer, 1 or bigger'
                }
            },
            project: {
                add: {
                    pleaseSelectFeatures: '<span>Lets start and define now the project scope!</span><br/>Please click the features you would like to use in your project.',
                    pleaseFillProjectAddress: '<span>Let\'s start with some general questions first!</span><br/> Please tell us the exact project address (street, zip code, city, country):',
                    whenWouldYouLikeToStart: '<span>When would you like to start marketing at best?</span><br/> (please note that we usually need 8-10 weeks lead time for new projects, depending on the project size).',
                    whatIsTheProjectCompanyName: '<span>What is the official name of the project company carrying out this project?</span>',
                    pleaseSelectProjectType: '<span>Please select your project type here!</span>',
                    howManyMeters: '<span>How many m2 are we talking about in total?</span>',
                    howManyUnits: '<span>How many units will be built?</span>',
                    projectPriceRange: '<span>In what price range will your project be sold?</span>',
                    pleaseSelectProjectStyle: '<span>In what style would you most likely categorize your project?</span>',
                    areThereSampleFloorPlans: '<span>Are there sample floor plans already available?</span><br/> If yes, please upload them here, if not please skip it.',
                    areThereAlreadyVisualizations: '<span>Are there already visualizations?</span><br/> If yes, please upload them here, if not please skip it.',
                    pleaseSendUsTheEquipmentDescription: '<span>Please send us the equipment description of the project here!</span><br/> If this is not yet completely finished, please also provide the current status.',
                    whoDealsWithTheProductSelection: '<span>Who deals with the product selection (standard products + alternative products) in this project?</span>',
                    whoWillHandleSpecialRequests: '<span>Who will handle the special requests/ configurations/ defect processing for you?</span>',
                    areYouInterestedInBanner: '<span>Are you interested in a free custom construction fence banner for your construction site?</span>',
                    yourBannerCouldLookLikeThis: 'Your personal banner could look like this',
                    hasBuildingPermitAlreadyIssued: '<span>Has the building permit already been issued?</span>',
                    projectWasSuccessfullyCreated: '<span>CONGRATULATIONS YOUR PROJECT HAS BEEN SUCCESSFULLY CREATED.</span>',
                    weWillContactYouShortly: 'We will contact you shortly regarding further project steps.',
                    leaveUsNotes: 'Would you like to leave us notes - please use the field below!',
                    uploadLogo: '<span>Is there already a project logo?</span><br/> If so, please upload it here! If not - please upload your company logo!',
                    backToProjectOverview: 'Back To Project Overview',
                    steps: {
                        areYouInterestedInBanner: 'Are you interested in free construction fence banner for your construction site?',
                        yourBannerCouldLookLikeThis: 'Your personal banner can look like this:',
                        whenWouldYouLikeToStart: 'When would you like to start marketing at best?',
                        whenWouldYouLikeToStart_notice: '(please note that we usually need 8-10 weeks lead time for new projects, depending on the project size)',
                        leaveUsNotes: 'Would you like to leave us notes? - please use the field below!',
                        addNewProject: 'Add New Project',
                        projectInformation: 'Project Information',
                        projectDetails: 'Project Details',
                        projectType: 'Project Type',
                        projectDocuments: 'Project Documents',
                        marketplace: 'Marketplace',
                        deadlinesAndNotes: 'Deadlines & Notes',
                        finish: 'Finish',
                        not_issued_notes: 'Please tell us when you plan to start the construction process:',
                        integrations: {
                            title: 'Integrations',
                            PROPSTERAppConnection: 'PROPSTER App Connection',
                            defectManagementConnection: 'Defect Management Connection',
                            apiBaseURL: 'API Base URL',
                            apiPath: 'API Path',
                            apiUserKey: 'API User Key',
                            apiConsumerSecret: 'API Consumer Secret'
                        }
                    }
                },
                edit: {
                    General: 'General',
                    Services: 'Services',
                    Address: 'Address',
                    Contacts: 'Contacts',
                    Files: 'Files',
                    floor_plan: 'Floor Plan',
                    visualization: 'Visualization',
                    equipment_description: 'Equipment Description',
                    logo: 'Logo',
                    extension: 'Extension',
                    areThereSampleFloorPlans: 'Floor Plans',
                    areThereAlreadyVisualizations: 'Visualizations Upload',
                    pleaseSendUsTheEquipmentDescription: 'Equipment Descriptions Upload',
                    uploadLogo: 'Logo Upload',
                    dashboardSettings: 'Dashboard Settings',
                    propsterSettings: 'PROPSTER Settings',
                    projectURL: 'Project URL',
                    blogId: 'WP Multisite Blog ID',
                    blogRegion: 'WP Project Region',
                    apiPath: 'Project API path (version, e.g. "wc/v1")',
                    wcUserKey: 'API User Key',
                    wcUserConsumerSecret: 'API Consumer Secret',
                    behaviorReportURL: 'Google Tag Manager Report URL',
                    deleteTitle: 'File Delete',
                    fileDeleteSuccess: 'File has been successfully deleted',
                    fileDeleteFailure: 'Failed to delete file',
                    projectUpdated: 'Project successfully updated!',
                    whoDealsWithTheProductSelection: 'Configuration/Defect Processing Contact',
                    whoWillHandleSpecialRequests: 'Product Selection Contact',
                    projectOnboardingOverviewURL: 'Project Onboarding Overview',
                    mmgmtURL: 'MMGMT URL',
                    mmgmtUserKey: 'MMGMT API User Key',
                    mmgmtConsumerSecret: 'MMGMT API Consumer Secret',
                    mmgmtApiPath: 'MMGMT API path (version, e.g. "wc/v1")',
                    changeIcon: 'Change Icon',
                    removeIcon: 'Remove Icon',
                    service: 'Service',
                    licenseId: 'License ID',
                    addon: 'Add-On',
                    editService: 'Edit a Service',
                    servicesProjectUrl: 'Site URL',
                    gtmURL: 'Google Tag Manager Report URL',
                    editAddon: 'Edit an Add-On',
                    siteUrlError: 'Please enter valid URL starting with HTTP or HTTPS',
                    confirmDeactivation: 'Confirm Deactivation',
                    doYouWishToContinueDeactivate: 'Do you wish to continue deactivating this Service?',
                    doYouWishToDeactivateAddon: 'Do you wish to continue deactivating this Add-on?',
                    doYouWishToActivateAddon: 'Do you wish to continue activating this Add-on?',
                    confirmActivation: 'Confirm Activation',
                    doYouWishToContinueActivate: 'Do you wish to continue activating this Service?',
                    activate: 'Activate',
                    deactivate: 'Deactivate',
                    pleaseSelectServices: 'Please select the services you would like to use in your project:',
                    pleaseSelectAddons: 'Please select PROPSTER add-ons you want to book:'
                },
                list: {
                    somethingWentWrong: 'Something went wrong',
                    company: 'Company',
                    exportAsCsv: 'Export As CSV',
                    addNewProject: 'Add New Project',
                    actions: 'Actions',
                    delete: 'Delete',
                    id: 'ID',
                    name: 'Name',
                    Name: 'Name',
                    AssignedProjectUsers: 'Assigned Project Users',
                    confirmDelete: 'Delete Confirmation',
                    youAreAboutToDelete: 'You are about to delete',
                    projectDeleted: 'Project has been deleted',
                    selectedProjectDeleted: 'Selected project has been deleted',
                    projectNotDeleted: 'Project could not be deleted',
                    confirmCancelEditing: 'Are you sure you want to cancel editing?',
                    youHaveUnsavedChanges: 'You have unsaved changes in your form',
                    editingARoleHasBeenCanceled: 'Editing a role has been canceled',
                    address: 'Address',
                    status: 'Status',
                    projectInfo: 'Project Info',
                    createdBy: 'Created By',
                    createdAt: 'Created At',
                    services: 'Services',
                    AllCompanies: 'All Companies',
                    AllStatuses: 'All Statuses',
                    AllCountries: 'All Countries',
                    AllUsers: 'All Users',
                    created_at: 'Created At',
                    creator: 'Created By',
                    created_between: 'Created, between',
                    created_from: 'Select start date',
                    created_to: 'Select end date',
                },
                csv: {
                    company: 'Company',
                    projectName: 'Project',
                    address1: 'Address Line 1',
                    address2: 'Address Line 2',
                    propsterProjectId: 'PROPSTER Project ID',
                    is_third_party_company: 'Building for 3rd party',
                    client_name: 'Client Name',
                    client_company_name: 'Client Company Name',
                    client_address_1: 'Client Address Line 1',
                    client_address_2: 'Client Address Line 2',
                    client_postcode: 'Client Post Code',
                    client_city: 'Client City',
                    project_number: 'Project Number',
                    services: 'Services',
                    addons: 'Add-Ons',
                    building_from_scratch: 'Building from the scratch',
                    residential_area: 'Residential Area',
                    commercial_area: 'Commercial Area',
                    area_residential_investment: 'Residential: Investment and/or Direct Sales',
                    area_residential_rent: 'Residential: Rent',
                    area_commercial_investment: 'Commercial: Investment and/or Direct Sales',
                    area_commercial_rent: 'Commercial: Rent',
                    project_style: 'Project Style',
                    projectOnboardingOverviewURL: 'Project Onboarding Overview',
                    handle_user: 'Contact for special requests / configurations / defect',
                    deals_user: 'Contact for product selection',
                    project_manager_name: 'Responsible Project Manager',
                    fence_banner: 'Fence banner',
                    building_permit: 'Building permit',
                    building_permit_comments: 'Building permit comments',
                    notes: 'Notes',
                    project_url: 'PAPP API Base URL',
                    stats_swm_wc_user_key: 'PAPP API User Key',
                    stats_swm_wc_consumer_secret: 'PAPP API Consumer Secret',
                    stats_mmgmt_api_url: 'MMGT API Base URL',
                    stats_mmgmt_user_key: 'MMGT API User Key',
                    stats_mmgmt_consumer_secret: 'MMGT API Consumer Secret',
                    created_at: 'Created At',
                    creator: 'Created By',
                    postCode: 'Post Code',
                    city: 'City:',
                    status: 'Status',
                    country: 'Country',
                    clientCountry: 'Client Country'
                },
                statuses: {
                    draft: 'Draft',
                    payment_outstanding: 'Payment Outstanding',
                    in_setup: 'In Setup',
                    active: 'Active',
                    inactive: 'Inactive',
                    archived: 'Archived'
                },
                overview: {
                    overview: 'OVERVIEW',
                    services: 'SERVICES',
                    areaAndSales: 'AREA AND SALES',
                    responsibleProjectManager: 'RESPONSIBLE PROJECT MANAGER (SUPPORT)',
                    configurationContact: 'CONFIGURATION/DEFECT PROCESSING CONTACT',
                    productSelectionContact: 'PRODUCT SELECTION CONTACT',
                    projectName: 'PROJECT NAME',
                    propsterProjectID: 'PROPSTER PROJECT ID',
                    status: 'STATUS',
                    company: 'COMPANY',
                    address: 'ADDRESS',
                    projectStyle: 'PROJECT STYLE',
                    marketingStartDate: 'MARKETING START DATE',
                    buildingForAThirdParty: 'BUILDING FOR A 3RD PARTY',
                    refubrishmentProject: 'REFURBISHMENT PROJECT',
                    buildingPermit: 'BUILDING PERMIT',
                    addons: 'ADD-ONS',
                    name: 'NAME',
                    email: 'EMAIL',
                    mobile: 'MOBILE',
                    residential: 'Residential',
                    commercial: 'Commercial',
                    investmentDirectSales: 'Investment, Direct sales',
                    renting: 'Renting',
                    ProjectDoesNotExist: 'Project does not exist.'
                },
                other: {
                    wrongIcon: 'Please upload the image up to {mb}MB in one of supported file types: PNG, JPG, JPEG',
                    Theiconsizemustbelessthan1000KB: 'File size must not exceed 1 MB',
                    Theiconfieldmusthaveavalidfiletype: 'Please upload the image in one of supported file types: PNG, JPG, JPEG',
                    marketingStartDate: 'Marketing Start Date',
                    projectName: 'Project Name',
                    propsterProjectID: 'PROPSTER Project ID',
                    project: 'Project',
                    projectCompanyName: 'Project Company Name',
                    projectStyle: 'Project Style',
                    projectType: 'Project Type',
                    priceRange: 'Price Range',
                    grossArea: 'Gross Area',
                    companyName: 'Company Name',
                    units: 'Units',
                    notes: 'Notes',
                    servicesList: 'Services List',
                    Services: 'Services',
                    responsibleProjectManager: 'Responsible Project Manager',
                    name: 'Name',
                    mobile: 'Mobile',
                    whatIsTheProjectCompanyName: '<span>Official name of the company, which is carrying out this project:</span>',
                    generalQuestions: '<span>Project Address</span>',
                    projectIcon: '<span>Project Icon | Logo</span>',
                    projectIconRecommendedSize: '<br/>(200 x 200 px recommended)',
                    projectKeyVisual: '<span>Project Key Visual | Rendering</span>',
                    projectKeyVisualRecommendedSize: '<br/>(1280 x 960 px recommended)',
                    addressLine1: 'Address Line 1',
                    addressLine2: 'Address Line 2',
                    postCode: 'Post Code',
                    city: 'City',
                    country: 'Country',
                    status: 'Status',
                    thirdPartyCompany: '<span>Are you building for a third-party company?</span>',
                    whoIsYourClient: '<span>Who is your client?</span>',
                    projectMembers: '<span>Project Members</span>',
                    newProjectMember: 'New Project Member',
                    updateProjectMember: 'Update Project Member',
                    deleteProjectMember: 'Remove user from Project',
                    confirmDeleteProjectMember: 'Are you sure to remove the user {userName} from {projectName}?',
                    clientName: 'Client Name',
                    projectNumber: 'Project Number',
                    createProject: 'Create Project',
                    saveAndReview: 'Save & Review',
                    changeIcon: 'Change Icon',
                    removeIcon: 'Remove Icon',
                    changeImage: 'Change Image',
                    removeImage: 'Remove Image',
                    addACompany: 'Add a Company',
                    tabs: {
                        generalInfo: 'General Information',
                        services: 'Services',
                        projectType: 'Project Type',
                        areaAndPricing: 'Area & Pricing',
                        projectStyle: 'Project Style',
                        documents: 'Documents',
                        contactData: 'Contact Data',
                        deadlinesAndNotes: 'Deadlines & Notes',
                        projectMembers: 'Project Members',
                    },
                    services: {
                        SWM: 'Configurator Platform',
                        MMGMT: 'Handingover & Defect Platform',
                        propsterPromo: 'Sales Mode'
                    },
                    types: {
                        apartments: 'Apartments',
                        rental_apartments: 'Rental apartments',
                        investments_apartments: 'Investments apartments',
                        offices: 'Offices',
                        commercial: 'Commercial',
                        houses: 'Houses'
                    },
                    statuses: {
                        draft: 'Draft',
                        payment_outstanding: 'Payment outstanding',
                        in_setup: 'In set up',
                        active: 'Active (Live)',
                        inactive: 'Inactive',
                        archived: 'Archived'
                    },
                    projectDetails: 'Project Details',
                    viewDetails: 'View Details',
                    includingAreaTypes: 'What type of areas you project includes?',
                    residentialProjectType: 'Residential Project Type',
                    kindOfResidentialProject: 'What kind of residential project is being created?',
                    residentialProjectSales: 'Residential Project Sales',
                    sellResidentialUnits: 'How you gonna sell Residential units?',
                    commercialProjectType: 'Commercial Project Type',
                    kindOfCommercialProject: 'What kind of commercial project is being created?',
                    commercialProjectSales: 'Commercial Project Sales',
                    sellCommercialUnits: 'How you gonna sell Commercial units?',
                    construction_types: {
                        scratch: 'Construction from the scratch',
                        refurbishment: 'Refurbishment project'
                    },
                    area_types: {
                        residential: 'Residential',
                        commercial: 'Commercial'
                    },
                    residential_project_types: {
                        multi_story: 'Multi-story residential building',
                        single_family_houses: 'Single family houses, terraced houses, semi-detached houses'
                    },
                    project_sales: {
                        direct_sales: 'Direct sales',
                        investment: 'Investment',
                        renting: 'Renting'
                    },
                    commercial_project_types: {
                        office: 'Office',
                        hotel: 'Hotel',
                        serviced_apartments: 'Serviced Apartments',
                        trade: 'Trade',
                        medical_institution: 'Medical Institution',
                        warehouse_and_logistics: 'Warehouse and Logistics',
                        other: 'Other:',
                        office_building: 'Office Building',
                        business_park: 'Business Park',
                        multi_story_car_park: 'Multi-storey Car Park',
                        school_building: 'School Building',
                        sports_hall: 'Sports Hall',
                        hall: 'Hall',
                    },
                    residentialAreaForInvestment: 'Residential Area for Investment and/or Direct Sales',
                    residentialAreaForRent: 'Residential Areas for Rent',
                    commercialAreaForInvestment: 'Commercial Area for Investment and/or Direct Sales',
                    commercialAreaForRent: 'Commercial Areas for Rent',
                    pleaseSelectProjectTypeEtc: 'Please select a project type and a project usage first!',
                    projectAreaM2: 'Project area (m2)',
                    priceRangeForM2: 'Price range for m2',
                    numberOfUnits: 'Number of Units',
                    selectStyleOfYourProject: 'Please select the style of your project:',
                    project_styles: {
                        contemporary: 'Contemporary',
                        urban: 'Urban',
                        classic: 'Classic',
                        traditional: 'Traditional',
                        other: 'Other'
                    },
                    contemporaryDescription: 'Building form with clear modern character; rather renunciation of representative details; asymmetrical groupings & cubistic elements; white or colored plaster on the facade; abundance of light.',
                    urbanDescription: 'Building form with urban character; loft-like appearance; brick facades, green facades, more experimental paneling with facade panels made of wood, metal or plastics; large window openings.',
                    classicDescription: 'Building form in classic & timeless style; representative details kept simple; minimalistic plain light-colored facades of natural stone or natural-looking stone paneling; symmetrical window divisions and clear symmetries throughout the building appearance.',
                    traditionalDescription: 'Building form in traditional style; gable roof, hip roof; regionally-historical recognizable architectural language; stone, wood, plaster as facade elements; traditional interpretation of new materials.',
                    handleUser: 'Who will handle the special requests / configurations / defect processing for you?',
                    dealsUser: 'Who deals with the product selection (standard products + alternative products) in this project?',
                    propsterProjectManager: 'Responsible Project Manager (PROPSTER)',
                    pleaseSelectAUser: 'Please select a user',
                    addNewUser: 'Add new User',
                    pleaseUpload: 'Please upload corresponding documents / images, if already available:',
                    selectFileType: 'Select file type',
                    file_types: {
                        visualization: 'Visualization',
                        equipment_description: 'Equipment Description',
                        floor_plan: 'Floor Plan'
                    },
                    supportedFileTypes: 'Supported file types: PNG, JPG, JPEG, PDF, XLS(X), DOC(X), PPT(X), CSV',
                    dragFile: 'Drag your file here or click to upload',
                    projectOnboardingOverview: 'Project Onboarding Overview',
                    fileName: 'File Name',
                    type: 'Type',
                    size: 'Size',
                    actions: 'Actions',
                    user: 'User',
                    role: 'Role',
                    configuratorProjectRoles: 'Project Roles: Configurator & Defect Management',
                    promoProjectRoles: 'Project Roles: Promo Site',
                    marketplaceProjectRoles: 'Project Roles: {marketplaceName} Marketplace',
                    placeholders: {
                        https: 'https://...',
                        wcApiPath: 'wc/v1',
                        wcApiUserKey: 'ck_...',
                        wcApiConsumerSecret: 'cs_...',
                    },
                    errors: {
                        required: 'Value is required',
                        unique: 'The value is not unique',
                        incorrectFormat: 'Incorrect format',
                        integer: 'Whole number value is expected',
                        decimal: 'Decimal value is expected'
                    },
                    deleteProject: 'Delete a Project',
                    confirmDeleteProject: 'Please confirm deleting project {projectName}',
                    canNotDeleteProject: 'You cannot delete project {projectName} because it’s connected to Services or Add-Ons.',
                    selectAUser: 'Please, select a user',
                    selectARole: 'Please, select a project role',
                }
            },
            admin: {
                api_users: {
                    list: {
                        addApiUser: 'Add API User'
                    }
                },
                user_roles: {
                    KeyName: 'Key Name',
                    ListKeyName: 'Key Name',
                    CompanyRestricted: 'Company Restricted',
                    LicenseRestricted: 'License Restricted',
                    ProjectRestricted: 'Project Restricted',
                    EditRole: 'Edit a Role',
                    Name: 'Name',
                    UserRoles: 'User Roles',
                    Admin: 'Admin',
                    key_names: 'Key Name',
                    key_name: 'Key Name',
                    addNewRole: 'Add New Role',
                    NewRole: 'Add a Role',
                    add: 'Add',
                    update: 'Update',
                    cancel: 'Cancel',
                    Level: 'Level',
                    roleSuccessfullyCreated: "New role has been successfully created",
                    roleCreated: "Role Created",
                    roleSuccessfullyUpdated: 'Role successfully updated',
                    roleUpdated: 'Role Updated',
                    selectedRoleDeleted: 'Role has been succefully deleted',
                    roleDeleted: 'Deleted',
                    Canceled: 'Canceled',
                    addingARoleHasBeenCanceled: 'Adding a new Role has been canceled.',
                    editingARoleHasBeenCanceled: 'Editing a Role has been canceled.',
                    OK: 'Ok',
                    Close: 'Close',
                    youHaveUnsavedChanges: 'You have usaved changes in your form which will be lost.',
                    confirmCancelEditing: 'Are you sure?',
                    ContinueEditing: 'Continue Editing',
                    projectList: 'Project List',
                    AssignedProjectUsers: 'Users assigned to a project',
                    add_users: 'Add Users',
                    edit_users: 'Edit Users',
                    view_users: 'View Users',
                    delete_users: 'Delete Users',
                    add_companies: 'Add Companies',
                    edit_companies: 'Edit Companies',
                    view_companies: 'View Companies',
                    delete_companies: 'Delete Companies',
                    add_projects: 'Add Projects',
                    edit_projects: 'Edit Projects',
                    view_projects: 'View Projects',
                    delete_projects: 'Delete Projects',
                    view_emails: 'View Email Log',
                    view_activity: 'View Activities',
                    Permissions: 'Permissions',
                    id: 'ID',
                    actions: 'Actions',
                    name: 'Name',
                    of: 'of',
                    Search: 'Search',
                    exportAsCsv: 'Export As CSV',
                    delete: 'Delete',
                    roleNotUpdated: 'Role has not been updated',
                    somethingWentWrong: 'An error occurred',
                    roleNotCreated: 'Role has not been created',
                    edit_propster_id: 'Edit PROPSTER ID',
                    view_propster_id: 'View PROPSTER ID',
                    edit_company_type: 'Edit Company Type',
                    allow_no_parent_company: 'Allow Empty Parent Company',
                    edit_parent_company: 'Edit Parent Company',
                    edit_propster_admin: 'Edit PROPSTER Company Manager',
                    add_orders: 'Add Orders',
                    edit_orders: 'Edit Orders',
                    view_orders: 'View Orders',
                    delete_orders: 'Delete Orders',
                    confirmDelete: 'Delete a Role',
                    youAreAboutToDelete: 'Please confirm deleting role %1$s.',
                    canNotDelete: 'Cannot delete a Role %1$s, it is already used for at least one User.',
                    noOfUsers: 'Number of Users',
                    keyNameValidationErrorUnique: 'The role Key Name is already in use for the app.',
                    tabs: {
                        company_dashboard: 'PROPSTER Company Dashboard',
                        marketplaces: 'Marketplaces',
                        sso_configurator: 'PROPSTER App',
                        sso_dashboard: 'PROPSTER Company Dashboard'
                    }
                },
                dictionaries: {
                    KeyName: 'Key Name',
                    ListKeyName: 'Key',
                    CompanyRestricted: 'Company Restricted',
                    LicenseRestricted: 'License Restricted',
                    ProjectRestricted: 'Project Restricted',
                    EditItem: 'Edit a Item',
                    Name: 'Name',
                    UserRoles: 'User Roles',
                    Admin: 'Admin',
                    key_names: 'Key Name',
                    key_name: 'Key Name',
                    addNewItem: 'Add New Item',
                    NewItem: 'Add an Item',
                    add: 'Add',
                    update: 'Update',
                    cancel: 'Cancel',
                    Level: 'Level',
                    itemSuccessfullyCreated: "New item has been successfully created",
                    itemCreated: "Item Created",
                    itemSuccessfullyUpdated: 'Item successfully updated',
                    itemUpdated: 'Item Updated',
                    itemNotDeleted: 'Item not deleted',
                    selectedItemDeleted: 'Item has been succefully deleted',
                    itemDeleted: 'Deleted',
                    Canceled: 'Canceled',
                    addingAnItemHasBeenCanceled: 'Adding a new Item has been canceled.',
                    editingAnItemHasBeenCanceled: 'Editing an Item has been canceled.',
                    OK: 'Ok',
                    Close: 'Close',
                    youHaveUnsavedChanges: 'You have unsaved changes in your form which will be lost.',
                    confirmCancelEditing: 'Are you sure?',
                    ContinueEditing: 'Continue Editing',
                    projectList: 'Project List',
                    AssignedProjectUsers: 'Users assigned to a project',
                    add_users: 'Add Users',
                    edit_users: 'Edit Users',
                    view_users: 'View Users',
                    delete_users: 'Delete Users',
                    add_companies: 'Add Companies',
                    edit_companies: 'Edit Companies',
                    view_companies: 'View Companies',
                    delete_companies: 'Delete Companies',
                    add_projects: 'Add Projects',
                    edit_projects: 'Edit Projects',
                    view_projects: 'View Projects',
                    delete_projects: 'Delete Projects',
                    view_emails: 'View Email Log',
                    view_activity: 'View Activities',
                    Permissions: 'Permissions',
                    id: 'ID',
                    actions: 'Actions',
                    name: 'Name',
                    of: 'of',
                    Search: 'Search',
                    exportAsCsv: 'Export As CSV',
                    delete: 'Delete',
                    itemNotUpdated: 'Item has not been updated',
                    somethingWentWrong: 'An error occurred',
                    itemNotCreated: 'Item has not been created',
                    edit_propster_id: 'Edit PROPSTER ID',
                    view_propster_id: 'View PROPSTER ID',
                    edit_company_type: 'Edit Company Type',
                    allow_no_parent_company: 'Allow Empty Parent Company',
                    edit_parent_company: 'Edit Parent Company',
                    edit_propster_admin: 'Edit PROPSTER Company Manager',
                    confirmDelete: 'Delete an Item',
                    youAreAboutToDelete: 'Please, confirm deleting %1$s.',
                    canNotDelete: 'Cannot delete the %1$s, it is already used for at least one Entry.',
                    keyNameValidationErrorUnique: 'The item Key Name is already in use for the app.',
                    languageNames: {
                        name_en: 'Name (English)',
                        name_de: 'Name (German)'
                    },
                    tabs: {
                        request_statuses: 'Request Statuses',
                        defect_statuses: 'Defect Statuses',
                        request_categories: 'Product Categories',
                        defect_categories: 'Defect Categories',
                        ticket_statuses: 'Ticket Statuses',
                    }
                },
                identity_providers: {
                    id: 'ID',
                    addNewIP: 'Add New IP',
                    name: 'Name',
                    ipType: 'IP Type',
                    actions: 'Actions',
                    AddAnIP: 'Add an Identity Provider',
                    EditAnIP: 'Edit an Identity Provider',
                    add: 'Add',
                    update: 'Update',
                    cancel: 'Cancel',
                    Canceled: 'Canceled',
                    addingHasBeenCanceled: 'Adding IP has been canceled',
                    IPNotCreated: 'Adding IP has been canceled',
                    somethingWentWrong: 'An error occurred',
                    IPCreated: 'IP has been created',
                    IPSuccessfullyCreated: 'Identity Provider has been successfully created',
                    azure_client_id: 'Client ID',
                    azure_tenant_id: 'Tenant ID',
                    azure_client_secret: 'Client Secret',
                    azure_certificate: 'Certificate',
                    IPDeleted: 'Success',
                    selectedIPDeleted: 'Selected IP has been deleted',
                    IPNotDeleted: 'Delete Failed',
                    confirmCancelEditing: 'Cancel editing?',
                    youHaveUnsavedChanges: 'There are unsaved changes',
                    Close: 'Close',
                    ContinueEditing: 'Go Back',
                    editingAnIPHasBeenCanceled: 'Editing an IP has been canceled',
                    ipNotUpdated: 'Update Failed',
                    ipUpdated: 'Success',
                    ipSuccessfullyUpdated: 'IP has been successfully updated',
                    confirmDelete: 'Delete an IP',
                    youAreAboutToDelete: 'Please confirm deleting IP "%1$s"',
                    delete: 'Delete',
                    canNotDelete: 'Cannot delete an IP "%1$s", it is already used by at least one Company',
                    OK: 'OK'
                }
            },
            helpcenter: {
                faq: {
                    questions: {
                        howToCreateNewProject: 'How can I create a new project?',
                        howToDuplicateProjects: 'Can I "duplicate" a new project based on an existing project?',
                        howProjectOnboardingWorks: 'How does a project onboarding at PROPSTER work in detail?',
                        howToOrderAdditionalFunctions: 'How can I order additional functions for existing projects?',
                        howToGiveAccessToDashboard: 'How can I give colleagues access to the PROPSTER Company Dashboard?',
                        howToBookOnlineWorkshop: 'How can I book an online workshop for my company?',
                        howLongDoesItTake: 'How long does it take to create the configuration and collaboration platform?',
                        whichDocumentsAreRequired: 'Which documents are required for the creation of the configuration and collaboration platform?',
                        whichFileFormats: 'What options are there for exporting data. Which file formats?',
                        canPotentialBuyerAlreadyBePresented: 'Can a potential buyer already be presented with the configuration options via the platform?',
                        doBuyersAutomaticallyGetAccess: 'Do buyers automatically get access to defect management when the time comes? How can I, as project manager, influence when the individual buyers get access after handover of the apartment?',
                        howToDefineProprietaryProductSelection: 'How can I define a proprietary product selection that I can apply to all future projects?',
                        howToMakeAnAppointment: 'How and where can I make an appointment for an online demo presentation?',
                        whatIsGenerallyIncluded: 'What is generally included in PROPSTER Acquirer Support (1st level support)?',
                        whatAddonsAvailable: 'What add-ons and additional features are available?',
                        whereToFindTermsAndConds: 'Where can I find the terms and conditions?',
                        howToCancelContract: 'How can I cancel a current contract with PROPSTER?',
                        howToChangeMyBillingAddress: 'How can I change my billing address?',
                        whoCanIContactAboutMyInvoice: 'Who can I contact with questions about my invoice?',
                        canIPauseLicensePayments: 'Can I pause license payments?',
                        whereToSeeDurationOfMyLicenses: 'Where can I see the duration of my licenses?',
                        whereToSeeOverviewOfMyInvoices: 'Where can I see an overview of my invoices?',
                        whereToChangeMyPaymentMethods: 'Where can I change my payment methods?'
                    },
                    answers: {
                        howToCreateNewProject: 'Under the point "Projects" you can create and start a new project. After you have entered all relevant information for the project start, it appears in the project overview and the onboarding phase begins.',
                        howToDuplicateProjects: 'The URL must always be unique, so please decide on a new project address first. The settings can be applied, but project-specific conditions apply at the product level and prices further down the line.',
                        howProjectOnboardingWorks: 'After creating a new project, the onboarding phase starts. The PROPSTER project team will contact you and discuss the individual project steps. The onboarding phase includes up to 3 online onboardings with the responsible PROPSTER project manager. Depending on the progress of the project, onboarding appointments will be scheduled. The project set-up begins with the creation of your project-specific URL. The customer platform is set up with your project or company colors and logos. Following this, apartments are created in the system based on your room book. After your product selection is complete, craft-specific configurators are set up. During onboarding, the use of the project dashboard will be discussed. The end of the onboarding phase is set with product acceptance.',
                        howToOrderAdditionalFunctions: 'Please contact the responsible PROPSTER project manager with your specific requirements. Furthermore, it will be clarified whether this function is already available, or whether an additional cost for additional product adjustments must be charged.',
                        howToGiveAccessToDashboard: 'Under the menu item Users you can give other colleagues access to the PROPSTER Company Dashboard. If you do not have the appropriate authorization, please contact your responsible project manager at PROPSTER.',
                        howToBookOnlineWorkshop: 'Please contact your personal contact person at PROPSTER. You can request online workshops directly from the respective project manager or via your sales manager.',
                        howLongDoesItTake: 'Experience shows that 8-10 weeks lead time is planned for the creation of the configuration and collaboration platform. This can be significantly shorter if there has already been cooperation with PROPSTER in the past. During this time, the collection of all relevant documents and their correct transmission will take place. After complete receipt of all documents, the adjustments are usually carried out within a few weeks. For a successful cooperation we recommend to plan enough time.',
                        whichDocumentsAreRequired: 'The required documents vary depending on the particular use case and project. You will always receive a detailed list of the required information and how it will be submitted at the beginning of the project. Basically, PROPSTER requires color codes and visualizations for the visually appealing presentation, a topography including the room, floor and tile area, a listing of all available products including product images and the floor plans of the individual residential units. The required documents vary depending on the specific application. You will always receive a detailed list of the required information and the type of transmission at the beginning of the project.',
                        whichFileFormats: 'The data from your PROPSTER customer platform can be exported in various formats (Excel, csv, etc.). Here we follow the common file formats. For more detailed information, please contact your PROPSTER project manager directly.',
                        canPotentialBuyerAlreadyBePresented: 'Yes, a potential buyer can already be given access to the platform in the so-called sales mode. No registration is required for this, as the prospective buyer is given immediate access by clicking a button on the sales page with a corresponding link to the PROPSTER customer platform. The display of products and prices can be customized and thus differ from the view that a buyer sees during configuration.',
                        doBuyersAutomaticallyGetAccess: 'This currently requires additional activation by PROPSTER. Only when the sampling of all apartments is completed will a change take place.',
                        howToDefineProprietaryProductSelection: 'In PROPSTER Marketplace you can create a Wishlist, which can be adopted for all your projects. You can save the final product selection in the PROPSTER Company Dashboard.',
                        howToMakeAnAppointment: 'Click the link below and leave your email address directly: <a href="https://www.propster.tech/demo-praesentation" target="_blank">https://www.propster.tech/demo-praesentation</a>. We will actively contact you shortly regarding an online demo appointment.',
                        whatIsGenerallyIncluded: 'The 1st level support assists you in contacting and handling customer requests (sampling, document management, message box requests, etc.), thus guaranteeing that your customers receive the best possible support in configuring their properties. The exact scope of services may vary from project to project, but the PROPSTER sales team will be happy to explain these details to you.',
                        whatAddonsAvailable: 'The best way to find out is to contact our sales department. We will be happy to advise you in a personal telephone call. In addition, you can find an overview here: <a href="https://www.propster.tech/features/" target="_blank">https://www.propster.tech/features/</a>',
                        whereToFindTermsAndConds: 'You can view the terms and conditions and the license agreement of PROPSTER <a href="#">here</a>',
                        howToCancelContract: 'Please send an email with the information which project you want to cancel on which date to rechnungen@propster.tech.',
                        howToChangeMyBillingAddress: 'Simply click into the project settings and update the data regarding the project company. Then send us the invoice number on rechnungen@propster.tech so that we can reissue the corresponding invoice.',
                        whoCanIContactAboutMyInvoice: 'Contact rechnungen@propster.tech with any questions regarding your invoices.',
                        canIPauseLicensePayments: 'Generally, it is not possible to pause license payments. However, we will be happy to review your request. Please contact us at rechnungen@propster.tech.',
                        whereToSeeDurationOfMyLicenses: 'You can see the term of the licenses in the respective invoices in the remarks section or in the product description.',
                        whereToSeeOverviewOfMyInvoices: 'You can find an overview of the invoices in the company settings in the section "Invoices and payment methods".',
                        whereToChangeMyPaymentMethods: 'You can change your payment methods in the company settings in the section "Invoices and payment methods".'
                    },
                    categories: {
                        all: 'All',
                        projectManagement: 'Project Management',
                        sales: 'Sales',
                        finance: 'Finance'
                    },
                    other: {
                        haveAnyQuestions: 'Let\'s answer some questions',
                        orChooseACategory: 'or choose a category to quickly find help you need',
                        askAQuestion: 'Ask a question...',
                        categories: 'Categories'
                    }
                }
            },
            billing: {
                order: {
                    add: {
                        orderCreated: 'Order Created',
                        orderSuccessfullyCreated: 'New Order Was Successfully Created!',
                        orderNotCreated: 'Order Not Created'
                    },
                    edit: {
                        orderNotFound: 'Order Not Found',
                        orderWithIdNotFound: 'Order record with id: {orderId} not found.',
                        allOrders: 'All Orders',
                        orderUpdated: 'Order Updated',
                        orderNotUpdated: 'Order Not Updated',
                        orderSuccessfullyUpdated: 'Order was successfully updated'
                    },
                    list: {
                        addNewOrder: 'Add New Order',
                        orderDeleted: 'Order Deleted',
                        selectedOrderDeleted: 'The selected order was successfully deleted',
                        orderNotDeleted: 'Order Not Deleted'
                    },
                    other: {
                        number: 'Number',
                        date: 'Date',
                        title: 'Title',
                        license: 'License',
                        amount: 'Amount',
                        link: 'Link',
                        status: 'Status',
                        userEmail: 'User Email',
                        paymentDueDate: 'Due Date',
                        company: 'Company',
                        project: 'Project',
                        user: 'User',
                        servicePeriod: 'Service Period',
                        documentType: 'Type',
                        services: 'Services',
                        companyLicense: 'Company License',
                        projectLicense: 'Project License',
                        confirmApprove: 'Confirm Approve',
                        youAreAboutToApprove: 'You are about to approve "{title}"',
                        approve: 'Approve',
                        orderApproved: 'Order Approved',
                        selectedOrderApproved: 'Selected Order Was Successfully Approved',
                        orderNotApproved: 'Order Not Approved',
                        viewDetails: 'View Details',
                        documentTypes: {
                            invoice: 'Invoice',
                            offer: 'Offer'
                        },
                        statuses: {
                            payment_outstanding: 'Payment outstanding',
                            invoice_paid: 'Invoice paid',
                            credited: 'Credited',
                            paid_partly: 'Paid partly'
                        }
                    }
                }
            },
            login: {
                other: {
                    welcome: 'Welcome To PROPSTER Company Dashboard!',
                    pleaseSignIn: 'Please use your email address to log in',
                    signIn: 'Sign In',
                    rememberMe: 'Remember Me',
                    forgotPassword: 'Forgot Password?',
                    login: 'Back to login',
                    supportedBy: 'Supported by PROPSTER',
                    dontWorry: 'Don\'t worry, we will help you!',
                    enterTheEmailAddress: 'Enter the email address associated with your account',
                    send: 'Send',
                    accessHeading: 'Already PROPSTER customer, but you do not have access to the PROPSTER Company Dashboard yet?',
                    accessDescription: 'If you are already a PROPSTER customer and are currently having problems logging in, please contact us.',
                    notCustomerHeading: 'Not PROPSTER customer yet?',
                    notCustomerDescription: 'The PROPSTER Company Dashboard is only available for existing customers. If you would like more information about our Company Dashboard and the entire product range, please <a href="https://propster.tech/en/contact/" target="_blank">contact us.</a>',
                    next: 'Next',
                    back: 'Go Back',
                    password: 'Password',
                    azure_you_are_being_redirected: "You are being redirected to Microsoft Azure login page.",
                },
                messages: {
                    Incorrect_username_or_password: 'Email-address or password is incorrect',
                    product_partner_login_prohibited: 'Currently members of product-partner companies aren’t allowed to access this resource',
                    azure_can_not_determine_user: 'An error occurred: can not determine Microsoft Azure user.',
                    api_user_is_not_allowed_to_login: 'You can not log in with API user account.',
                    invalid_email: 'Invalid email format',
                    empty_password: 'Password must not be empty',
                }
            },
            logout: {
                messages: {
                    logout: "Logout",
                    being_logged_out: "You were successfully logged out",
                    you_are_not_logged_in: "You are not logged in"
                }
            },
            auth: {
                forgot: {
                    passwordResetSent: 'Password reset link has been sent',
                    passwordChanged: 'Password has been changed'
                },
                set_password: {
                    setupYourPassword: 'Setup Your Password',
                    createPassword: 'Create your password',
                    passwordSet: 'Password has been successfully set',
                    setYourPassword: 'Set Your Password',
                    pleaseSetPassword: 'Please enter your new password',
                    Set: 'Set',
                    Reset: 'Reset',
                    messages: {
                        TOKEN_INVALID: 'Password setup token is invalid',
                        TOKEN_EXPIRED: 'Password setup token is expired'
                    }
                },
                reset_password: {
                    messages: {
                        TOKEN_INVALID: 'Password reset token is invalid',
                        TOKEN_EXPIRED: 'Password reset token is expired'
                    }
                }
            },
            reports: {
                orders: 'Orders',
                userBehavior: 'User Behaviour',
                projectReports: 'Project Reports',
                allProjects: 'All Projects',
                eventLog: 'Event Log',
                event: {
                    dateTime: 'Date & Time',
                    userRole: 'User & Role',
                    appInstance: 'App & Instance',
                    company: 'Company',
                    eventObject: 'Event & Object',
                    noRows: 'The filter seems to be outdated. Try to reset the filter, and search again.',
                    showingRows: 'Showing %1$s-%2$s out of %3$s filtered events.',
                    filteredResults: '%1$s items found',
                    filters: {
                        dateFrom: 'From',
                        dateTo: 'To',
                        allUsers: 'All Users',
                        allCompanies: 'All Companies',
                        allApps: 'All Apps',
                        allRoles: 'All Roles',
                        allInstances: 'All Instances',
                        allEventTypes: 'All Event Types',
                        allObjects: 'All Objects',
                        objectId: 'ID',
                        itemsFound: 'item(s) found',
                        reset: 'Reset',
                        hidePropster: 'Hide Propster Activity'
                    }
                },
                order: {
                    statuses: {
                        pending: 'New Order',
                        neuebemusterung: 'New sampling',
                        internepruefung: 'Internal audit',
                        externepruefung: 'External audit',
                        pruefungfertig: 'Examination completed',
                        angebotversendet: 'Release offer',
                        rechnunggesendet: 'Release invoice',
                        freigabekunde: 'Request release',
                        nachunternehmen: 'Order execution',
                        deadlineexpired: 'Expired deadline',
                        cancelled: 'Cancelled',
                        completed: 'Completed',
                        onhold: 'On Hold',
                        protokoll: 'Handover protocol sent',
                        behoben: 'Fixed',
                        erstenachfrist: '3. grace period',
                        zweitenachfrist: '2. grace period',
                        drittenachfrist: '1. grace period',
                        ersatzvornahme: 'Substitute performance',
                        dpexterneprfng: 'External audit',
                        dpinterneprfng: 'Internal audit',
                        keinmangel: 'No defect',
                        neuermangel: 'New Defect'
                    },
                    ticketStatuses: {
                        processing: 'In Processing',
                        queued: 'Pending',
                        hold: 'On Hold',
                        trash: 'Deleted'
                    }
                },
                fileDownloads: 'Documents Statistics',
                fileDownloadsByMonth: 'Downloads by Date',
                Downloads: 'Downloads',
                tickets: 'Tickets',
                defects: 'Defects Management',
                defectsByStatus: 'Number of Defects by Status',
                defectsByCategory: 'Number of Defects by Category',
                requestsByStatus: 'Number of Orders by Status',
                requestsByCategory: 'Number of Orders by Category',
                defect: {
                    statuses: {
                        externepruefung: 'External audit',
                        internepruefung: 'Internal audit',
                        pending: 'New defect',
                        protokoll: 'Handover protocol sent',
                        behoben: 'Fixed',
                        erstenachfrist: '3. grace period',
                        zweitenachfrist: '2. grace period',
                        drittenachfrist: '1. grace period',
                        ersatzvornahme: 'Substitute performance',
                        cancelled: 'No defect',
                        completed: 'Fixed',
                        dpexterneprfng: 'External audit',
                        dpinterneprfng: 'Internal audit',
                        keinmangel: 'No defect',
                        neuermangel: 'New Defect',
                        neuebemusterung: 'New sampling',
                        pruefungfertig: 'Examination completed',
                        angebotversendet: 'Release offer',
                        rechnunggesendet: 'Release invoice',
                        freigabekunde: 'Request release',
                        nachunternehmen: 'Order execution'
                    },
                    categories: {
                        installateur_hkls: 'INSTALLER / HVAC',
                        aufzuganlage: 'ACCESSORIES',
                        fenster_sonnenschutz: 'SUN PROTECTION',
                        fliesen: 'TILES',
                        parkettboden: 'PARQUET FLOORING',
                        tischler_innentueren: 'WHG & INSIDE DOORS',
                        maler: 'PAINTING',
                        kueche: 'KITCHEN',
                        schlosser: 'LOCKSMITH',
                        wohnungsuebergabe: 'APARTMENT HANDOVER',
                        gewerke: 'TRADES',
                        elektro: 'ELECTRO',
                        fenster: 'WINDOWS',
                        terrassenbelag: 'TERRACE',
                        other: 'OTHER'
                    }
                }
            },
            stream: {
                sent_emails: {
                    dateAndTime: 'Date & Time',
                    from: 'From',
                    to: 'To',
                    subject: 'Subject',
                    viewed: 'Viewed',
                    actions: 'Actions',
                    viewEmail: 'View'
                },
                activities: {
                    dateAndTime: 'Date & Time',
                    action: 'Action',
                    context: 'Context',
                    who: 'Who',
                    created_File: 'Created File',
                    updated_File: 'Updated File',
                    deleted_File: 'Deleted File',
                    created_CompanyUser: 'Created User',
                    updated_CompanyUser: 'Updated User',
                    deleted_CompanyUser: 'Deleted User',
                    created_Company: 'Created Company',
                    updated_Company: 'Updated Company',
                    deleted_Company: 'Deleted Company',
                    updated_CompanyProject: 'Updated Project',
                    created_CompanyProject: 'Created Project',
                    deleted_CompanyProject: 'Deleted Project',
                    created_User: 'Created User',
                    updated_User: 'Updated User',
                    deleted_User: 'Deleted User',
                    logged_in_User: 'Logged In',
                    logged_out_User: 'Logged Out',
                    created_Order: 'Created Order',
                    updated_Order: 'Updated Order',
                    deleted_Order: 'Deleted Order',
                    created_ProjectService: 'Created Service',
                    updated_ProjectService: 'Updated Service',
                    deleted_ProjectService: 'Deleted Service',
                    created_Service: 'Created Service',
                    updated_Service: 'Updated Service',
                    deleted_Service: 'Deleted Service',
                    created_Country: 'Created Country',
                    updated_Country: 'Updated Country',
                    deleted_Country: 'Deleted Country',
                    created_Language: 'Created Language',
                    updated_Language: 'Updated Language',
                    deleted_Language: 'Deleted Language',
                    updated_CompanyUserRole: 'Updated Role',
                    created_CompanyUserRole: 'Created Role',
                    deleted_CompanyUserRole: 'Deleted Role',
                    updated_Marketplace: 'Updated Marketplace'
                }
            },
            news: {
                Add: 'Add News',
                editCategories: 'Edit Categories',
                categories: {
                    id: 'ID',
                    name: 'Title',
                    title: 'Title',
                    icon: 'Icon',
                    color: 'Color',
                    actions: 'Actions',
                    of: 'of',
                    Search: 'Search',
                    addNewCategory: 'Create Category',
                    NewCategory: 'New Category',
                    Name: 'Title',
                    delete: 'Delete',
                    Icon: 'Icon',
                    EditCategory: 'Edit Category',
                    Title: 'Title',
                    Color: 'Color',
                    confirmCancelEditing: 'Cancel editing?',
                    youHaveUnsavedChanges: 'There are unsaved changes',
                    Close: 'Close',
                    ContinueEditing: 'Go Back',
                    Canceled: 'Canceled',
                    editingACategoryHasBeenCanceled: 'Editing has been canceled',
                    categoryNotUpdated: 'Category was not updated',
                    somethingWentWrong: 'An error occurred',
                    addingACategoryHasBeenCanceled: 'Adding a category canceled',
                    categoryUpdated: 'Updated',
                    categorySuccessfullyUpdated: 'Category has been successfully updated.',
                    categoryNotCreated: 'Category not created!',
                    categoryCreated: 'Category Created',
                    categorySuccessfullyCreated: 'Category has been successfully created.',
                    confirmDelete: 'Are you sure?',
                    youAreAboutToDelete: 'You are about to delete a category.',
                    categoryDeleted: 'Category Deleted',
                    selectedCategoryDeleted: 'Selected Category was successfully deleted.'
                },
                add: {
                    success: 'Success!',
                    TargetAudience: 'Target Audience'
                },
                update: {
                    success: 'Success!'
                }
            }
        },
        navmenu: {
            items: {
                Home: 'Home',
                Apps: 'Apps',
                Users: 'Users',
                List: 'List',
                View: 'View',
                Edit: 'Edit',
                AddNew: 'Add New',
                Company: 'Company',
                Service: 'Service',
                Services: 'Services',
                Addons: 'Add-Ons',
                Projects: 'Projects',
                Project: 'Project',
                Pages: 'Pages',
                Profile: 'Profile',
                UserSettings: 'User Settings',
                Admin: 'Admin',
                Roles: 'Roles',
                UserRoles: 'User Roles',
                Reports: 'Reports',
                Dashboard: 'Dashboard',
                Helpcenter: 'Helpcenter',
                Faq: 'FAQ',
                Orders: 'Orders',
                EmailLog: 'Email Log',
                Marketplaces: 'Marketplaces',
                ActivityLog: 'Activity Stream',
                News: 'News',
                Dictionaries: 'Dictionaries',
                IdentityProviders: 'Identity Providers',
            },
            titles: {
                admin: {
                    apiUsers: 'API Users',
                    apiUserView: 'View API User',
                    apiUserEdit: 'Edit API User',
                    apiUserAdd: 'Add API User',
                },
                user: {
                    userList: 'User List',
                    userView: 'User View',
                    userEdit: 'User Edit',
                    addNewUser: 'Add New User',
                    editMyProfile: 'Edit My Profile'
                },
                company: {
                    companyList: 'Company List',
                    companyEdit: 'Company Edit',
                    viewCompany: 'View Company',
                    addNewCompany: 'Add New Company'
                },
                service: {
                    serviceList: 'Services',
                    addNewService: 'Add a Service',
                    editService: 'Edit Service'
                },
                addon: {
                    addonList: 'Add-Ons',
                    addNewAddon: 'Add an Add-On',
                    editAddon: 'Edit an Add-On'
                },
                project: {
                    addNewProject: 'Add New Project',
                    projectList: 'Projects',
                    viewProject: 'View Project',
                    editProject: 'Edit Project'
                },
                order: {
                    orderList: 'Orders',
                    addNewOrder: 'Add New Order',
                    viewOrder: 'View Order',
                    editOrder: 'Edit Order'
                },
                news: {
                    viewNews: 'News',
                    readNews: 'Read News',
                    categories: 'News Categories',
                    addNews: 'New Post',
                    editNews: 'Edit Post'
                },
                marketplace: {
                    marketplaceList: 'Marketplaces',
                    addNewMarketplace: 'Add a Marketplace',
                    editMarketplace: 'Edit a Marketplace'
                },
                other: {
                    Settings: 'Settings',
                    Profile: 'Profile',
                    Roles: 'Roles',
                    File: 'File',
                    EmailLog: 'Email Log',
                    PrivacyPolicy: 'Privacy Policy',
                    ActivityLog: 'Activity Stream',
                    Dictionaries: 'Dictionaries',
                    IdentityProviders: 'Identity Providers',
                }
            }
        },
        other: {
            countries: {
                noCountry: '-',
                DE: 'Germany',
                GB: 'United Kingdom',
                AT: 'Austria',
                CH: 'Switzerland',
                AL: 'Albania',
                AD: 'Andorra',
                AM: 'Armenia',
                AZ: 'Azerbaijan',
                BE: 'Belgium',
                BA: 'Bosnia and Herzegovina',
                BG: 'Bulgaria',
                HR: 'Croatia',
                CY: 'Cyprus',
                CZ: 'Czech Republic',
                DK: 'Denmark',
                EE: 'Estonia',
                FI: 'Finland',
                FR: 'France',
                GE: 'Georgia',
                GR: 'Greece',
                HU: 'Hungary',
                IS: 'Iceland',
                IE: 'Ireland',
                IT: 'Italy',
                LV: 'Latvia',
                LI: 'Liechtenstein',
                LT: 'Lithuania',
                LU: 'Luxembourg',
                MT: 'Malta',
                MC: 'Monaco',
                ME: 'Montenegro',
                NL: 'Netherlands',
                MK: 'North Macedonia',
                NO: 'Norway',
                PL: 'Poland',
                PT: 'Portugal',
                MD: 'Republic of Moldova',
                RO: 'Romania',
                RU: 'Russian Federation',
                SM: 'San Marino',
                RS: 'Serbia',
                SK: 'Slovak Republic',
                SI: 'Slovenia',
                ES: 'Spain',
                SE: 'Sweden',
                TR: 'Turkey',
                UA: 'Ukraine',
                SA: 'Saudi Arabia'
            },
            tooltips: {
                delete: 'Delete',
                edit: 'Edit',
                moreDetails: 'More Details',
                goToProject: 'Go To Project',
                goToOnboardingOverview: 'Go to Onboarding Overview'
            },
            validation: {
                messages: {
                    TheServicesFieldIsRequired: 'The services field is required',
                    TheProject_styleFieldIsRequired: 'The Project Style field is required',
                    TheTypeFieldIsRequired: 'The Type field is required',
                    exists: 'Exists',
                    validating: 'Validating...'
                }
            },
              cookies: {
                acceptAll: 'Accept All',
                onlyTechCookies: 'Only technically necessary cookies',
                message: 'We use Cookies to improve your experience on our website.',
                learnMore: 'Learn More'
              },
            somethingWentWrong: 'Something went wrong.',
            check: 'Check',
            copied: 'Copied',
            saveChanges: 'Save Changes',
            create: 'Create',
            reset: 'Reset',
            addressLine: 'Address Line',
            postCode: 'Post Code',
            city: 'City',
            country: 'Country',
            actions: 'Actions',
            name: 'Name',
            Search: 'Search',
            edit: 'Edit',
            add: 'Add',
            save: 'Save',
            update: 'Update',
            delete: 'Delete',
            cancel: 'Cancel',
            id: 'ID',
            confirmDelete: 'Confirm Delete',
            youAreAboutToDelete: 'You are about to delete "{name}"',
            previous: 'Previous',
            next: 'Next',
            step: 'Step',
            upTo: 'up to',
            perMeters: 'per m2',
            moreThan: 'more than',
            addFile: 'Add File',
            addFiles: 'Add File(s)',
            uploadFile: 'Upload File',
            fileNotFound: 'File Not Found',
            fileWithIdNotFound: 'File with id: {fileId} not found.',
            download: 'Download',
            fileDownload: 'File Download',
            showReport: 'Show Report',
            pleaseSelectProject: 'Please select project first!',
            pleaseSelectCompany: 'Please select company first!',
            projectGAUserBehaviourReportNotFound: 'GA User Behaviour Report for this project not found!',
            of: 'of',
            Close: 'Close',
            ContinueEditing: 'Continue Editing',
            Canceled: 'Canceled',
            status: 'Status',
            services: 'Services',
            company: 'Company',
            project: 'Project',
            marketplace: 'Marketplace',
            type: 'Type',
            address: 'Address',
            clickHere: 'Click Here',
            mobile: 'Mobile',
            yes: 'Yes',
            no: 'No',
            exportAsCsv: 'Export as CSV',
            privacyPolicy: 'Privacy Policy',
            marketplaces: 'Marketplaces',
            projects: 'Projects',
            Logout: 'Logout',
            All: 'All',
            warning: 'Warning',
            youHaveUnsavedChanges: 'You have not saved your changes',
            continueEditing: 'Continue editing',
            discardChanges: 'Discard changes',
            switchTo: 'Switch To',
            companyDashboard: 'Company Dashboard',
            projectDashboard: 'Customer Platform Editor',
            error: 'Error',
        },
        headerMenu: {
            myProfile: 'My Profile',
            Logout: 'Logout'
        },
        notifications: {
            titles: {
                new: 'New',
                new_multiple: 'New',
                notifications_list: 'Notifications',
                News_Published: 'New post shared:',
                Order_Created: 'New order created:',
                Order_Status_Updated: 'Order has been updated:',
                Project_Created: 'New project created:',
                Project_Status_Updated: 'Project has been updated:',
            },
            texts: {
                News_Published: '%1$s',
                Order_Created: '%1$s: No.%2$s',
                Order_Status_Updated: '%1$s: No.%2$s has now status "%3$s"',
                Project_Created: '%1$s created a new project "%2$s, %3$s, %4$s %5$s"',
                Project_Status_Updated: '"%1$s, %2$s, %3$s %4$s" has now status "%5$s"',
                no_notifications_yet: 'You don’t have any notifications'
            },
            time: {
                years_ago: '%1$s Years ago',
                year_ago: '%1$s Year ago',
                days_ago: '%1$s Days ago',
                day_ago: '%1$s Day ago',
                hours_ago: '%1$s Hrs ago',
                hour_ago: '%1$s Hour ago',
                mins_ago: '%1$s Mins ago',
                min_ago: '%1$s Min ago',
                secs_ago: '%1$s sec ago',
                sec_ago: '%1$s sec ago',
                just_now: 'Just Now'
            }
        }
    },
    pm: {
        navmenu: {
            items: {
                home: "Home",
                overview: "Overview",
                settings: "Settings",
                general: "General",
                homepage: "Home Page",
                design: "Design",
                features: "Features",
            }
        },
        settings: {
            general: {
                siteTitle: "Customer Platform Title",
                UseCustomDomain: "Use Custom Domain",
                customDomain: "Custom Domain",
                siteURL: "Customer Platform URL",
                language: "Platform Language",
                email: "Main Platform Communication Email",
                admin_email: "Admin email-address",
                signature: "Email Signature",
                ClientsCanAccess: 'Clients can access the project',
                CustomersCanAccess: 'Customers can access the project',
                buttons: {
                    create: "Create Project Website",
                    update: "Update Project Settings",
                },
                messages: {
                    create_success: "Platform address: %site_url%.propster.app has been successfully created and will be available in a few minutes. Some settings were copied from the previous project of your company. Please go across the settings and adjust them for your new project needs.",
                    update_success: "Settings have been successfully updated.",
                    validation_logo_key_visual_fail: 'Before creating a Project Website, please go to <a href="%wizard_url%" target="_blank"><b><u>Project Wizard</b></u></a> and upload <b>Logo</b> and <b>Project Key Visual</b> images.',
                    data_can_not_be_empty: "Data can not be empty.",
                    project_not_found: "Project not found.",
                    default_domain_not_defined: "Default domain not defined.",
                    can_not_save_design_settings: "Can not save Design settings.",
                    can_not_save_homepage_settings: "Can not save Homepage settings.",
                    can_not_save_features_settings: "Can not save Features settings.",
                    api_request_failed: "API request failed.",
                    internal_error: "Internal error."
                },
            },
            homepage: {
                Block1: "Home Page, Block 1",
                Image: "Image",
                Headline: "Headline",
                Text: "Text",
                Block2Contact: "Home Page, Block 2: Contact ",
                Name: "Name",
                Position: "Position",
                Photo: "Photo",
                Email: "Email",
                PhoneNumber: "Phone Number",
                ButtonText: "Button Text",
                ButtonURL: "Button URL",
                Block3: "Home Page, Block 3",
                LinkText: "Link Text",
                LinkTo: "Link To",
                Block4: "Home Page, Block 4",
                CustomLink: 'Custom Link',
                features: {
                    docubox: 'Docu Box',
                    messagebox: 'Message Box',
                    faq: 'FAQ/Helpcenter',
                    deadlines: 'Deadlines',
                    custom: 'Custom Link',
                },
            },
            design: {
                Logo: "Logo",
                LoginScreenImage: "Login Screen Image",
                PrimaryColor: "Primary Color",
                SecondaryColor: "Secondary Color",
                Favicon: 'Favicon',
            },
            features: {
                UseDeadlines: "Use Deadlines",
                UseHelpCenter: "Use Help Center",
                UseNews: "Use News",
                UseDocuBox: "Use Docu Box",
                CommunicationMethod: "Communication Method with customers",
                communication_methods: {
                    MessageBox: "Message Box",
                    ContactForm: "Contact Form",
                },
                ProjectMode: "Project Mode - view for customers",
                project_modes: {
                    Configurator: "Configurator",
                    DefectManagement: "Defect Management",
                }
            }
        }
    }
}
