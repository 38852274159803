/*=========================================================================================
  File Name: moduleSsoAppMutations.js
  Description: SSO App Module Mutations
==========================================================================================*/


export default {
 SET_MP_ROLES (state, ssoAppRoles) {
  state.ssoMpRoles = ssoAppRoles;
 },
 SET_MP_ROLES_LIST (state, ssoAppRoles) {
  state.ssoMpRolesList = ssoAppRoles;
 },
 ADD_NEW_MP_ROLE (state, role) {
  state.ssoMpRoles = [...state.ssoMpRoles, ...role]
 },
 DELETE_MP_ROLE (state, deletedRole) {
  state.ssoMpRoles = state.ssoMpRoles.filter(role => {
   return role.id !== deletedRole.id
  })
 }
}
