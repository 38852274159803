/*=========================================================================================
  File Name: moduleProjectsMutations.js
  Description: Projects Module Mutations
==========================================================================================*/

export default {
  SET_SENT_EMAILS (state, sent_emails) {
    state.sent_emails = sent_emails
  },
  SET_SENT_EMAIL (state, sent_email) {
    state.sent_email = sent_email
  },
  SET_ACTIVITIES (state, activities) {
    state.activities = activities
  }
}
