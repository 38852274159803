/*=========================================================================================
  File Name: moduleSsoAppState.js
  Description: SSO App Module State
==========================================================================================*/

export default {
  RequestStatusDetailedList: [],
  DefectStatusDetailedList: [],
  SwmProductCategoryDetailedList: [],
  DefectItemCategoryDetailedList: [],
  TicketStatusDetailedList: [],
}
