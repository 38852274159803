import helperFunctions from '@/helper/functions.js';

export default {
	syncCompanies() {
		return `
			{
				ssoSyncCompanies {
					status,
					message
				}
			}
		`;
	},
	syncProjects() {
		return `
			{
				ssoSyncProjects {
					status,
					message
				}
			}
		`;
	},
	syncUsers() {
		return `
			{
				ssoSyncUsers {
					status,
					message
				}
			}
		`;
	},
}
