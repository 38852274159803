export default {
    dashboard: {
        pages: {
            dashboard: {
                welcomeHeader: 'Herzlich Willkommen {fullName}!',
                welcomeContent: 'Auf Ihrem persönlichen Dashboard finden Sie sofort alle wichtigen Informationen zur Ihren Projekten!',
                welcomeButtonText: 'Projektübersicht',
                startNewProjectHeader: 'Neues Projekt starten',
                startNewProjectContent: 'In ein paar Schritten ein neues Projekt starten!',
                startNewProjectButtonText: 'Projekt starten',
                reportsHeader: 'Berichte ansehen',
                reportsContent: 'Hier finden Sie alle wichtigen Zahlen und Fakten zu Ihren Projekten!',
                reportsButtonText: 'Berichte Anzeigen',
                projectsHeader: 'Projekte',
                gbSpecificationLine1: 'Willkommen im GOLDBECK Bemusterungsmanager,',
                gbSpecificationLine2: 'Sie haben Zugriff zu den folgenden Datenbanken und Features.',
                gbSpecificationLine3: 'Bitte wählen Sie Ihr Ziel:',
                gbProjectList: 'Projekt-Stammdaten',
            },
            user: {
                add: {
                    userCreated: 'Benutzer erfolgreich angelegt!',
                    sendWelcomeEmailQuestion: 'Willkommens-E-Mail an neuen Benutzer senden?',
                    welcomeEmailDoesntSent: 'Die Willkommens-E-Mail wurde nicht gesendet.',
                    validation: {
                        aUserWithSuchEmailAlreadyExists: 'Ein Benutzer mit dieser E-mail ist bereits vorhanden.'
                    }
                },
                edit: {
                    userUpdated: 'Benutzer erfolgreich aktualisiert!',
                    userNotFound: 'Benutzer nicht gefunden',
                    userWithIdNotFound: 'Benutzer mit ID: {userId} nicht gefunden',
                    allUsers: 'Alle Benutzer',
                    changeAvatar: 'Avatar ändern',
                    removeAvatar: 'Avatar entfernen',
                    password: 'Passwort',
                    newPassword: 'Neues Passwort anlegen',
                    confirmPassword: 'Passwort Bestätigen',
                    passwordValidateMessage: 'Das Passwort muss mindestens enthalten: 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Zahl und 1 Sonderzeichen (z. B. @ _ & ? etc)',
                    passwordConfirmValidateMessage: 'Passwörter stimmen nicht überein',
                    personalInformation: 'Persönliche Informationen',
                    birthdayDate: 'Geburtsdatum',
                    position: 'Position',
                    department: 'Abteilung',
                    gender: 'Geschlecht',
                    male: 'Männlich',
                    female: 'Weiblich',
                    address: 'Adresse',
                    resendWelcomeEmail: 'Begrüßungs-E-Mail erneut senden',
                    areYouSureYouWantToResend: 'Möchten Sie die Begrüßungs-E-Mail wirklich erneut senden?',
                    assignedProjects: 'Zugewiesene Projekte',
                    marketplaces: {
                        Marketplaces: 'Marketplace-wide',
                        Company: 'Company-wide',
                        Project: 'Project',
                        NoProjectsMessage: 'Derzeit hat dieses Unternehmen keine verknüpften Projekte.<br/><br/>\n' +
                         '\n' +
                         'Sie können ein neues Projekt im Abschnitt “Projekte” erstellen.<br/><br/>\n' +
                         '\n' +
                         'Bis dahin können Sie nur die Standard-Benutzerrolle für alle zukünftigen Projekte in diesem Unternehmen für die Marketplaces festlegen.',
                        NoCompaniesMessage: 'Derzeit ist dieser Benutzer mit keinem Unternehmen verbunden.<br/><br/>\n' +
                         '\n' +
                         'Bitte gehen Sie zum Tab “Account”, und fügen Sie mindestens ein Unternehmen hinzu, speichern Sie die Änderungen und kehren Sie zu dieser Seite zurück.'
                    },
                    apps: {
                        Applications: 'App-wide',
                        Company: 'Company-wide',
                        Project: 'Projekt',
                        ssoAppServices: {
                            sso_configurator: 'Konfigurator & Mangelmanagement',
                            sso_dashboard: 'PROPSTER Company Dashboard',
                        },
                        NoCompanies: 'Keine Unternehmen',
                        NoProjects: 'Keine Projekte',
                        NoRole: 'Keine Rolle',
                        NoProjectsMessage: 'Derzeit hat dieses Unternehmen keine verknüpften Projekte.<br/><br/>\n' +
                         '\n' +
                         'Sie können ein neues Projekt im Abschnitt “Projekte” erstellen.<br/><br/>\n' +
                         '\n' +
                         'Bis dahin können Sie nur die Standard-Benutzerrolle für alle zukünftigen Projekte des Unternehmens festlegen.',
                        NoCompaniesMessage: 'Derzeit ist dieser Benutzer mit keinem Unternehmen verbunden.<br/><br/>\n' +
                         '\n' +
                         'Bitte gehen Sie zum Tab “Account”, und fügen Sie mindestens ein Unternehmen hinzu, speichern Sie die Änderungen, und kehren Sie zu dieser Seite zurück.<br/><br/>\n' +
                         '\n' +
                         'Bis dahin können Sie nur die Standard-Benutzerrolle für alle zukünftigen Unternehmen und Projekte festlegen.'
                    },
                    validation: {
                        aUserWithSuchEmailAlreadyExists: 'Ein Benutzer mit dieser E-mail ist bereits vorhanden.'
                    }
                },
                myProfile: {
                    changeIcon: 'Symbol ändern',
                    removeIcon: 'Symbol entfernen',
                    generalInformation: 'Allgemeine Informationen',
                    newPassword: 'Neues Passwort (bitte zweimal eingeben)',
                    socialInformation: 'Social',
                    company: 'Company',
                    contactData: 'Contact Data',
                    addressLine: 'Adresszeile'
                },
                list: {
                    active: 'Aktiv',
                    inactive: 'Inaktiv',
                    status: 'Status',
                    addUser: 'Nutzer hinzufügen',
                    AllAppServices: 'All Apps',
                    AllMpMarketplaces: 'Alle Marktplätze',
                    AllCompanies: 'Alle Unternehmen',
                    AllProjects: 'Alle Projekte',
                    AllRoles: 'Alle Rollen',
                },
                other: {
                    firstName: 'Vorname',
                    lastName: 'Nachname',
                    email: 'Email',
                    phoneNumber: 'Telefonnummer',
                    company: 'Unternehmen',
                    role: 'Rolle',
                    language: 'Sprache',
                    noCompany: 'Kein Unternehmen',
                    noCompanies: 'No Companies',
                    noProjects: 'No Projects',
                    deleteAUser: 'Benutzer löschen',
                    pleaseConfirmDeletingUser: 'Bitte bestätigen Sie das Löschen des Benutzers {firstName} {lastName}.',
                    youCanNotDeleteUser: 'Sie können den Benutzer {firstName} {lastName} nicht löschen, da dieser entweder einer Benutzerrolle zugewiesen ist oder über Berechtigungen in Projekten oder im Marketplace verfügt.',
                    roles: {
                        no_role: 'No Role',
                        super_administrator: 'Super Administrator',
                        administrator: 'Administrator',
                        admin: 'Admin',
                        company_admin: 'Unternehmens Admin',
                        owner: 'Inhaber',
                        project_manager: 'Projektmanager',
                        staff: 'Mitarbeiter',
                        propster_finance: 'PROPSTER finance',
                        subscriber: 'Subscriber',
                        shop_manager: 'Shop Manager',
                        pending_vendor: 'Pending Vendor',
                        vendor_admin: 'Vendor Admin',
                        vendor_manager: 'Vendor Manager',
                        translator: 'Translator',
                        project_admin: 'Projekt Admin',
                    }
                }
            },
            company: {
                add: {
                    companyCreated: 'Unternehmen erstellt',
                    companyNotCreated: 'Unternehmen nicht erstellt',
                    companySuccessfullyCreated: 'Neue Unternehmen wurde erfolgreich erstellt!'
                },
                edit: {
                    companyNotFound: 'Unternehmen nicht gefunden',
                    companyWithIdNotFound: 'Unternehmensndatensatz mit der ID: {companyId} nicht gefunden.',
                    allCompanies: 'Alle Unternehmen',
                    companyUpdated: 'Unternehmen aktualisiert',
                    companyNotUpdated: 'Unternehmen nicht aktualisiert',
                    companySuccessfullyUpdated: 'Unternehmen wurde erfolgreich aktualisiert',
                    tabGeneral: 'Allgemeine Informationen',
                    tabInvoicing: 'Rechnungsdaten',
                    tabMarketplaces: 'Marketplaces',
                    tabPaymentInformation: 'Zahlungsinformation',
                    hasProjectDashboard: 'Aktivieren Sie den Kundenplattform-Editor',
                    identityProvider: 'Identity Provider',
                },
                list: {
                    of: 'von',
                    exportAsCsv: 'Als CSV exportieren',
                    addNewCompany: 'Neue Unternehmen hinzufügen',
                    bulkDeleteCompaniesConfirmMessage: 'Wollen Sie wirklich alle ausgewählten Unternehmen löschen?',
                    companiesDeleted: 'Unternehmen gelöscht',
                    companiesNotDeleted: 'Nicht gelöschte Unternehmen',
                    selectedCompaniesDeleted: 'Alle ausgewählten Unternehmen wurden erfolgreich gelöscht',
                    companyDeleted: 'Unternehmen gelöscht',
                    selectedCompanyDeleted: 'Die ausgewählte Unternehmen wurde erfolgreich gelöscht',
                    companyNotDeleted: 'Unternehmen nicht gelöscht',
                    deleteACompany: 'Delete a Company',
                    confirmDelete: 'Please confirm deleting company {name}.',
                    cantDeleteCompany: 'Sie können das Unternehmen “{name}” nicht löschen, da Nutzer und/oder Projekte mit dem Unternehmen verbunden sind:',
                },
                other: {
                    uidNumber: 'VAT / UID',
                    paymentModality: 'Zahlungsmodalität',
                    paymentCadence: 'Zahlungsfrequenz',
                    iban: 'IBAN',
                    bic: 'BIC/SWIFT',
                    bankInstitute: 'Bankinstitut',
                    sepa: 'SEPA',
                    bankTransfer: 'Überweisung',
                    monthlyPayment: 'Monatliche Zahlung',
                    quarterlyPayment: 'Quartalsweise Zahlung',
                    annualPayment: 'Jährliche Zahlung',
                    street: 'Straße',
                    houseNumber: 'Hausnummer',
                    companyRegister: 'Firmenregister',
                    website: 'Website',
                    viewDetails: 'Details anzeigen',
                    responsibleSalesManager: 'Verantwortlicher Verkaufsleiter',
                    parentCompany: 'Muttergesellschaft',
                    companyType: 'Typ des Unternehmens',
                    development: 'Kunde',
                    vendor: 'Produkt-Partner',
                    public_phone_number: 'zentrale Telefonnummer',
                    billingCompanyName: 'Name',
                    billingPostCode: 'Postleitzahl',
                    billingStreet: 'Straße',
                    billingHouseNumber: 'Hausnummer',
                    billingCity: 'Stadt',
                    billingCountry: 'Land',
                    billingInvoicingEmail: 'Rechnungs-Email',
                    billingContactPerson: 'Ansprechpartner',
                    billingPhone: 'Tel. Buchhaltung',
                    privateMarketplaces: 'Private Marketplaces',
                    publicMarketplaces: 'Öffentliche Marketplaces',
                    parentCompanyWarning: 'Achtung! Dieses Unternehmen hat {childCompaniesLength} Tochtergesellschaften. Wenn Sie dieses Unternehmen mit  Marketplaces verknüpfen, werden automatisch auch alle Tochterunternehmen verknüpft. Bitte fahren Sie nur fort, wenn dies Ihre Absicht ist. Andernfalls wählen Sie bitte die untergeordneten Unternehmen einzeln aus, um den gewünschten Zugriff individuell zu definieren.',
                    companyLogo: 'Company Logo',
                    changeLogo: 'Change Logo',
                    removeLogo: 'Remove Logo',
                }
            },
            marketplace: {
                add: {
                    name: 'Name',
                    marketplaceType: 'Marketplace Typ',
                    websiteUrl: 'Webseiten URL',
                    marketplaceId: 'Marketplace ID'
                },
                edit: {
                    generalInfo: 'Generelle Infos',
                    companies: 'Unternehmen',
                    marketplaceNotFound: 'Marketplace nicht gefunden',
                    marketplaceWithIdNotFound: 'Marketplace-Datensatz mit der ID: {marketplaceId} nicht gefunden.',
                    allMarketplaces: 'Alle Marketplaces',
                    parentCompanySelectedWarning: 'Achtung! Dieses Unternehmen hat {childCompaniesLength} Tochtergesellschaften. Wenn Sie dieses Unternehmen mit diesem Marketplace verknüpfen, werden automatisch auch alle Tochterunternehmen verknüpft. Bitte fahren Sie nur fort, wenn dies Ihre Absicht ist. Andernfalls wählen Sie bitte die untergeordneten Unternehmen einzeln aus, um den gewünschten Zugriff individuell zu definieren.',
                    changeIcon: 'Icon ändern',
                    removeIcon: 'Icon entfernen'
                },
                list: {
                    addNewMarketplace: 'Neuen Marketplace hinzufügen',
                    confirmDeactivation: 'Bestätigung der Deaktivierung',
                    doYouWishToContinueDeactivate: 'Soll dieser Marketplace wirklich deaktiviert werden?',
                    confirmActivation: 'Bestätigung der Aktivierung',
                    doYouWishToContinueActivate: 'Soll dieser Marketplace wirklich aktiviert werden?',
                    deleteMarketplace: 'Delete a Marketplace',
                    confirmDeleting: 'Please confirm deleting marketplace "{marketplaceName}".',
                    cantDeleteMarketplace: 'You cannot delete marketplace "{marketplaceName}" because it’s connected to one or more Companies.',
                    marketplaceDeleted: 'Marketplace Deleted',
                    selectedMarketplaceDeleted: 'The selected Marketplace was successfully deleted',
                    marketplaceNotDeleted: 'Marketplace Not Deleted'
                },
                other: {
                    name: 'Name',
                    edit: 'Bearbeiten',
                    numberOfCompanies: '# der verbundenen Unternehmen',
                    activate: 'Aktivieren',
                    deactivate: 'Deaktivieren',
                    public: 'Öffentlich',
                    private: 'Privat',
                    delete: 'Delete',
                    ok: 'OK'
                },
                errors: {
                    nameIsRequired: 'Name des Marketplace ist erforderlich',
                    websiteUrlIsRequired: 'Webseiten URL ist erforderlich',
                    marketplaceIdIsRequired: 'Marketplace ID ist erforderlich'
                }
            },
            service: {
                add: {},
                edit: {},
                list: {
                    addNewService: 'Add New Service',
                    deleteService: 'Delete a Service',
                    confirmDeleting: 'Please confirm deleting service "{serviceName}".',
                    cantDeleteService: 'You cannot delete service "{serviceName}" because it’s connected to one or more Projects.',
                    serviceDeleted: 'Service Deleted',
                    selectedServiceDeleted: 'The selected service was successfully deleted',
                    serviceNotDeleted: 'Service Not Deleted'
                },
                other: {
                    order: 'Order',
                    icon: 'Icon',
                    iconCode: 'Icon Code',
                    numberOfProjects: '# of Projects',
                    name: 'Name',
                    description: 'Description',
                    descriptionPlaceholder: 'Write description...',
                    delete: 'Delete',
                    ok: 'OK'
                },
                errors: {
                    valueIsRequired: 'The {field} is required',
                    shouldBeInteger: 'Order should be an Integer, 1 or bigger'
                }
            },
            addon: {
                add: {},
                edit: {},
                list: {
                    addNewAddon: 'Add New Add-On',
                    deleteAddon: 'Delete an Add-On',
                    confirmDeleting: 'Please confirm deleting Add-On "{addonName}".',
                    cantDeleteAddon: 'You cannot delete Add-On "{addonName}" because it’s connected to one or more Projects.',
                    addonDeleted: 'Add-On Deleted',
                    selectedAddonDeleted: 'The selected Add-On was successfully deleted',
                    addonNotDeleted: 'Add-On Not Deleted'
                },
                other: {
                    order: 'Order',
                    icon: 'Icon',
                    iconCode: 'Icon Code',
                    numberOfProjects: '# of Projects',
                    name: 'Name',
                    delete: 'Löschen',
                    ok: 'OK',
                    keyName: 'Key Name',
                    description: 'Description',
                    availableDisclaimer: 'Available for a Project only if one of following services is selected:'
                },
                errors: {
                    valueIsRequired: 'The {field} is required',
                    shouldBeInteger: 'Order should be an Integer, 1 or bigger'
                }
            },
            project: {
                add: {
                    pleaseSelectFeatures: '<span>Fangen wir an und legen wir nun den Projektumfang fest!</span><br/>Klicken Sie bitte die Features, die Sie in Ihrem Projekt nutzen möchten.',
                    pleaseFillProjectAddress: '<span>Starten wir zuerst mit einigen allgemeinen Fragen!</span><br/> Nennen Sie uns bitte die genaue Projekt-Adresse (Straße, PLZ, Ort, Land):',
                    whenWouldYouLikeToStart: '<span>Wann möchten Sie bestenfalls mit der Vermarktung starten?</span><br/> (bitte beachten Sie, dass wir bei Erstprojekten im Normalfall, je nach Projektgröße, 8-10 Wochen Vorlaufzeit für neue Projekte benötigen).',
                    whatIsTheProjectCompanyName: '<span>Wie lautet der offizielle Name der Projektgesellschaft, die dieses Projekt ausführt?</span>',
                    pleaseSelectProjectType: '<span>Bitte wählen Sie hier Ihren Projekttyp aus!</span>',
                    howManyMeters: '<span>Über wieviele m2 sprechen wir insgesamt?</span>',
                    howManyUnits: '<span>Wieviele Einheiten werden gebaut?</span>',
                    projectPriceRange: '<span>In welchem Preisrahmen wird sich Ihr Projekt im Verkauf bewegen?</span>',
                    pleaseSelectProjectStyle: '<span>In welche Stilrichtung würden Sie Ihr Projekt am ehesten kategorisieren?</span>',
                    areThereSampleFloorPlans: '<span>Gibt es bereits Beispiel-Grundrisse?</span><br/> Wenn ja, dann bitte hier hochladen! Wenn nicht - bitte einfach überspringen!',
                    areThereAlreadyVisualizations: '<span>Gibt es bereits Visualisierungen?</span><br/> Wenn ja, dann bitte hier hochladen! Wenn nicht - bitte einfach überspringen!',
                    pleaseSendUsTheEquipmentDescription: '<span>Bitte übermitteln Sie uns hier die Ausstattungsbeschreibung des Projektes!</span><br/> Falls diese noch nicht vollständig fertig ist, gerne auch den derzeitigen Stand.',
                    whoDealsWithTheProductSelection: '<span>Wer beschäftigt sich bei diesem Projekt mit der Produktauswahl (Standardprodukte + Alternativprodukte)?</span>',
                    whoWillHandleSpecialRequests: '<span>Wer wird bei Ihnen die Sonderwünsche/ Konfigurationen/ Mangelbearbeitung abwickeln?</span>',
                    areYouInterestedInBanner: '<span>Haben Sie Interesse an einem kostenlosen, auf ihr Projekt maßgeschneidertes Bauzaunbanner für Ihre Baustelle?</span>',
                    yourBannerCouldLookLikeThis: 'So könnte ihr persönliches Banner aussehen',
                    hasBuildingPermitAlreadyIssued: '<span>Wurde die Baubewilligung bereits erteilt?</span>',
                    projectWasSuccessfullyCreated: '<span>HERZLICHEN GLÜCKWUNSCH IHR PROJEKT WURDE ERFOLGREICH ERSTELLT.</span>',
                    weWillContactYouShortly: 'Wir werden Sie in Kürze bezüglich weiterer Projektschritte kontaktieren.',
                    leaveUsNotes: 'Möchten Sie uns noch Notizen hinterlassen - bitte nutzen Sie das nachstehende Feld!',
                    uploadLogo: '<span>Gibt es bereits ein Projektlogo?</span><br/> Wenn ja, dann bitte hier hochladen! Wenn nicht - laden Sie bitte Ihr Firmenlogo hoch!',
                    backToProjectOverview: 'Zurück zur Projektübersicht',
                    steps: {
                        areYouInterestedInBanner: 'Sind Sie an einem kostenlosen Baustellenbanner für Ihre Baustelle interessiert?',
                        yourBannerCouldLookLikeThis: 'Ihr persönliches Banner kann wie folgt aussehen:',
                        whenWouldYouLikeToStart: 'Wann würden Sie gerne mit der Vermarktung beginnen?',
                        whenWouldYouLikeToStart_notice: '(Bitte beachten Sie, dass wir in der Regel, je nach Projektgröße, für Erstprojekte 8-10 Wochen Vorlaufzeit benötigen)',
                        leaveUsNotes: 'Möchten Sie uns eine Notiz hinterlassen? - nutzen Sie bitte das untenstehende Feld!',
                        addNewProject: 'Neues Projekt hinzufügen',
                        projectInformation: 'Projekt Information',
                        projectDetails: 'Projekt Details',
                        projectType: 'Projekttyp',
                        projectDocuments: 'Dokumente',
                        marketplace: 'Kontakt',
                        deadlinesAndNotes: 'Fristen & Notizen',
                        finish: 'Fertig',
                        not_issued_notes: 'Bitte teilen Sie uns mit, wann der Baustart geplant ist:',
                        integrations: {
                            title: 'Integrations',
                            PROPSTERAppConnection: 'PROPSTER App Connection',
                            defectManagementConnection: 'Defect Management Connection',
                            apiBaseURL: 'API Base URL',
                            apiPath: 'API Path',
                            apiUserKey: 'API User Key',
                            apiConsumerSecret: 'API Consumer Secret'
                        }
                    }
                },
                edit: {
                    General: 'Allgemein',
                    Services: 'Features',
                    Address: 'Adresse',
                    Contacts: 'Kontakte',
                    Files: 'Dokumente',
                    floor_plan: 'Grundrisse',
                    visualization: 'Visualisierung',
                    equipment_description: 'Baubeschreibung',
                    logo: 'Logo',
                    extension: 'Extension',
                    areThereSampleFloorPlans: 'Grundrisse Hochladen',
                    areThereAlreadyVisualizations: 'Visualisierung Hochladen',
                    pleaseSendUsTheEquipmentDescription: 'Baubeschreibung Hochladen',
                    uploadLogo: 'Logo Hochladen',
                    dashboardSettings: 'Dashboard-Einstellungen',
                    propsterSettings: 'PROPSTER-Einstellungen',
                    projectURL: 'Projekt-URL',
                    blogId: 'Project Multisite Blog ID',
                    blogRegion: 'Projektregion',
                    apiPath: 'Project API Pfad (Version, z.B. "wc/v1")',
                    wcUserKey: 'API Benutzerschlüssel',
                    wcUserConsumerSecret: 'API Verbrauchergeheimnis',
                    behaviorReportURL: 'Google Tag Manager Bericht URL',
                    deleteTitle: 'Datei Entfernen',
                    fileDeleteSuccess: 'Datei wurde erfolgreich gelöscht',
                    fileDeleteFailure: 'Datei konnte nicht gelöscht werden',
                    projectUpdated: 'Projekt erfolgreich aktualisiert!',
                    whoDealsWithTheProductSelection: 'AnsprechpartnerIn Produkt-/ Mangelbearbeitung',
                    whoWillHandleSpecialRequests: 'AnsprechpartnerIn Produktauswahl (bearbeitet)',
                    projectOnboardingOverviewURL: 'Onboarding Übersicht',
                    mmgmtURL: 'MMGMT URL',
                    mmgmtUserKey: 'MMGMT API Benutzerschlüssel',
                    mmgmtConsumerSecret: 'MMGMT API Verbrauchergeheimnis',
                    mmgmtApiPath: 'MMGMT API Pfad (Version, z.B. "wc/v1")',
                    changeIcon: 'Change Icon',
                    removeIcon: 'Remove Icon',
                    service: 'Service',
                    licenseId: 'Lizenz ID',
                    addon: 'Add-On',
                    editService: 'Service bearbeiten',
                    servicesProjectUrl: 'Projekt URL',
                    gtmURL: 'Google Tag Manager Report URL',
                    editAddon: 'Add-On bearbeiten',
                    siteUrlError: 'Bitte geben Sie eine URL mit http oder https ein!',
                    confirmDeactivation: 'Bestätigung der Deaktivierung',
                    doYouWishToContinueDeactivate: 'Soll dieser Service wirklich deaktiviert werden?',
                    doYouWishToDeactivateAddon: 'Soll dieser Add-on wirklich deaktiviert werden?',
                    doYouWishToActivateAddon: 'Soll dieser Add-on wirklich aktiviert werden?',
                    confirmActivation: 'Bestätigung der Aktivierung',
                    doYouWishToContinueActivate: 'Soll dieser Service wirklich aktiviert werden?',
                    activate: 'Aktivieren',
                    deactivate: 'Deaktivieren',
                    pleaseSelectServices: 'Bitte wählen Sie hier die gewünschten PROPSTER Services für Ihr Projekt aus:',
                    pleaseSelectAddons: 'Bitte wählen Sie hier die gewünschten Add-Ons für Ihr Projekt aus:'
                },
                list: {
                    somethingWentWrong: 'Ein Fehler ist aufgetreten',
                    company: 'Unternehmen',
                    exportAsCsv: 'Export As CSV',
                    addNewProject: 'Neues Projekt hinzufügen',
                    actions: 'Aktionen',
                    delete: 'Löschen',
                    id: 'ID',
                    name: 'Name',
                    Name: 'Name',
                    AssignedProjectUsers: 'dem Projekt zugewiesene User',
                    confirmDelete: 'Löschen bestätigen.',
                    youAreAboutToDelete: 'Sind Sie sicher den Datensatz löschen zu wollen?',
                    projectDeleted: 'Projekt wurde gelöscht!',
                    selectedProjectDeleted: 'Ausgewähltes Projekt wurde gelöscht',
                    projectNotDeleted: 'Projekt konnte nicht gelöscht werden',
                    confirmCancelEditing: 'Wollen Sie die Bearbeitung wirklich abbrechen?',
                    youHaveUnsavedChanges: 'Es gibt nicht gespeicherte Änderungen',
                    editingARoleHasBeenCanceled: 'Rollenbearbeitung wurde abgebrochen',
                    address: 'Adresse',
                    status: 'Status',
                    projectInfo: 'Projekt Information',
                    createdBy: 'erstellt von',
                    createdAt: 'erstellt am',
                    services: 'Features',
                    AllCompanies: 'Alle Unternehmen',
                    AllStatuses: 'Alle Status',
                    AllCountries: 'Alle Länder',
                    AllUsers: 'Alle Nutzer',
                    created_at: 'ERSTELLT AM',
                    creator: 'Erstellt von',
                    created_between: 'Erstellt, zwischen',
                    created_from: 'Startdatum auswählen',
                    created_to: 'Enddatum auswählen',
                },
                csv: {
                    company: 'Projektgesellschaft',
                    projectName: 'Projektname',
                    address1: 'Adresse',
                    address2: 'Adresse (Zusatz)',
                    propsterProjectId: 'PROPSTER Project ID',
                    is_third_party_company: 'für Drittunternehmen gebaut',
                    client_name: 'Kundenname',
                    client_company_name: 'Unternehmen',
                    client_address_1: 'Kundenadresse',
                    client_address_2: 'Kundenadresse (Zusatz)',
                    client_postcode: 'Postleitzahl des Kunden',
                    client_city: 'Stadt des Kunden',
                    project_number: 'Projektnummer',
                    services: 'Services',
                    addons: 'Add-Ons',
                    building_from_scratch: 'Neubau',
                    residential_area: 'Wohnbau',
                    commercial_area: 'Gewerbe',
                    area_residential_investment: 'Wohnbau: Anelger oder Direktverkauf',
                    area_residential_rent: 'Wohnbau: Vermietung',
                    area_commercial_investment: 'Gewerbe: Anleger oder Direktverkauf',
                    area_commercial_rent: 'Gewerbe: Vermietung',
                    project_style: 'Projektstil',
                    projectOnboardingOverviewURL: 'Überblick Projekt Onboarding',
                    handle_user: 'Kontaktperson Produkt- & Sonderwunschbearbeitung',
                    deals_user: 'Kontaktperson Produktauswahl/ Planung',
                    project_manager_name: 'Verantwortlicher Projektmanager',
                    fence_banner: 'Baustellenbanner',
                    building_permit: 'Baubewillgung',
                    building_permit_comments: 'Kommentare Baubewilligung',
                    notes: 'Weitere Informationen',
                    project_url: 'PAPP API Base URL',
                    stats_swm_wc_user_key: 'PAPP API User Key',
                    stats_swm_wc_consumer_secret: 'PAPP API Consumer Secret',
                    stats_mmgmt_api_url: 'MMGT API Base URL',
                    stats_mmgmt_user_key: 'MMGT API User Key',
                    stats_mmgmt_consumer_secret: 'MMGT API Consumer Secret',
                    created_at: 'Erstellt Am',
                    creator: 'Erstellt von',
                    postCode: 'Postleitzahl',
                    city: 'Stadt',
                    status: 'Status',
                    country: 'Land',
                    clientCountry: 'Kunde Land'
                },
                statuses: {
                    draft: 'Entwurf',
                    payment_outstanding: 'Zahlung ausständig',
                    in_setup: 'Im Aufbau',
                    active: 'Aktiv (live)',
                    inactive: 'Inaktiv',
                    archived: 'Archiviert'
                },
                overview: {
                    overview: 'ÜBERSICHT',
                    services: 'SERVICES',
                    areaAndSales: 'PROJEKT PARAMETER & PREISE',
                    responsibleProjectManager: 'VERANTWORTLICHER PROJEKTMANAGER (SUPPORT)',
                    configurationContact: 'VERANTWORTLICHER ABWICKLUNG',
                    productSelectionContact: 'VERANTWORTLICHER PRODUKTAUSWAHL',
                    projectName: 'Projektname',
                    propsterProjectID: 'PROPSTER Projekt ID',
                    status: 'Status',
                    company: 'Unternehmen',
                    address: 'Adresse',
                    projectStyle: 'Projekttyp',
                    marketingStartDate: 'Vermarktungsstart',
                    buildingForAThirdParty: 'Drittunternehmen',
                    refubrishmentProject: 'Sanierungsprojekt',
                    buildingPermit: 'Baubewilligung',
                    addons: 'ADD-ONS',
                    name: 'NAME',
                    email: 'Email Adresse',
                    mobile: 'Telefonnummer',
                    residential: 'Wohnen',
                    commercial: 'Gewerbe',
                    investmentDirectSales: 'Anleger/ Investment, Direktverkauf',
                    renting: 'Vermietung',
                    ProjectDoesNotExist: 'Projekt existiert nicht.'
                },
                other: {
                    wrongIcon: 'Bitte laden Sie das Bild mit einer Größe von bis zu {mb} MB in einem der unterstützten Dateitypen hoch: PNG, JPG, JPEG',
                    Theiconsizemustbelessthan1000KB: 'Die Dateigröße darf 1 MB nicht überschreiten',
                    Theiconfieldmusthaveavalidfiletype: 'Bitte laden das Bild in einem der unterstützen Dateitypen hoch: PNG, JPG, JPEG',
                    marketingStartDate: 'Vermarktungsstart',
                    projectName: 'Projektname',
                    propsterProjectID: 'PROPSTER Project ID',
                    project: 'Projekt',
                    projectCompanyName: 'Unternehmen',
                    projectStyle: 'Projekt-Stil',
                    projectType: 'Projekttyp',
                    priceRange: 'Preisspanne',
                    grossArea: 'Bruttofläche',
                    companyName: 'Unternehmen',
                    units: 'Einheiten',
                    notes: 'Notizen',
                    servicesList: 'Features',
                    Services: 'Gewählte Produkte',
                    responsibleProjectManager: 'Verantwortlicher Projektmanager',
                    name: 'Name',
                    mobile: 'Mobile',
                    whatIsTheProjectCompanyName: '<span>Offizieller Name des Unternehmens, das dieses Projekt ausführt:</span>',
                    generalQuestions: '<span>Projektadresse</span>',
                    projectIcon: '<span>Projekt Icon | Logo</span>',
                    projectIconRecommendedSize: '<br/>(200 x 200 px empfohlen)',
                    projectKeyVisual: '<span>Projektbild | Rendering</span>',
                    projectKeyVisualRecommendedSize: '<br/>(1280 x 960 px empfohlen)',
                    addressLine1: 'Adresse',
                    addressLine2: 'Adresse (Zusatz)',
                    postCode: 'Postleitzahl',
                    city: 'Stadt',
                    country: 'Land',
                    status: 'Status',
                    thirdPartyCompany: '<span>Bauen Sie für ein Drittunternehmen?</span>',
                    whoIsYourClient: '<span>Wer ist Ihr Kunde?</span>',
                    projectMembers: '<span>Projektbeteiligte</span>',
                    newProjectMember: 'Neues Projektmitglied',
                    updateProjectMember: 'Projektmitglied Aktualisieren',
                    deleteProjectMember: 'Nutzer von Projekt entfernen',
                    confirmDeleteProjectMember: 'Sind Sie sicher dass Sie den Nutzer {userName} von dem Projekt {projectName} entfernen möchten?',
                    clientName: 'Kundenname',
                    projectNumber: 'Projektnummer',
                    createProject: 'Projekt erstellen',
                    saveAndReview: 'Speichern & zur Übersicht',
                    changeIcon: 'Icon bearbeiten',
                    removeIcon: 'Icon löschen',
                    changeImage: 'Bild ändern',
                    removeImage: 'Entferne Bild',
                    addACompany: 'UNTERNEHMEN HINZUFÜGEN',
                    tabs: {
                        generalInfo: 'Allgemeine Information',
                        services: 'Services',
                        projectType: 'Projekttyp',
                        areaAndPricing: 'Flächen & Preise',
                        projectStyle: 'Projektstil',
                        documents: 'Dokumente',
                        contactData: 'Kontaktdaten',
                        deadlinesAndNotes: 'Fristen & Notizen',
                        projectMembers: 'Projektbeteiligte',
                    },
                    services: {
                        SWM: 'Konfigurator Plattform',
                        MMGMT: 'Übergabe und Mangelmanagement',
                        propsterPromo: 'Projektwebsite und Verkaufsmodus'
                    },
                    types: {
                        apartments: 'Eigentumswohnungen',
                        rental_apartments: 'Mietwohnungen',
                        investments_apartments: 'Vorsorgewohnungen',
                        offices: 'Büroeinheiten',
                        commercial: 'Gewerbeeinheiten',
                        houses: 'Reihenhäuser, Doppelhäuser, Einfamilienhäuser'
                    },
                    statuses: {
                        draft: 'Entwurf',
                        payment_outstanding: 'Zahlung ausständig',
                        in_setup: 'Im Aufbau',
                        active: 'Aktiv (Live)',
                        inactive: 'Inaktiv',
                        archived: 'Archiviert'
                    },
                    projectDetails: 'Projekt Details',
                    viewDetails: 'Details anzeigen',
                    includingAreaTypes: 'Welche Art der Nutzung umfasst Ihr Projekt?',
                    residentialProjectType: 'Wohnen',
                    kindOfResidentialProject: 'Welche Art von Wohnprojekt wird gebaut?',
                    residentialProjectSales: 'Verkauf von Wohneinheiten',
                    sellResidentialUnits: 'Wie wollen Sie Ihre Wohneinheiten verkaufen?',
                    commercialProjectType: 'Gewerbe',
                    kindOfCommercialProject: 'Welche Art von Gewerbe Projekt wird gebaut?',
                    commercialProjectSales: 'Verkauf von Gewerbeeinheiten',
                    sellCommercialUnits: 'Wie wollen Sie Ihre Gewerbe Einheiten verkaufen?',
                    construction_types: {
                        scratch: 'Neubau',
                        refurbishment: 'Sanierung'
                    },
                    area_types: {
                        residential: 'Wohnen',
                        commercial: 'Gewerbe'
                    },
                    residential_project_types: {
                        multi_story: 'Mehrgeschossiges Wohngebäude',
                        single_family_houses: 'Einfamilienhäuser, Reihenhäuser, Doppelhaushälften'
                    },
                    project_sales: {
                        direct_sales: 'Direktverkauf',
                        investment: 'Anleger/ Investment',
                        renting: 'Vermietung'
                    },
                    commercial_project_types: {
                        office: 'Büro',
                        hotel: 'Hotel',
                        serviced_apartments: 'Serviced Apartments',
                        trade: 'Handel',
                        medical_institution: 'Medizinische Einrichtungen',
                        warehouse_and_logistics: 'Lager & Logistik',
                        other: 'anderer Projekttyp:',
                        office_building: 'Bürogebäude',
                        business_park: 'Gewerbepark',
                        multi_story_car_park: 'Parkhaus',
                        school_building: 'Schulgebäude',
                        sports_hall: 'Sporthalle',
                        hall: 'Halle',
                    },
                    residentialAreaForInvestment: 'Projektumfang (Wohnbau) für Anleger und/oder Direktverkauf',
                    residentialAreaForRent: 'Projektumfang (Wohnbau) zur Vermietung',
                    commercialAreaForInvestment: 'Projektumfang (Gewerbe) für Anleger und/oder Direktverkauf',
                    commercialAreaForRent: 'Projektumfang (Gewerbe) zur Vermietung',
                    pleaseSelectProjectTypeEtc: 'Bitte wählen Sie zuerst einen Projekttyp und eine Projektnutzung!',
                    projectAreaM2: 'Projektumfang (m2)',
                    priceRangeForM2: 'Durchschnittspreis pro m2',
                    numberOfUnits: 'Anzahl der Einheiten',
                    selectStyleOfYourProject: 'Bitte wählen Sie den Stil Ihres Projekts:',
                    project_styles: {
                        contemporary: 'Modern',
                        urban: 'Urban',
                        classic: 'Klassisch',
                        traditional: 'Traditionell',
                        other: 'Anderer Stil'
                    },
                    contemporaryDescription: 'Gebäudeform mit klarem modernem Charakter; eher Verzicht auf repräsentative Details; asymmetrische Gruppierungen & kubistische Elemente; weißer oder farbiger Verputz an der Fassade; Lichtfülle.',
                    urbanDescription: 'Gebäudeform mit urbanem Charakter; loftartiges Erscheinungsbild; Backsteinfassaden, begrünte Fassaden, experimentellere Vertäfelungen mit Fassadenplatten Holz, Metall oder Kunststoffen; große Fensteröffnungen.',
                    classicDescription: 'Gebäudeform in klassisch zeitlosem Stil; einfach gehaltene repräsentative Details; minimalistisch schlichte helle Fassaden aus Naturstein oder Naturstein-nahen Vertäfelungen; symmetrische Fensterteilungen und klare Symmetrien im gesamten Gebäudeerscheinungsbild.',
                    traditionalDescription: 'Gebäudeform im traditionellen Stil; Satteldach, Walmdach; eher regional-historische wiedererkennbare Architektursprache; Stein, Holz, Verputz als Fassadenelemente; traditionelle interpretation von neuen Materialien.',
                    handleUser: 'Wer ist für die Bearbeitung der Sonderwünsche / Konfigurationen / Mangelmeldungen verantwortlich?',
                    dealsUser: 'Wer ist für die Produktauswahl (Standardprodukte + Alternativprodukte) verantwortlich?',
                    propsterProjectManager: 'Verantwortlicher Projektmanager (PROPSTER)',
                    pleaseSelectAUser: 'Please select a user',
                    addNewUser: 'Neuen Benutzer hinzufügen',
                    pleaseUpload: 'Bitte laden Sie, falls bereits vorhanden, erste Beispiel Dokumente / Bilder hoch:',
                    selectFileType: 'Dateityp auswählen',
                    file_types: {
                        visualization: 'Visualisierungen',
                        equipment_description: 'Baubeschreibung',
                        floor_plan: 'Geschosspläne/ Grundrisspläne'
                    },
                    supportedFileTypes: 'akzeptierte Dateiformate: PNG, JPG, JPEG, PDF, XLS(X), DOC(X), PPT(X), CSV',
                    dragFile: 'Ziehen Sie Ihre Datei hierher oder klicken Sie zum Hochladen',
                    projectOnboardingOverview: 'Überblick Projekt Onboarding',
                    fileName: 'Dateiname',
                    type: 'Dateityp',
                    size: 'Dateigröße',
                    actions: 'Aktionen',
                    user: 'Benutzer',
                    role: 'Rolle',
                    configuratorProjectRoles: 'Benutzerrollen: Konfigurator & Gewährleistungsmanagement',
                    promoProjectRoles: 'Benutzerrollen: Propster Promo',
                    marketplaceProjectRoles: 'Benutzerrollen: {marketplaceName} Marketplace',
                    placeholders: {
                        https: 'https://...',
                        wcApiPath: 'wc/v1',
                        wcApiUserKey: 'ck_...',
                        wcApiConsumerSecret: 'cs_...',
                    },
                    errors: {
                        required: 'Pflichtfeld',
                        unique: 'Dieser Wert ist bereits in Verwendung.',
                        incorrectFormat: 'Bitte geben Sie einen gültigen Wert ein',
                        integer: 'Bitte geben Sie eine ganze Zahl ein',
                        decimal: 'Bitte geben Sie eine Dezimalzahl ein'
                    },
                    deleteProject: 'Projekt löschen',
                    confirmDeleteProject: 'Bitte bestätigen Sie das Löschen des Projektes {projectName}',
                    canNotDeleteProject: 'Sie können das Projekt {projectName} nicht löschen, da bei diesem Projekt ein Service oder Add-Ons aktiviert sind.',
                    selectAUser: 'Bitte wählen Sie einen Benutzer aus',
                    selectARole: 'Bitte wählen Sie eine Projektrolle aus',
                }
            },
            admin: {
                api_users: {
                    list: {
                        addApiUser: 'Add API User'
                    }
                },
                user_roles: {
                    KeyName: 'Rolle',
                    ListKeyName: 'Rollenbezeichnung',
                    CompanyRestricted: 'Unternehmen eingeschränkt',
                    LicenseRestricted: 'Lizenz eingeschränkt',
                    ProjectRestricted: 'Projekt eingeschränkt',
                    EditRole: 'Rolle bearbeiten',
                    Name: 'Name',
                    UserRoles: 'Benutzerregeln',
                    Admin: 'Admin',
                    key_names: 'Rollen',
                    key_name: 'Rolle',
                    addNewRole: 'Neue Rolle hinzufügen',
                    NewRole: 'Rolle hinzufügen',
                    add: 'Hinzufügen',
                    update: 'Aktualisieren',
                    cancel: 'Abbrechen',
                    Level: 'Niveau',
                    roleSuccessfullyCreated: "Neue Rolle wurde erfolgreich erstellt",
                    roleCreated: "Rolle erstellt",
                    roleSuccessfullyUpdated: 'Rolle erfolgreich aktualisiert',
                    roleUpdated: 'Rolle aktualisiert',
                    selectedRoleDeleted: 'Die Rolle wurde erfolgreich gelöscht',
                    roleDeleted: 'Gelöscht',
                    Canceled: 'Abgebrochen',
                    addingARoleHasBeenCanceled: 'Das Hinzufügen einer neuen Rolle wurde abgebrochen.',
                    editingARoleHasBeenCanceled: 'Das Bearbeiten einer Rolle wurde abgebrochen.',
                    OK: 'OK',
                    Close: 'Schließen',
                    youHaveUnsavedChanges: 'Sie haben nicht gespeicherte Änderungen, die verloren gehen.',
                    confirmCancelEditing: 'Bist du sicher?',
                    ContinueEditing: 'Kontinuierliche Bearbeitung',
                    projectList: 'Projektliste',
                    AssignedProjectUsers: 'Benutzer, die einem Projekt zugewiesen sind',
                    add_users: 'Add Users',
                    edit_users: 'Edit Users',
                    view_users: 'View Users',
                    delete_users: 'Delete Users',
                    add_companies: 'Add Companies',
                    edit_companies: 'Edit Companies',
                    view_companies: 'View Companies',
                    delete_companies: 'Delete Companies',
                    add_projects: 'Add Projects',
                    edit_projects: 'Edit Projects',
                    view_projects: 'View Projects',
                    delete_projects: 'Delete Projects',
                    view_emails: 'View Email Log',
                    view_activity: 'View Activities',
                    Permissions: 'Benutzerrecht',
                    id: 'ID',
                    actions: 'Aktionen',
                    name: 'Name',
                    of: 'of',
                    Search: 'Suchen',
                    exportAsCsv: 'als CSV exportieren',
                    delete: 'Löschen',
                    roleNotUpdated: 'Die Rolle wurde nicht aktualisiert',
                    somethingWentWrong: 'Ein Fehler ist aufgetreten',
                    roleNotCreated: 'Rolle wurde nicht erstellt',
                    edit_propster_id: 'Edit PROPSTER ID',
                    view_propster_id: 'View PROPSTER ID',
                    edit_company_type: 'Edit Company Type',
                    allow_no_parent_company: 'Allow Empty Parent Company',
                    edit_parent_company: 'Edit Parent Company',
                    edit_propster_admin: 'Edit PROPSTER Company Manager',
                    add_orders: 'Add Orders',
                    edit_orders: 'Edit Orders',
                    view_orders: 'View Orders',
                    delete_orders: 'Delete Orders',
                    confirmDelete: 'Rolle löschen',
                    youAreAboutToDelete: 'Bitte bestätigen Sie das Löschen der Rolle %1$s.',
                    canNotDelete: 'Die Rolle %1$s kann nicht gelöscht werden, da diese bereits in Verwendung ist.',
                    noOfUsers: 'Anzahl der Nutzer',
                    keyNameValidationErrorUnique: 'Die Rolle existiert in diesem Service bereits.',
                    tabs: {
                        company_dashboard: 'PROPSTER Company Dashboard',
                        marketplaces: 'Marketplaces',
                        sso_configurator: 'PROPSTER App',
                        sso_dashboard: 'PROPSTER Company Dashboard'
                    }
                },
                dictionaries: {
                    KeyName: 'Key Name',
                    ListKeyName: 'Key',
                    CompanyRestricted: 'Company Restricted',
                    LicenseRestricted: 'License Restricted',
                    ProjectRestricted: 'Project Restricted',
                    EditItem: 'Edit a Item',
                    Name: 'Name',
                    UserRoles: 'User Roles',
                    Admin: 'Admin',
                    key_names: 'Key Name',
                    key_name: 'Key Name',
                    addNewItem: 'Add New Item',
                    NewItem: 'Add an Item',
                    add: 'Add',
                    update: 'Update',
                    cancel: 'Cancel',
                    Level: 'Level',
                    itemSuccessfullyCreated: "New item has been successfully created",
                    itemCreated: "Item Created",
                    itemSuccessfullyUpdated: 'Item successfully updated',
                    itemUpdated: 'Item Updated',
                    itemNotDeleted: 'Item not deleted',
                    selectedItemDeleted: 'Item has been succefully deleted',
                    itemDeleted: 'Deleted',
                    Canceled: 'Canceled',
                    addingAnItemHasBeenCanceled: 'Adding a new Item has been canceled.',
                    editingAnItemHasBeenCanceled: 'Editing an Item has been canceled.',
                    OK: 'Ok',
                    Close: 'Close',
                    youHaveUnsavedChanges: 'You have unsaved changes in your form which will be lost.',
                    confirmCancelEditing: 'Are you sure?',
                    ContinueEditing: 'Continue Editing',
                    projectList: 'Project List',
                    AssignedProjectUsers: 'Users assigned to a project',
                    add_users: 'Add Users',
                    edit_users: 'Edit Users',
                    view_users: 'View Users',
                    delete_users: 'Delete Users',
                    add_companies: 'Add Companies',
                    edit_companies: 'Edit Companies',
                    view_companies: 'View Companies',
                    delete_companies: 'Delete Companies',
                    add_projects: 'Add Projects',
                    edit_projects: 'Edit Projects',
                    view_projects: 'View Projects',
                    delete_projects: 'Delete Projects',
                    view_emails: 'View Email Log',
                    view_activity: 'View Activities',
                    Permissions: 'Permissions',
                    id: 'ID',
                    actions: 'Actions',
                    name: 'Name',
                    of: 'of',
                    Search: 'Search',
                    exportAsCsv: 'Export As CSV',
                    delete: 'Delete',
                    itemNotUpdated: 'Item has not been updated',
                    somethingWentWrong: 'An error occurred',
                    itemNotCreated: 'Item has not been created',
                    edit_propster_id: 'Edit PROPSTER ID',
                    view_propster_id: 'View PROPSTER ID',
                    edit_company_type: 'Edit Company Type',
                    allow_no_parent_company: 'Allow Empty Parent Company',
                    edit_parent_company: 'Edit Parent Company',
                    edit_propster_admin: 'Edit PROPSTER Company Manager',
                    confirmDelete: 'Delete an Item',
                    youAreAboutToDelete: 'Please, confirm deleting %1$s.',
                    canNotDelete: 'Cannot delete the %1$s, it is already used for at least one Entry.',
                    keyNameValidationErrorUnique: 'The item Key Name is already in use for the app.',
                    languageNames: {
                        name_en: 'Name (English)',
                        name_de: 'Name (German)'
                    },
                    tabs: {
                        request_statuses: 'Request Statuses',
                        defect_statuses: 'Defect Statuses',
                        request_categories: 'Product Categories',
                        defect_categories: 'Defect Categories',
                        ticket_statuses: 'Ticket Statuses',
                    }
                },
                identity_providers: {
                    id: 'ID',
                    addNewIP: 'Add New IP',
                    name: 'Name',
                    ipType: 'IP Type',
                    actions: 'Actions',
                    AddAnIP: 'Add an Identity Provider',
                    EditAnIP: 'Edit an Identity Provider',
                    add: 'Add',
                    update: 'Update',
                    cancel: 'Cancel',
                    Canceled: 'Canceled',
                    addingHasBeenCanceled: 'Adding IP has been canceled',
                    IPNotCreated: 'Adding IP has been canceled',
                    somethingWentWrong: 'An error occurred',
                    IPCreated: 'IP has been created',
                    IPSuccessfullyCreated: 'Identity Provider has been successfully created',
                    azure_client_id: 'Client ID',
                    azure_tenant_id: 'Tenant ID',
                    azure_client_secret: 'Client Secret',
                    azure_certificate: 'Certificate',
                    IPDeleted: 'Success',
                    selectedIPDeleted: 'Selected IP has been deleted',
                    IPNotDeleted: 'Delete Failed',
                    confirmCancelEditing: 'Cancel editing?',
                    youHaveUnsavedChanges: 'There are unsaved changes',
                    Close: 'Close',
                    ContinueEditing: 'Go Back',
                    editingAnIPHasBeenCanceled: 'Editing an IP has been canceled',
                    ipNotUpdated: 'Update Failed',
                    ipUpdated: 'Success',
                    ipSuccessfullyUpdated: 'IP has been successfully updated',
                    confirmDelete: 'Delete an IP',
                    youAreAboutToDelete: 'Please confirm deleting IP "%1$s"',
                    delete: 'Delete',
                    canNotDelete: 'Cannot delete an IP "%1$s", it is already used by at least one Company',
                    OK: 'OK'
                }
            },
            helpcenter: {
                faq: {
                    questions: {
                        howToCreateNewProject: 'Wie kann ich ein neues Projekt anlegen?',
                        howToDuplicateProjects: 'Kann ich ein neues Projekt bestehend auf einem existierenden Projekt “duplizieren”?',
                        howProjectOnboardingWorks: 'Wie läuft ein Projekt Onboarding bei PROPSTER im Detail ab?',
                        howToOrderAdditionalFunctions: 'Wie kann ich zusätzliche Funktionen zu bestehenden Projekten bestellen?',
                        howToGiveAccessToDashboard: 'Wie kann ich Kollegen Zugang zum PROPSTER Company Dashboard geben?',
                        howToBookOnlineWorkshop: 'Wie kann ich einen Online Workshop für mein Unternehmen buchen?',
                        howLongDoesItTake: 'Wie lange dauert die Erstellung der Konfigurations- und Kollaborationsplattform?',
                        whichDocumentsAreRequired: 'Welche Unterlagen werden für die Erstellung der Konfigurations- und Kollaborationsplattform benötigt?',
                        whichFileFormats: 'Welches Möglichkeiten gibt es Daten zu exportieren. Welche Dateiformate?',
                        canPotentialBuyerAlreadyBePresented: 'Können einem potenziellen Käufer bereits die Konfigurationsmöglichkeiten über die Plattform präsentiert werden?',
                        doBuyersAutomaticallyGetAccess: 'Bekommen Käufer automatisch auch zum Mängelmanagement Zugang wenn es soweit ist? Wie kann ich als Projektmanager beeinflussen, wann die einzelnen Käufer nach Wohnungsübergabe Zugang erhalten?',
                        howToDefineProprietaryProductSelection: 'Wie kann ich eine unternehmenseigene Produktauswahl festlegen, welche ich für alle zukünftigen Projekte übernehmen kann?',
                        howToMakeAnAppointment: 'Wie und wo kann ich einen Termin für eine Online Demo Präsentation vereinbaren?',
                        whatIsGenerallyIncluded: 'Was ist im PROPSTER Erwerber Support (1st level support) im Allgemeinen inkludiert?',
                        whatAddonsAvailable: 'Welche Add-ons und Zusatzfeatures gibt es?',
                        whereToFindTermsAndConds: 'Wo finde ich die Geschäftsbedingungen?',
                        howToCancelContract: 'Wie kann ich einen laufenden Vertrag bei PROPSTER kündigen?',
                        howToChangeMyBillingAddress: 'Wie kann ich meine Rechnungsadresse ändern?',
                        whoCanIContactAboutMyInvoice: 'An wen kann ich mich mit Fragen zu meiner Rechnung wenden?',
                        canIPauseLicensePayments: 'Kann ich Lizenzzahlungen pausieren?',
                        whereToSeeDurationOfMyLicenses: 'Wo sehe ich die Laufzeit meiner Lizenzen?',
                        whereToSeeOverviewOfMyInvoices: 'Wo sehe ich eine Übersicht meiner Rechnungen?',
                        whereToChangeMyPaymentMethods: 'Wo kann ich meine Zahlungsmodalitäten ändern?'
                    },
                    answers: {
                        howToCreateNewProject: 'Unter dem Punkt “Projekte” können Sie ein neues Projekt anlegen und starten. Nachdem Sie alle relevanten Infos zum Projektstart eingetragen haben erscheint dieses in der Projektübersicht und die Onboarding Phase beginnt.',
                        howToDuplicateProjects: 'Die URL muss immer einzigartig sein, daher entscheiden Sie sich bitte zuerst für eine neue Projekt Adresse. Die Einstellungen können übernommen werden, jedoch gelten hinsichtlich der Produkte und der Preise im weiteren Verlauf projektspezifische Bedingungen.',
                        howProjectOnboardingWorks: 'Nach dem Anlegen eines neuen Projektes startet die Onboarding Phase. Das PROPSTER Projektteam wird Sie kontaktieren und die einzelnen Projektschritte besprechen. Die Onboarding Phase beinhaltet bis zu 3 Online Onboardings mit dem zuständigen PROPSTER Projektmanager. Je nach Projektfortschritt werden Onboarding Termine vereinbart. Das Projekt Set-up beginnt mit der Erstellung Ihrer projektspezifischen URL. Die Kundenplattform wird mit Ihren Projekt oder Unternehmensfarben und Logos aufgesetzt. Im Anschluss daran werden anhand Ihres Raumbuches die Wohnungen im System erstellt. Nachdem Ihre Produktauswahl abgeschlossen ist, werden gewerk-spezifische Konfiguratoren aufgesetzt. Im Zuge der Onboardings wird die Nutzung des Kundenplattform-Editor besprochen. Das Ende der Onboarding Phase wird mit der Produktabnahme festgelegt.',
                        howToOrderAdditionalFunctions: 'Treten Sie hierzu mit Ihrer konkreten Anforderung mit dem verantwortlichen PROPSTER Projektmanager in Verbindung. Weiters wird abgeklärt ob diese Funktion bereits verfügbar bzw. umsetzbar ist, und ob ein Mehraufwand für zusätzliche Produktanpassungen verrechnet werden muss.',
                        howToGiveAccessToDashboard: 'Unter dem Menüpunkt Benutzer können Sie weitere KollegInnen den Zugang zum PROPSTER Company Dashboard geben. Sollten Sie keine entsprechenden Berechtigung haben, wenden Sie sich bitte an Ihre/n zuständige/n Projektmanager/in bei PROPSTER.',
                        howToBookOnlineWorkshop: 'Kontaktieren Sie bitte hierzu Ihren persönlichen Ansprechpartner bei PROPSTER. Sie können Online Workshops direkt beim jeweiligen Projektmanager oder über Ihren Sales Manager erfragen.',
                        howLongDoesItTake: 'Erfahrungsgemäß werden 8-10 Wochen Vorlaufzeit für die Erstellung der Konfigurations- und Kollaborationsplattform bei Erstprojekten geplant. Diese kann deutlich kürzer ausfallen, wenn es bereits eine Zusammenarbeit mit PROPSTER in der Vergangenheit gab. In dieser Zeit erfolgt die Sammlung aller relevanten Unterlagen und die korrekte Übermittlung dieser. Nach vollständigem Erhalt aller Unterlagen werden die Anpassungen in der Regel innerhalb von wenigen Wochen durchgeführt. Für eine erfolgreiche Zusammenarbeit empfehlen wir ausreichend Zeit einzuplanen.',
                        whichDocumentsAreRequired: 'Die benötigten Unterlagen variieren abhängig vom jeweiligen Anwendungsfall und Projekt. Eine genaue Auflistung der benötigten Informationen und wie diese übermittelt werden erhalten Sie stets zu Projektbeginn. Grundsätzlich benötigt PROPSTER Farbcodes und Visualisierungen für die optisch ansprechende Darstellung, eine Topografie inklusive der Raum-, Boden- und Fliesenfläche, eine Auflistung aller zur Verfügung stehenden Produkte inklusive Produktbildern und die Grundrisspläne der einzelnen Wohneinheiten. Die benötigten Unterlagen variieren abhängig vom jeweiligen Anwendungsfall. Eine genaue Auflistung der benötigten Informationen und der Art der Übermittlung erhalten Sie stets zu Projektbeginn.',
                        whichFileFormats: 'Die Daten aus Ihrer PROPSTER Kundenplattform können in verschiedenen Formaten (Excel, csv, etc.) exportiert werden. Hierbei orientieren wir uns an den gängigen Dateiformaten. Für genauere Infos wenden Sie sich bitte direkt an Ihre/n Projektmanager/in bei PROPSTER.',
                        canPotentialBuyerAlreadyBePresented: 'Ja. Im Rahmen des sogenannten Sales Mode (“Verkaufsmodus”) kann einem Interessenten bereits Zugang zur Plattform gegeben werden. Hierfür wird keine Anmeldung benötigt, denn der Interessent erhält sofortigen Zugang durch Klicken eines Buttons auf der Verkaufsseite mit einer entsprechenden Verlinkung zur PROPSTER Kundenplattform. Die Darstellung der Produkte und Preise kann angepasst werden und sich somit von jener Ansicht, die ein Käufer im Rahmen der Konfiguration sieht, unterscheiden.',
                        doBuyersAutomaticallyGetAccess: 'Dies bedarf derzeit einer zusätzlichen Freischaltung durch PROPSTER. Erst wenn die Bemusterung aller Wohnungen abgeschlossen ist, wird ein Wechsel stattfinden.',
                        howToDefineProprietaryProductSelection: 'Im PROPSTER Marketplace können Sie eine Wishlist erstellen, welche für alle Ihre Projekte übernommen werden kann. Die finale Produktauswahl können Sie in Zukunft auch im PROPSTER Company Dashboard speichern. Sollten Sie Interesse haben den Marketplace zu nutzen, wenden Sie sich bitte direkt an Ihre/n persönliche/n Projektmanager/in bei PROPSTER.',
                        howToMakeAnAppointment: 'Klicken Sie den nachstehenden Link und hinterlassen Sie direkt Ihre Email Adresse: <a href="https://www.propster.tech/demo-praesentation" target="_blank">https://www.propster.tech/demo-praesentation</a>. Wir werden uns in Kürze aktiv bei Ihnen hinsichtlich eines Online Demo Termins melden.',
                        whatIsGenerallyIncluded: 'Der 1st Level Support unterstützt Sie beim Kontakt und der Abwicklung von Kundenanfragen (Bemusterungen, Dokumentenverwaltung, Message Box Anfragen, etc.), Dadurch garantieren wir, dass Ihre Kunden die bestmögliche Unterstützung bei der Konfigurationen derer Immobilien erhalten. Der genaue Leistungsumfang kann von Projekt zu Projekt abweichen aber diese Details erläutert Ihnen gerne das PROPSTER Sales-Team.',
                        whatAddonsAvailable: 'Am besten setzen Sie sich hierfür mit unserem Vertrieb in Kontakt. Wir beraten Sie hier gerne auch in einem persönlichen Telefonat. Zusätzlich finden Sie hier eine Übersicht unserer Produktfeatures: <a href="https://www.propster.tech/features/" target="_blank">https://www.propster.tech/features/</a>',
                        whereToFindTermsAndConds: 'Die AGB und den Lizenzvertrag von PROPSTER lassen wir Ihnen gerne auf Anfrage zukommen. Wenden Sie sich hierzu an eine/n beliebige/n Ansprechpartner/in bei PROPSTER.',
                        howToCancelContract: 'Bitte senden Sie hierzu eine E-Mail mit den Infos, welches Projekt Sie zu welchem Datum kündigen möchten, an rechnungen@propster.tech.',
                        howToChangeMyBillingAddress: 'Klicken Sie einfach in die Firmenübersicht und aktualisieren Sie die Daten hinsichtlich der gewünschten Projektgesellschaft. Senden sie uns anschließend auf rechnungen@propster.tech die Rechnungsnummer, damit wir die entsprechende Rechnung neu ausstellen können.',
                        whoCanIContactAboutMyInvoice: 'Wenden Sie sich mit jeglichen Fragen zu Ihren Rechnungen an rechnungen@propster.tech.',
                        canIPauseLicensePayments: 'Grundsätzlich ist das Pausieren von Lizenzen nicht möglich. Wir prüfen jedoch gerne Ihr Anliegen. Bitte wenden Sie sich an uns unter rechnungen@propster.tech',
                        whereToSeeDurationOfMyLicenses: 'Die Laufzeit der Lizenzen sehen Sie in den jeweiligen Rechnungen im Bereich Bemerkungen oder in der Produktbeschreibung.',
                        whereToSeeOverviewOfMyInvoices: 'Eine Übersicht der Rechnungen wird Ihnen ebenfalls bald im Company Dashboard zur Verfügung stehen. Wir informieren Sie, sobald das Update fertiggestellt ist.',
                        whereToChangeMyPaymentMethods: 'Für die Änderung Ihrer Zahlungsmodalitäten wenden Sie sich bitte direkt an rechnungen@propster.tech . In Zukunft werden Sie diese Einstellungen auch hier im Company Dashboard vornehmen können.'
                    },
                    categories: {
                        all: 'Alle',
                        projectManagement: 'Projektmanagement',
                        sales: 'Sales',
                        finance: 'Finance'
                    },
                    other: {
                        haveAnyQuestions: 'Haben Sie Fragen?',
                        orChooseACategory: 'Geben Sie einen Suchbegriff ein oder wählen Sie eine gewünschte Kategorie!',
                        askAQuestion: 'Stelle eine Frage...',
                        categories: 'Kategorien'
                    }
                }
            },
            billing: {
                order: {
                    add: {
                        orderCreated: 'Auftrag erstellt',
                        orderSuccessfullyCreated: 'eine neue Auftrag wurde erfolgreich erstellt!',
                        orderNotCreated: 'Auftrag wurde nicht erstellt!'
                    },
                    edit: {
                        orderNotFound: 'Auftrag nicht gefunden',
                        orderWithIdNotFound: 'Bestelldatensatz mit der ID: {orderId} nicht gefunden.',
                        allOrders: 'Alle Auftrag',
                        orderUpdated: 'Auftrag aktualisiert',
                        orderNotUpdated: 'Auftrag nicht aktualisiert',
                        orderSuccessfullyUpdated: 'Auftrag wurde erfolgreich aktualisiert'
                    },
                    list: {
                        addNewOrder: 'neue Auftrag erstellen',
                        orderDeleted: 'Bestellung gelöscht',
                        selectedOrderDeleted: 'Die ausgewählte Bestellung wurde erfolgreich gelöscht',
                        orderNotDeleted: 'Bestellung nicht gelöscht'
                    },
                    other: {
                        number: 'Nummer',
                        date: 'Datum',
                        title: 'Titel',
                        license: 'Lizenz',
                        amount: 'Betrag',
                        link: 'Link',
                        status: 'Status',
                        userEmail: 'User Email',
                        paymentDueDate: 'Fälligkeit',
                        company: 'Unternehmen',
                        project: 'Projekt',
                        user: 'Nutzername',
                        servicePeriod: 'Wartungsintervall',
                        documentType: 'Art',
                        services: 'Dienstleistungen',
                        companyLicense: 'Company Lizenz',
                        projectLicense: 'Projekt Lizenz',
                        confirmApprove: 'Auftrag auf bezahlt stellen!',
                        youAreAboutToApprove: 'Möchten Sie den Status der Auftrag "{title}" auf bezahlt stellen?',
                        approve: 'Bestätigen',
                        orderApproved: 'Status geändert',
                        selectedOrderApproved: 'Ausgewählt Auftrag erfolgreich aktualisiert!',
                        orderNotApproved: 'Auftrag nicht aktualisiert',
                        viewDetails: 'Details anzeigen',
                        documentTypes: {
                            invoice: 'Rechnung',
                            offer: 'Angebot'
                        },
                        statuses: {
                            payment_outstanding: 'Zahlungs ausständig',
                            invoice_paid: 'Rechnung bezahlt',
                            credited: 'Gutgeschrieben',
                            paid_partly: 'Rechnung teilweise bezahlt'
                        }
                    }
                }
            },
            login: {
                other: {
                    welcome: 'Willkommen im PROPSTER Company Dashboard!',
                    pleaseSignIn: 'Bitte verwenden Sie Ihre E-Mail-Adresse für die Anmeldung',
                    signIn: 'Einloggen',
                    rememberMe: 'Passwort speichern',
                    forgotPassword: 'Passwort vergessen?',
                    login: "Zurück zur Anmeldung",
                    supportedBy: 'unterstützt von PROPSTER',
                    dontWorry: 'Keine Sorge, wir helfen Ihnen gerne!',
                    enterTheEmailAddress: 'Geben Sie die Ihrem Konto zugeordnete E-Mail-Adresse ein',
                    send: 'Senden',
                    accessHeading: 'Sie sind bereits PROPSTER Kunde, aber haben noch keinen Zugriff auf das PROPSTER Company Dashboard?',
                    accessDescription: 'Wenn Sie bereits PROPSTER Kunde sind und derzeit Probleme beim Login haben, kontaktieren Sie uns.',
                    notCustomerHeading: 'Noch kein PROPSTER Kunde?',
                    notCustomerDescription: 'Das PROPSTER Company Dashboard ist nur für unsere Kunden verfügbar. Wenn Sie weitere Informationen zum Company Dashboard und dem gesamten Produktsortiment wünschen, <a href="https://propster.tech/kontakt/" target="_blank">kontaktieren Sie uns</a> bitte.',
                    next: 'Weiter',
                    back: 'Zurück',
                    password: 'Passwort',
                    azure_you_are_being_redirected: "Sie werden zur Anmeldeseite von Microsoft Azure weitergeleitet.",
                },
                messages: {
                    Incorrect_username_or_password: 'E-Mail-Adresse oder Passwort sind falsch',
                    product_partner_login_prohibited: 'Derzeit ist es Mitgliedern von Produktpartnerunternehmen nicht gestattet, auf diese Ressource zuzugreifen.',
                    azure_can_not_determine_user: 'Es ist ein Fehler aufgetreten: Microsoft Azure-Benutzer kann nicht ermittelt werden.',
                    api_user_is_not_allowed_to_login: 'You can not log in with API user account.',
                    invalid_email: 'Ungültiges Email-Format',
                    empty_password: 'Password must not be empty',
                }
            },
            logout: {
                messages: {
                    logout: "Ausloggen",
                    being_logged_out: "Sie wurden erfolgreich abgemeldet",
                    you_are_not_logged_in: "Du bist nicht eingeloggt"
                }
            },
            auth: {
                forgot: {
                    passwordResetSent: 'Der Link zum Zurücksetzen des Passworts wurde gesendet!',
                    passwordChanged: 'Das Passwort wurde geändert'
                },
                set_password: {
                    setupYourPassword: 'Richten Sie Ihr Passwort ein',
                    createPassword: 'Erstelle dein Passwort',
                    passwordSet: 'Das Passwort wurde erfolgreich festgelegt',
                    setYourPassword: 'Passwort zurücksetzen',
                    pleaseSetPassword: 'Geben Sie bitte Ihr neues Passwort ein!',
                    Set: 'Setzen',
                    Reset: 'Zurücksetzen',
                    messages: {
                        TOKEN_INVALID: 'Passwortsatz-Token ist ungültig',
                        TOKEN_EXPIRED: 'Passwortsatz-Token ist abgelaufen'
                    }
                },
                reset_password: {
                    messages: {
                        TOKEN_INVALID: 'Das Token zum Zurücksetzen des Passworts ist ungültig',
                        TOKEN_EXPIRED: 'Token zum Zurücksetzen des Passworts ist abgelaufen'
                    }
                }
            },
            reports: {
                orders: 'Statistik Bemusterungen/ Produktanfragen',
                userBehavior: 'Nutzverhalten',
                projectReports: 'Projektreports',
                allProjects: 'Alle Projekte',
                eventLog: 'Aktivitätenprotokoll',
                event: {
                    dateTime: 'Datum & Uhrzeit',
                    userRole: 'Benutzer & Rolle',
                    appInstance: 'Anwendung & Plattform',
                    company: 'Unternehmen',
                    eventObject: 'Aktivität & Objekt',
                    noRows: 'Keine Ergebnisse gefunden. Bitte versuchen Sie den Filter zurückzusetzen und starten Sie die Suche erneut.',
                    showingRows: 'Zeigt %1$s-%2$s von %3$s gefilterten Ereignissen.',
                    filteredResults: '%1$s Ergebnisse gefunden',
                    filters: {
                        dateFrom: 'Von',
                        dateTo: 'Bis',
                        allUsers: 'Alle Benutzer',
                        allCompanies: 'Alle Unternehmen',
                        allApps: 'Alle Anwendungen',
                        allRoles: 'Alle Rollen',
                        allInstances: 'Alle Plattformen',
                        allEventTypes: 'Alle Aktivitäten',
                        allObjects: 'Objekt Typ',
                        objectId: 'ID',
                        itemsFound: 'ergebnisse gefunden',
                        reset: 'Zurücksetzen',
                        hidePropster: 'Hide Propster Activity'
                    }
                },
                order: {
                    statuses: {
                        pending: 'Neue Anfrage',
                        neuebemusterung: 'Neue Bemusterung',
                        internepruefung: 'Interne Prüfung',
                        externepruefung: 'Externe Prüfung',
                        pruefungfertig: 'Prüfung abgeschlossen',
                        angebotversendet: 'Angebot zur Freigabe',
                        rechnunggesendet: 'Rechnung zur Freigabe',
                        freigabekunde: 'Freigabe der Anfrage',
                        nachunternehmen: 'Auftrag Ausführung',
                        deadlineexpired: 'Abgelaufene Frist',
                        cancelled: 'Abgebrochen/Storniert',
                        completed: 'Abgeschlossen',
                        onhold: 'In Wartestellung',
                        protokoll: 'Übergabeprotokoll versendet',
                        behoben: 'Behoben',
                        erstenachfrist: '3. Nachfrist GU',
                        zweitenachfrist: '2. Nachfrist GU',
                        drittenachfrist: '1. Nachfrist GU',
                        ersatzvornahme: 'Ersatzvornahme',
                        dpexterneprfng: 'Externe Prüfung',
                        dpinterneprfng: 'Interne Prüfung',
                        keinmangel: 'Kein Mangel',
                        neuermangel: 'Neuer Mangel',
                    },
                    ticketStatuses: {
                        processing: 'In Bearbeitung',
                        queued: 'Neu',
                        hold: 'Beantwortet',
                        trash: 'Im Papierkorb'
                    }
                },
                fileDownloads: 'Statistik Dokumenten Downloads',
                fileDownloadsByMonth: 'Downloads nach Datum',
                Downloads: 'Downloads',
                tickets: 'Statistik Message Box Nachrichten',
                defects: 'Mängelmanagement',
                defectsByStatus: 'Anzahl der Mängel nach Status',
                defectsByCategory: 'Anzahl der Mängel nach Kategorie',
                requestsByStatus: 'Anzahl der Bestellungen nach Status',
                requestsByCategory: 'Anzahl der Bestellungen nach Kategorie',
                defect: {
                    statuses: {
                        externepruefung: 'Externe Prüfung',
                        internepruefung: 'Interne Prüfung',
                        pending: 'Neuer Mangel',
                        protokoll: 'Übergabeprotokoll versendet',
                        behoben: 'Behoben',
                        erstenachfrist: '3. Nachfrist GU',
                        zweitenachfrist: '2. Nachfrist GU',
                        drittenachfrist: '1. Nachfrist GU',
                        ersatzvornahme: 'Ersatzvornahme',
                        cancelled: 'Kein Mangel',
                        completed: 'Behoben',
                        dpexterneprfng: 'Externe Prüfung',
                        dpinterneprfng: 'Interne Prüfung',
                        keinmangel: 'Kein Mangel',
                        neuermangel: 'Neuer Mangel',
                        neuebemusterung: 'Neue Bemusterung',
                        pruefungfertig: 'Prüfung abgeschlossen',
                        angebotversendet: 'Angebot zur Freigabe',
                        rechnunggesendet: 'Rechnung zur Freigabe',
                        freigabekunde: 'Freigabe der Anfrage',
                        nachunternehmen: 'Auftrag Ausführung'
                    },
                    categories: {
                        installateur_hkls: 'INSTALLATEUR / HKLS',
                        aufzuganlage: 'AUFZUGANLAGE',
                        fenster_sonnenschutz: 'SONNENSCHUTZ',
                        fliesen: 'FLIESEN',
                        parkettboden: 'PARKETTBODEN',
                        tischler_innentueren: 'WHG- & INNENTÜREN',
                        maler: 'MALERARBEITEN',
                        kueche: 'KÜCHE',
                        schlosser: 'SCHLOSSER',
                        wohnungsuebergabe: 'WOHNUNGSÜBERGABE',
                        gewerke: 'GEWERKE',
                        elektro: 'ELEKTRO',
                        fenster: 'FENSTER',
                        terrassenbelag: 'TERRASSENBELAG',
                        other: 'ANDERE'
                    }
                }
            },
            stream: {
                sent_emails: {
                    dateAndTime: 'Terminzeit',
                    from: 'Absender',
                    to: 'Empfängerin(-er)',
                    subject: 'Betreff',
                    viewed: 'Gesehene Zeit',
                    actions: 'Aktionen',
                    viewEmail: 'Aussicht'
                },
                activities: {
                    dateAndTime: 'Date & Time',
                    action: 'Action',
                    context: 'Context',
                    who: 'Who',
                    created_File: 'Created File',
                    updated_File: 'Updated File',
                    deleted_File: 'Deleted File',
                    created_CompanyUser: 'Created User',
                    updated_CompanyUser: 'Updated User',
                    deleted_CompanyUser: 'Deleted User',
                    created_Company: 'Created Company',
                    updated_Company: 'Updated Company',
                    deleted_Company: 'Deleted Company',
                    updated_CompanyProject: 'Updated Project',
                    created_CompanyProject: 'Created Project',
                    deleted_CompanyProject: 'Deleted Project',
                    created_User: 'Created User',
                    updated_User: 'Updated User',
                    deleted_User: 'Deleted User',
                    logged_in_User: 'Logged In',
                    logged_out_User: 'Logged Out',
                    created_Order: 'Created Order',
                    updated_Order: 'Updated Order',
                    deleted_Order: 'Deleted Order',
                    created_ProjectService: 'Created Service',
                    updated_ProjectService: 'Updated Service',
                    deleted_ProjectService: 'Deleted Service',
                    created_Service: 'Created Service',
                    updated_Service: 'Updated Service',
                    deleted_Service: 'Deleted Service',
                    created_Country: 'Created Country',
                    updated_Country: 'Updated Country',
                    deleted_Country: 'Deleted Country',
                    created_Language: 'Created Language',
                    updated_Language: 'Updated Language',
                    deleted_Language: 'Deleted Language',
                    updated_CompanyUserRole: 'Updated Role',
                    created_CompanyUserRole: 'Created Role',
                    deleted_CompanyUserRole: 'Deleted Role',
                    updated_Marketplace: 'Updated Marketplace'
                }
            },
            news: {
                Add: 'Add News',
                editCategories: 'Edit Categories',
                categories: {
                    id: 'ID',
                    name: 'Title',
                    title: 'Title',
                    icon: 'Icon',
                    color: 'Color',
                    actions: 'Actions',
                    of: 'of',
                    Search: 'Search',
                    addNewCategory: 'Create Category',
                    NewCategory: 'New Category',
                    Name: 'Title',
                    delete: 'Delete',
                    Icon: 'Icon',
                    EditCategory: 'Edit Category',
                    Title: 'Title',
                    Color: 'Color',
                    confirmCancelEditing: 'Cancel editing?',
                    youHaveUnsavedChanges: 'There are unsaved changes',
                    Close: 'Close',
                    ContinueEditing: 'Go Back',
                    Canceled: 'Canceled',
                    editingACategoryHasBeenCanceled: 'Editing has been canceled',
                    categoryNotUpdated: 'Category was not updated',
                    somethingWentWrong: 'An error occurred',
                    addingACategoryHasBeenCanceled: 'Adding a category canceled',
                    categoryUpdated: 'Updated',
                    categorySuccessfullyUpdated: 'Category has been successfully updated.',
                    categoryNotCreated: 'Category not created!',
                    categoryCreated: 'Category Created',
                    categorySuccessfullyCreated: 'Category has been successfully created.',
                    confirmDelete: 'Are you sure?',
                    youAreAboutToDelete: 'You are about to delete a category.',
                    categoryDeleted: 'Category Deleted',
                    selectedCategoryDeleted: 'Selected Category was successfully deleted.'
                },
                add: {
                    success: 'Success!',
                    TargetAudience: 'Zielpublikum'
                },
                update: {
                    success: 'Success!'
                }
            }
        },
        navmenu: {
            items: {
                Home: 'Home',
                Apps: 'Apps',
                Users: 'Benutzer',
                List: 'Liste',
                View: 'Ansehen',
                Edit: 'Bearbeiten',
                AddNew: 'Neu hinzufügen',
                Company: 'Unternehmen',
                Service: 'Service',
                Services: 'Services',
                Addons: 'Add-Ons',
                Projects: 'Projekte',
                Project: 'Projekt',
                Pages: 'Seiten',
                Profile: 'Profil',
                UserSettings: 'Benutzereinstellungen',
                Admin: 'Admin',
                Roles: 'Rollen',
                UserRoles: 'Benutzereinstellungen',
                Reports: 'Berichte',
                Dashboard: 'Dashboard',
                Helpcenter: 'Helpcenter',
                Faq: 'FAQ',
                Orders: 'Aufträge',
                EmailLog: 'E-Mail-Protokoll',
                Marketplaces: 'Marketplaces',
                ActivityLog: 'Activity Stream',
                News: 'News',
                Dictionaries: 'Dictionaries',
                IdentityProviders: 'Identity Providers',
            },
            titles: {
                admin: {
                    apiUsers: 'API Users',
                    apiUserView: 'View API User',
                    apiUserEdit: 'Edit API User',
                    apiUserAdd: 'Add API User',
                },
                user: {
                    userList: 'Benutzer',
                    userView: 'Benutzeransicht',
                    userEdit: 'Benutzer bearbeiten',
                    addNewUser: 'Benutzer hinzufügen',
                    editMyProfile: 'Profil Bearbeiten'
                },
                company: {
                    companyList: 'Unternehmensliste',
                    companyEdit: 'Unternehmen bearbeiten',
                    viewCompany: 'Unternehmen Details',
                    addNewCompany: 'Neue Unternehmen hinzufügen'
                },
                service: {
                    serviceList: 'Services',
                    addNewService: 'Add a Service',
                    editService: 'Edit Service'
                },
                addon: {
                    addonList: 'Add-Ons',
                    addNewAddon: 'Add an Add-On',
                    editAddon: 'Edit an Add-On'
                },
                project: {
                    addNewProject: 'Neues Projekt hinzufügen',
                    projectList: 'Projekt Übersicht',
                    viewProject: 'Projekt Details',
                    editProject: 'Projekt bearbeiten'
                },
                order: {
                    orderList: 'Bestellliste',
                    addNewOrder: 'neue Auftrag hinzufügen',
                    viewOrder: 'Auftrag Details',
                    editOrder: 'Reihenfolge bearbeiten'
                },
                news: {
                    viewNews: 'News',
                    readNews: 'Read News',
                    categories: 'News Categories',
                    addNews: 'New Post',
                    editNews: 'Edit Post'
                },
                marketplace: {
                    marketplaceList: 'Marketplaces',
                    addNewMarketplace: 'Marketplace hinzufügen',
                    editMarketplace: 'Marketplace bearbeiten'
                },
                other: {
                    Settings: 'Einstellungen',
                    Profile: 'Profil',
                    Roles: 'Rollen',
                    File: 'Datei',
                    EmailLog: 'E-Mail-Protokoll',
                    PrivacyPolicy: 'Datenschutz',
                    ActivityLog: 'Activity Stream',
                    Dictionaries: 'Dictionaries',
                    IdentityProviders: 'Identity Providers',
                }
            }
        },
        other: {
            countries: {
                noCountry: '-',
                DE: 'Deutschland',
                GB: 'Vereinigtes Königreich',
                AT: 'Österreich',
                CH: 'Schweiz',
                AL: 'Albanien',
                AD: 'Andorra',
                AM: 'Armenien',
                AZ: 'Aserbaidschan',
                BE: 'Belgien',
                BA: 'Bosnien und Herzegowina',
                BG: 'Bulgarien',
                HR: 'Kroatien',
                CY: 'Zypern',
                CZ: 'Tschechische Republik',
                DK: 'Dänemark',
                EE: 'Estland',
                FI: 'Finnland',
                FR: 'Frankreich',
                GE: 'Georgien',
                GR: 'Griechenland',
                HU: 'Ungarn',
                IS: 'Island',
                IE: 'Irland',
                IT: 'Italien',
                LV: 'Lettland',
                LI: 'Liechtenstein',
                LT: 'Litauen',
                LU: 'Luxemburg',
                MT: 'Malta',
                MC: 'Monaco',
                ME: 'Montenegro',
                NL: 'Niederlande',
                MK: 'Nordmazedonien',
                NO: 'Norwegen',
                PL: 'Polen',
                PT: 'Portugal',
                MD: 'Republik Moldau',
                RO: 'Rumänien',
                RU: 'Russische Föderation',
                SM: 'San Marino',
                RS: 'Serbien',
                SK: 'Slowakische Republik',
                SI: 'Slowenien',
                ES: 'Spanien',
                SE: 'Schweden',
                TR: 'Türkei',
                UA: 'Ukraine',
                SA: 'Saudi Arabien'
            },
            tooltips: {
                delete: 'Löschen',
                edit: 'Bearbeiten',
                moreDetails: 'Mehr Details',
                goToProject: 'Zum Projekt',
                goToOnboardingOverview: 'Zur Onboarding Übersicht'
            },
            validation: {
                messages: {
                    TheServicesFieldIsRequired: 'Dieses Feld ist erforderlich',
                    TheProject_styleFieldIsRequired: 'Das Feld ist erforderlich',
                    TheTypeFieldIsRequired: 'Das Feld ist erforderlich',
                    exists: 'Existiert',
                    validating: 'Validierung...'
                }
            },
			cookies: {
				acceptAll: 'Alle Akzeptieren',
				onlyTechCookies: 'Nur technisch notwendige Cookies',
				message: 'Wir verwenden Cookies, um Ihr Erlebnis auf unserer Website zu verbessern.',
				learnMore: 'Mehr erfahren'
			},
            somethingWentWrong: 'Etwas ist schief gelaufen.',
            check: 'Prüfen',
            copied: 'Kopiert',
            saveChanges: 'Änderungen speichern',
            create: 'Erstellen',
            reset: 'Änderungen zurücksetzen',
            addressLine: 'Adresse',
            postCode: 'Postleitzahl',
            city: 'Stadt',
            country: 'Land',
            actions: 'Aktionen',
            name: 'Name',
            Search: 'Suche',
            edit: 'Bearbeiten',
            add: 'Addieren',
            save: 'Speichern',
            update: 'Aktualisieren',
            delete: 'Löschen',
            cancel: 'Abbrechen',
            id: 'ID',
            confirmDelete: 'Löschen bestätigen',
            youAreAboutToDelete: 'Möchte Sie "{name}" wirklich löschen?',
            previous: 'zurück',
            next: 'weiter',
            step: 'Schritt',
            upTo: 'bis',
            perMeters: 'pro m2',
            moreThan: 'mehr als',
            addFile: 'Datei hinzufügen',
            addFiles: 'Dateien hinzufügen',
            uploadFile: 'Datei hochladen',
            fileNotFound: 'Datei nicht gefunden',
            fileWithIdNotFound: 'Datei mit ID: {fileId} nicht gefunden',
            download: 'Herunterladen',
            fileDownload: 'Datei herunterladen',
            showReport: 'Bericht anzeigen',
            pleaseSelectProject: 'Bitte wählen Sie zuerst das Projekt aus!',
            pleaseSelectCompany: 'Bitte wählen Sie zuerst das Unternehmen aus!',
            projectGAUserBehaviourReportNotFound: 'Es wurde keine Statistik zu Ihrem gewählten Projekt gefunden!',
            of: 'von',
            Close: 'Schließen',
            ContinueEditing: 'Bearbeitung fortfahren',
            Canceled: 'Abgebrochen',
            status: 'Status',
            services: 'Features',
            company: 'Unternehmen',
            project: 'Projekt',
            marketplace: 'Marketplace',
            type: 'Typ',
            address: 'Adresse',
            clickHere: 'Klicken Sie hier',
            mobile: 'Mobile',
            yes: 'Ja',
            no: 'Nein',
            exportAsCsv: 'Als CSV exportieren',
            privacyPolicy: 'Datenschutz',
            marketplaces: 'Marketplaces',
            projects: 'Projekte',
            Logout: 'Abmelden',
            All: 'Alle',
            warning: 'Warnung',
            youHaveUnsavedChanges: 'Sie haben Ihre Änderungen nicht gespeichert',
            continueEditing: 'Bearbeitung fortsetzen',
            discardChanges: 'Änderungen verwerfen',
            switchTo: 'Wechseln zu',
            companyDashboard: 'Company Dashboard',
            projectDashboard: 'Kundenplattform-Editor',
            error: 'Fehler',
        },
        headerMenu: {
            myProfile: 'Mein Profil',
            Logout: 'Abmelden',
        },
        notifications: {
            titles: {
                new: 'Neu',
                new_multiple: 'Neue',
                notifications_list: 'Benachrichtigungen',
                News_Published: 'Neuer Beitrag geteilt:',
                Order_Created: 'Neuer Auftrag erstellt:',
                Order_Status_Updated: 'Auftrag wurde aktualisiert:',
                Project_Created: 'Neues Projekt erstellt:',
                Project_Status_Updated: 'Projekt wurde aktualisiert:',
            },
            texts: {
                News_Published: '%1$s',
                Order_Created: '%1$s: Nr.%2$s',
                Order_Status_Updated: '%1$s: Nr.%2$s hat jetzt Status "%3$s"',
                Project_Created: '%1$s hat ein neues Projekt erstellt "%2$s, %3$s, %4$s %5$s"',
                Project_Status_Updated: '"%1$s, %2$s, %3$s %4$s" hat jetzt den Status "%5$s"',
                no_notifications_yet: 'Sie haben keine Benachrichtigungen'
            },
            time: {
                years_ago: 'Vor %1$s Jahren',
                year_ago: 'Vor %1$s Jahr',
                days_ago: 'Vor %1$s Tagen',
                day_ago: 'Vor %1$s Tag',
                hours_ago: 'Vor %1$s Stunden',
                hour_ago: 'Vor %1$s Stunde',
                mins_ago: 'Vor %1$s Minuten',
                min_ago: 'Vor %1$s Minute',
                secs_ago: 'Vor %1$s Sekunden',
                sec_ago: 'Vor %1$s Sekunde',
                just_now: 'Grade eben'
            }
        }
    },
    pm: {
        navmenu: {
            items: {
                home: "Home",
                overview: "Overview",
                settings: "Einstellungen",
                general: "Allgemein",
                homepage: "Startseite",
                design: "Design",
                features: "Features",
            }
        },
        settings: {
            general: {
                siteTitle: "Name der Kundenplattform",
                UseCustomDomain: "Use Custom Domain",
                customDomain: "Custom Domain",
                siteURL: "URL der Kundenplattform",
                language: "Plattform Sprache",
                email: "E-Mail für Plattform Benachrichtigungen",
                admin_email: "Admin E-Mail-Adresse",
                signature: "E-Mail Signatur",
                ClientsCanAccess: 'Clients can access the project',
                CustomersCanAccess: 'Customers can access the project',
                buttons: {
                    create: "Kundenplattform erstellen",
                    update: "Einstellungen aktualisieren",
                },
                messages: {
                    create_success: "Plattformadresse: %site_url%.propster.app wurde erfolgreich erstellt und wird in wenigen Minuten verfügbar sein. Einige Einstellungen wurden aus dem zuletzt von Ihrem Unternehmen erstellten Projekt übernommen. Bitte gehen Sie die Einstellungen durch und passen Sie sie gemäß den Anforderungen des neuen Projektes an.",
                    update_success: "Einstellungen wurden aktualisiert.",
                    validation_logo_key_visual_fail: 'Bevor Sie eine Projekt-Website erstellen, gehen Sie bitte zu den <a href="%wizard_url%" target="_blank"><b><u>Projekt Einstellungen</b></u></a> und laden Sie das <b>Logo</b> und die <b>Key Visuals</b> des Projekts hoch.',
                    data_can_not_be_empty: "Data can not be empty.",
                    project_not_found: "Project not found.",
                    default_domain_not_defined: "Default domain not defined.",
                    can_not_save_design_settings: "Can not save Design settings.",
                    can_not_save_homepage_settings: "Can not save Homepage settings.",
                    can_not_save_features_settings: "Can not save Features settings.",
                    api_request_failed: "API request failed.",
                    internal_error: "Internal error."
                },
            },
            homepage: {
                Block1: "Startseite, Block 1",
                Image: "Bild",
                Headline: "Überschrift",
                Text: "Text",
                Block2Contact: "Startseite, Block 2: Kontakt",
                Name: "Name",
                Position: "Position",
                Photo: "Foto",
                Email: "E-Mail",
                PhoneNumber: "Telefonnummer",
                ButtonText: "Button Text",
                ButtonURL: "Button URL",
                Block3: "Startseite, Block 3",
                LinkText: "Link Text",
                LinkTo: "Verlinkt zu",
                Block4: "Startseite, Block 4",
                CustomLink: 'Benutzerdefinierter Link',
                features: {
                    docubox: 'Docu Box',
                    messagebox: 'Message Box',
                    faq: 'FAQ/Helpcenter',
                    deadlines: 'Deadlines',
                    custom: 'Benutzerdefinierter Link',
                },
            },
            design: {
                Logo: "Logo",
                LoginScreenImage: "Bild für Login-Seite",
                PrimaryColor: "Primäre Farbe",
                SecondaryColor: "Sekundäre Farbe",
                Favicon: 'Favicon',
            },
            features: {
                UseDeadlines: "Fristen anzeigen",
                UseHelpCenter: "Helpcenter anzeigen",
                UseNews: "News anzeigen",
                UseDocuBox: "Docu Box anzeigen",
                CommunicationMethod: "Bevorzugte Kommunikation mit Kunden",
                communication_methods: {
                    MessageBox: "Message Box",
                    ContactForm: "Kontaktformular",
                },
                ProjectMode: "Projektmodus - Ansicht für Kunden",
                project_modes: {
                    Configurator: "Konfigurator",
                    DefectManagement: "Mangelmanagement",
                }
            }
        }
    }
}
