const JSON5 = require('json5')

const userListFields = () => {
	return `
		id
		model_name
		first_name
		last_name
		email
		phone_number
		is_active
		avatar_url
		position
		department
		gender
		birthday_date
		address_1
		address_2
		postcode
		city
		country_id
		linkedin_url
		website_url
		xing_url
		user_role_id
		user_companies
		role {
			id
			key_name
			name
			level
			company_restricted
			permission {
				permissions {
					key_name
				}
			}
		}
		ssoServiceRoles {
			sso_app_service_id
			sso_app_role_id
			sso_app_role_key_name
			user_id
		}
		ssoCompanyServiceRoles {
			company_id
			sso_app_service_id
			sso_app_role_id
			sso_app_role_key_name
			user_id
		}
		ssoProjectRoles {
			project_id
			company_id
			sso_app_service_id
			sso_app_role_id
			sso_app_role_key_name
			user_id
		}
		ssoMarketplaceRoles {
			marketplace_id
			sso_mp_role_id
			sso_mp_role_key_name
			user_id
		}
		ssoCompanyMarketplaceRoles {
			company_id
			marketplace_id
			sso_mp_role_id
			sso_mp_role_key_name
			user_id
		}
		ssoMarketplaceProjectRoles {
			project_id
			company_id
			marketplace_id
			sso_mp_role_id
			sso_mp_role_key_name
			user_id
		}
		country_id
		country {
			id
			name
		}
		language_id
		language {
			id
			name
			code
			icon_url
		}
		companies {
			id
			name
		}
		responsibleProjectManager {
			id
			name
		}
		productSelectionContact {
			id
			name
		}
		orderManagementContact {
			id
			name
		}
		remember_token
		created_at
		updated_at
	`;
};

const userFields = () => {
	return `
		id
		model_name
		first_name
		last_name
		email
		phone_number
		is_active
		avatar_url
		position
		department
		gender
		birthday_date
		address_1
		address_2
		postcode
		city
		country_id
		linkedin_url
		website_url
		xing_url
		user_role_id
		user_companies
		role {
			id
			key_name
			level
			company_restricted
			permission {
				permissions {
					key_name
				}
			}
			company_restricted
			license_restricted
		}
		country_id
		country {
			id
			name
		}
		language_id
		language {
			id
			name
			code
			icon_url
		}
		companies {
			id
			name
		}
		ssoServiceRoles {
			sso_app_service_id
			sso_app_role_id
			sso_app_role_key_name
			user_id
		}
		ssoCompanyServiceRoles {
			company_id
			sso_app_service_id
			sso_app_role_id
			sso_app_role_key_name
			user_id
		}
		ssoProjectRoles {
			project_id
			company_id
			sso_app_service_id
			sso_app_role_id
			sso_app_role_key_name
			user_id
		}
		ssoMarketplaceRoles {
			marketplace_id
			sso_mp_role_id
			sso_mp_role_key_name
			user_id
		}
		ssoCompanyMarketplaceRoles {
			company_id
			marketplace_id
			sso_mp_role_id
			sso_mp_role_key_name
			user_id
		}
		ssoMarketplaceProjectRoles {
			project_id
			company_id
			marketplace_id
			sso_mp_role_id
			sso_mp_role_key_name
			user_id
		}
		responsibleProjectManager {
			id
			name
			icon
		}
		productSelectionContact {
			id
			name
			icon
		}
		orderManagementContact {
			id
			name
			icon
		}
		remember_token
		created_at
		updated_at
	`;
};

import helperFunctions from '@/helper/functions.js';

export default {
  getUsers () {
    return `{
        users {
          ${userListFields()}
        }
      }`
  },
	addNewUser(fields) {
		return `
			mutation {
				createUser(${helperFunctions.convertFieldsToString(fields)}, companies: {sync:${JSON.stringify(fields['companies'])}}) {
					${userListFields()}
				}
			}
		`;
	},
	getFilteredUsers(page, items_per_page = null, app_id = null, marketplace_id = null, company_id = null, project_id = null, role = null, is_dashboard = null, search = '') {
		return `
			{
				filteredUsers(page: ${page}, items_per_page: ${items_per_page}, app_id: ${app_id}, marketplace_id: ${marketplace_id}, company_id: ${company_id}, project_id: ${project_id}, role: "${role}", is_dashboard: ${is_dashboard}, search: "${search}") {
					${userListFields()}
				}
			}
		`;
	},
	fetchAvailableUserFiltersData(app_id = null, marketplace_id = null, company_id = null, project_id = null, role = null, is_dashboard = null, search = '') {
		return `
			{
				availableUserFiltersData(app_id: ${app_id}, marketplace_id: ${marketplace_id}, company_id: ${company_id}, project_id: ${project_id}, role: "${role}", is_dashboard: ${is_dashboard}, search: "${search}")
			}
		`;
	},
	updateUser(userId, fields) {
		return `
			mutation($avatar: Upload) {
				updateUser(
					id: ${userId}, 
					companies: {sync:${JSON.stringify(fields['companies'].map((c) => {return c.id;}))}}, 
					avatar: $avatar, 
					${fields.ssoRoles?` ssoRoles: ${JSON5.stringify(fields.ssoRoles).replace(/\'/g,'"')},` : ``} 
					${helperFunctions.convertFieldsToString(fields)}
				) {
					${userFields()}
				}
			}
		`;
	},
	updateCurrentUser(userId, fields) {
		return `
			mutation($avatar: Upload) {
				updateCurrentUser(id: ${userId}, 
				avatar: $avatar,
				${helperFunctions.convertFieldsToString(fields)}
			) {
					id
					model_name
					first_name
					last_name
					email
					phone_number
					avatar_url
					position
					department
					gender
					birthday_date
					address_1
					address_2
					postcode
					city
					country_id
					linkedin_url
					website_url
					xing_url
					country_id
					country {
						id
						name
					}
					language_id
					language {
						id
						name
						code
						icon_url
					}
				}
			}
		`;
	},
	getUserById(userId) {
		return `
			{
				userById(id: ${userId}) {
					${userFields()}
				}
			}
		`;
	},
	getCurrentUserById(userId) {
		return `
			{
				userById(id: ${userId}) {
					id
					model_name
					first_name
					last_name
					email
					phone_number
					avatar_url
					position
					department
					gender
					birthday_date
					address_1
					address_2
					postcode
					city
					country_id
					linkedin_url
					website_url
					xing_url
					country_id
					country {
						id
						name
					}
					language_id
					language {
						id
						name
						code
						icon_url
					}
				}
			}
		`;
	},
	deleteUser(userId) {
		return `
			mutation {
				deleteUser(id: ${userId}) {
					${userListFields()}
				}
			}
		`;
	}
}
