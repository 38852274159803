const companyFields = () => {
	return `
		id
		propster_company_id
		name
		street
		house_number
		postcode
		city
		payment_modality
		payment_cadence
		iban
		bic
		bank_institute
		public_phone_number
		uid_number
		company_register
		website_url
		country_id
		company_id
		is_vendor
		has_project_dashboard
		billing_company_name
		billing_postcode
		billing_invoicing_email
		billing_phone
		billing_street
		billing_house_humber
		billing_city
		billing_country
		billing_contact_person_type
		billing_contact_person
		billing_contact_person_user_id
		identity_provider_id
		country {
			id
			name
		}
		projects {
			id
			name
		}
		users {
			id
			first_name
			last_name
			email
		}
		sales_manager_id
		salesManager {
			id
			first_name
			last_name
			email
			phone_number
			avatar_url
		}
		created_at
		updated_at
		model_name
		parentCompany {
			id
			name
			website_url
			city
			is_vendor
			country {
				name
			}
		}
		childCompanies {
			id
			name
			website_url
			city
			is_vendor
			country {
				name
			}
		}
		marketplaces {
			id
			name
			website
			icon
		}
		logo
	`;
};

const companyFieldsFiltered = () => {
	return `
		id
		propster_company_id
		name
		street
		house_number
		postcode
		city
		payment_modality
		payment_cadence
		iban
		bic
		bank_institute
		public_phone_number
		uid_number
		company_register
		website_url
		country_id
		company_id
		is_vendor
		has_project_dashboard
		billing_company_name
		billing_postcode
		billing_invoicing_email
		billing_phone
		billing_street
		billing_house_humber
		billing_city
		billing_country
		billing_contact_person_type
		billing_contact_person
		billing_contact_person_user_id
		identity_provider_id
		country {
			id
			name
		}
		projects {
			id
			name
		}
		users {
			id
			first_name
			last_name
			email
		}
		sales_manager_id
		salesManager {
			id
			first_name
			last_name
			email
			phone_number
			avatar_url
		}
		created_at
		updated_at
		model_name
		parentCompany {
			id
			name
			website_url
			city
			is_vendor
			country {
				name
			}
		}
		childCompanies {
			id
			name
			website_url
			city
			is_vendor
			country {
				name
			}
			projects {
				id
				name
			}
			users {
				id
				first_name
				last_name
				email
			}
		}
		marketplaces {
			id
			name
			website
			icon
		}
		logo
	`;
};

const adminCompanyFields = () => {
	return `
		id
		propster_company_id
		name
		street
		house_number
		postcode
		city
		payment_modality
		payment_cadence
		iban
		bic
		bank_institute
		public_phone_number
		uid_number
		company_register
		website_url
		country_id
		company_id
		is_vendor
		has_project_dashboard
		billing_company_name
		billing_postcode
		billing_street
		billing_house_humber
		billing_city
		billing_country
		billing_invoicing_email
		billing_phone
		billing_contact_person_type
		billing_contact_person
		billing_contact_person_user_id
		identity_provider_id
		country {
			id
			name
		}
		projects {
			id
			name
		}
		users {
			id
			first_name
			last_name
			email
		}
		sales_manager_id
		salesManager {
			id
			first_name
			last_name
			email
			phone_number
			avatar_url
		}
		created_at
		updated_at
		model_name
		parentCompany {
			id
			name
			website_url
			city
			is_vendor
			country {
				name
			}
		}
		childCompanies {
			id
			name
			website_url
			city
			is_vendor
			country {
				name
			}
		}
		marketplaces {
			id
			name
			website
			icon
		}
		adminMarketplaces {
			id
			name
			website
			icon
		}
		logo
	`;
};

const parentCompanyFields = () => {
	return `
		id
		propster_company_id
		name
		street
		house_number
		postcode
		city
		uid_number
		company_register
		public_phone_number
		website_url
		country_id
		company_id
		created_at
		updated_at
		model_name
		is_vendor
		identity_provider_id
		childCompanies {
			id
			name
			website_url
			city
			is_vendor
			country {
				name
			}
		}
		logo
	`;
};

import helperFunctions from '@/helper/functions.js';

export default {
	getAllCompanies() {
		return `
			{
				companies {
					${companyFields()}
				}
			}
		`;
	},
	getAllParentCompanies() {
		return `
			{
				parentCompanies(orderBy: [
					{
						field: "name"
						order: ASC
					}
				]) {
					${parentCompanyFields()}
				}
			}
		`;
	},
	getAllCompaniesList() {
		return `
			{
				companies(orderBy: [
					{
						field: "name"
						order: ASC
					}
				]) {
					${companyFields()}
				}
			}
		`;
	},
	getFilteredCompanies(page, items_per_page = null, search = '') {
		return `
			{
				filteredCompanies(page: ${page}, items_per_page: ${items_per_page}, search: "${search}") {
					${companyFieldsFiltered()}
				}
			}
		`;
	},
	getCompanyById(companyId) {
		return `
			{
				companyById(id: ${companyId}) {
					${companyFields()}
				}
			}
		`;
	},
	getAdminCompanyById(companyId) {
		return `
			{
				companyById(id: ${companyId}) {
					${adminCompanyFields()}
				}
			}
		`;
	},
	getUserMarketplaceCompanies(userId) {
		return `
			{
				userMarketplaceCompanies(id: ${userId}) {
					${companyFields()}
				}
			}
		`;
	},
	createCompany(companyData) {
		return `
			mutation($logo: Upload) {
				createCompany(${helperFunctions.convertFieldsToString(companyData)}, logo: $logo) {
					${companyFields()}
				}
			}
		`;
	},
	updateCompany(companyId, fields) {
		return `
			mutation($logo: Upload) {
				updateCompany(id: ${companyId}, ${helperFunctions.convertFieldsToString(fields)}, logo: $logo) {
					${companyFields()}
				}
			}
		`;
	},
	pappApiUpdate(companyId) {
		return `
			mutation {
				response: companyPappApiUpdate(id: ${companyId}) {
					${companyFields()}
				}
			}
		`;
	},
	deleteCompany(companyId) {
		return `
			mutation {
				deleteCompany(id: ${companyId}) {
					${companyFields()}
				}
			}
		`;
	},
	bulkDeleteCompanies(companyIds) {
		return `
			mutation {
				bulkDeleteCompanies(ids: ${JSON.stringify(companyIds)}) {
					${companyFields()}
				}
			}
		`;
	},
	associateCompaniesToMarketplaces(marketplaceIds, companyIds, parentCompanyID) {
		return `
			mutation {
				associateCompaniesToMarketplace(ids: ${JSON.stringify(marketplaceIds)}, companyIds: ${JSON.stringify(companyIds)}, parentCompanyID: ${parentCompanyID}) {
					id
					name
					website
					icon
					is_private
					is_active
					model_name
				}
			}
		`;
	}
}
