/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import has from 'lodash/has'
import apiQueries from './api/queries.js'
import axios from '@/http/axios'
import helperFunctions from '@/helper/functions.js'

export default {
  login ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let query = apiQueries.login(
        payload.userDetails.email,
        payload.userDetails.password,
        payload.checkbox_remember_me
      );
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          // If there's user data in response
          if (has(response, ['data', 'data', 'login', 'user'])) {
            axios.post('/graphql', {
              query: apiQueries.fetchPermissionsList()
            })
              .then((permissionsResponse) => {
                if (has(permissionsResponse, ['data', 'data', 'userPermissions'])) {
                  const userData = response.data.data.login.user
                  userData.userRole = userData.role.key_name
                  userData.status = 'online'
                  const existingPermissions = userData.role.permission.map(function (permission) {
                    return permission.permissions.key_name
                  })
                  userData.aclPermissions = {}
                  for (const perm in permissionsResponse.data.data.userPermissions) {
                    if (existingPermissions.indexOf(permissionsResponse.data.data.userPermissions[perm].key_name) >= 0) {
                      userData.aclPermissions[permissionsResponse.data.data.userPermissions[perm].key_name] = true
                    } else {
                      userData.aclPermissions[permissionsResponse.data.data.userPermissions[perm].key_name] = false
                    }
                  }
                  userData.menuAccess = {
                    marketplaces: [],
                    applications: []
                  }
                  userData.companies.forEach(function (company) {
                    company.marketplaces.forEach(function (marketplace) {
                      userData.menuAccess.marketplaces.push(marketplace)
                    })
                    delete company.marketplaces
                  })
                  commit('UPDATE_USER_INFO', userData, {root: true})
                  commit('SET_AUTH', response.data.data.login)
                  axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.data.login.access_token}`
                  resolve(response)
                } else {
                  return reject({message: 'Something went wrong'})
                }
                resolve(response.data.data.users)
              })
              .catch(error => { reject(error) })
          } else if (has(response, ['data', 'errors']) && has(response.data.errors[0], ['extensions', 'reason'])) {
            return reject({reason: response.data.errors[0].extensions.reason, message: response.data.errors[0].message});
          } else {
            return reject({reason: 'Incorrect_username_or_password', message: 'UNKNOWN_LOGIN_ERROR'});
          }
        })
        .catch(error => { reject(error) })
    })
  },
  ssoLogin ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let query;
      axios.post('/graphql', {
        query: apiQueries.ssoLogin(payload.email, payload.adcode)
      })
        .then((response) => {
          // If there's user data in response
          if (has(response, ['data', 'data', 'ssoLogin', 'user'])) {
            axios.post('/graphql', {
              query: apiQueries.fetchPermissionsList()
            })
              .then((permissionsResponse) => {
                if (has(permissionsResponse, ['data', 'data', 'userPermissions'])) {
                  const userData = response.data.data.ssoLogin.user
                  userData.userRole = userData.role.key_name
                  userData.status = 'online'
                  const existingPermissions = userData.role.permission.map(function (permission) {
                    return permission.permissions.key_name
                  })
                  userData.aclPermissions = {}
                  for (const perm in permissionsResponse.data.data.userPermissions) {
                    if (existingPermissions.indexOf(permissionsResponse.data.data.userPermissions[perm].key_name) >= 0) {
                      userData.aclPermissions[permissionsResponse.data.data.userPermissions[perm].key_name] = true
                    } else {
                      userData.aclPermissions[permissionsResponse.data.data.userPermissions[perm].key_name] = false
                    }
                  }
                  userData.menuAccess = {
                    marketplaces: [],
                    applications: []
                  }
                  userData.companies.forEach(function (company) {
                    company.marketplaces.forEach(function (marketplace) {
                      userData.menuAccess.marketplaces.push(marketplace)
                    })
                    delete company.marketplaces
                  });
                  commit('UPDATE_USER_INFO', userData, {root: true})
                  commit('SET_AUTH', response.data.data.ssoLogin)
                  axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.data.ssoLogin.access_token}`
                  resolve(response);
                } else {
                  return reject({message: 'Something went wrong'})
                }
                resolve(response.data.data.users)
              })
              .catch(error => { reject(error) })
          } else if (has(response, ['data', 'errors']) && has(response.data.errors[0], ['extensions', 'reason'])) {
            return reject({reason: response.data.errors[0].extensions.reason, message: response.data.errors[0].message});
          } else {
            return reject({reason: 'Wrong Email or Password', message: 'UNKNOWN_LOGIN_ERROR'});
          }
        })
        .catch(error => { reject(error) })
    })
  },
  setAutologinInterval ({ commit }, interval) {
    commit('SET_AUTOLOGIN_INTERVAL', interval);
  },
  unsetAutologinInterval ({ commit }) {
    commit('RESET_AUTOLOGIN_INTERVAL');
  },
  refreshUserInfo ({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getUserById(userId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors);
          }

          axios.post('/graphql', {
            query: apiQueries.fetchPermissionsList()
          })
            .then((permissionsResponse) => {
              if (has(permissionsResponse, ['data', 'data', 'userPermissions'])) {
                let userData = response.data.data.userById;
                userData.userRole = userData.role.key_name;
                userData.status = 'online';

                const existingPermissions = userData.role.permission.map(function (permission) {
                  return permission.permissions.key_name;
                });

                userData.aclPermissions = {};

                for (const perm in permissionsResponse.data.data.userPermissions) {
                  if (existingPermissions.indexOf(permissionsResponse.data.data.userPermissions[perm].key_name) >= 0) {
                    userData.aclPermissions[permissionsResponse.data.data.userPermissions[perm].key_name] = true;
                  } else {
                    userData.aclPermissions[permissionsResponse.data.data.userPermissions[perm].key_name] = false;
                  }
                }

                userData.menuAccess = {
                  marketplaces: [],
                  applications: []
                };

                userData.companies.forEach(function (company) {
                  company.marketplaces.forEach(function (marketplace) {
                    userData.menuAccess.marketplaces.push(marketplace);
                  });

                  delete company.marketplaces;
                });

                commit('UPDATE_USER_INFO', userData, {root: true});
                resolve(response);
              } else {
                return reject({message: 'Something went wrong'});
              }
            })
            .catch(error => { reject(error) })
          
        })
        .catch(error => { reject(error) })
    })
  },
  refreshAccessToken ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.refreshToken(payload.refreshToken)
      })
        .then((response) => {
          if (has(response, ['data', 'data', 'refreshToken', 'access_token'])) {
            commit('SET_AUTH', response.data.data.refreshToken)
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.data.refreshToken.access_token}`
            resolve(response.data.data.refreshToken.access_token)
          } else if (has(response, ['data', 'errors']) && has(response.data.errors[0], ['extensions', 'reason'])) {
            reject({message: response.data.errors[0].extensions.reason})
          } else {
            reject({message: 'Unknown error occurred'})
          }
        })
        .catch(error => { reject(error) })
    })
  },
  logout ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.logout()
      })
        .then((response) => {
          commit('UNSET_USER_INFO', {}, {root: true})
          commit('UNSET_AUTH')
          if (has(response, ['data', 'data', 'logout', 'status'])) {
            if (response.data.data.logout.status === 'TOKEN_REVOKED') {
              resolve(response)
            } else {
              return reject({message: 'Something went wrong'})
            }
          } else if (has(response, ['data', 'errors']) && has(response.data.errors[0], ['debugMessage'])) {
            return reject({message: response.data.errors[0].debugMessage})
          } else {
            return reject({message: 'Something went wrong'})
          }
        })
        .catch(error => {
          commit('UNSET_USER_INFO', {}, {root: true})
          commit('UNSET_AUTH')
          reject(error)
        })
    })
  },
  forgotPassword ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.forgotPassword(payload.email)
      })
        .then((response) => {
          if (has(response, ['data', 'data', 'forgotPassword', 'status'])) {
            if (response.data.data.forgotPassword.status === 'EMAIL_SENT') {
              resolve(response)
            } else {
              return reject({message: 'Something went wrong'})
            }
          } else if (has(response, ['data', 'errors']) && has(response.data.errors[0], ['message'])) {
            return reject({message: response.data.errors[0].message})
          } else if (has(response, ['data', 'errors']) && has(response.data.errors[0], ['debugMessage'])) {
            return reject({message: response.errors[0].debugMessage})
          } else {
            return reject({message: 'Something went wrong'})
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  checkResetToken ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getResetTokenStatus(data.token, data.email)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setPassword ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.setPassword(payload.email, payload.token, payload.password, payload.password_confirmation)
      })
        .then((response) => {
          if (has(response, ['data', 'data', 'updateForgottenPassword', 'status'])) {
            if (response.data.data.updateForgottenPassword.status === 'PASSWORD_UPDATED') {
              resolve(response)
            } else {
              return reject({message: 'Something went wrong'})
            }
          } else if (has(response, ['data', 'errors']) && has(response.data.errors[0], ['message'])) {
            let responseErrors = [response.data.errors[0].message]
            if (has(response.data.errors[0], ['extensions', 'validation'])) {
              for (let i in response.data.errors[0].extensions.validation) {
                responseErrors = [...responseErrors, ...response.data.errors[0].extensions.validation[i]]
              }
            }
            if (has(response.data.errors[0], ['extensions', 'errors'])) {
              for (const [field, message] of Object.entries(response.data.errors[0].extensions.errors)) {
                responseErrors = [...responseErrors, ...[message]]
              }
            }
            return reject(responseErrors)
          } else {
            return reject({message: 'Something went wrong'})
          }
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchRolesList ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.fetchRolesList()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors))
          }
          if (has(response, ['data', 'data', 'userRoles'])) {
            commit('SET_ROLES_LIST', response.data.data.userRoles)
          }
          resolve(response.data.data.users)
        })
        .catch(error => { reject(error) })
    })
  },
  fetchRoles ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.fetchRoles()
      })
        .then((response) => {
          if (has(response, ['data', 'data', 'userRoles'])) {
            commit('SET_ROLES', response.data.data.userRoles)
          } else {
            return reject({message: 'Something went wrong'})
          }
          resolve(response.data.data.userRoles)
        })
        .catch(error => { reject(error) })
    })
  },
  createRole ({ commit }, roleData) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.createRole(roleData)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          commit('ADD_NEW_ROLE', [response.data.data.createUserRole])
          resolve(response.data.data.createUserRole)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateRole ({ commit }, roleData) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.updateRole(roleData)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.updateUserRole)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteRole ({ commit }, roleId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.deleteRole(roleId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          commit('DELETE_ROLE', response.data.data.deleteUserRole)
          resolve(response.data.data.deleteUserRole)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchPermissionsList ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.fetchPermissionsList()
      })
        .then((response) => {
          if (has(response, ['data', 'data', 'userPermissions'])) {
            commit('SET_PERMISSIONS_LIST', response.data.data.userPermissions)
          } else {
            return reject({message: 'Something went wrong'})
          }
          resolve(response.data.data.users)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchIdentityProviders ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.fetchIdentityProviders()
      })
        .then((response) => {
          if (has(response, ['data', 'data', 'list'])) {
            commit('SET_IDENTITY_PROVIDERS', response.data.data.list);
          } else {
            return reject({message: 'Something went wrong'});
          }
          resolve(response.data.data.list);
        })
        .catch(error => { reject(error); })
    })
  },
  fetchIdentityProviderTypes ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.fetchIdentityProviderTypes()
      })
        .then((response) => {
          if (has(response, ['data', 'data', 'list'])) {
            commit('SET_IDENTITY_PROVIDER_TYPES', response.data.data.list);
          } else {
            return reject({message: 'Something went wrong'});
          }
          resolve(response.data.data.list);
        })
        .catch(error => { reject(error); })
    })
  },
  createIdentityProvider ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.createIdentityProvider(data)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors);
          }
          commit('ADD_NEW_IDENTITY_PROVIDER', [response.data.data.create]);
          resolve(response.data.data.create);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateIdentityProvider ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.updateIdentityProvider(data)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors);
          }
          resolve(response.data.data.update);
        })
        .catch((error) => {
          reject(error);
        })
    })
  },
  deleteIdentityProvider ({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.deleteIdentityProvider(id)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          commit('DELETE_IDENTITY_PROVIDER', response.data.data.delete)
          resolve(response.data.data.delete)
        })
        .catch((error) => { reject(error) })
    })
  },
  checkIdentityProvider ({ commit }, email) {
    return new Promise((resolve, reject) => {
      let query = apiQueries.checkIdentityProvider(email);
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        resolve(response.data.data.checkIdentityProvider);
      })
      .catch(error => { reject(error) })
    })
  },
  identityProviderLogout ({ commit }) {
    return new Promise((resolve, reject) => {
      let query = apiQueries.identityProviderLogout();
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          resolve(response.data.data.identityProviderLogout);
        })
        .catch(error => { reject(error) })
    })
  },
  loginNext({ commit }) {
    commit('LOGIN_NEXT');
  },
  loginBack({ commit }) {
    commit('LOGIN_BACK');
  }
}
