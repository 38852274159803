/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

import moduleEmail from './email/moduleEmail.js'
import moduleAuth from './auth/moduleAuth.js'
import moduleECommerce from './eCommerce/moduleECommerce.js'
import moduleUserManagement from './user-management/moduleUserManagement.js'
import moduleCompanies from './companies/moduleCompanies.js'
import moduleProjects from './projects/moduleProjects.js'
import moduleCountries from './countries/moduleCountries.js'
import moduleRolesPermissions from './roles-permissions/moduleRolesPermissions.js'
import moduleLanguages from './languages/moduleLanguages'
import moduleOrders from './orders/moduleOrders'
import moduleStream from './stream/moduleStream'
import moduleNews from './news/moduleNews'
import moduleNotifications from './notifications/moduleNotifications'
import moduleMarketplaces from './marketplaces/moduleMarketplaces'
import moduleSsoApp from './sso/app/moduleSsoApp'
import moduleSsoMp from './sso/mp/moduleSsoMp'
import moduleSso from './sso/moduleSso'
import moduleDictionaries from './dictionaries/moduleDictionaries'
import moduleEvents from './events/moduleEvents'
import moduleApiUsers from './api-users/moduleApiUsers'

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    // todo: moduleTodo,
    // calendar: moduleCalendar,
    // chat: moduleChat,
    email: moduleEmail,
    auth: moduleAuth,
    eCommerce: moduleECommerce,
    userManagement: moduleUserManagement,
    companies: moduleCompanies,
    projects: moduleProjects,
    countries: moduleCountries,
    rolesPermissions: moduleRolesPermissions,
    languages: moduleLanguages,
    orders: moduleOrders,
    stream: moduleStream,
    news: moduleNews,
    notifications: moduleNotifications,
    marketplaces: moduleMarketplaces,
    ssoApp: moduleSsoApp,
    ssoMp: moduleSsoMp,
    sso: moduleSso,
    dictionaries: moduleDictionaries,
    events: moduleEvents,
    apiUsers: moduleApiUsers
  },
  strict: process.env.NODE_ENV !== 'production'
})
