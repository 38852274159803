export default {
	getAllLanguages() {
		return `
			{
				languages {
					id
					name
					code
					icon_url
				}
			}
		`;
	}
}
