/*=========================================================================================
  File Name: moduleSsoAppMutations.js
  Description: SSO App Module Mutations
==========================================================================================*/


export default {
 SET_APP_SERVICES (state, ssoAppServices) {
  state.ssoAppServices = ssoAppServices;
 },
 SET_APP_ROLES (state, ssoAppRoles) {
  state.ssoAppRoles = ssoAppRoles;
 },
 ADD_NEW_APP_ROLE (state, role) {
  state.ssoAppRoles = [...state.ssoAppRoles, ...role]
 },
 DELETE_APP_ROLE (state, deletedRole) {
  state.ssoAppRoles = state.ssoAppRoles.filter(role => {
   return role.id !== deletedRole.id
  })
 }
}
