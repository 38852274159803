/*=========================================================================================
  File Name: moduleProjectsState.js
  Description: Projects Module State
==========================================================================================*/

export default {
  newNotificationsCount: {},
  unreadNotificationsCount: {},
  notifications: []
}
