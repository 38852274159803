const marketplaceFields = () => {
	return `
		id
		name
		website
		icon
		is_private
		is_active
		model_name
		companies {
			id
			name
			website_url
			city
			country {
				name
			}
		}
	`;
};

import helperFunctions from '@/helper/functions.js';

export default {
	getAllMarketplaces() {
		return `
			{
				marketplaces (orderBy: [
					{
						field: "name"
						order: ASC
					}
				]) {
					${marketplaceFields()}
				}
			}
		`;
	},
	getActiveMarketplaces() {
		return `
			{
				marketplaces (orderBy: [
					{
						field: "name"
						order: ASC
					}
				]) {
					${marketplaceFields()}
				}
			}
		`;
	},
	getFilteredMarketplaces(page, search = '') {
		return `
			{
				filteredMarketplaces(page: ${page}, search: "${search}") {
					${marketplaceFields()}
				}
			}
		`;
	},
	getMarketplaceById(marketplaceId) {
		return `
			{
				marketplaceById(id: ${marketplaceId}) {
					${marketplaceFields()}
				}
			}
		`;
	},
	getUserMarketplacesWithRoles(userId) {
		return `
			{
				userMarketplacesWithRoles(id: ${userId}) {
					${marketplaceFields()}
				}
			}
		`;
	},
	createMarketplace(marketplaceData) {
		return `
			mutation {
				createMarketplace(${helperFunctions.convertFieldsToString(marketplaceData)}) {
					${marketplaceFields()}
				}
			}
		`;
	},
	updateMarketplace(marketplaceId, fields) {
		return `
			mutation($icon: Upload) {
				updateMarketplace(id: ${marketplaceId}, icon: $icon, ${helperFunctions.convertFieldsToString(fields)}) {
					${marketplaceFields()}
				}
			}
		`;
	},
	associateCompaniesToMarketplace(marketplaceIds, companyIds) {
		return `
			mutation {
				associateCompaniesToMarketplace(ids: ${JSON.stringify(marketplaceIds)}, companyIds: ${JSON.stringify(companyIds)}) {
					${marketplaceFields()}
				}
			}
		`;
	},
	deleteMarketplace(marketplaceId) {
		return `
			mutation {
				deleteMarketplace(id: ${marketplaceId}) {
					${marketplaceFields()}
				}
			}
		`;
	}
}
