/*=========================================================================================
  File Name: moduleOrdersActions.js
  Description: Orders Module Actions
==========================================================================================*/

import axios from '@/axios.js';
import helperFunctions from '@/helper/functions.js';
import apiQueries from './api/queries.js';

export default {
  fetchOrders ({ commit }) {
    const query = apiQueries.getAllOrders();

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.orders;
          commit('SET_ORDERS', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchOrder (context, orderId) {
    const query = apiQueries.getOrderById(orderId);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.orderById;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  createOrder({ commit }, orderData) {
    const query = apiQueries.createOrder(orderData, orderData.service_ids);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.createOrder;
          commit('ADD_NEW_ORDER', [responseData]);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  updateOrder({ commit }, orderData) {
    const query = apiQueries.updateOrder(orderData.id, orderData.serviceIds, orderData.updatedFields);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.updateOrder;
          commit('UPDATE_ORDER', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  deleteOrder ({ commit }, orderId) {
    const query = apiQueries.deleteOrder(orderId);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.deleteOrder;
          commit('DELETE_ORDER', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
}
