/*=========================================================================================
  File Name: moduleUserManagement.js
  Description: User Management Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleApiUsersState.js'
import mutations from './moduleApiUsersMutations.js'
import actions from './moduleApiUsersActions.js'
import getters from './moduleApiUsersGetters.js'

export default {
  isRegistered: true,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

