export default class CompanyPolicy
{
    companyPermission (user, company, permission) {

        if (user.role.key_name === 'admin') {
            return true
        }
        if (user.role.company_restricted && company
         && user.user_companies.indexOf(parseInt(company.id)) == -1
         && user.user_companies.indexOf(parseInt(company.company_id)) == -1) {
            return false
        }

        return user.aclPermissions[permission]
    }

    /**
     * Determine whether the user can view any models.
     *
     * @param  {object}  user
     * @return mixed
     */
    viewAny(user)
    {
        return user.aclPermissions.view_companies
    }

    /**
     * Determine whether the user can view the model.
     *
     * @param  {object}  user
     * @param  {object}  company
     * @return {mixed}
     */
    view(user, company)
    {
        return this.companyPermission(user, company, 'view_companies')
    }

    /**
     * Determine whether the user can create models.
     *
     * @param  {object}  user
     * @return {mixed}
     */
    create(user)
    {
        return this.companyPermission(user, false, 'add_companies')
    }

    /**
     * Determine whether the user can update the model.
     *
     * @param  {object}  user
     * @param  {object}  company
     * @return {mixed}
     */
    update(user, company)
    {
        return this.companyPermission(user, company, 'edit_companies')
    }

    /**
     * Determine whether the user can restore the model.
     *
     * @param  {object}  user
     * @param  {object}  company
     * @return {mixed}
     */
    restore(user, company)
    {
        return this.companyPermission(user, company, 'delete_companies')
    }

    /**
     * Determine whether the user can delete the model.
     *
     * @param  {object}  user
     * @param  {object}  company
     * @return {mixed}
     */
    delete(user, company)
    {
        return this.companyPermission(user, company, 'delete_companies')
    }

    /**
     * Determine whether the user can permanently delete the model.
     *
     * @param  {object}  user
     * @param  {object}  company
     * @return {mixed}
     */
    forceDelete(user, company)
    {
        return this.companyPermission(user, company, 'delete_companies')
    }

    /**
     * Determine whether the user can delete the model.
     *
     * @param  {object}  user
     * @return {mixed}
     */
    adminOnly(user)
    {
        return (user.role.key_name === 'admin')
    }

    /**
     * Determine whether the user can delete the model.
     *
     * @param  {object}  user
     * @return {mixed}
     */
    deleteAll(user)
    {
        return this.adminOnly(user);
    }

    /**
     * Determine whether the user can see the PROPSTER admin field in company form.
     *
     * @param  {object}  user
     * @return {mixed}
     */
    editPropsterAdmin (user, company)
    {
        return this.companyPermission(user, company, 'edit_propster_admin') && !user.role.company_restricted;
    }

    seePropsterID (user, company)
    {
        return this.companyPermission(user, company, 'view_propster_id')
    }

    editPropsterID (user, company)
    {
        return this.companyPermission(user, company, 'edit_propster_id') && !user.role.company_restricted;
    }

    editCompanyType (user, company)
    {
        return this.companyPermission(user, company, 'edit_company_type') && !user.role.company_restricted;
    }

    allowNoParentCompany (user, company)
    {
        return this.companyPermission(user, company, 'allow_no_parent_company') && !user.role.company_restricted;
    }

    editMarketplaces (user, company)
    {
        return user.role.key_name === 'admin'
    }
}
