const notificationFields = () => {
  return `
      id
      user_id
      notification_type_id
      event
      company_id
      project_id
      order_id
      news_id
      seen
      read
      meta
      icon
      color
      created_at
      created_time
  `
}

export default {
  fetchNotificationsCounts() {
    return `
      {
          userStatus {
              new_notifications
              unread_notifications
          }
      }`
  },
  fetchNotifications(perPage, lastID) {
    return `
      {
          notifications (pagination: {
              perPage: ${parseInt(perPage)},
              lastID: ${lastID}
          }) {
             remaining_notifications
             notifications {
              ${notificationFields()}
             }
          }
      }`
  },
  readNotification(notificationID) {
    return `
      {
          readNotification (notification_id: ${parseInt(notificationID)}) {
            id
            read
            seen
          }
      }`
  },
  fetchEmailNotificationsData() {
    return `
      {
        emailNotificationsData {
          id
          project_created
          project_updated
        }
      }
    `;
  },
  updateEmailNotificationsData(data) {
    return `
      mutation {
        updateEmailNotificationsData(id: ${data.id}, project_updated: ${JSON.stringify(data.project_updated)}, project_created: ${JSON.stringify(data.project_created)}) {
          id
          project_created
          project_updated
        }
      }
    `;
  }
}
