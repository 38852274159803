/*=========================================================================================
  File Name: moduleLanguagesMutations.js
  Description: Languages Module Mutations
==========================================================================================*/


export default {
  SET_LANGUAGES (state, languages) {
    state.languages = languages
  }
}
