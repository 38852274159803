export default {
	privacyPolicy: `<h1>Impressum</h1>
<p>Informationspflicht laut &sect;5 E-Commerce Gesetz, &sect;14 Unternehmensgesetzbuch, &sect;63 Gewerbeordnung und Offenlegungspflicht laut &sect;25 Mediengesetz.</p>
<p>PROPSTER Sonderwunsch Meister GmbH<br /> Mariahilferstra&szlig;e 37-39, DG<br /> 1060 Wien, <br /> &Ouml;sterreich</p>
<p><strong>Unternehmensgegenstand:</strong> IT Dienstleistungen<br /><strong>UID-Nummer:</strong> ATU72559689<br /><strong>Firmenbuchnummer:</strong> FN 477854 b<br /><strong>Firmenbuchgericht:</strong> Handelsgericht Wien</p>
<p><strong>Tel.:</strong> +4313610101<br /><strong>E-Mail: </strong><a href="mailto:office@propster.tech">office@propster.tech</a></p>
<p><strong>Mitglied bei:</strong> Wirtschaftskammer Wien</p>
<p><strong>Verleihungsstaat:</strong> &Ouml;sterreich</p>
<p><strong>Gesch&auml;ftsf&uuml;hrer</strong><br /> Milan Zahradnik</p>
<p><strong>Kontaktdaten des Verantwortlichen f&uuml;r Datenschutz</strong><br /> Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br /> PROPSTER - Sonderwunsch Meister GmbH<br /> Mariahilferstra&szlig;e 37-39, DG<br /> 1060 Wien<br /> &Ouml;sterreich<br /> Vertretungsberechtigt: Milan Zahradnik<br /> E-Mail-Adresse: <a href="mailto:office@propster.tech">office@propster.tech</a><br /> Telefon: +4313610101<br /> Impressum: <a href="https://www.propster.tech/impressum/">https://www.propster.tech/impressum/</a></p>
<h2>EU-Streitschlichtung</h2>
<p>Gem&auml;&szlig; Verordnung &uuml;ber Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) m&ouml;chten wir Sie &uuml;ber die Online-Streitbeilegungsplattform (OS-Plattform) informieren.<br /> Verbraucher haben die M&ouml;glichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europ&auml;ischen Kommission unter <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE">https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE</a> zu richten. Die daf&uuml;r notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.</p>
<p>Wir m&ouml;chten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
<h2>Haftung f&uuml;r Inhalte dieser Website</h2>
<p>Wir entwickeln die Inhalte dieser Website st&auml;ndig weiter und bem&uuml;hen uns korrekte und aktuelle Informationen bereitzustellen. Leider k&ouml;nnen wir keine Haftung f&uuml;r die Korrektheit aller Inhalte auf dieser Website &uuml;bernehmen, speziell f&uuml;r jene, die seitens Dritter bereitgestellt wurden. Als Diensteanbieter sind wir nicht verpflichtet, die von Ihnen &uuml;bermittelten oder gespeicherten Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen.</p>
<p>Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder beh&ouml;rdlichen Anordnungen bleiben auch im Falle unserer Nichtverantwortlichkeit davon unber&uuml;hrt.</p>
<p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte entfernen k&ouml;nnen. Sie finden die Kontaktdaten im Impressum.</p>
<h2>Haftung f&uuml;r Links auf dieser Website</h2>
<p>Unsere Website enth&auml;lt Links zu anderen Websites f&uuml;r deren Inhalt wir nicht verantwortlich sind. Haftung f&uuml;r verlinkte Websites besteht f&uuml;r uns nicht, da wir keine Kenntnis rechtswidriger T&auml;tigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links sofort entfernen w&uuml;rden, wenn uns Rechtswidrigkeiten bekannt werden.</p>
<p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.</p>
<h2>Urheberrechtshinweis</h2>
<p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Bitte fragen Sie uns bevor Sie die Inhalte dieser Website verbreiten, vervielf&auml;ltigen oder verwerten wie zum Beispiel auf anderen Websites erneut ver&ouml;ffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.</p>
<p>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.</p>
<h2>Bildernachweis</h2>
<p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich gesch&uuml;tzt.</p>
<p>Die Bilderrechte liegen bei:</p>
<p>Fotograf Markus Schieder<br /> Fotografin Flo Hanatschek</p>
<p>Alle Texte sind urheberrechtlich gesch&uuml;tzt.</p>
<h1>Datenschutzerkl&auml;rung</h1>
<h2>Inhaltsverzeichnis</h2>
<ul>
<li><a href="#einleitung-ueberblick">Einleitung und &Uuml;berblick</a></li>
<li><a href="#anwendungsbereich">Anwendungsbereich</a></li>
<li><a href="#rechtsgrundlagen">Rechtsgrundlagen</a></li>
<li><a href="#kontaktdaten-verantwortliche">Kontaktdaten des Verantwortlichen</a></li>
<li><a href="#speicherdauer">Speicherdauer</a></li>
<li><a href="#rechte-dsgvo">Rechte laut Datenschutz-Grundverordnung</a></li>
<li><a href="#datenuebertragung-drittlaender">Daten&uuml;bertragung in Drittl&auml;nder</a></li>
<li><a href="#sicherheit-datenverarbeitung">Sicherheit der Datenverarbeitung</a></li>
<li><a href="#kommunikation">Kommunikation</a></li>
<li><a href="#auftragsverarbeitungsvertrag-avv">Auftragsverarbeitungsvertrag (AVV)</a></li>
<li><a href="#cookies">Cookies</a></li>
<li><a href="#webhosting-einleitung">Webhosting Einleitung</a></li>
<li><a href="#website-baukastensysteme-einleitung">Website Baukastensysteme Einleitung</a></li>
<li><a href="#web-analytics-einleitung">Web Analytics Einleitung</a></li>
<li><a href="#e-mail-marketing-einleitung">E-Mail-Marketing Einleitung</a></li>
<li><a href="#push-nachrichten-einleitung">Push-Nachrichten Einleitung</a></li>
<li><a href="#messenger-kommunikation-einleitung">Messenger &amp; Kommunikation Einleitung</a></li>
<li><a href="#chatbots-einleitung">Chatbots Einleitung</a></li>
<li><a href="#social-media-einleitung">Social Media Einleitung</a></li>
<li><a href="#blogs-und-publikationsmedien-einleitung">Blogs und Publikationsmedien Einleitung</a></li>
<li><a href="#online-marketing-einleitung">Online-Marketing Einleitung</a></li>
<li><a href="#content-delivery-networks-einleitung">Content Delivery Networks Einleitung</a></li>
<li><a href="#audio-video-einleitung">Audio &amp; Video Einleitung</a></li>
<li><a href="#videokonferenzen-streaming-einleitung">Videokonferenzen &amp; Streaming Einleitung</a></li>
</ul>
<h2 id="einleitung-ueberblick">Einleitung und &Uuml;berblick</h2>
<p>Wir haben diese Datenschutzerkl&auml;rung (Fassung 19.04.2022-121865447) verfasst, um Ihnen gem&auml;&szlig; der Vorgaben der <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=121865447#d1e2269-1-1">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erkl&auml;ren, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &ndash; und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &ndash; verarbeiten, zuk&uuml;nftig verarbeiten werden und welche rechtm&auml;&szlig;igen M&ouml;glichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br /><strong>Kurz gesagt:</strong> Wir informieren Sie umfassend &uuml;ber Daten, die wir &uuml;ber Sie verarbeiten.</p>
<p>Datenschutzerkl&auml;rungen klingen f&uuml;r gew&ouml;hnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerkl&auml;rung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie m&ouml;glich beschreiben. Soweit es der Transparenz f&ouml;rderlich ist, werden technische <strong>Begriffe leserfreundlich erkl&auml;rt</strong>, <strong>Links</strong> zu weiterf&uuml;hrenden Informationen geboten und <strong>Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Gesch&auml;ftst&auml;tigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht m&ouml;glich, wenn man m&ouml;glichst knappe, unklare und juristisch-technische Erkl&auml;rungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erl&auml;uterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br /> Wenn trotzdem Fragen bleiben, m&ouml;chten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverst&auml;ndlich auch im Impressum.</p>
<h2 id="anwendungsbereich">Anwendungsbereich</h2>
<p>Diese Datenschutzerkl&auml;rung gilt f&uuml;r alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und f&uuml;r alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt daf&uuml;r, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen k&ouml;nnen, sei es online oder offline. Der Anwendungsbereich dieser Datenschutzerkl&auml;rung umfasst:</p>
<ul>
<li>alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
<li>Social Media Auftritte und E-Mail-Kommunikation</li>
<li>mobile Apps f&uuml;r Smartphones und andere Ger&auml;te</li>
</ul>
<p><strong>Kurz gesagt:</strong> Die Datenschutzerkl&auml;rung gilt f&uuml;r alle Bereiche, in denen personenbezogene Daten im Unternehmen &uuml;ber die genannten Kan&auml;le strukturiert verarbeitet werden. Sollten wir au&szlig;erhalb dieser Kan&auml;le mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert informieren.</p>
<h2 id="rechtsgrundlagen">Rechtsgrundlagen</h2>
<p>In der folgenden Datenschutzerkl&auml;rung geben wir Ihnen transparente Informationen zu den rechtlichen Grunds&auml;tzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns erm&ouml;glichen, personenbezogene Daten zu verarbeiten.<br /> Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROP&Auml;ISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU k&ouml;nnen Sie selbstverst&auml;ndlich online auf EUR-Lex, dem Zugang zum EU-Recht, unter <a href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=121865447">https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679</a> nachlesen.</p>
<p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
<ol>
<li><strong>Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel w&auml;re die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.</li>
<li><strong>Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erf&uuml;llen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschlie&szlig;en, ben&ouml;tigen wir vorab personenbezogene Informationen.</li>
<li><strong>Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen f&uuml;r die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.</li>
<li><strong>Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschr&auml;nken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir m&uuml;ssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu k&ouml;nnen. Diese Verarbeitung ist somit ein berechtigtes Interesse.</li>
</ol>
<p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im &ouml;ffentlichen Interesse und Aus&uuml;bung &ouml;ffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschl&auml;gig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
<p>Zus&auml;tzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
<ul>
<li>In <strong>&Ouml;sterreich</strong> ist dies das Bundesgesetz zum Schutz nat&uuml;rlicher Personen bei der Verarbeitung personenbezogener Daten (<strong>Datenschutzgesetz</strong>), kurz <strong>DSG</strong>.</li>
<li>In <strong>Deutschland</strong> gilt das <strong>Bundesdatenschutzgesetz</strong>, kurz<strong> BDSG</strong>.</li>
</ul>
<p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden Abschnitten dar&uuml;ber.</p>
<h2 id="kontaktdaten-verantwortliche">Kontaktdaten des Verantwortlichen</h2>
<p>Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br /> PROPSTER - Sonderwunsch Meister GmbH<br /> Mariahilferstra&szlig;e 37-39, DG<br /> 1060 Wien<br /> &Ouml;sterreich <br /> Vertretungsberechtigt: Milan Zahradnik <br /> E-Mail: <a href="mailto:office@propster.tech">office@propster.tech</a><br /> Telefon: <a href="tel:+4313610101">+4313610101</a><br /> Impressum: <a href="https://www.propster.tech/impressum/">https://www.propster.tech/impressum/</a></p>
<h2 id="speicherdauer">Speicherdauer</h2>
<p>Dass wir personenbezogene Daten nur so lange speichern, wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten l&ouml;schen, sobald der Grund f&uuml;r die Datenverarbeitung nicht mehr vorhanden ist. In einigen F&auml;llen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des urspr&uuml;ngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchf&uuml;hrung.</p>
<p>Sollten Sie die L&ouml;schung Ihrer Daten w&uuml;nschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so rasch wie m&ouml;glich und soweit keine Pflicht zur Speicherung besteht, gel&ouml;scht.</p>
<p>&Uuml;ber die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben.</p>
<h2 id="rechte-dsgvo">Rechte laut Datenschutz-Grundverordnung</h2>
<p>Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</p>
<ul>
<li>Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht dar&uuml;ber, ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:
<ul>
<li>zu welchem Zweck wir die Verarbeitung durchf&uuml;hren;</li>
<li>die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
<li>wer diese Daten erh&auml;lt und wenn die Daten an Drittl&auml;nder &uuml;bermittelt werden, wie die Sicherheit garantiert werden kann;</li>
<li>wie lange die Daten gespeichert werden;</li>
<li>das Bestehen des Rechts auf Berichtigung, L&ouml;schung oder Einschr&auml;nkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
<li>dass Sie sich bei einer Aufsichtsbeh&ouml;rde beschweren k&ouml;nnen (Links zu diesen Beh&ouml;rden finden Sie weiter unten);</li>
<li>die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
<li>ob Profiling durchgef&uuml;hrt wird, ob also Daten automatisch ausgewertet werden, um zu einem pers&ouml;nlichen Profil von Ihnen zu gelangen.</li>
</ul>
</li>
<li>Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen m&uuml;ssen, falls Sie Fehler finden.</li>
<li>Sie haben laut Artikel 17 DSGVO das Recht auf L&ouml;schung (&bdquo;Recht auf Vergessenwerden&ldquo;), was konkret bedeutet, dass Sie die L&ouml;schung Ihrer Daten verlangen d&uuml;rfen.</li>
<li>Sie haben laut Artikel 18 DSGVO das Recht auf Einschr&auml;nkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern d&uuml;rfen aber nicht weiter verwenden.</li>
<li>Sie haben laut Artikel 19 DSGVO das Recht auf Daten&uuml;bertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem g&auml;ngigen Format zur Verf&uuml;gung stellen.</li>
<li>Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine &Auml;nderung der Verarbeitung mit sich bringt.
<ul>
<li>Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (&ouml;ffentliches Interesse, Aus&uuml;bung &ouml;ffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, k&ouml;nnen Sie gegen die Verarbeitung Widerspruch einlegen. Wir pr&uuml;fen danach so rasch wie m&ouml;glich, ob wir diesem Widerspruch rechtlich nachkommen k&ouml;nnen.</li>
<li>Werden Daten verwendet, um Direktwerbung zu betreiben, k&ouml;nnen Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir d&uuml;rfen Ihre Daten danach nicht mehr f&uuml;r Direktmarketing verwenden.</li>
<li>Werden Daten verwendet, um Profiling zu betreiben, k&ouml;nnen Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir d&uuml;rfen Ihre Daten danach nicht mehr f&uuml;r Profiling verwenden.</li>
</ul>
</li>
<li>Sie haben laut Artikel 22 DSGVO unter Umst&auml;nden das Recht, nicht einer ausschlie&szlig;lich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.</li>
</ul>
<p><strong>Kurz gesagt:</strong> Sie haben Rechte &ndash; z&ouml;gern Sie nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
<p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verst&ouml;&szlig;t oder Ihre datenschutzrechtlichen Anspr&uuml;che in sonst einer Weise verletzt worden sind, k&ouml;nnen Sie sich bei der Aufsichtsbeh&ouml;rde beschweren. Diese ist f&uuml;r &Ouml;sterreich die Datenschutzbeh&ouml;rde, deren Website Sie unter <a href="https://www.dsb.gv.at/?tid=121865447">https://www.dsb.gv.at/</a> finden. In Deutschland gibt es f&uuml;r jedes Bundesland einen Datenschutzbeauftragten. F&uuml;r n&auml;here Informationen k&ouml;nnen Sie sich an die <a href="https://www.bfdi.bund.de/DE/Home/home_node.html">Bundesbeauftragte f&uuml;r den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. F&uuml;r unser Unternehmen ist die folgende lokale Datenschutzbeh&ouml;rde zust&auml;ndig:</p>
<h2>&Ouml;sterreich Datenschutzbeh&ouml;rde</h2>
<p><strong>Leiterin: </strong>Mag. Dr. Andrea Jelinek <strong><br /><strong>Adresse: </strong></strong>Barichgasse 40-42, 1030 Wien <strong><br /><strong>Telefonnr.: </strong></strong>+43 1 52&nbsp;152-0 <strong><br /><strong>E-Mail-Adresse: </strong></strong><a href="mailto:dsb@dsb.gv.at">dsb@dsb.gv.at</a><strong><br /><strong>Website: </strong></strong><a href="https://www.dsb.gv.at/">https://www.dsb.gv.at/</a></p>
<h2 id="datenuebertragung-drittlaender">Daten&uuml;bertragung in Drittl&auml;nder</h2>
<p>Wir &uuml;bertragen oder verarbeiten Daten nur dann in L&auml;nder au&szlig;erhalb der EU (Drittl&auml;nder), wenn Sie dieser Verarbeitung zustimmen, dies gesetzlich vorgeschrieben ist oder vertraglich notwendig und in jedem Fall nur soweit dies generell erlaubt ist. Ihre Zustimmung ist in den meisten F&auml;llen der wichtigste Grund, dass wir Daten in Drittl&auml;ndern verarbeiten lassen. Die Verarbeitung personenbezogener Daten in Drittl&auml;ndern wie den USA, wo viele Softwarehersteller Dienstleistungen anbieten und Ihre Serverstandorte haben, kann bedeuten, dass personenbezogene Daten auf unerwartete Weise verarbeitet und gespeichert werden.</p>
<p>Wir weisen ausdr&uuml;cklich darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Die Datenverarbeitung durch US-Dienste (wie beispielsweise Google Analytics) kann dazu f&uuml;hren, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden. Ferner k&ouml;nnen gegebenenfalls US-amerikanische staatliche Beh&ouml;rden Zugriff auf einzelne Daten nehmen. Zudem kann es vorkommen, dass erhobene Daten mit Daten aus anderen Diensten desselben Anbieters, sofern Sie ein entsprechendes Nutzerkonto haben, verkn&uuml;pft werden. Nach M&ouml;glichkeit versuchen wir Serverstandorte innerhalb der EU zu nutzen, sofern das angeboten wird.</p>
<p>Wir informieren Sie an den passenden Stellen dieser Datenschutzerkl&auml;rung genauer &uuml;ber Daten&uuml;bertragung in Drittl&auml;nder, sofern diese zutrifft.</p>
<h2 id="sicherheit-datenverarbeitung">Sicherheit der Datenverarbeitung</h2>
<p>Um personenbezogene Daten zu sch&uuml;tzen, haben wir sowohl technische als auch organisatorische Ma&szlig;nahmen umgesetzt. Wo es uns m&ouml;glich ist, verschl&uuml;sseln oder pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im Rahmen unserer M&ouml;glichkeiten so schwer wie m&ouml;glich, dass Dritte aus unseren Daten auf pers&ouml;nliche Informationen schlie&szlig;en k&ouml;nnen.</p>
<p>Art. 25 DSGVO spricht hier von &ldquo;Datenschutz durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen&rdquo; und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch Hardware (z. B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Ma&szlig;nahmen setzt. Im Folgenden gehen wir, falls erforderlich, noch auf konkrete Ma&szlig;nahmen ein.</p>
<h2>TLS-Verschl&uuml;sselung mit https</h2>
<p>TLS, Verschl&uuml;sselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht f&uuml;r &bdquo;sicheres Hypertext-&Uuml;bertragungsprotokoll&ldquo;), um Daten abh&ouml;rsicher im Internet zu &uuml;bertragen.<br /> Das bedeutet, dass die komplette &Uuml;bertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist &ndash; niemand kann &ldquo;mith&ouml;ren&rdquo;.</p>
<p>Damit haben wir eine zus&auml;tzliche Sicherheitsschicht eingef&uuml;hrt und erf&uuml;llen Datenschutz durch Technikgestaltung <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=121865447">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschl&uuml;sselungsprotokoll zur sicheren Daten&uuml;bertragung im Internet, k&ouml;nnen wir den Schutz vertraulicher Daten sicherstellen.<br /> Sie erkennen die Benutzung dieser Absicherung der Daten&uuml;bertragung am kleinen Schlosssymbol links oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.<br /> Wenn Sie mehr zum Thema Verschl&uuml;sselung wissen m&ouml;chten, empfehlen wir die Google Suche nach &ldquo;Hypertext Transfer Protocol Secure wiki&rdquo; um gute Links zu weiterf&uuml;hrenden Informationen zu erhalten.</p>
<h2 id="kommunikation">Kommunikation</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Kommunikation Zusammenfassung</strong><br /> 👥 Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren<br /> 📓 Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart<br /> 🤝 Zweck: Abwicklung der Kommunikation mit Kunden, Gesch&auml;ftspartnern usw.<br /> 📅 Speicherdauer: Dauer des Gesch&auml;ftsfalls und der gesetzlichen Vorschriften<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
</td>
</tr>
</tbody>
</table>
<p>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.</p>
<p>Die Daten werden f&uuml;r die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenh&auml;ngenden Gesch&auml;ftsvorgangs verarbeitet. Die Daten w&auml;hrend eben solange gespeichert bzw. solange es das Gesetz vorschreibt.</p>
<h3>Betroffene Personen</h3>
<p>Von den genannten Vorg&auml;ngen sind alle betroffen, die &uuml;ber die von uns bereit gestellten Kommunikationswege den Kontakt zu uns suchen.</p>
<h3>Telefon</h3>
<p>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endger&auml;t und beim eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Au&szlig;erdem k&ouml;nnen Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gel&ouml;scht, sobald der Gesch&auml;ftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<h3>E-Mail</h3>
<p>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endger&auml;t (Computer, Laptop, Smartphone,&hellip;) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gel&ouml;scht, sobald der Gesch&auml;ftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<h3>Online Formulare</h3>
<p>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gel&ouml;scht, sobald der Gesch&auml;ftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
<h3>Rechtsgrundlagen</h3>
<p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
<ul>
<li>6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter f&uuml;r den Gesch&auml;ftsfall betreffende Zwecke zu verwenden;</li>
<li>6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit f&uuml;r die Erf&uuml;llung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir m&uuml;ssen die Daten f&uuml;r vorvertragliche T&auml;tigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;</li>
<li>6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und gesch&auml;ftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu k&ouml;nnen.</li>
</ul>
<h2 id="auftragsverarbeitungsvertrag-avv">Auftragsverarbeitungsvertrag (AVV)</h2>
<p>In diesem Abschnitt m&ouml;chten wir Ihnen erkl&auml;ren, was ein Auftragsverarbeitungsvertrag ist und warum dieser ben&ouml;tigt wird. Weil das Wort &ldquo;Auftragsverarbeitungsvertrag&rdquo; ein ziemlicher Zungenbrecher ist, werden wir hier im Text auch &ouml;fters nur das Akronym AVV benutzen. Wie die meisten Unternehmen arbeiten wir nicht alleine, sondern nehmen auch selbst Dienstleistungen anderer Unternehmen oder Einzelpersonen in Anspruch. Durch die Einbeziehung verschiedener Unternehmen bzw. Dienstleister kann es sein, dass wir personenbezogene Daten zur Verarbeitung weitergeben. Diese Partner fungieren dann als Auftragsverarbeiter, mit denen wir einen Vertrag, den sogenannten Auftragsverarbeitungsvertrag (AVV), abschlie&szlig;en. F&uuml;r Sie am wichtigsten zu wissen ist, dass die Verarbeitung Ihrer personenbezogenen Daten ausschlie&szlig;lich nach unserer Weisung erfolgt und durch den AVV geregelt werden muss.</p>
<h3>Wer sind Auftragsverarbeiter?</h3>
<p>Wir sind als Unternehmen und Websiteinhaber f&uuml;r alle Daten, die wir von Ihnen verarbeiten verantwortlich. Neben den Verantwortlichen kann es auch sogenannte Auftragsverarbeiter geben. Dazu z&auml;hlt jedes Unternehmen bzw. jede Person, die in unserem Auftrag personenbezogene Daten verarbeitet. Genauer und nach der DSGVO-Definition gesagt: jede nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder eine andere Stelle, die in unserem Auftrag personenbezogene Daten verarbeitet, gilt als Auftragsverarbeiter. Auftragsverarbeiter k&ouml;nnen folglich Dienstleister wie Hosting- oder Cloudanbieter, Bezahlungs- oder Newsletter-Anbieter oder gro&szlig;e Unternehmen wie beispielsweise Google oder Microsoft sein.</p>
<p>Zur besseren Verst&auml;ndlichkeit der Begrifflichkeiten hier ein &Uuml;berblick &uuml;ber die drei Rollen in der DSGVO:</p>
<p><strong>Betroffener</strong>&nbsp;(Sie als Kunde oder Interessent) &rarr; <strong>Verantwortlicher</strong> (wir als Unternehmen und Auftraggeber) &rarr; <strong>Auftragsverarbeiter</strong> (Dienstleister wie z. B. Webhoster oder Cloudanbieter)</p>
<h3>Inhalt eines Auftragsverarbeitungsvertrages</h3>
<p>Wie bereits oben erw&auml;hnt, haben wir mit unseren Partnern, die als Auftragsverarbeiter fungieren, einen AVV abgeschlossen. Darin wird allen voran festgehalten, dass der Auftragsverarbeiter die zu bearbeitenden Daten ausschlie&szlig;lich gem&auml;&szlig; der DSGVO verarbeitet. Der Vertrag muss schriftlich abgeschlossen werden, allerdings gilt in diesem Zusammenhang auch der elektronische Vertragsabschluss als &bdquo;schriftlich&ldquo;. Erst auf der Grundlage des Vertrags erfolgt die Verarbeitung der personenbezogenen Daten. Im Vertrag muss folgendes enthalten sein:</p>
<ul>
<li>Bindung an uns als Verantwortlichen</li>
<li>Pflichten und Rechte des Verantwortlichen</li>
<li>Kategorien betroffener Personen</li>
<li>Art der personenbezogenen Daten</li>
<li>Art und Zweck der Datenverarbeitung</li>
<li>Gegenstand und Dauer der Datenverarbeitung</li>
<li>Durchf&uuml;hrungsort der Datenverarbeitung</li>
</ul>
<p>Weiters enth&auml;lt der Vertrag alle Pflichten des Auftragsverarbeiters. Die wichtigsten Pflichten sind:</p>
<ul>
<li>Ma&szlig;nahmen zur Datensicherheit zu gew&auml;hrleisten</li>
<li>m&ouml;gliche technische und organisatorischen Ma&szlig;nahmen zu treffen, um die Rechte der betroffenen Person zu sch&uuml;tzen</li>
<li>ein Daten-Verarbeitungsverzeichnis zu f&uuml;hren</li>
<li>auf Anfrage der Datenschutz-Aufsichtsbeh&ouml;rde mit dieser zusammenzuarbeiten</li>
<li>eine Risikoanalyse in Bezug auf die erhaltenen personenbezogenen Daten durchzuf&uuml;hren</li>
<li>Sub-Auftragsverarbeiter d&uuml;rfen nur mit schriftlicher Genehmigung des Verantwortlichen beauftragt werden</li>
</ul>
<p>Wie so eine AVV konkret aussieht, k&ouml;nnen Sie sich beispielsweise unter <a href="https://www.wko.at/service/wirtschaftsrecht-gewerberecht/eu-dsgvo-mustervertrag-auftragsverarbeitung.html">https://www.wko.at/service/wirtschaftsrecht-gewerberecht/eu-dsgvo-mustervertrag-auftragsverarbeitung.html</a> ansehen. Hier wird ein Mustervertrag vorgestellt.</p>
<h2 id="cookies">Cookies</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Cookies Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: abh&auml;ngig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br /> 📓 Verarbeitete Daten: Abh&auml;ngig vom jeweils eingesetzten Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br /> 📅 Speicherdauer: abh&auml;ngig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren variieren<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was sind Cookies?</h3>
<p>Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.<br /> Im Folgenden erkl&auml;ren wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerkl&auml;rung besser verstehen.</p>
<p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Websites speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
<p>Eines ist nicht von der Hand zu weisen: Cookies sind echt n&uuml;tzliche Helferlein. Fast alle Websites verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies f&uuml;r andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &ldquo;Hirn&rdquo; Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies m&uuml;ssen zus&auml;tzlich ein oder mehrere Attribute angegeben werden.</p>
<p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder pers&ouml;nliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, &uuml;bermittelt Ihr Browser die &bdquo;userbezogenen&ldquo; Informationen an unsere Seite zur&uuml;ck. Dank der Cookies wei&szlig; unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.</p>
<p>Die folgende Grafik zeigt eine m&ouml;gliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem Webserver. Dabei fordert der Webbrowser eine Website an und erh&auml;lt vom Server ein Cookie zur&uuml;ck, welches der Browser erneut verwendet, sobald eine andere Seite angefordert wird.</p>
<p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere &bdquo;Sch&auml;dlinge&ldquo;. Cookies k&ouml;nnen auch nicht auf Informationen Ihres PCs zugreifen.</p>
<p>So k&ouml;nnen zum Beispiel Cookie-Daten aussehen:</p>
<p><strong>Name:</strong> _ga<br /><strong>Wert:</strong>&nbsp;GA1.2.1326744211.152121865447-9<br /><strong>Verwendungszweck:</strong> Unterscheidung der Websitebesucher<br /><strong>Ablaufdatum:</strong>&nbsp;nach 2 Jahren</p>
<p>Diese Mindestgr&ouml;&szlig;en sollte ein Browser unterst&uuml;tzen k&ouml;nnen:</p>
<ul>
<li>Mindestens 4096 Bytes pro Cookie</li>
<li>Mindestens 50 Cookies pro Domain</li>
<li>Mindestens 3000 Cookies insgesamt</li>
</ul>
<h3>Welche Arten von Cookies gibt es?</h3>
<p>Die Frage welche Cookies wir im Speziellen verwenden, h&auml;ngt von den verwendeten Diensten ab und wird in den folgenden Abschnitten der Datenschutzerkl&auml;rung gekl&auml;rt. An dieser Stelle m&ouml;chten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.</p>
<p>Man kann 4 Arten von Cookies unterscheiden:</p>
<p><strong>Unerl&auml;ssliche Cookies</strong><strong><br /></strong>Diese Cookies sind n&ouml;tig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und sp&auml;ter erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gel&ouml;scht, selbst wenn der User sein Browserfenster schlie&szlig;t.</p>
<p><strong>Zweckm&auml;&szlig;ige Cookies</strong><strong><br /></strong>Diese Cookies sammeln Infos &uuml;ber das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern gemessen.</p>
<p><strong>Zielorientierte Cookies</strong><strong><br /></strong>Diese Cookies sorgen f&uuml;r eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgr&ouml;&szlig;en oder Formulardaten gespeichert.</p>
<p><strong>Werbe-Cookies</strong><strong><br /></strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
<p>&Uuml;blicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Sie zulassen m&ouml;chten. Und nat&uuml;rlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
<p>Wenn Sie mehr &uuml;ber Cookies wissen m&ouml;chten und technische Dokumentationen nicht scheuen, empfehlen wir <a href="https://datatracker.ietf.org/doc/html/rfc6265">https://datatracker.ietf.org/doc/html/rfc6265</a>,&nbsp;dem Request for Comments der Internet Engineering Task Force (IETF) namens &ldquo;HTTP State Management Mechanism&rdquo;.</p>
<h3>Zweck der Verarbeitung &uuml;ber Cookies</h3>
<p>Der Zweck ist letztendlich abh&auml;ngig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, die das Cookie setzt.</p>
<h3>Welche Daten werden verarbeitet?</h3>
<p>Cookies sind kleine Gehilfen f&uuml;r eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert werden, kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen der folgenden Datenschutzerkl&auml;rung &uuml;ber die verarbeiteten bzw. gespeicherten Daten informieren.</p>
<h3>Speicherdauer von Cookies</h3>
<p>Die Speicherdauer h&auml;ngt vom jeweiligen Cookie ab und wird weiter unter pr&auml;zisiert. Manche Cookies werden nach weniger als einer Stunde gel&ouml;scht, andere k&ouml;nnen mehrere Jahre auf einem Computer gespeichert bleiben.</p>
<p>Sie haben au&szlig;erdem selbst Einfluss auf die Speicherdauer. Sie k&ouml;nnen &uuml;ber ihren Browser s&auml;mtliche Cookies jederzeit manuell l&ouml;schen (siehe auch unten &ldquo;Widerspruchsrecht&rdquo;). Ferner werden Cookies, die auf einer Einwilligung beruhen, sp&auml;testens nach Widerruf Ihrer Einwilligung gel&ouml;scht, wobei die Rechtm&auml;&szlig;igkeit der Speicherung bis dahin unber&uuml;hrt bleibt.</p>
<h3>Widerspruchsrecht &ndash; wie kann ich Cookies l&ouml;schen?</h3>
<p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabh&auml;ngig von welchem Service oder welcher Website die Cookies stammen, haben Sie immer die M&ouml;glichkeit Cookies zu l&ouml;schen, zu deaktivieren oder nur teilweise zuzulassen. Zum Beispiel k&ouml;nnen Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.</p>
<p>Wenn Sie feststellen m&ouml;chten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen &auml;ndern oder l&ouml;schen wollen, k&ouml;nnen Sie dies in Ihren Browser-Einstellungen finden:</p>
<p><a href="https://support.google.com/chrome/answer/95647?tid=121865447">Chrome: Cookies in Chrome l&ouml;schen, aktivieren und verwalten</a></p>
<p><a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=121865447">Safari: Verwalten von Cookies und Websitedaten mit Safari</a></p>
<p><a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=121865447">Firefox: Cookies l&ouml;schen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a></p>
<p><a href="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=121865447">Internet Explorer: L&ouml;schen und Verwalten von Cookies</a></p>
<p><a href="https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-l%C3%B6schen-63947406-40ac-c3b8-57b9-2a946a29ae09?tid=121865447">Microsoft Edge: L&ouml;schen und Verwalten von Cookies</a></p>
<p>Falls Sie grunds&auml;tzlich keine Cookies haben wollen, k&ouml;nnen Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So k&ouml;nnen Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in Google mit dem Suchbegriff &ldquo;Cookies l&ouml;schen Chrome&rdquo; oder &ldquo;Cookies deaktivieren Chrome&rdquo; im Falle eines Chrome Browsers.</p>
<h3>Rechtsgrundlage</h3>
<p>Seit 2009 gibt es die sogenannten &bdquo;Cookie-Richtlinien&ldquo;. Darin ist festgehalten, dass das Speichern von Cookies eine <strong>Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen verlangt. Innerhalb der EU-L&auml;nder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In &Ouml;sterreich erfolgte aber die Umsetzung dieser Richtlinie in &sect; 96 Abs. 3 des Telekommunikationsgesetzes (TKG). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in &sect; 15 Abs.3 des Telemediengesetzes (TMG).</p>
<p>F&uuml;r unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt. bestehen <strong>berechtigte Interessen</strong> (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten F&auml;llen wirtschaftlicher Natur sind. Wir m&ouml;chten den Besuchern der Website eine angenehme Benutzererfahrung bescheren und daf&uuml;r sind bestimmte Cookies oft unbedingt notwendig.</p>
<p>Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.</p>
<p>In den folgenden Abschnitten werden Sie genauer &uuml;ber den Einsatz von Cookies informiert, sofern eingesetzte Software Cookies verwendet.</p>
<h2 id="webhosting-einleitung">Webhosting Einleitung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Webhosting Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br /> 📓 Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.<br /> 📅 Speicherdauer: abh&auml;ngig vom jeweiligen Provider, aber in der Regel 2 Wochen<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was ist Webhosting?</h3>
<p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &ndash; auch personenbezogene Daten &ndash; automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten m&ouml;glichst sparsam und nur mit Begr&uuml;ndung verarbeitet werden. Mit Website meinen wir &uuml;brigens die Gesamtheit aller Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
<p>Wenn Sie eine Website auf einem Computer, Tablet oder Smartphone ansehen m&ouml;chten, verwenden Sie daf&uuml;r ein Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari. Wir sagen kurz Browser oder Webbrowser dazu.</p>
<p>Um die Website anzuzeigen, muss sich der Browser zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den Providern, &uuml;bernommen wird. Diese bieten Webhosting an und sorgen damit f&uuml;r eine verl&auml;ssliche und fehlerfreie Speicherung der Daten von Websites. Eine ganze Menge Fachbegriffe, aber bitte bleiben Sie dran, es wird noch besser!</p>
<p>Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Tablet oder Smartphone) und w&auml;hrend der Daten&uuml;bertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen. Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen ordentlichen Betrieb zu gew&auml;hrleisten.</p>
<p>Ein Bild sagt mehr als tausend Worte, daher zeigt folgende Grafik zur Veranschaulichung das Zusammenspiel zwischen Browser, dem Internet und dem Hosting-Provider.</p>
<h3>Warum verarbeiten wir personenbezogene Daten?</h3>
<p>Die Zwecke der Datenverarbeitung sind:</p>
<ol>
<li>Professionelles Hosting der Website und Absicherung des Betriebs</li>
<li>zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
<li>Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Anspr&uuml;chen</li>
</ol>
<h3>Welche Daten werden verarbeitet?</h3>
<p>Auch w&auml;hrend Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p>
<ul>
<li>die komplette Internetadresse (URL) der aufgerufenen Webseite</li>
<li>Browser und Browserversion (z. B. Chrome 87)</li>
<li>das verwendete Betriebssystem (z. B. Windows 10)</li>
<li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. <a href="https://www.beispielquellsite.de/vondabinichgekommen.html/">https://www.beispielquellsite.de/vondabinichgekommen.html/</a>)</li>
<li>den Hostnamen und die IP-Adresse des Ger&auml;ts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)</li>
<li>Datum und Uhrzeit</li>
<li>in Dateien, den sogenannten Webserver-Logfiles</li>
</ul>
<h3>Wie lange werden Daten gespeichert?</h3>
<p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gel&ouml;scht. Wir geben diese Daten nicht weiter, k&ouml;nnen jedoch nicht ausschlie&szlig;en, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Beh&ouml;rden eingesehen werden.</p>
<p><strong>Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen l&auml;sst), protokolliert, aber wir geben Ihre Daten nicht ohne Zustimmung weiter!</p>
<h3>Rechtsgrundlage</h3>
<p>Die Rechtm&auml;&szlig;igkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich pr&auml;sentieren und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu k&ouml;nnen.</p>
<p>Zwischen uns und dem Hosting-Provider besteht in der Regel ein Vertrag &uuml;ber die Auftragsverarbeitung gem&auml;&szlig; Art. 28 f. DSGVO, der die Einhaltung von Datenschutz gew&auml;hrleistet und Datensicherheit garantiert.</p>
<h2>Hetzner Datenschutzerkl&auml;rung</h2>
<p>Wir nutzen f&uuml;r unsere Website Hetzner, unter anderem ein Webhosting-Anbieter. Dienstanbieter ist das deutsche Unternehmen Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen, Deutschland. Mehr &uuml;ber die Daten, die durch die Verwendung von Hetzner verarbeitet werden, erfahren Sie in der Datenschutzerkl&auml;rung auf <a href="https://www.hetzner.com/de/legal/privacy-policy">https://www.hetzner.com/de/legal/privacy-policy</a>.</p>
<h2 id="website-baukastensysteme-einleitung">Website Baukastensysteme Einleitung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Website Baukastensysteme Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: Daten wie etwa technische Nutzungsinformationen wie Browseraktivit&auml;t, Clickstream-Aktivit&auml;ten, Sitzungs-Heatmaps sowie Kontaktdaten, IP-Adresse oder Ihr geografischer Standort. Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerkl&auml;rung und in der Datenschutzerkl&auml;rung der Anbieter.<br /> 📅 Speicherdauer: h&auml;ngt vom Anbieter ab<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen), Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was sind Website Baukastensysteme?</h3>
<p>Wir verwenden f&uuml;r unsere Website ein Website Baukastensystem. Baukastensysteme sind besondere Formen eines Content-Management-Systems (CMS). Mit einem Baukastensystem k&ouml;nnen Websitebetreiber sehr leicht und ohne Programmierkenntnisse eine Website erstellen. In vielen F&auml;llen bieten auch Webhoster Baukastensysteme an. Durch die Verwendung eines Baukastensystems k&ouml;nnen auch personenbezogene Daten von Ihnen erhoben, gespeichert und verarbeitet werden. In diesem Datenschutztext geben wir Ihnen allgemeine Informationen &uuml;ber die Datenverarbeitung durch Baukastensysteme. N&auml;here Informationen finden Sie in den Datenschutzerkl&auml;rungen des Anbieters.</p>
<h3>Warum verwenden wir Website Baukastensysteme f&uuml;r unsere Website?</h3>
<p>Der gr&ouml;&szlig;te Vorteil eines Baukastensystems ist die einfache Bedienbarkeit. Wir wollen Ihnen eine klare, einfache und &uuml;bersichtliche Website bieten, die wir selbst &ndash; ohne externe Unterst&uuml;tzung &ndash; problemlos bedienen und warten k&ouml;nnen. Ein Baukastensystem bietet mittlerweile viele hilfreiche Funktionen, die wir auch ohne Programmierkenntnisse anwenden k&ouml;nnen. Dadurch k&ouml;nnen wir unsere Webpr&auml;senz nach unseren W&uuml;nschen gestalten und Ihnen eine informative und angenehme Zeit auf unserer Website bieten.</p>
<h3>Welche Daten werden von einem Baukastensystem gespeichert?</h3>
<p>Welche Daten genau gespeichert werden h&auml;ngt nat&uuml;rlich vom verwendeten Website-Baukastensystem ab. Jeder Anbieter verarbeitet und erhebt unterschiedliche Daten des Websitebesuchers. Doch in der Regel werden technische Nutzungsinformationen wie etwa etwa Betriebssystem, Browser, Bildschirmaufl&ouml;sung, Sprach- und Tastatureinstellungen, Hostinganbieter und das Datum Ihres Websitebesuches erhoben. Weiter k&ouml;nnen auch Trackingdaten (z.B. Browseraktivit&auml;t, Clickstreamaktivit&auml;ten, Sitzungs-Heatmaps u.a.) verarbeitet werden. Zudem k&ouml;nnen auch personenbezogene Daten erfasst und gespeichert werden. Dabei handelt es sich meistens um Kontaktdaten wie E-Mail-Adresse, Telefonnummer (falls Sie diese angegeben haben), IP-Adresse und geografischen Standortdaten. Welche Daten genau gespeichert werden, finden Sie in der Datenschutzerkl&auml;rung des Anbieters.</p>
<h3>Wie lange und wo werden die Daten gespeichert?</h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir Sie weiter unten im Zusammenhang mit dem verwendeten Website Baukastensystem, sofern wir weitere Informationen dazu haben. In den Datenschutzerkl&auml;rung des Anbieters finden Sie detaillierte Informationen dar&uuml;ber. Generell verarbeiten wir personenbezogene Daten nur so lange wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Es kann sein, dass der Anbieter nach eigenen Ma&szlig;gaben Daten von Ihnen speichert, worauf wir keinen Einfluss haben.</p>
<h3>Widerspruchsrecht</h3>
<p>Sie haben immer das Recht auf Auskunft, Berichtigung und L&ouml;schung Ihrer personenbezogenen Daten. Bei Fragen k&ouml;nnen Sie auch jederzeit Verantwortliche des verwendeten Website Baukastensystems kontaktieren. Kontaktdaten finden Sie entweder in unserer Datenschutzerkl&auml;rung oder auf der Website des entsprechenden Anbieters.</p>
<p>Cookies, die Anbieter f&uuml;r ihre Funktionen verwenden, k&ouml;nnen Sie in Ihrem Browser l&ouml;schen, deaktivieren oder verwalten. Je nachdem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise. Bitte beachten Sie aber, dass dann eventuell nicht mehr alle Funktionen wie gewohnt funktionieren.</p>
<h3>Rechtsgrundlage</h3>
<p>Wir haben ein berechtigtes Interesse daran, ein Website Baukastensystem zu verwenden, um unseren Online-Service zu optimieren und f&uuml;r Sie effizient und nutzeransprechend darzustellen. Die daf&uuml;r entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen den Baukasten gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<p>Soweit die Verarbeitung von Daten f&uuml;r den Betrieb der Webseite nicht unbedingt notwendig ist, werden die Daten nur auf Grundlage Ihrer Einwilligung verarbeitet. Dies betrifft insbesondere Aktivit&auml;ten des Trackings. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.</p>
<p>Mit dieser Datenschutzerkl&auml;rung haben wir Ihnen die wichtigsten allgemeinen Informationen rund um die Datenverarbeitung n&auml;hergebracht. Wenn Sie sich diesbez&uuml;glich noch genauer informieren wollen, finden Sie weitere Informationen &ndash; sofern vorhanden &ndash; in dem folgenden Abschnitt bzw. in der Datenschutzerkl&auml;rung des Anbieters.</p>
<h2>Wordpress.com Datenschutzerkl&auml;rung</h2>
<p>Wir nutzen f&uuml;r unsere Website WordPress.com, ein Website Baukastensystem. Dienstanbieter ist das amerikanische Unternehmen Automattic Inc., 60 29th Street #343, San Francisco, CA 94110, USA.</p>
<p>WordPress verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;&szlig;igkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (au&szlig;erhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet WordPress sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich WordPress, bei der Verarbeitung Ihrer relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Datenverarbeitungsbedingungen (Data Processing Agreements), welche den Standardvertragsklauseln entsprechen, finden Sie unter <a href="https://wordpress.com/support/data-processing-agreements/">https://wordpress.com/support/data-processing-agreements/</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von WordPress.com verarbeitet werden, erfahren Sie in der Datenschutzerkl&auml;rung auf <a href="https://automattic.com/de/privacy/">https://automattic.com/de/privacy/</a>.</p>
<h2>Auftragsverarbeitungsvertrag (AVV) Wordpress.com</h2>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit WordPress.com einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, k&ouml;nnen Sie in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil WordPress.com in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass WordPress.com Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) finden Sie unter <a href="https://wordpress.com/support/data-processing-agreements/">https://wordpress.com/support/data-processing-agreements/</a>.</p>
<h2 id="web-analytics-einleitung">Web Analytics Einleitung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Web Analytics Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br /> 📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Mehr Details dazu finden Sie beim jeweils eingesetzten Web Analytics Tool.<br /> 📅 Speicherdauer: abh&auml;ngig vom eingesetzten Web-Analytics-Tool<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was ist Web Analytics?</h3>
<p>Wir verwenden auf unserer Website Software zur Auswertung des Verhaltens der Website-Besucher, kurz Web Analytics oder Web-Analyse genannt. Dabei werden Daten gesammelt, die der jeweilige Analytic-Tool-Anbieter (auch Trackingtool genannt) speichert, verwaltet und verarbeitet. Mit Hilfe der Daten werden Analysen &uuml;ber das Nutzerverhalten auf unserer Website erstellt und uns als Websitebetreiber zur Verf&uuml;gung gestellt. Zus&auml;tzlich bieten die meisten Tools verschiedene Testm&ouml;glichkeiten an. So k&ouml;nnen wir etwa testen, welche Angebote oder Inhalte bei unseren Besuchern am besten ankommen. Daf&uuml;r zeigen wir Ihnen f&uuml;r einen begrenzten Zeitabschnitt zwei verschiedene Angebote. Nach dem Test (sogenannter A/B-Test) wissen wir, welches Produkt bzw. welcher Inhalt unsere Websitebesucher interessanter finden. F&uuml;r solche Testverfahren, wie auch f&uuml;r andere Analytics-Verfahren, k&ouml;nnen auch Userprofile erstellt werden und die Daten in Cookies gespeichert werden.</p>
<h3>Warum betreiben wir Web Analytics?</h3>
<p>Mit unserer Website haben wir ein klares Ziel vor Augen: wir wollen f&uuml;r unsere Branche das besten Webangebot auf dem Markt liefern. Um dieses Ziel zu erreichen, wollen wir einerseits das beste und interessanteste Angebot bieten und andererseits darauf achten, dass Sie sich auf unserer Website rundum wohlf&uuml;hlen. Mit Hilfe von Webanalyse-Tools k&ouml;nnen wir das Verhalten unserer Websitebesucher genauer unter die Lupe nehmen und dann entsprechend unser Webangebot f&uuml;r Sie und uns verbessern. So k&ouml;nnen wir beispielsweise erkennen wie alt unsere Besucher durchschnittlich sind, woher sie kommen, wann unsere Website am meisten besucht wird oder welche Inhalte oder Produkte besonders beliebt sind. All diese Informationen helfen uns die Website zu optimieren und somit bestens an Ihre Bed&uuml;rfnisse, Interessen und W&uuml;nsche anzupassen.</p>
<h3>Welche Daten werden verarbeitet?</h3>
<p>Welche Daten genau gespeichert werden, h&auml;ngt nat&uuml;rlich von den verwendeten Analyse-Tools ab. Doch in der Regel wird zum Beispiel gespeichert, welche Inhalte Sie auf unserer Website ansehen, auf welche Buttons oder Links Sie klicken, wann Sie eine Seite aufrufen, welchen Browser sie verwenden, mit welchem Ger&auml;t (PC, Tablet, Smartphone usw.) Sie die Website besuchen oder welches Computersystem Sie verwenden. Wenn Sie damit einverstanden waren, dass auch Standortdaten erhoben werden d&uuml;rfen, k&ouml;nnen auch diese durch den Webanalyse-Tool-Anbieter verarbeitet werden.</p>
<p>Zudem wird auch Ihre IP-Adresse gespeichert. Gem&auml;&szlig; der Datenschutz-Grundverordnung (DSGVO) sind IP-Adressen personenbezogene Daten. Ihre IP-Adresse wird allerdings in der Regel pseudonymisiert (also in unkenntlicher und gek&uuml;rzter Form) gespeichert. F&uuml;r den Zweck der Tests, der Webanalyse und der Weboptimierung werden grunds&auml;tzlich keine direkten Daten, wie etwa Ihr Name, Ihr Alter, Ihre Adresse oder Ihre E-Mail-Adresse gespeichert. All diese Daten werden, sofern sie erhoben werden, pseudonymisiert gespeichert. So k&ouml;nnen Sie als Person nicht identifiziert werden.</p>
<p>Das folgende Beispiel zeigt schematisch die Funktionsweise von Google Analytics als Beispiel f&uuml;r client-basiertes Webtracking mit Java-Script-Code.</p>
<p>Wie lange die jeweiligen Daten gespeichert werden, h&auml;ngt immer vom Anbieter ab. Manche Cookies speichern Daten nur f&uuml;r ein paar Minuten bzw. bis Sie die Website wieder verlassen, andere Cookies k&ouml;nnen Daten &uuml;ber mehrere Jahre speichern.</p>
<h3>Dauer der Datenverarbeitung</h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch &uuml;berschritten werden.</p>
<h3>Widerspruchsrecht</h3>
<p>Sie haben auch jederzeit das Recht und die M&ouml;glichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Beispiel k&ouml;nnen Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder l&ouml;schen.</p>
<h3>Rechtsgrundlage</h3>
<p>Der Einsatz von Web-Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
<p>Zus&auml;tzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, das Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von Web-Analytics erkennen wir Fehler der Website, k&ouml;nnen Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage daf&uuml;r ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen die Tools gleichwohl nur ein, soweit sie eine Einwilligung erteilt haben.</p>
<p>Da bei Web-Analytics-Tools Cookies zum Einsatz kommen, empfehlen wir Ihnen auch das Lesen unserer allgemeinen Datenschutzerkl&auml;rung zu Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
<p>Informationen zu speziellen Web-Analytics-Tools, erhalten Sie &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<h2>Google Analytics Datenschutzerkl&auml;rung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Google Analytics Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br /> 📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: abh&auml;ngig von den verwendeten Properties<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was ist Google Analytics?</h3>
<p>Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics (GA) des amerikanischen Unternehmens Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich. Google Analytics sammelt Daten &uuml;ber Ihre Handlungen auf unserer Website. Wenn Sie beispielsweise einen Link anklicken, wird diese Aktion in einem Cookie gespeichert und an Google Analytics versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten, k&ouml;nnen wir unsere Website und unser Service besser an Ihre W&uuml;nsche anpassen. Im Folgenden gehen wir n&auml;her auf das Tracking-Tool ein und informieren Sie vor allem dar&uuml;ber, welche Daten gespeichert werden und wie Sie das verhindern k&ouml;nnen.</p>
<p>Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Damit Google Analytics funktioniert, wird ein Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere Website besuchen, zeichnet dieser Code verschiedene Handlungen auf, die Sie auf unserer Website ausf&uuml;hren. Sobald Sie unsere Website verlassen, werden diese Daten an die Google-Analytics-Server gesendet und dort gespeichert.</p>
<p>Google verarbeitet die Daten und wir bekommen Berichte &uuml;ber Ihr Userverhalten. Dabei kann es sich unter anderem um folgende Berichte handeln:</p>
<ul>
<li>Zielgruppenberichte: &Uuml;ber Zielgruppenberichte lernen wir unsere User besser kennen und wissen genauer, wer sich f&uuml;r unser Service interessiert.</li>
<li>Anzeigeberichte: Durch Anzeigeberichte k&ouml;nnen wir unsere Onlinewerbung leichter analysieren und verbessern.</li>
<li>Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen dar&uuml;ber, wie wir mehr Menschen f&uuml;r unser Service begeistern k&ouml;nnen.</li>
<li>Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren. Wir k&ouml;nnen nachvollziehen welchen Weg Sie auf unserer Seite zur&uuml;cklegen und welche Links Sie anklicken.</li>
<li>Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund einer Marketing-Botschaft eine gew&uuml;nschte Handlung ausf&uuml;hren. Zum Beispiel, wenn Sie von einem reinen Websitebesucher zu einem K&auml;ufer oder Newsletter-Abonnent werden. Mithilfe dieser Berichte erfahren wir mehr dar&uuml;ber, wie unsere Marketing-Ma&szlig;nahmen bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.</li>
<li>Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website passiert. Zum Beispiel sehen wir wie viele User gerade diesen Text lesen.</li>
</ul>
<h3>Warum verwenden wir Google Analytics auf unserer Website?</h3>
<p>Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das bestm&ouml;gliche Service bieten. Die Statistiken und Daten von Google Analytics helfen uns dieses Ziel zu erreichen.</p>
<p>Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den St&auml;rken und Schw&auml;chen unserer Website. Einerseits k&ouml;nnen wir unsere Seite so optimieren, dass sie von interessierten Menschen auf Google leichter gefunden wird. Andererseits helfen uns die Daten, Sie als Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an unserer Website verbessern m&uuml;ssen, um Ihnen das bestm&ouml;gliche Service zu bieten. Die Daten dienen uns auch, unsere Werbe- und Marketing-Ma&szlig;nahmen individueller und kosteng&uuml;nstiger durchzuf&uuml;hren. Schlie&szlig;lich macht es nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die sich daf&uuml;r interessieren.</p>
<h3>Welche Daten werden von Google Analytics gespeichert?</h3>
<p>Google Analytics erstellt mithilfe eines Tracking-Codes eine zuf&auml;llige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie das n&auml;chste Mal unsere Seite besuchen, werden Sie als &bdquo;wiederkehrender&ldquo; User erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So ist es &uuml;berhaupt erst m&ouml;glich pseudonyme Userprofile auszuwerten.</p>
<p>Um mit Google Analytics unsere Website analysieren zu k&ouml;nnen, muss eine Property-ID in den Tracking-Code eingef&uuml;gt werden. Die Daten werden dann in der entsprechenden Property gespeichert. F&uuml;r jede neu angelegte Property ist die Google Analytics 4-Property standardm&auml;&szlig;ig. Alternativ kann man aber auch noch die Universal Analytics Property erstellen. Je nach verwendeter Property werden Daten unterschiedlich lange gespeichert.</p>
<p>Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre Interaktionen auf unserer Website gemessen. Interaktionen sind alle Arten von Handlungen, die Sie auf unserer Website ausf&uuml;hren. Wenn Sie auch andere Google-Systeme (wie z.B. ein Google-Konto) n&uuml;tzen, k&ouml;nnen &uuml;ber Google Analytics generierte Daten mit Drittanbieter-Cookies verkn&uuml;pft werden. Google gibt keine Google Analytics-Daten weiter, au&szlig;er wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen, wenn es gesetzlich erforderlich ist.</p>
<p>Folgende Cookies werden von Google Analytics verwendet:</p>
<p><strong>Name:</strong> _ga<br /><strong>Wert: </strong>2.1326744211.152121865447-5<br /><strong>Verwendungszweck:</strong> Standardm&auml;&szlig;ig verwendet analytics.js das Cookie _ga, um die User-ID zu speichern. Grunds&auml;tzlich dient es zur Unterscheidung der Webseitenbesucher.<br /><strong>Ablaufdatum:</strong> nach 2 Jahren</p>
<p><strong>Name:</strong> _gid<br /><strong>Wert: </strong>2.1687193234.152121865447-1<br /><strong>Verwendungszweck:</strong>&nbsp;Das Cookie dient auch zur Unterscheidung der Webseitenbesucher<br /><strong>Ablaufdatum:</strong>&nbsp;nach 24 Stunden</p>
<p><strong>Name:</strong> _gat_gtag_UA_&lt;property-id&gt;<br /><strong>Wert:</strong> 1<br /><strong>Verwendungszweck:</strong> Wird zum Senken der Anforderungsrate verwendet. Wenn Google Analytics &uuml;ber den Google Tag Manager bereitgestellt wird, erh&auml;lt dieser Cookie den Namen _dc_gtm_ &lt;property-id&gt;.<br /><strong>Ablaufdatum: </strong>nach 1 Minute</p>
<p><strong>Name:</strong> AMP_TOKEN<br /><strong>Wert:</strong> keine Angaben<br /><strong>Verwendungszweck:</strong> Das Cookie hat einen Token, mit dem eine User ID vom AMP-Client-ID-Dienst abgerufen werden kann. Andere m&ouml;gliche Werte weisen auf eine Abmeldung, eine Anfrage oder einen Fehler hin.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 30 Sekunden bis zu einem Jahr</p>
<p><strong>Name:</strong> __utma<br /><strong>Wert: </strong>1564498958.1564498958.1564498958.1<br /><strong>Verwendungszweck:</strong> Mit diesem Cookie kann man Ihr Verhalten auf der Website verfolgen und die Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br /><strong>Ablaufdatum:</strong> nach 2 Jahren</p>
<p><strong>Name:</strong> __utmt<br /><strong>Wert:</strong> 1<br /><strong>Verwendungszweck:</strong> Das Cookie wird wie _gat_gtag_UA_&lt;property-id&gt; zum Drosseln der Anforderungsrate verwendet.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 10 Minuten</p>
<p><strong>Name:</strong> __utmb<br /><strong>Wert: </strong>3.10.1564498958<br /><strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen zu bestimmen. Es wird jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics gesendet werden.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 30 Minuten</p>
<p><strong>Name:</strong> __utmc<br /><strong>Wert:</strong> 167421564<br /><strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um neue Sitzungen f&uuml;r wiederkehrende Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und wird nur solange gespeichert, bis Sie den Browser wieder schlie&szlig;en.<br /><strong>Ablaufdatum:</strong> Nach Schlie&szlig;ung des Browsers</p>
<p><strong>Name:</strong> __utmz<br /><strong>Wert:</strong> m|utmccn=(referral)|utmcmd=referral|utmcct=/<br /><strong>Verwendungszweck:</strong> Das Cookie wird verwendet, um die Quelle des Besucheraufkommens auf unserer Website zu identifizieren. Das hei&szlig;t, das Cookie speichert, von wo Sie auf unsere Website gekommen sind. Das kann eine andere Seite bzw. eine Werbeschaltung gewesen sein.<br /><strong>Ablaufdatum:</strong> nach 6 Monaten</p>
<p><strong>Name:</strong> __utmv<br /><strong>Wert:</strong> keine Angabe<br /><strong>Verwendungszweck:</strong> Das Cookie wird verwendet, um benutzerdefinierte Userdaten zu speichern. Es wird immer aktualisiert, wenn Informationen an Google Analytics gesendet werden.<br /><strong>Ablaufdatum:</strong> nach 2 Jahren</p>
<p><strong>Anmerkung:</strong>&nbsp;Diese Aufz&auml;hlung kann keinen Anspruch auf Vollst&auml;ndigkeit erheben, da Google die Wahl ihrer Cookies immer wieder auch ver&auml;ndert.</p>
<p>Hier zeigen wir Ihnen einen &Uuml;berblick &uuml;ber die wichtigsten Daten, die mit Google Analytics erhoben werden:</p>
<p><strong>Heatmaps:</strong> Google legt sogenannte Heatmaps an. &Uuml;ber Heatmaps sieht man genau jene Bereiche, die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer Seite &bdquo;unterwegs&ldquo; sind.</p>
<p><strong>Sitzungsdauer:</strong> Als Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung automatisch.</p>
<p><strong>Absprungrate</strong> (engl. Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer Website nur eine Seite ansehen und dann unsere Website wieder verlassen.</p>
<p><strong>Kontoerstellung:</strong> Wenn Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung machen, erhebt Google Analytics diese Daten.</p>
<p><strong>IP-Adresse:</strong> Die IP-Adresse wird nur in gek&uuml;rzter Form dargestellt, damit keine eindeutige Zuordnung m&ouml;glich ist.</p>
<p><strong>Standort:</strong> &Uuml;ber die IP-Adresse kann das Land und Ihr ungef&auml;hrer Standort bestimmt werden. Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.</p>
<p><strong>Technische Informationen:</strong> Zu den technischen Informationen z&auml;hlen unter anderem Ihr Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmaufl&ouml;sung.</p>
<p><strong>Herkunftsquelle:</strong> Google Analytics beziehungsweise uns interessiert nat&uuml;rlich auch &uuml;ber welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.</p>
<p>Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z.B., wenn Sie ein Video &uuml;ber unsere Seite abspielen), das Teilen von Inhalten &uuml;ber Social Media oder das Hinzuf&uuml;gen zu Ihren Favoriten. Die Aufz&auml;hlung hat keinen Vollst&auml;ndigkeitsanspruch und dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch Google Analytics.</p>
<h3>Wie lange und wo werden die Daten gespeichert?</h3>
<p>Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten Server befinden sich in Amerika und folglich werden Ihre Daten meist auf amerikanischen Servern gespeichert. Hier k&ouml;nnen Sie genau nachlesen wo sich die Google-Rechenzentren befinden: <a href="https://www.google.com/about/datacenters/locations/?hl=de">https://www.google.com/about/datacenters/inside/locations/?hl=de</a></p>
<p>Ihre Daten werden auf verschiedenen physischen Datentr&auml;gern verteilt. Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor Manipulation besser gesch&uuml;tzt sind. In jedem Google-Rechenzentrum gibt es entsprechende Notfallprogramme f&uuml;r Ihre Daten. Wenn beispielsweise die Hardware bei Google ausf&auml;llt oder Naturkatastrophen Server lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering.</p>
<p>Die Aufbewahrungsdauer der Daten h&auml;ngt von den verwendeten Properties ab. Bei der Verwendung der neueren Google Analytics 4-Properties ist die Aufbewahrungsdauer Ihrer Userdaten auf 14 Monate fix eingestellt. F&uuml;r andere sogenannte Ereignisdaten haben wir die M&ouml;glichkeit eine Aufbewahrungsdauer von 2 Monaten oder 14 Monaten zu w&auml;hlen.</p>
<p>Bei Universal Analytics-Properties ist bei Google Analytics eine Aufbewahrungsdauer Ihrer Userdaten von 26 Monaten standardisiert eingestellt. Dann werden Ihre Userdaten gel&ouml;scht. Allerdings haben wir die M&ouml;glichkeit, die Aufbewahrungsdauer von Nutzdaten selbst zu w&auml;hlen. Daf&uuml;r stehen uns f&uuml;nf Varianten zur Verf&uuml;gung:</p>
<ul>
<li>L&ouml;schung nach 14 Monaten</li>
<li>L&ouml;schung nach 26 Monaten</li>
<li>L&ouml;schung nach 38 Monaten</li>
<li>L&ouml;schung nach 50 Monaten</li>
<li>Keine automatische L&ouml;schung</li>
</ul>
<p>Zus&auml;tzlich gibt es auch die Option, dass Daten erst dann gel&ouml;scht werden, wenn Sie innerhalb des von uns gew&auml;hlten Zeitraums nicht mehr unsere Website besuchen. In diesem Fall wird die Aufbewahrungsdauer jedes Mal zur&uuml;ckgesetzt, wenn Sie unsere Website innerhalb des festgelegten Zeitraums wieder besuchen.</p>
<p>Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gel&ouml;scht. Diese Aufbewahrungsdauer gilt f&uuml;r Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der DoubleClick-Domain) verkn&uuml;pft sind. Berichtergebnisse basieren auf aggregierten Daten und werden unabh&auml;ngig von Nutzerdaten gespeichert. Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer gr&ouml;&szlig;eren Einheit.</p>
<h3>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</h3>
<p>Nach dem Datenschutzrecht der Europ&auml;ischen Union haben Sie das Recht, Auskunft &uuml;ber Ihre Daten zu erhalten, sie zu aktualisieren, zu l&ouml;schen oder einzuschr&auml;nken. Mithilfe des Browser-Add-ons zur Deaktivierung von Google Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das Browser-Add-on k&ouml;nnen Sie unter <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a> runterladen und installieren. Beachten Sie bitte, dass durch dieses Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.</p>
<p>Falls Sie grunds&auml;tzlich Cookies deaktivieren, l&ouml;schen oder verwalten wollen, finden Sie unter dem Abschnitt &bdquo;Cookies&ldquo; die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<h3>Rechtsgrundlage</h3>
<p>Der Einsatz von Google Analytics setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
<p>Zus&auml;tzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von Google Analytics erkennen wir Fehler der Website, k&ouml;nnen Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage daf&uuml;r ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Analytics gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;&szlig;igkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (au&szlig;erhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche den Standardvertragsklauseln entsprechen und auch f&uuml;r Google Analytics geltend sind, finden Sie unter <a href="https://business.safety.google/adsprocessorterms/">https://business.safety.google/adsprocessorterms/</a>.</p>
<p>Wir hoffen, wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung von Google Analytics n&auml;herbringen. Wenn Sie mehr &uuml;ber den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden Links: <a href="https://marketingplatform.google.com/about/analytics/terms/de/">https://marketingplatform.google.com/about/analytics/terms/de/</a> und <a href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>
<h2>Auftragsverarbeitungsvertrag (AVV) Google Analytics</h2>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit Google einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, k&ouml;nnen Sie in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil Google in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass Google Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) finden Sie unter <a href="https://business.safety.google/adsprocessorterms">https://business.safety.google/adsprocessorterms</a>.</p>
<h2>Google Analytics Berichte zu demografischen Merkmalen und Interessen</h2>
<p>Wir haben in Google Analytics die Funktionen f&uuml;r Werbeberichte eingeschaltet. Die Berichte zu demografischen Merkmalen und Interessen enthalten Angaben zu Alter, Geschlecht und Interessen. Damit k&ouml;nnen wir uns &ndash; ohne diese Daten einzelnen Personen zuordnen zu k&ouml;nnen &ndash; ein besseres Bild von unseren Nutzern machen. Mehr &uuml;ber die Werbefunktionen erfahren Sie auf&nbsp;<a href="https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad">https://support.google.com/analytics/answer/3450482?hl=de_AT&amp;utm_id=ad</a>.</p>
<p>Sie k&ouml;nnen die Nutzung der Aktivit&auml;ten und Informationen Ihres Google Kontos unter &ldquo;Einstellungen f&uuml;r Werbung&rdquo; auf <a href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a> per Checkbox beenden.</p>
<h2>Google Analytics IP-Anonymisierung</h2>
<p>Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von Google Analytics implementiert.&nbsp;Diese Funktion wurde von Google entwickelt, damit diese Webseite die geltenden Datenschutzbestimmungen und Empfehlungen der lokalen Datenschutzbeh&ouml;rden einhalten kann, wenn diese eine Speicherung der vollst&auml;ndigen IP-Adresse untersagen. Die Anonymisierung bzw. Maskierung der IP findet statt, sobald die IP-Adressen im Google Analytics-Datenerfassungsnetzwerk eintreffen und bevor eine Speicherung oder Verarbeitung der Daten stattfindet.</p>
<p>Mehr Informationen zur IP-Anonymisierung finden Sie auf&nbsp;<a href="https://support.google.com/analytics/answer/2763052?hl=de">https://support.google.com/analytics/answer/2763052?hl=de</a>.</p>
<h2>Google Tag Manager Datenschutzerkl&auml;rung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Google Tag Manager Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Organisation der einzelnen Tracking-Tools<br /> 📓 Verarbeitete Daten: Der Google Tag Manager speichert selbst keine Daten. Die Daten erfassen die Tags der eingesetzten Web-Analytics-Tools.<br /> 📅 Speicherdauer: abh&auml;ngig vom eingesetzten Web-Analytics-Tool<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was ist der Google Tag Manager?</h3>
<p>F&uuml;r unsere Website verwenden wir den Google Tag Manager des Unternehmens Google Inc. F&uuml;r den europ&auml;ischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) f&uuml;r alle Google-Dienste verantwortlich. Dieser Tag Manager ist eines von vielen hilfreichen Marketing-Produkten von Google. &Uuml;ber den Google Tag Manager k&ouml;nnen wir Code-Abschnitte von diversen Trackingtools, die wir auf unserer Website verwenden, zentral einbauen und verwalten.</p>
<p>In dieser Datenschutzerkl&auml;rung wollen wir Ihnen genauer erkl&auml;ren was der Google Tag Manager macht, warum wir ihn verwenden und in welcher Form Daten verarbeitet werden.</p>
<p>Der Google Tag Manager ist ein Organisationstool, mit dem wir Website-Tags zentral und &uuml;ber eine Benutzeroberfl&auml;che einbinden und verwalten k&ouml;nnen. Als Tags bezeichnet man kleine Code-Abschnitte, die beispielsweise Ihre Aktivit&auml;ten auf unserer Website aufzeichnen (tracken). Daf&uuml;r werden JavaScript-Code-Abschnitte in den Quelltext unserer Seite eingesetzt. Die Tags stammen oft von google-internen Produkten wie Google Ads oder Google Analytics, aber auch Tags von anderen Unternehmen k&ouml;nnen &uuml;ber den Manager eingebunden und verwaltet werden. Solche Tags &uuml;bernehmen unterschiedliche Aufgaben. Sie k&ouml;nnen Browserdaten sammeln, Marketingtools mit Daten f&uuml;ttern, Buttons einbinden, Cookies setzen und auch Nutzer &uuml;ber mehrere Websites hinweg verfolgen.</p>
<h3>Warum verwenden wir den Google Tag Manager f&uuml;r unserer Website?</h3>
<p>Wie sagt man so sch&ouml;n: Organisation ist die halbe Miete! Und das betrifft nat&uuml;rlich auch die Pflege unserer Website. Um unsere Website f&uuml;r Sie und alle Menschen, die sich f&uuml;r unsere Produkte und Dienstleistungen interessieren, so gut wie m&ouml;glich zu gestalten, brauchen wir diverse Trackingtools wie beispielsweise Google Analytics. Die erhobenen Daten dieser Tools zeigen uns, was Sie am meisten interessiert, wo wir unsere Leistungen verbessern k&ouml;nnen und welchen Menschen wir unsere Angebote noch zeigen sollten. Und damit dieses Tracking funktioniert, m&uuml;ssen wir entsprechende JavaScript-Codes in unsere Website einbinden. Grunds&auml;tzlich k&ouml;nnten wir jeden Code-Abschnitt der einzelnen Tracking-Tools separat in unseren Quelltext einbauen. Das erfordert allerdings relativ viel Zeit und man verliert leicht den &Uuml;berblick. Darum n&uuml;tzen wir den Google Tag Manager. Wir k&ouml;nnen die n&ouml;tigen Skripte einfach einbauen und von einem Ort aus verwalten. Zudem bietet der Google Tag Manager eine leicht zu bedienende Benutzeroberfl&auml;che und man ben&ouml;tigt keine Programmierkenntnisse. So schaffen wir es, Ordnung in unserem Tag-Dschungel zu halten.</p>
<h3>Welche Daten werden vom Google Tag Manager gespeichert?</h3>
<p>Der Tag Manager selbst ist eine Domain, die keine Cookies setzt und keine Daten speichert. Er fungiert als blo&szlig;er &bdquo;Verwalter&ldquo; der implementierten Tags. Die Daten erfassen die einzelnen Tags der unterschiedlichen Web-Analysetools. Die Daten werden im Google Tag Manager quasi zu den einzelnen Tracking-Tools durchgeschleust und nicht gespeichert.</p>
<p>Ganz anders sieht das allerdings mit den eingebundenen Tags der verschiedenen Web-Analysetools, wie zum Beispiel Google Analytics, aus. Je nach Analysetool werden meist mit Hilfe von Cookies verschiedene Daten &uuml;ber Ihr Webverhalten gesammelt, gespeichert und verarbeitet. Daf&uuml;r lesen Sie bitte unsere Datenschutztexte zu den einzelnen Analyse- und Trackingtools, die wir auf unserer Website verwenden.</p>
<p>In den Kontoeinstellungen des Tag Managers haben wir Google erlaubt, dass Google anonymisierte Daten von uns erh&auml;lt. Dabei handelt es sich aber nur um die Verwendung und Nutzung unseres Tag Managers und nicht um Ihre Daten, die &uuml;ber die Code-Abschnitte gespeichert werden. Wir erm&ouml;glichen Google und anderen, ausgew&auml;hlte Daten in anonymisierter Form zu erhalten. Wir stimmen somit der anonymen Weitergabe unseren Website-Daten zu. Welche zusammengefassten und anonymen Daten genau weitergeleitet werden, konnten wir &ndash; trotz langer Recherche &ndash; nicht in Erfahrung bringen. Auf jeden Fall l&ouml;scht Google dabei alle Infos, die unsere Website identifizieren k&ouml;nnten. Google fasst die Daten mit Hunderten anderen anonymen Website-Daten zusammen und erstellt, im Rahmen von Benchmarking-Ma&szlig;nahmen, Usertrends. Bei Benchmarking werden eigene Ergebnisse mit jenen der Mitbewerber verglichen. Auf Basis der erhobenen Informationen k&ouml;nnen Prozesse optimiert werden.</p>
<h3>Wie lange und wo werden die Daten gespeichert?</h3>
<p>Wenn Google Daten speichert, dann werden diese Daten auf den eigenen Google-Servern gespeichert. Die Server sind auf der ganzen Welt verteilt. Die meisten befinden sich in Amerika. Unter <a href="https://www.google.com/about/datacenters/locations/?hl=de">https://www.google.com/about/datacenters/locations/?hl=de</a>&nbsp;k&ouml;nnen Sie genau nachlesen, wo sich die Google-Server befinden.</p>
<p>Wie lange die einzelnen Tracking-Tools Daten von Ihnen speichern, entnehmen Sie unseren individuellen Datenschutztexten zu den einzelnen Tools.</p>
<h3>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</h3>
<p>Der Google Tag Manager selbst setzt keine Cookies, sondern verwaltet Tags verschiedener Tracking-Websites. In unseren Datenschutztexten zu den einzelnen Tracking-Tools, finden Sie detaillierte Informationen wie Sie Ihre Daten l&ouml;schen bzw. verwalten k&ouml;nnen.</p>
<p>Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von Ihnen auch au&szlig;erhalb der EU gespeichert und verarbeitet werden k&ouml;nnen. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem europ&auml;ischen Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten d&uuml;rfen also nicht einfach &uuml;bertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa EU-Standardvertragsklauseln) zwischen uns und dem au&szlig;ereurop&auml;ischen Dienstleister gibt.</p>
<h3>Rechtsgrundlage</h3>
<p>Der Einsatz des Google Tag Managers setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
<p>Zus&auml;tzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe der Google Tag Managers k&ouml;nnen die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage daf&uuml;r ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen den Google Tag Manager gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;&szlig;igkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (au&szlig;erhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche den Standardvertragsklauseln entsprechen und auch f&uuml;r den Google Tag Manager geltend sind, finden Sie unter <a href="https://business.safety.google/adsprocessorterms/">https://business.safety.google/adsprocessorterms/</a>.</p>
<p>Wenn Sie mehr &uuml;ber den Google Tag Manager erfahren wollen, empfehlen wir Ihnen die FAQs unter <a href="https://support.google.com/tagmanager/?hl=de#topic=3441530">https://support.google.com/tagmanager/?hl=de#topic=3441530</a>.</p>
<h2>Auftragsverarbeitungsvertrag (AVV) Google Tag Manager</h2>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit Google einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, k&ouml;nnen Sie in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil Google in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass Google Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) finden Sie unter <a href="https://business.safety.google/adsprocessorterms/">https://business.safety.google/adsprocessorterms/</a>.</p>
<h2>Hotjar Datenschutzerkl&auml;rung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Hotjar Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung der Benutzererfahrung.<br /> 📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten.<br /> 📅 Speicherdauer: die Daten werden nach einem Jahr gel&ouml;scht<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was ist Hotjar?</h3>
<p>Wir verwenden auf unserer Website Hotjar der Firma Hotjar Limited (Level 2, St Julian&rsquo;s Business Centre, 3, Elia Zammit Street, St Julian&rsquo;s STJ 1000, Malta), um Besucherdaten statistisch auszuwerten. Hotjar ist ein Dienst, der das Verhalten und das Feedback von Ihnen als Nutzer auf unserer Webseite durch eine Kombination von Analyse- und Feedback-Tools analysiert. Wir erhalten von Hotjar Berichte und visuelle Darstellungen, die uns zeigen wo und wie Sie sich auf unserer Seite &ldquo;bewegen&rdquo;. Personenbezogenen Daten werden automatisch anonymisiert und erreichen niemals die Server von Hotjar. Das hei&szlig;t Sie werden als Webseitenbenutzer nicht pers&ouml;nlich identifiziert und wir lernen dennoch vieles &uuml;ber Ihr Userverhalten.</p>
<p>Wie im oberen Abschnitt bereits erw&auml;hnt, hilft uns&nbsp;Hotjar das Verhalten unserer Seitenbesucher zu analysieren.&nbsp;Zu diesen Tools, die Hotjar anbietet, geh&ouml;ren Heatmaps, Conversion Funnels, Visitor Recording, Incoming Feedback, Feedback Polls und Surveys (mehr Informationen dar&uuml;ber erhalten Sie unter <a href="https://www.hotjar.com?tid=121865447/">https://www.hotjar.com/</a>). Damit hilft uns Hotjar, Ihnen eine bessere Nutzererfahrung und ein besseres Service anzubieten. Es bietet somit einerseits eine gute Analyse &uuml;ber das Onlineverhalten, andererseits erhalten wir auch ein gutes Feedback &uuml;ber die Qualit&auml;t unserer Webseite. Denn neben all den analysetechnischen Aspekten wollen wir nat&uuml;rlich auch einfach Ihre Meinung &uuml;ber unsere Webseite wissen. Und mit dem Feedbacktool ist genau das m&ouml;glich.</p>
<h3>Warum verwenden wir Hotjar auf unserer Website?</h3>
<p>In den letzten Jahren nahm die Bedeutung von User Experience (also Benutzererfahrung) auf Webseiten stark zu. Und das auch aus gutem Grund. Eine Webseite soll so aufgebaut sein, dass Sie sich als Besucher wohl f&uuml;hlen und sich einfach zurechtfinden. Wir k&ouml;nnen dank der Analyse-Tools und des Feedback-Tools von Hotjar unsere Webseite und unser Angebot attraktiver gestalten. F&uuml;r uns besonders wertvoll erweisen sich die Heatmaps von Hotjar. Bei Heatmaps handelt es sich um eine Darstellungsform f&uuml;r die Visualisierung von Daten. Durch die Heatmaps von Hotjar sehen wir beispielsweise sehr genau, was Sie gerne anklicken, antippen und wohin Sie scrollen.</p>
<h3>Welche Daten werden von Hotjar gespeichert?</h3>
<p>W&auml;hrend Sie durch unsere Webseite surfen, sammelt Hotjar automatisch Informationen &uuml;ber Ihr Userverhalten. Um diese Informationen sammeln zu k&ouml;nnen, haben wir auf unserer Webseite einen eigenen Tracking-Code eingebaut. Folgende Daten k&ouml;nnen &uuml;ber Ihren Computer beziehungsweise Ihren Browser gesammelt werden:</p>
<ul>
<li>IP-Adresse Ihres Computers (wird in einem anonymen Format gesammelt und gespeichert)</li>
<li>Bildschirmgr&ouml;&szlig;e</li>
<li>Browserinfos (welcher Browser, welche Version usw.)</li>
<li>Ihr Standort (aber nur das Land)</li>
<li>Ihre bevorzugte Spracheinstellung</li>
<li>Besuchte Webseiten (Unterseiten)</li>
<li>Datum und Uhrzeit des Zugriffs auf eine unserer Unterseiten (Webseiten)</li>
</ul>
<p>Zudem speichern auch Cookies Daten, die auf Ihrem Computer (meist in Ihrem Browser) platziert werden. Darin werden keine personenbezogenen Daten gesammelt. Grunds&auml;tzlich gibt Hotjar keine gesammelten Daten an Dritte weiter. Hotjar weist allerdings ausdr&uuml;cklich darauf hin, dass es manchmal notwendig ist, Daten mit Amazon Web Services zu teilen. Dann werden Teile Ihrer Informationen auf deren Servern gespeichert. Amazon ist aber durch eine Geheimhaltungspflicht gebunden, diese Daten nicht preiszugeben.</p>
<p>Auf die gespeicherten Informationen haben nur eine begrenzte Anzahl an Personen (Mitarbeiter von Hotjar) Zugriff. Die Hotjar-Server sind durch Firewalls und IP-Beschr&auml;nkungen (Zugriff nur genehmigter IP-Adressen) gesch&uuml;tzt. Firewalls sind Sicherheitssysteme, die Computer vor unerw&uuml;nschten Netzwerkzugriffen sch&uuml;tzen. Sie sollen als Barriere zwischen dem sicheren internen Netzwerk von Hotjar und dem Internet dienen. Weiters verwendet Hotjar f&uuml;r Ihre Dienste auch Drittunternehmen, wie etwa Google Analytics oder Optimizely. Diese Firmen k&ouml;nnen auch Informationen, die Ihr Browser an unsere Webseite sendet, speichern.</p>
<p>Folgende Cookies werden von Hotjar verwendet. Da wir uns unter anderem auf die Cookie-Liste aus der Datenschutzerkl&auml;rung von Hotjar unter <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a> beziehen, liegt nicht bei jedem Cookie ein exemplarischer Wert vor. Die Liste zeigt Beispiele von verwendeten Hotjar-Cookies und erhebt keinen Anspruch auf Vollst&auml;ndigkeit.</p>
<p><strong>Name</strong>:&nbsp;ajs_anonymous_id<br /><strong>Wert:&nbsp;</strong>%2258832463-7cee-48ee-b346-a195f18b06c3%22121865447-5<br /><strong>Verwendungszweck:</strong> Das Cookie wird gew&ouml;hnlich f&uuml;r Analysezwecke verwendet und hilft beim Z&auml;hlen von Besuchern unserer Website, indem es verfolgt, ob Sie schon mal auf dieser Seite waren.<br /><strong>Ablaufdatum:</strong>&nbsp;nach einem Jahr</p>
<p><strong>Name</strong>:&nbsp;ajs_group_id<br /><strong>Wert:&nbsp;</strong>0<br /><strong>Verwendungszweck:&nbsp;</strong>Dieses Cookie sammelt Daten &uuml;ber das User-Verhalten. Diese Daten k&ouml;nnen dann, basierend auf Gemeinsamkeiten der Websitebesucher, einer bestimmten Besuchergruppe zugeordnet werden.<br /><strong>Ablaufdatum:</strong>&nbsp;nach einem Jahr</p>
<p><strong>Name</strong>: _hjid<br /><strong>Wert:&nbsp;</strong>699ffb1c-4bfb-483f-bde1-22cfa0b59c6c<br /><strong>Verwendungszweck:&nbsp;</strong>Das Cookie wird verwendet, um eine Hotjar-User-ID beizubehalten, die f&uuml;r die Website im Browser eindeutig ist. So kann das Userverhalten bei den n&auml;chsten Besuchen derselben User-ID zugeordnet werden.<br /><strong>Ablaufdatum:</strong>&nbsp;nach einem Jahr</p>
<p><strong>Name:</strong> _hjMinimizedPolls<br /><strong>Wert:</strong>&nbsp;462568121865447-8<br /><strong>Verwendungszweck:</strong> Immer, wenn Sie ein Feedback Poll Widget minimieren, setzt Hotjar dieses Cookie. Das Cookie stellt sicher, dass das Widget auch wirklich minimiert bleibt, wenn Sie auf unseren Seiten surfen.<br /><strong>Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name:</strong> _hjIncludedInSample<br /><strong>Wert:</strong> 1<br /><strong>Verwendungszweck:</strong> Dieses Session-Cookie wird gesetzt, um Hotjar dar&uuml;ber zu informieren, ob Sie Teil der ausgew&auml;hlten Personen (Sample) sind, die zum Erzeugen von Trichtern (Funnels) herangezogen werden.<br /><strong>Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name</strong>: _hjClosedSurveyInvites<br /><strong>Verwendungszweck:</strong> Dieses Cookie wird gesetzt, wenn Sie &uuml;ber ein Popup-Fenster eine Einladung zu einer Feedback-Umfrage sehen. Das Cookie wird verwendet, um sicherzustellen, dass diese Einladung f&uuml;r Sie nur einmal erscheint.<br /><strong>Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name:</strong> _hjDonePolls<br /><strong>Verwendungszweck:</strong> Sobald Sie eine Feedback- &bdquo;Fragerunde&ldquo; mit dem sogenannten Feedback Poll Widget beenden, wird dieses Cookie in Ihrem Browser gesetzt. Damit verhindert Hotjar, dass Sie in Zukunft wieder dieselben Umfragen erhalten.<br /><strong>Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name:</strong> _hjDoneTestersWidgets <strong><br /><strong>Verwendungszweck:</strong></strong> Dieses Cookie wird verwendet, sobald Sie Ihre Daten im &bdquo;Recruit User Tester Widget&ldquo; angeben. Mit diesem Widget wollen wir Sie als Tester anheuern. Damit dieses Formular nicht immer wieder erscheint, wird das Cookie verwendet.<br /><strong>Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name:</strong> _hjMinimizedTestersWidgets<br /><strong>Verwendungszweck:</strong> Damit der &bdquo;Recruit User Tester&ldquo; auch wirklich auf all unseren Seiten minimiert bleibt, sobald Sie Ihn einmal minimiert haben, wird dieses Cookie gesetzt.<br /><strong>Ablaufdatum:</strong> nach einem Jahr</p>
<p><strong>Name:</strong> _hjShownFeedbackMessage<br /><strong>Verwendungszweck:</strong> Dieses Cookie wird gesetzt, wenn Sie das eingehende Feedback minimiert oder erg&auml;nzt haben. Dies geschieht, damit das eingehende Feedback sofort als minimiert geladen wird, wenn Sie zu einer anderen Seite navigieren, auf der es angezeigt werden soll.<br /><strong>Ablaufdatum:</strong> nach einem Jahr</p>
<h3>Wie lange und wo werden die Daten gespeichert?</h3>
<p>Wir haben einen Tracking-Code auf unserer Website eingebaut, der an die Hotjar-Server in Irland (EU) &uuml;bertragen wird. Dieser Tracking-Code kontaktiert die Server von Hotjar und sendet ein Skript an Ihren Computer oder Ihr Endger&auml;t, mit dem Sie auf unsere Seite zugreifen. Das Skript erfasst bestimmte Daten in Bezug auf Ihre Interaktion mit unserer Website. Diese Daten werden dann zur Verarbeitung an die Server von Hotjar gesendet. Hotjar hat sich selbst eine 365-Tage-Datenspeicherungsfrist auferlegt. Das hei&szlig;t alle Daten, die Hotjar gesammelt hat und &auml;lter als ein Jahr sind, werden automatisch wieder gel&ouml;scht.</p>
<h3>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</h3>
<p>Hotjar speichert f&uuml;r die Analyse keine personenbezogenen Daten von Ihnen. Das Unternehmen wirbt sogar mit dem Slogan &bdquo;We track behavior, not individuals&ldquo; (also &bdquo;Wir tracken Userverhalten, aber keine identifizierbaren, individuellen Daten). Sie haben auch immer die M&ouml;glichkeit die Erhebung Ihrer Daten zu unterbinden. Daf&uuml;r m&uuml;ssen Sie nur auf die &bdquo;<a href="https://www.hotjar.com/policies/do-not-track/">Opt-out-Seite</a>&ldquo;&nbsp;gehen und auf &bdquo;Hotjar deaktivieren&ldquo; klicken. Bitte beachten Sie, dass das L&ouml;schen von Cookies, die Nutzung des Privatmodus Ihres Browsers oder die Nutzung eines anderen Browsers dazu f&uuml;hrt, Daten wieder zu erheben. Weiters k&ouml;nnen Sie auch in Ihrem Browser den &bdquo;Do Not Track&ldquo;-Button aktivieren. Im Browser Chrome beispielsweise m&uuml;ssen Sie daf&uuml;r rechts oben auf die drei Balken klicken und auf &bdquo;Einstellungen&ldquo; gehen. Dort finden Sie im Abschnitt &bdquo;Datenschutz&ldquo; die Option &bdquo;Mit Browserzugriffen eine &ldquo;Do Not Track&rdquo;-Anforderung senden&rdquo;. Jetzt aktivieren Sie nur noch diese Schaltfl&auml;che und schon werden keinen Daten von Hotjar erhoben.</p>
<h3>Rechtsgrundlage</h3>
<p>Der Einsatz von Hotjar setzt Ihre Einwilligung voraus, welche wir mit unserem Cookie Popup eingeholt haben. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Web-Analytics Tools vorkommen kann, dar.</p>
<p>Zus&auml;tzlich zur Einwilligung besteht von unserer Seite ein berechtigtes Interesse daran, dass Verhalten der Websitebesucher zu analysieren und so unser Angebot technisch und wirtschaftlich zu verbessern. Mit Hilfe von Hotjar erkennen wir Fehler der Website, k&ouml;nnen Attacken identifizieren und die Wirtschaftlichkeit verbessern. Die Rechtsgrundlage daf&uuml;r ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Hotjar gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<p>Mehr Details zur Datenschutzrichtlinie und welche Daten auf welche Art durch Hotjar erfasst werden finden Sie auf <a href="https://www.hotjar.com/legal/policies/privacy/">https://www.hotjar.com/legal/policies/privacy?tid=121865447</a>.</p>
<h2 id="e-mail-marketing-einleitung">E-Mail-Marketing Einleitung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>E-Mail-Marketing Zusammenfassung</strong><br /> 👥 Betroffene: Newsletter-Abonnenten<br /> 🤝 Zweck: Direktwerbung per E-Mail, Benachrichtigung &uuml;ber systemrelevante Ereignisse<br /> 📓 Verarbeitete Daten: Eingegebene Daten bei der Registrierung jedoch mindestens die E-Mail-Adresse. Mehr Details dazu finden Sie beim jeweils eingesetzten E-Mail-Marketing-Tool.<br /> 📅 Speicherdauer: Dauer des Bestehens des Abonnements<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (berechtigte Interessen)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was ist E-Mail-Marketing?</h3>
<p>Um Sie stets auf dem Laufenden zu halten, nutzen wir auch die M&ouml;glichkeit des E-Mail-Marketings. Dabei werden, sofern Sie dem Empfang unserer E-Mails bzw. Newsletter zugestimmt haben, auch Daten von Ihnen verarbeitet und gespeichert. E-Mail-Marketing ist ein Teilbereich des Online-Marketings. Dabei werden Neuigkeiten oder allgemeine Informationen &uuml;ber ein Unternehmen, Produkte oder Dienstleistungen per E-Mail an eine bestimmte Gruppe an Menschen, die sich daf&uuml;r interessieren, gesendet.</p>
<p>Wenn Sie an unserem E-Mail-Marketing (meist per Newsletter) teilnehmen wollen, m&uuml;ssen Sie sich im Normalfall einfach nur mit Ihrer E-Mail-Adresse anmelden. Daf&uuml;r f&uuml;llen Sie ein Online-Formular aus und senden es ab. Es kann aber auch vorkommen, dass wir Sie etwa um Ihre Anrede und Ihren Namen bitten, damit wir Sie auch pers&ouml;nlich anschreiben k&ouml;nnen.</p>
<p>Grunds&auml;tzlich funktioniert das Anmelden zu Newslettern mit Hilfe des sogenannten &bdquo;Double-Opt-In-Verfahrens&ldquo;. Nachdem Sie sich f&uuml;r unseren Newsletter auf unserer Website angemeldet haben, bekommen Sie eine E-Mail, &uuml;ber die Sie die Newsletter-Anmeldung best&auml;tigen. So wird sichergestellt, dass Ihnen die E-Mail-Adresse geh&ouml;rt und sich niemand mit einer fremden E-Mail-Adresse angemeldet hat. Wir oder ein von uns verwendetes Benachrichtigungs-Tool protokolliert jede einzelne Anmeldung. Dies ist n&ouml;tig, damit wir den rechtlich korrekten Anmeldevorgang auch nachweisen k&ouml;nnen. Dabei wird in der Regel der Zeitpunkt der Anmeldung, der Zeitpunkt der Anmeldebest&auml;tigung und Ihre IP-Adresse gespeichert. Zus&auml;tzlich wird auch protokolliert, wenn Sie &Auml;nderungen Ihrer gespeicherten Daten vornehmen.</p>
<h3>Warum nutzen wir E-Mail-Marketing?</h3>
<p>Wir wollen nat&uuml;rlich mit Ihnen in Kontakt bleiben und Ihnen stets die wichtigsten Neuigkeiten &uuml;ber unser Unternehmen pr&auml;sentieren. Daf&uuml;r nutzen wir unter anderem E-Mail-Marketing &ndash; oft auch nur &ldquo;Newsletter&rdquo; bezeichnet &ndash; als wesentlichen Bestandteil unseres Online-Marketings. Sofern Sie sich damit einverstanden erkl&auml;ren oder es gesetzlich erlaubt ist, schicken wir Ihnen Newsletter, System-E-Mails oder andere Benachrichtigungen per E-Mail. Wenn wir im folgenden Text den Begriff &bdquo;Newsletter&ldquo; verwenden, meinen wir damit haupts&auml;chlich regelm&auml;&szlig;ig versandte E-Mails. Nat&uuml;rlich wollen wir Sie mit unseren Newsletter in keiner Weise bel&auml;stigen. Darum sind wir wirklich stets bem&uuml;ht, nur relevante und interessante Inhalte zu bieten. So erfahren Sie etwa mehr &uuml;ber unser Unternehmen, unsere Leistungen oder Produkte. Da wir unsere Angebote auch immer verbessern, erfahren Sie &uuml;ber unseren Newsletter auch immer, wenn es Neuigkeiten gibt oder wir gerade spezielle, lukrative Aktionen anbieten. Sofern wir einen Dienstleister, der ein professionelles Versand-Tool anbietet, f&uuml;r unser E-Mail-Marketing beauftragen, machen wir das, um Ihnen schnelle und sichere Newsletter bieten zu k&ouml;nnen. Zweck unseres E-Mail-Marketings ist grunds&auml;tzlich, Sie &uuml;ber neue Angebote zu informieren und auch unseren unternehmerischen Zielen n&auml;her zu kommen.</p>
<h3>Welche Daten werden verarbeitet?</h3>
<p>Wenn Sie &uuml;ber unsere Website Abonnent unseres Newsletters werden, best&auml;tigen Sie per E-Mail die Mitgliedschaft in einer E-Mail-Liste. Neben IP-Adresse und E-Mail-Adresse k&ouml;nnen auch Ihre Anrede, Ihr Name, Ihre Adresse und Ihre Telefonnummer gespeichert werden. Allerdings nur, wenn Sie dieser Datenspeicherungen zustimmen. Die als solche markierten Daten sind notwendig, damit Sie an dem angebotenen Dienst teilnehmen k&ouml;nnen. Die Angabe ist freiwillig, die Nichtangabe f&uuml;hrt jedoch dazu, dass Sie den Dienst nicht nutzen k&ouml;nnen. Zus&auml;tzlich k&ouml;nnen etwa auch Informationen zu Ihrem Ger&auml;t oder zu Ihren bevorzugten Inhalten auf unserer Website gespeichert werden. Mehr zur Speicherung von Daten, wenn Sie eine Website besuchen, finden Sie im Abschnitt &ldquo;Automatische Datenspeicherung&rdquo;. Ihre Einwilligungserkl&auml;rung zeichnen wir auf, damit wir stets nachweisen k&ouml;nnen, dass dieser unseren Gesetzen entspricht.</p>
<h3>Dauer der Datenverarbeitung</h3>
<p>Wenn Sie Ihre E-Mail-Adresse aus unserem E-Mail/Newsletter-Verteiler austragen, d&uuml;rfen wir Ihre Adresse bis zu drei Jahren auf Grundlage unserer berechtigten Interessen speichern, damit wir Ihre damalige Einwilligung noch nachweisen k&ouml;nnen. Verarbeiten d&uuml;rfen wir diese Daten nur, wenn wir uns gegen etwaige Anspr&uuml;che wehren m&uuml;ssen.</p>
<p>Wenn Sie allerdings best&auml;tigen, dass Sie uns die Einwilligung zur Newsletter-Anmeldung gegeben haben, k&ouml;nnen Sie jederzeit einen individuellen L&ouml;schantrag stellen. Widersprechen Sie der Einwilligung dauerhaft, behalten wir uns das Recht vor, Ihre E-Mail-Adresse in einer Sperrliste zu speichern. Solange Sie freiwillig unseren Newsletter abonniert haben, solange behalten wir selbstverst&auml;ndlich auch Ihre E-Mail-Adresse.</p>
<h3>Widerspruchsrecht</h3>
<p>Sie haben jederzeit die M&ouml;glichkeit Ihre Newsletter-Anmeldung zu k&uuml;ndigen. Daf&uuml;r m&uuml;ssen Sie lediglich Ihre Einwilligung zur Newsletter-Anmeldung widerrufen. Das dauert im Normalfall nur wenige Sekunden bzw. einen oder zwei Klicks. Meistens finden Sie direkt am Ende jeder E-Mail einen Link, um das Newsletter-Abonnement zu k&uuml;ndigen. Wenn der Link im Newsletter wirklich nicht zu finden ist, kontaktieren Sie uns bitte per Mail und wir werden Ihr Newsletter-Abo unverz&uuml;glich k&uuml;ndigen.</p>
<h3>Rechtsgrundlage</h3>
<p>Das Versenden unseres Newsletters erfolgt auf Grundlage Ihrer <strong>Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO). Das hei&szlig;t, wir d&uuml;rfen Ihnen nur dann einen Newsletter schicken, wenn Sie sich zuvor aktiv daf&uuml;r angemeldet haben. Gegebenenfalls k&ouml;nnen wir Ihnen auch Werbenachrichten auf Grundlage des &sect; 7 Abs. 3 UWG senden, sofern Sie unser Kunde geworden sind und der Verwendung ihrer E-Mailadresse f&uuml;r Direktwerbung nicht widersprochen haben.</p>
<p>Informationen zu speziellen E-Mail-Marketing Diensten und wie diese personenbezogene Daten verarbeiten, erfahren Sie &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<h2 id="push-nachrichten-einleitung">Push-Nachrichten Einleitung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Push-Nachrichten Zusammenfassung</strong><br /> 👥 Betroffene: Push-Nachrichten Abonnenten<br /> 🤝 Zweck: Benachrichtigung &uuml;ber systemrelevante und interessante Ereignisse<br /> 📓 Verarbeitete Daten: Eingegebene Daten bei der Registrierung, meistens auch Standortdaten. Mehr Details dazu finden Sie beim jeweils eingesetzten Push-Nachrichten-Tool.<br /> 📅 Speicherdauer: Daten meist so lange gespeichert, wie es f&uuml;r die Bereitstellung der Dienste n&ouml;tig ist.<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was sind Push-Nachrichten?</h3>
<p>Wir nutzen auf unserer Website auch sogenannte Push-Benachrichtigungsdienste, mit denen wir unsere User stets auf dem Laufenden halten k&ouml;nnen. Das bedeutet, wenn Sie der Nutzung von solchen Push-Nachrichten zugestimmt haben, k&ouml;nnen wir Ihnen mit Hilfe eines Softwaretools kurze News schicken. Push-Nachrichten sind eine Textnachrichten-Form, die bei Ihnen direkt auf dem Smartphone oder auf anderen Ger&auml;ten wie etwa Tablets oder PCs erscheinen, wenn Sie sich daf&uuml;r angemeldet haben. Sie bekommen diese Nachrichten auch, wenn Sie sich nicht auf unserer Website befinden bzw. unser Angebot nicht aktiv nutzen. Dabei k&ouml;nnen auch Daten &uuml;ber Ihren Standort und Ihr Nutzungsverhalten gesammelt und gespeichert werden.</p>
<h3>Warum verwenden wir Push-Nachrichten?</h3>
<p>Einerseits nutzen wir Push-Nachrichten, um unsere Leistungen, die wir mit Ihnen vertraglich vereinbart haben, auch vollst&auml;ndig erbringen zu k&ouml;nnen. Andererseits dienen die Nachrichten auch unserem Online-Marketing. Wir k&ouml;nnen Ihnen mit Hilfe dieser Nachrichten unser Service oder unsere Produkte n&auml;herbringen. Besonders wenn es Neuigkeiten in unserem Unternehmen gibt, k&ouml;nnen wir Sie dar&uuml;ber sofort informieren. Wir wollen die Vorlieben und die Gewohnheiten all unserer User so gut wie m&ouml;glich kennenlernen, um unser Angebot laufend zu verbessern.</p>
<h3>Welche Daten werden verarbeitet?</h3>
<p>Damit Sie Push-Nachrichten bekommen k&ouml;nnen, m&uuml;ssen Sie auch best&auml;tigen, dass Sie diese Nachrichten erhalten wollen. Die Daten, die w&auml;hrend des Prozesses der Einwilligung angesammelt werden, werden auch gespeichert, verwaltet und verarbeitet. Das ist notwendig, damit bewiesen und erkannt werden kann, dass ein User dem Empfangen der Push-Nachrichten zugestimmt hat. Daf&uuml;r wird ein sogenannter Device-Token oder Push-Token in Ihrem Browser gespeichert. F&uuml;r gew&ouml;hnlich werden auch die Daten Ihres Standorts bzw. des Standorts Ihres verwendeten Endger&auml;ts gespeichert.</p>
<p>Damit wir auch stets interessante und wichtige Push-Nachrichten versenden, wird der Umgang mit den Nachrichten auch statistisch ausgewertet. So sehen wir dann zum Beispiel, ob und wann Sie die Nachricht &ouml;ffnen. Mit Hilfe dieser Erkenntnisse k&ouml;nnen wir unsere Kommunikationsstrategie an Ihre W&uuml;nsche und Interessen anpassen. Obwohl diese gespeicherten Daten Ihnen zugeordnet werden k&ouml;nnen, wollen wir Sie als Einzelperson nicht &uuml;berpr&uuml;fen. Vielmehr interessieren uns die gesammelten Daten all unserer User, damit wir Optimierungen vornehmen k&ouml;nnen. Welche Daten genau gespeichert werden, erfahren Sie in den Datenschutzerkl&auml;rungen der jeweiligen Dienstanbieter.</p>
<h3>Dauer der Datenverarbeitung</h3>
<p>Wie lange die Daten verarbeitet und gespeichert werden, h&auml;ngt in erster Linie von unserem verwendeten Tool ab. Weiter unten erfahren Sie mehr &uuml;ber die Datenverarbeitung der einzelnen Tools. In den Datenschutzerkl&auml;rungen der Anbieter steht &uuml;blicherweise genau, welche Daten wie lange gespeichert und verarbeitet werden. Grunds&auml;tzlich werden personenbezogene Daten nur so lange verarbeitet, wie es f&uuml;r die Bereitstellung unserer Dienste n&ouml;tig ist. Wenn Daten in Cookies gespeichert werden, variiert die Speicherdauer stark. Die Daten k&ouml;nnen gleich nach dem Verlassen einer Website wieder gel&ouml;scht werden, sie k&ouml;nnen aber auch &uuml;ber mehrere Jahre gespeichert bleiben. Daher sollten Sie sich jedes einzelnen Cookie im Detail ansehen, wenn Sie &uuml;ber die Datenspeicherung Genaueres wissen wollen. Meistens finden Sie in den Datenschutzerkl&auml;rungen der einzelnen Anbieter auch aufschlussreiche Informationen &uuml;ber die einzelnen Cookies.</p>
<h3>Rechtsgrundlage</h3>
<p>Es kann auch sein, dass die Push-Nachrichten notwendig sind, damit gewisse Pflichten, die in einem Vertrag stehen, erf&uuml;llt werden k&ouml;nnen. Zum Beispiel, damit wir Ihnen technische oder organisatorische Neuigkeiten mitteilen k&ouml;nnen. Dann lautet die Rechtsgrundlage Art. 6 Abs. 1 lit. b DSGVO.</p>
<p>Wenn dies nicht der Fall ist, werden die Push-Nachrichten nur auf Grundlage Ihrer Einwilligung verschickt. Unsere Push-Nachrichten k&ouml;nnen insbesondere einen werbenden Inhalt haben. Die Push-Nachrichten k&ouml;nnen auch abh&auml;ngig von Ihrem Standort, die Ihr Endger&auml;t anzeigt, versendet werden. Auch die oben genannten analytischen Auswertungen basieren auf Grundlage Ihrer Einwilligung, solche Nachrichten empfangen zu wollen. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO. Sie k&ouml;nnen nat&uuml;rlich in den Einstellungen Ihre Einwilligung jederzeit widerrufen bzw. diverse Einstellungen &auml;ndern.</p>
<h2 id="messenger-kommunikation-einleitung">Messenger &amp; Kommunikation Einleitung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Messenger &amp; Kommunikation Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Kontaktanfragen und die allgemeine Kommunikation zwischen uns und Ihnen<br /> 📓 Verarbeitete Daten: Daten wie etwa Name, Adresse, E-Mailadresse, Telefonnummer, allgemeine Inhaltsdaten, gegebenenfalls IP-Adresse<br /> Mehr Details dazu finden Sie bei den jeweils eingesetzten Tools.<br /> 📅 Speicherdauer: abh&auml;ngig von den verwendeten Messenger- &amp; Kommunikationsfunktionen<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen), Art. 6 Abs. 1 S. 1 lit. b. DSGVO (vertragliche oder vorvertragliche Verpflichtungen)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was sind Messenger- &amp; Kommunikationsfunktionen?</h3>
<p>Wir bieten auf unserer Website verschiedene M&ouml;glichkeiten (etwa Messenger- und Chatfunktionen, Online- bzw. Kontaktformulare, E-Mail, Telefon), um mit uns zu kommunizieren. Dabei werden auch Ihre Daten, soweit es f&uuml;r die Beantwortung Ihrer Anfrage und unseren darauffolgenden Ma&szlig;nahmen n&ouml;tig ist, verarbeitet und gespeichert.</p>
<p>Wir nutzen neben klassischen Kommunikationsmitteln wie E-Mail, Kontaktformularen oder Telefon auch Chats bzw. Messenger. Die derzeit am h&auml;ufigsten verwendete Messenger-Funktion ist WhatsApp, aber es gibt nat&uuml;rlich speziell f&uuml;r Websites viele verschiedene Anbieter, die Messenger-Funktionen anbieten. Wenn Inhalte Ende zu Ende verschl&uuml;sselt sind, wird darauf in den einzelnen Datenschutztexten oder in der Datenschutzerkl&auml;rung des jeweiligen Anbieters hingewiesen. Eine Ende-zu-Ende-Verschl&uuml;sselung bedeutet nichts anders, als dass Inhalte einer Nachricht selbst f&uuml;r den Anbieter nicht sichtbar sind. Allerdings k&ouml;nnen trotzdem Informationen zu Ihrem Ger&auml;t, Standorteinstellungen und andere technische Daten verarbeitet und gespeichert werden.</p>
<h3>Warum nutzen wir Messenger- &amp; Kommunikationsfunktionen?</h3>
<p>Kommunikationsm&ouml;glichkeiten mit Ihnen sind f&uuml;r uns von gro&szlig;er Bedeutung. Schlie&szlig;lich wollen wir mit Ihnen sprechen und alle m&ouml;glichen Fragen zu unserem Service bestm&ouml;glich beantworten. Eine gut funktionierende Kommunikation ist bei uns ein wichtiger Teil unserer Dienstleistung. Mit den praktischen Messenger- &amp; Kommunikationsfunktionen k&ouml;nnen Sie jederzeit jene w&auml;hlen, die Ihnen am liebsten sind. In Ausnahmef&auml;llen kann es aber auch vorkommen, dass wir bestimmte Fragen &uuml;ber Chat bzw. Messenger nicht beantworten. Das ist der Fall, wenn es etwa um interne vertragliche Angelegenheiten geht. Hier empfehlen wir andere Kommunikationsm&ouml;glichkeiten wie E-Mail oder Telefon.</p>
<p>Wir gehen in der Regel davon aus, dass wir datenschutzrechtlich verantwortlich bleiben, auch wenn wir Dienste einer Social-Media-Plattform nutzen. Der Europ&auml;ische Gerichtshof hat jedoch entschieden, dass in bestimmten F&auml;llen der Betreiber der Social-Media-Plattform zusammen mit uns gemeinsam verantwortlich im Sinne des Art. 26 DSGVO sein kann. Soweit dies der Fall ist, weisen wir gesondert darauf hin und arbeiten auf Grundlage einer diesbez&uuml;glichen Vereinbarung. Das Wesentliche der Vereinbarung ist weiter unten bei der betroffenen Plattform wiedergegeben.</p>
<p>Bitte beachten Sie, dass bei der Nutzung unserer eingebauten Elemente auch Daten von Ihnen au&szlig;erhalb der Europ&auml;ischen Union verarbeitet werden k&ouml;nnen, da viele Anbieter, beispielsweise Facebook-Messenger oder WhatsApp amerikanische Unternehmen sind. Dadurch k&ouml;nnen Sie m&ouml;glicherweise Ihre Rechte in Bezug auf Ihre personenbezogenen Daten nicht mehr so leicht einfordern bzw. durchsetzen.</p>
<h3>Welche Daten werden verarbeitet?</h3>
<p>Welche Daten genau gespeichert und verarbeitet werden, h&auml;ngt vom jeweiligen Anbieter der Messenger- &amp; Kommunikationsfunktionen ab. Grunds&auml;tzlich handelt es sich um Daten wie etwa Name, Adresse, Telefonnummer, E-Mailadresse und Inhaltsdaten wie beispielsweise alle Informationen, die Sie in ein Kontaktformular eingeben. Meistens werden auch Informationen zu Ihrem Ger&auml;t und die IP-Adresse gespeichert. Daten, die &uuml;ber eine Messenger- &amp; Kommunikationsfunktion erhoben werden, werden auch auf den Servern der Anbieter gespeichert.</p>
<p>Wenn Sie genau wissen wollen, welche Daten bei den jeweiligen Anbietern gespeichert und verarbeitet werden und wie Sie der Datenverarbeitung widersprechen k&ouml;nnen, sollten Sie die jeweilige Datenschutzerkl&auml;rung des Unternehmens sorgf&auml;ltig durchlesen.</p>
<h3>Wie lange werden Daten gespeichert?</h3>
<p>Wie lange die Daten verarbeitet und gespeichert werden, h&auml;ngt in erster Linie von unseren verwendeten Tools ab. Weiter unten erfahren Sie mehr &uuml;ber die Datenverarbeitung der einzelnen Tools. In den Datenschutzerkl&auml;rungen der Anbieter steht &uuml;blicherweise genau, welche Daten wie lange gespeichert und verarbeitet werden. Grunds&auml;tzlich werden personenbezogene Daten nur so lange verarbeitet, wie es f&uuml;r die Bereitstellung unserer Dienste n&ouml;tig ist. Wenn Daten in Cookies gespeichert werden, variiert die Speicherdauer stark. Die Daten k&ouml;nnen gleich nach dem Verlassen einer Website wieder gel&ouml;scht werden, sie k&ouml;nnen aber auch &uuml;ber mehrere Jahre gespeichert bleiben. Daher sollten Sie sich jedes einzelnen Cookie im Detail ansehen, wenn Sie &uuml;ber die Datenspeicherung Genaueres wissen wollen. Meistens finden Sie in den Datenschutzerkl&auml;rungen der einzelnen Anbieter auch aufschlussreiche Informationen &uuml;ber die einzelnen Cookies.</p>
<h3>Widerspruchsrecht</h3>
<p>Sie haben auch jederzeit das Recht und die M&ouml;glichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel k&ouml;nnen Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder l&ouml;schen. F&uuml;r weitere Informationen verweisen wir auf den Abschnitt zur Einwilligung.</p>
<p>Da bei Messenger- &amp; Kommunikationsfunktionen Cookies zum Einsatz kommen k&ouml;nnen, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerkl&auml;rung &uuml;ber Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
<h3>Rechtsgrundlage</h3>
<p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Messenger- &amp; Kommunikationsfunktionen verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Wir bearbeiten Ihre Anfrage und verwalten Ihre Daten im Rahmen vertraglicher oder vorvertraglicher Beziehungen, um unsere vorvertraglichen und vertraglichen Pflichten zu erf&uuml;llen bzw. Anfragen zu beantworten. Grundlage daf&uuml;r ist <strong>Art. 6 Abs. 1 S. 1 lit. b. DSGVO</strong>. Grunds&auml;tzlich werden Ihre Daten bei Vorliegen einer Einwilligung auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet.</p>
<h2>Zendesk Datenschutzerkl&auml;rung</h2>
<p>Wir nutzen auch die Kundenservice-Software Zendesk. Dienstanbieter ist das amerikanische Unternehmen Zendesk, Inc., 989 Market St, San Francisco, CA 94103, USA.</p>
<p>Zendesk verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;&szlig;igkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (au&szlig;erhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Zendesk sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Zendesk, bei der Verarbeitung Ihrer relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Weitere Informationen zu der Datenverarbeitung und den Standardvertragsklauseln bei Zendesk finden Sie unter <a href="https://www.zendesk.de/company/privacy-and-data-protection/">https://www.zendesk.de/company/privacy-and-data-protection/</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Zendesk verarbeitet werden, erfahren Sie in der Privacy Policy auf <a href="https://www.zendesk.de/company/agreements-and-terms/privacy-policy/">https://www.zendesk.de/company/agreements-and-terms/privacy-policy/</a><u>. </u></p>
<h2 id="chatbots-einleitung">Chatbots Einleitung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Chatbots Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Kontaktanfragen und die allgemeine Kommunikation zwischen uns und Ihnen<br /> 📓 Verarbeitete Daten: Daten wie etwa Name, Adresse, E-Mailadresse, Telefonnummer, allgemeine Inhaltsdaten, gegebenenfalls IP-Adresse<br /> Mehr Details dazu finden Sie bei den jeweils eingesetzten Tools.<br /> 📅 Speicherdauer: abh&auml;ngig von den verwendeten Chatbots &amp; Chatfunktionen<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen), Art. 6 Abs. 1 S. 1 lit. b. DSGVO (vertragliche oder vorvertragliche Verpflichtungen)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was sind Chatbots?</h3>
<p>Sie k&ouml;nnen mit uns auch &uuml;ber Chatbots oder &auml;hnliche Chatfunktionen kommunizieren. Ein Chat bietet die M&ouml;glichkeit, mit nur sehr geringer Zeitverz&ouml;gerung miteinander zu schreiben oder zu sprechen. Ein Chatbot ist eine Software, die Ihre Frage versucht zu beantworten und Sie gegebenenfalls &uuml;ber Neuigkeiten informiert. Durch die Verwendung dieser Kommunikationsmittel k&ouml;nnen auch personenbezogene Daten von Ihnen verarbeitet und gespeichert werden.</p>
<h3>Warum nutzen wir Chatbots?</h3>
<p>Kommunikationsm&ouml;glichkeiten mit Ihnen sind uns wichtig. Schlie&szlig;lich wollen wir mit Ihnen sprechen und alle m&ouml;glichen Fragen zu unserem Service bestm&ouml;glich beantworten. Eine gut funktionierende Kommunikation ist bei uns ein wichtiger Teil unserer Dienstleistung. Chatbots haben den gro&szlig;en Vorteil, dass wir h&auml;ufig gestellte Fragen mit Hilfe dieser Software automatisiert beantworten k&ouml;nnen. Das erspart uns Zeit und Sie erhalten dennoch ausf&uuml;hrliche und hilfreiche Antworten. Wenn der Chatbot nicht weiterhelfen kann, haben Sie nat&uuml;rlich jederzeit auch die M&ouml;glichkeit, mit uns pers&ouml;nlich in Kontakt zu treten.</p>
<p>Bitte beachten Sie, dass bei der Nutzung unserer eingebauten Elemente auch Daten von Ihnen au&szlig;erhalb der Europ&auml;ischen Union verarbeitet werden k&ouml;nnen, da viele Anbieter amerikanische Unternehmen sind. Dadurch k&ouml;nnen Sie m&ouml;glicherweise Ihre Rechte in Bezug auf Ihre personenbezogenen Daten nicht mehr so leicht einfordern bzw. durchsetzen.</p>
<h3>Welche Daten werden verarbeitet?</h3>
<p>Es kann vorkommen, dass Sie die Chatdienste auch bei anderen Websites/Plattformen verwenden. In diesem Fall wird auch Ihre User-ID auf den Servern dieser Website gespeichert. Wir k&ouml;nnen weiters auch dar&uuml;ber informiert werden, welcher User zu welchem Zeitpunkt den Chat verwendet hat. Auch die Inhalte werden gespeichert. Welche Daten genau gespeichert werden h&auml;ngt vom jeweiligen Dienst ab. In der Regel handelt es sich aber um Kontaktdaten wie E-Mail-Adresse oder Telefonnummer, IP-Adresse und diverse Nutzungsdaten.</p>
<p>Wenn Sie eingewilligt haben, dass die Chatfunktion zur Anwendung kommen kann, wird auch diese Einwilligung samt einer m&ouml;glichen Registrierung gespeichert bzw. protokolliert. Das machen wir, damit wir die Registrierung oder die Einwilligung auch vorweisen k&ouml;nnen, wenn dies gesetzlich verlangt wird.</p>
<p>Der Anbieter einer Chat-Plattform kann auch erfahren, wann Sie chatten, und erh&auml;lt auch technische Informationen &uuml;ber Ihr verwendetes Ger&auml;t. Welche Informationen genau gespeichert und verarbeitet werden, h&auml;ngt auch von Ihren PC-Einstellungen ab. In vielen F&auml;llen k&ouml;nnen etwa Daten zu Ihrem ungef&auml;hren Standort erhoben werden. Dies wird einerseits gemacht, um die Chatdienste zu optimieren und andererseits, um mehr Sicherheit zu gew&auml;hrleisten. Weiters k&ouml;nnen die Informationen auch genutzt werden, um personalisierte Werbe- und Marketingma&szlig;nahmen zu setzen.</p>
<p>Wenn Sie eingewilligt haben, dass Ihnen ein Chatbot eine Nachrichten senden kann, k&ouml;nnen Sie nat&uuml;rlich diese Aktivierung auch jederzeit wieder deaktivieren. Der Chatbot dient hier auch als Hilfe und zeigt Ihnen wie Sie diese Funktion abbestellen k&ouml;nnen. All Ihre diesbez&uuml;glichen Daten werden im Anschluss aus dem Empf&auml;nger-Verzeichnis gel&ouml;scht.</p>
<p>Die oben genannten Daten verwenden wir, um etwa Sie &uuml;ber den Chat pers&ouml;nlich ansprechen zu k&ouml;nnen, um Ihre Fragen und Anfragen beantworten zu k&ouml;nnen oder auch um Ihnen m&ouml;gliche Inhalte zu senden. Au&szlig;erdem k&ouml;nnen wir damit auch unsere Chat-Dienste grunds&auml;tzlich verbessern.</p>
<h3>Wie lange werden Daten gespeichert?</h3>
<p>Wie lange die Daten verarbeitet und gespeichert werden, h&auml;ngt in erster Linie von unseren verwendeten Tools ab. Weiter unten erfahren Sie mehr &uuml;ber die Datenverarbeitung der einzelnen Tools. In den Datenschutzerkl&auml;rungen der Anbieter steht &uuml;blicherweise genau, welche Daten wie lange gespeichert und verarbeitet werden. Grunds&auml;tzlich werden personenbezogene Daten nur so lange verarbeitet, wie es f&uuml;r die Bereitstellung unserer Dienste n&ouml;tig ist. Wenn Daten in Cookies gespeichert werden, variiert die Speicherdauer stark. Die Daten k&ouml;nnen gleich nach dem Verlassen einer Website wieder gel&ouml;scht werden, sie k&ouml;nnen aber auch &uuml;ber mehrere Jahre gespeichert bleiben. Daher sollten Sie sich jedes einzelnen Cookie im Detail ansehen, wenn Sie &uuml;ber die Datenspeicherung Genaueres wissen wollen. Meistens finden Sie in den Datenschutzerkl&auml;rungen der einzelnen Anbieter auch aufschlussreiche Informationen &uuml;ber die einzelnen Cookies.</p>
<h3>Widerspruchsrecht</h3>
<p>Sie haben auch jederzeit das Recht und die M&ouml;glichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel k&ouml;nnen Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder l&ouml;schen.</p>
<p>Da bei Chat-Diensten Cookies zum Einsatz kommen k&ouml;nnen, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerkl&auml;rung &uuml;ber Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
<h3>Rechtsgrundlage</h3>
<p>Wir fragen &uuml;ber ein Popup-Fenster um Ihre Erlaubnis, Daten von Ihnen im Rahmen der Chat-Dienste verarbeiten zu d&uuml;rfen. Wenn Sie einwilligen, gilt diese Einwilligung auch als rechtliche Grundlage <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong> zur Datenverarbeitung. Zudem bearbeiten wir Ihre Anfragen und verwalten Ihre Daten im Rahmen vertraglicher oder vorvertraglicher Beziehungen, um unsere vorvertraglichen und vertraglichen Pflichten zu erf&uuml;llen bzw. Anfragen zu beantworten. Grundlage daf&uuml;r ist <strong>Art. 6 Abs. 1 S. 1 lit. b. DSGVO</strong>. Grunds&auml;tzlich werden Ihre Daten auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet. Wir setzen die Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<h2>HubSpot (Chatbot) Datenschutzerkl&auml;rung</h2>
<p>Wir nutzen auch die Chatbotfunktion HubSpot. Dienstanbieter ist das amerikanische Unternehmen HubSpot, Inc., 25 First St., 2nd floor, Cambridge, Massachusetts 02141, USA.</p>
<p>HubSpot verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;&szlig;igkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (au&szlig;erhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet HubSpot sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich HubSpot, bei der Verarbeitung Ihrer relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Datenverarbeitungsbedingung (Data Processing Agreement), welche den Standardvertragsklauseln entspricht, finden Sie unter <a href="https://legal.hubspot.com/dpa">https://legal.hubspot.com/dpa</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von HubSpot verarbeitet werden, erfahren Sie in der Privacy Policy auf <a href="https://legal.hubspot.com/de/privacy-policy">https://legal.hubspot.com/de/privacy-policy</a>.</p>
<h2>Auftragsverarbeitungsvertrag (AVV) HubSpot (Chatbot)</h2>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit HubSpot einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, k&ouml;nnen Sie in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil HubSpot in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass HubSpot Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) finden Sie unter <a href="https://legal.hubspot.com/dpa">https://legal.hubspot.com/dpa</a>.</p>
<h2 id="social-media-einleitung">Social Media Einleitung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Social Media Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Darstellung und Optimierung unserer Serviceleistung, Kontakt zu Besuchern, Interessenten u.a., Werbung<br /> 📓 Verarbeitete Daten: Daten wie etwa Telefonnummern, E-Mail-Adressen, Kontaktdaten, Daten zum Nutzerverhalten, Informationen zu Ihrem Ger&auml;t und Ihre IP-Adresse.<br /> Mehr Details dazu finden Sie beim jeweils eingesetzten Social-Media-Tool.<br /> 📅 Speicherdauer: abh&auml;ngig von den verwendeten Social-Media-Plattformen<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was ist Social Media?</h3>
<p>Zus&auml;tzlich zu unserer Website sind wir auch in diversen Social-Media-Plattformen aktiv. Dabei k&ouml;nnen Daten von Usern verarbeitet werden, damit wir gezielt User, die sich f&uuml;r uns interessieren, &uuml;ber die sozialen Netzwerke ansprechen k&ouml;nnen. Dar&uuml;ber hinaus k&ouml;nnen auch Elemente einer Social-Media-Plattform direkt in unsere Website eingebettet sein. Das ist etwa der Fall, wenn Sie einen sogenannten Social-Button auf unserer Website anklicken und direkt zu unserem Social-Media-Auftritt weitergeleitet werden. Als sogenannte Sozialen Medien oder Social Media werden Websites und Apps bezeichnet, &uuml;ber die angemeldete Mitglieder Inhalte produzieren, Inhalte offen oder in bestimmten Gruppen austauschen und sich mit anderen Mitgliedern vernetzen k&ouml;nnen.</p>
<h3>Warum nutzen wir Social Media?</h3>
<p>Seit Jahren sind Social-Media-Plattformen der Ort, wo Menschen online kommunizieren und in Kontakt treten. Mit unseren Social-Media-Auftritten k&ouml;nnen wir unsere Produkte und Dienstleistungen Interessenten n&auml;herbringen. Die auf unserer Website eingebundenen Social-Media-Elemente helfen Ihnen, schnell und ohne Komplikationen zu unseren Social-Media-Inhalten wechseln k&ouml;nnen.</p>
<p>Die Daten, die durch Ihre Nutzung eines Social-Media-Kanals gespeichert und verarbeitet werden, haben in erster Linie den Zweck, Webanalysen durchf&uuml;hren zu k&ouml;nnen. Ziel dieser Analysen ist es, genauere und personenbezogene Marketing- und Werbestrategien entwickeln zu k&ouml;nnen. Abh&auml;ngig von Ihrem Verhalten auf einer Social-Media-Plattform, k&ouml;nnen mit Hilfe der ausgewerteten Daten, passende R&uuml;ckschl&uuml;sse auf Ihre Interessen getroffen werden und sogenannte Userprofile erstellt werden. So ist es den Plattformen auch m&ouml;glich, Ihnen ma&szlig;geschneiderte Werbeanzeigen zu pr&auml;sentieren. Meistens werden f&uuml;r diesen Zweck Cookies in Ihrem Browser gesetzt, die Daten zu Ihrem Nutzungsverhalten speichern.</p>
<p>Wir gehen in der Regel davon aus, dass wir datenschutzrechtlich verantwortlich bleiben, auch wenn wir Dienste einer Social-Media-Plattform nutzen. Der Europ&auml;ische Gerichtshof hat jedoch entschieden, dass in bestimmten F&auml;llen der Betreiber der Social-Media-Plattform zusammen mit uns gemeinsam verantwortlich im Sinne des Art. 26 DSGVO sein kann. Soweit dies der Fall ist, weisen wir gesondert darauf hin und arbeiten auf Grundlage einer diesbez&uuml;glichen Vereinbarung. Das Wesentliche der Vereinbarung ist dann weiter unten bei der betroffenen Plattform wiedergegeben.</p>
<p>Bitte beachten Sie, dass bei der Nutzung der Social-Media-Plattformen oder unserer eingebauten Elemente auch Daten von Ihnen au&szlig;erhalb der Europ&auml;ischen Union verarbeitet werden k&ouml;nnen, da viele Social-Media-Kan&auml;le, beispielsweise Facebook oder Twitter, amerikanische Unternehmen sind. Dadurch k&ouml;nnen Sie m&ouml;glicherweise Ihre Rechte in Bezug auf Ihre personenbezogenen Daten nicht mehr so leicht einfordern bzw. durchsetzen.</p>
<h3>Welche Daten werden verarbeitet?</h3>
<p>Welche Daten genau gespeichert und verarbeitet werden, h&auml;ngt vom jeweiligen Anbieter der Social-Media-Plattform ab. Aber f&uuml;r gew&ouml;hnlich handelt es sich um Daten wie etwa Telefonnummern, E-Mailadressen, Daten, die Sie in ein Kontaktformular eingeben, Nutzerdaten wie zum Beispiel welche Buttons Sie klicken, wen Sie liken oder wem folgen, wann Sie welche Seiten besucht haben, Informationen zu Ihrem Ger&auml;t und Ihre IP-Adresse. Die meisten dieser Daten werden in Cookies gespeichert. Speziell wenn Sie selbst ein Profil bei dem besuchten Social-Media-Kanal haben und angemeldet sind, k&ouml;nnen Daten mit Ihrem Profil verkn&uuml;pft werden.</p>
<p>Alle Daten, die &uuml;ber eine Social-Media-Plattform erhoben werden, werden auch auf den Servern der Anbieter gespeichert. Somit haben auch nur die Anbieter Zugang zu den Daten und k&ouml;nnen Ihnen die passenden Ausk&uuml;nfte geben bzw. &Auml;nderungen vornehmen.</p>
<p>Wenn Sie genau wissen wollen, welche Daten bei den Social-Media-Anbietern gespeichert und verarbeitet werden und wie sie der Datenverarbeitung widersprechen k&ouml;nnen, sollten Sie die jeweilige Datenschutzerkl&auml;rung des Unternehmens sorgf&auml;ltig durchlesen. Auch wenn Sie zur Datenspeicherung und Datenverarbeitung Fragen haben oder entsprechende Rechte geltend machen wollen, empfehlen wir Ihnen, sich direkt an den Anbieter wenden.</p>
<h3>Dauer der Datenverarbeitung</h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Beispielsweise speichert die Social-Media-Plattform Facebook Daten, bis sie f&uuml;r den eigenen Zweck nicht mehr ben&ouml;tigt werden. Kundendaten, die mit den eigenen Userdaten abgeglichen werden, werden aber schon innerhalb von zwei Tagen gel&ouml;scht. Generell verarbeiten wir personenbezogene Daten nur so lange wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch &uuml;berschritten werden.</p>
<h3>Widerspruchsrecht</h3>
<p>Sie haben auch jederzeit das Recht und die M&ouml;glichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern wie eingebettete Social-Media-Elemente zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel k&ouml;nnen Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder l&ouml;schen.</p>
<p>Da bei Social-Media-Tools Cookies zum Einsatz kommen k&ouml;nnen, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerkl&auml;rung &uuml;ber Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
<h3>Rechtsgrundlage</h3>
<p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grunds&auml;tzlich werden Ihre Daten bei Vorliegen einer Einwilligung auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet. Wir setzen die Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>Informationen zu speziellen Social-Media-Plattformen erfahren Sie &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<h2 id="blogs-und-publikationsmedien-einleitung">Blogs und Publikationsmedien Einleitung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Blogs und Publikationsmedien Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Darstellung und Optimierung unserer Serviceleistung sowie Kommunikation zwischen Websitebesuchern, Sicherheitsma&szlig;nahmen und Verwaltung<br /> 📓 Verarbeitete Daten: Daten wie etwa Kontaktdaten, IP-Adresse und ver&ouml;ffentlichte Inhalte.<br /> Mehr Details dazu finden Sie bei den eingesetzten Tools.<br /> 📅 Speicherdauer: abh&auml;ngig von den verwendeten Tools<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen), Art. 6 Abs. 1 S. 1 lit. b. DSGVO (Vertrag)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was sind Blogs und Publikationsmedien?</h3>
<p>Wir nutzen auf unsere Website Blogs oder auch andere Kommunikationsmittel, mit denen wir einerseits mit Ihnen und andererseits Sie auch mit uns kommunizieren k&ouml;nnen. Dabei k&ouml;nnen auch Daten von Ihnen bei uns gespeichert und verarbeitet werden. Das kann notwendig sein, damit wir Inhalte entsprechend darstellen k&ouml;nnen, die Kommunikation funktioniert und die Sicherheit erh&ouml;ht wird. In unserem Datenschutztext gehen wir allgemein darauf ein, welche Daten von Ihnen verarbeitet werden k&ouml;nnen. Exakte Angaben zur Datenverarbeitung h&auml;ngen stets auch von den verwendeten Tools und Funktionen ab. In den Datenschutzhinweisen der einzelnen Anbieter finden Sie genaue Informationen &uuml;ber die Datenverarbeitung.</p>
<h3>Warum nutzen wir Blogs und Publikationsmedien?</h3>
<p>Unser gr&ouml;&szlig;tes Anliegen mit unserer Website ist Ihnen interessante und spannende Inhalte zu bieten und gleichzeitig sind uns auch Ihre Meinungen und Inhalte wichtig. Darum wollen wir einen guten interaktiven Austausch zwischen uns und Ihnen schaffen. Mit diversen Blogs und Publikationsm&ouml;glichkeiten k&ouml;nnen wir genau das erreichen. Sie k&ouml;nnen beispielsweise Kommentare zu unseren Inhalten verfassen, andere Kommentare kommentieren oder auch in manchen F&auml;llen selbst Beitr&auml;ge verfassen.</p>
<h3>Welche Daten werden verarbeitet?</h3>
<p>Welche Daten genau verarbeitet werden, h&auml;ngt stets von den von uns verwendeten Kommunikationsfunktionen ab. Sehr oft werden IP-Adresse, Benutzername und der ver&ouml;ffentlichte Inhalt gespeichert. Das geschieht in erster Linie, um einen Sicherheitsschutz zu gew&auml;hrleisten, Spam vorzubeugen und um gegen widerrechtliche Inhalte vorgehen zu k&ouml;nnen. F&uuml;r die Datenspeicherung k&ouml;nnen auch Cookies verwendet werden. Das sind kleine Text-Dateien, die mit Informationen in Ihrem Browser abgespeichert werden. N&auml;heres zu den erhobenen und gespeicherten Daten finden Sie in unseren einzelnen Abschnitten und in der Datenschutzerkl&auml;rung des jeweiligen Anbieters.</p>
<h3>Dauer der Datenverarbeitung</h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Beispielsweise speichern Beitrags- und Kommentarfunktionen Daten, bis Sie die Datenspeicherung widerrufen. Generell werden personenbezogene Daten nur so lange, wie es f&uuml;r die Bereitstellung unserer Dienstleistungen unbedingt notwendig ist, gespeichert.</p>
<h3>Widerspruchsrecht</h3>
<p>Sie haben auch jederzeit das Recht und die M&ouml;glichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern von Kommunikations-Tools zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel k&ouml;nnen Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder l&ouml;schen.</p>
<p>Da bei Publikationsmedien auch Cookies zum Einsatz kommen k&ouml;nnen, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerkl&auml;rung &uuml;ber Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
<h3>Rechtsgrundlage</h3>
<p>Wir setzen die Kommunikationsmittel haupts&auml;chlich auf Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO) an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden, Gesch&auml;ftspartnern und Besuchern ein. Soweit die Nutzung der Abwicklung von vertraglichen Beziehungen oder deren Anbahnung dient, ist Rechtsgrundlage ferner Art. 6 Abs. 1 S. 1 lit. b. DSGVO.</p>
<p>Bestimmte Verarbeitungen, insbesondere der Einsatz von Cookies sowie die Nutzung von Kommentar- oder Nachrichtenfunktionen bed&uuml;rfen Ihrer Einwilligung. Wenn und soweit Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Publikationsmedien verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung (Art. 6 Abs. 1 lit. a DSGVO).&nbsp;Die meisten von uns verwendeten Kommunikationsfunktionen setzen Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>Informationen zu speziellen Tools erfahren Sie &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<h2>Wordpress-Emojis Datenschutzerkl&auml;rung</h2>
<p>Wir setzen in unserem Blog auch sogenannte Emojis und Smilies ein. Was Emojis genau sind, brauchen wir wohl hier nicht genauer erkl&auml;ren. Sie kennen diese lachenden, w&uuml;tenden oder traurigen Gesichter. Es sind grafische Elemente bzw. Dateien, die wir zur Verf&uuml;gung stellen und von einem anderen Server geladen werden. Dienstanbieter f&uuml;r den Abruf von WordPress-Emojis und Smilies ist Automattic Inc., 60 29th Street #343, San Francisco, CA 94110, USA. Dieser Drittanbieter speichert Ihre IP-Adresse, um die Emoji-Dateien an Ihren Browser &uuml;bermitteln zu k&ouml;nnen.</p>
<p>WordPress verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;&szlig;igkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (au&szlig;erhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet WordPress sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich WordPress, bei der Verarbeitung Ihrer relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Datenverarbeitungsbedingungen (Data Processing Agreements), welche den Standardvertragsklauseln entsprechen, finden Sie unter <a href="https://wordpress.com/support/data-processing-agreements/">https://wordpress.com/support/data-processing-agreements/</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von Automattic verarbeitet werden, erfahren Sie in der Privacy Policy auf <a href="https://automattic.com/privacy/">https://automattic.com/privacy/</a>.</p>
<h2 id="online-marketing-einleitung">Online-Marketing Einleitung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Online-Marketing Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Auswertung der Besucherinformationen zur Optimierung des Webangebots.<br /> 📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Auch personenbezogene Daten wie Name oder E-Mail-Adresse k&ouml;nnen verarbeitet werden. Mehr Details dazu finden Sie beim jeweils eingesetzten Online-Marketing-Tool.<br /> 📅 Speicherdauer: abh&auml;ngig von den eingesetzten Online-Marketing-Tools<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was ist Online-Marketing?</h3>
<p>Unter Online-Marketing bezeichnet man alle Ma&szlig;nahmen, die online durchgef&uuml;hrt werden, um Marketingziele wie etwa die Steigerung der Markenbekanntheit oder einen Gesch&auml;ftsabschluss zu erreichen. Weiters zielen unserer Online-Marketing-Ma&szlig;nahmen darauf ab, Menschen auf unsere Website aufmerksam zu machen. Um unser Angebot vielen interessierten Menschen zeigen zu k&ouml;nnen, betreiben wir also Online-Marketing. Meistens handelt es sich dabei um Onlinewerbung, Content-Marketing oder Suchmaschinenoptimierung. Damit wir Online-Marketing effizient und zielgerecht einsetzen k&ouml;nnen, werden auch personenbezogene Daten gespeichert und verarbeitet. Die Daten helfen uns einerseits unsere Inhalte nur wirklich jenen Personen zu zeigen, die sich daf&uuml;r auch interessieren und andererseits k&ouml;nnen wir den Werbeerfolg unserer Online-Marketing-Ma&szlig;nahmen messen.</p>
<h3>Warum nutzen wir Online-Marketing-Tools?</h3>
<p>Wir wollen jedem Menschen, der sich f&uuml;r unser Angebot interessiert, unsere Website zeigen. Uns ist bewusst, dass dies ohne bewusst gesetzte Ma&szlig;nahmen nicht m&ouml;glich ist. Darum machen wir Online-Marketing. Es gibt verschiedene Tools, die uns die Arbeit an unseren Online-Marketing-Ma&szlig;nahmen erleichtern und zus&auml;tzlich &uuml;ber Daten stets Verbesserungsvorschl&auml;ge liefern. So k&ouml;nnen wir unsere Kampagnen genauer an unsere Zielgruppe richten. Zweck dieser eingesetzten Online-Marketing-Tools ist also letztlich die Optimierung unseres Angebots.</p>
<h3>Welche Daten werden verarbeitet?</h3>
<p>Damit unser Online-Marketing funktioniert und der Erfolg der Ma&szlig;nahmen gemessen werden kann, werden Userprofile erstellt und Daten beispielsweise in Cookies (das sind kleine Text-Dateien) gespeichert. Mit Hilfe dieser Daten k&ouml;nnen wir nicht nur Werbung in klassischer Hinsicht schalten, sondern auch direkt auf unserer Website, unsere Inhalte so darstellen, wie es Ihnen am liebsten ist. Daf&uuml;r gibt es verschiedene Drittanbieter-Tools, die diese Funktionen anbieten und entsprechend auch Daten von Ihnen sammeln und speichern. In den benannten Cookies wird zum Beispiel gespeichert, welche Webseiten Sie auf unserer Website besucht haben, wie lange Sie diese Seiten angesehen haben, welche Links oder Buttons Sie klicken oder von welcher Website sie zu uns gekommen sind. Zus&auml;tzlich k&ouml;nnen auch technische Informationen gespeichert werden. Etwa Ihre IP-Adresse, welchen Browser Sie nutzen, von welchem Endger&auml;t Sie unsere Website besuchen oder die Uhrzeit, wann Sie unserer Website aufgerufen haben und wann Sie sie wieder verlassen haben. Wenn Sie eingewilligt haben, dass wir auch Ihren Standort bestimmen d&uuml;rfen, k&ouml;nnen wir auch diesen speichern und verarbeiten.</p>
<p>Ihre IP-Adresse wird in pseudonymisierter Form (also gek&uuml;rzt) gespeichert. Eindeutige Daten, die Sie als Person direkt identifizieren, wie etwa Name, Adresse oder E-Mail-Adresse, werden im Rahmen der Werbe- und Online-Marketing-Verfahren auch nur in pseudonymisierter Form gespeichert. Wir k&ouml;nnen Sie also nicht als Person identifizieren, sondern wir haben nur die pseudonymisierten, gespeicherten Informationen in den Userprofilen gespeichert.</p>
<p>Die Cookies k&ouml;nnen unter Umst&auml;nden auch auf anderen Websites, die mit denselben Werbetools arbeiten, eingesetzt, analysiert und f&uuml;r Werbezwecke verwendet werden. Die Daten k&ouml;nnen dann auch auf den Servern der Werbetools-Anbieter gespeichert werden.</p>
<p>In Ausnahmef&auml;llen k&ouml;nnen auch eindeutige Daten (Namen, Mail-Adresse usw.) in den Userprofilen gespeichert werden. Zu dieser Speicherung kommt es etwa, wenn Sie Mitglied eines Social-Media-Kanals sind, dass wir f&uuml;r unsere Online-Marketing-Ma&szlig;nahmen nutzen und das Netzwerk bereits fr&uuml;her eingegangene Daten mit dem Userprofil verbindet.</p>
<p>Bei allen von uns verwendeten Werbetools, die Daten von Ihnen auf deren Servern speichert, erhalten wir immer nur zusammengefasste Informationen und nie Daten, die Sie als Einzelperson erkennbar machen. Die Daten zeigen lediglich, wie gut gesetzte Werbema&szlig;nahmen funktionierten. Zum Beispiel sehen wir, welche Ma&szlig;nahmen Sie oder andere User dazu bewogen haben, auf unsere Website zu kommen und dort eine Dienstleistung oder ein Produkt zu erwerben. Anhand der Analysen k&ouml;nnen wir unser Werbeangebot in Zukunft verbessern und noch genauer an die Bed&uuml;rfnisse und W&uuml;nsche interessierter Personen anpassen.</p>
<h3>Dauer der Datenverarbeitung</h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Daten, die in Cookies gespeichert werden, werden unterschiedlich lange gespeichert.&nbsp;Manche Cookies werden bereits nach dem Verlassen der Website wieder gel&ouml;scht, anderen k&ouml;nnen &uuml;ber einige Jahre in Ihrem Browser gespeichert sein. In den jeweiligen Datenschutzerkl&auml;rungen der einzelnen Anbieter erhalten Sie in der Regel genaue Informationen &uuml;ber die einzelnen Cookies, die der Anbieter verwendet.</p>
<h3>Widerspruchsrecht</h3>
<p>Sie haben auch jederzeit das Recht und die M&ouml;glichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel k&ouml;nnen Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder l&ouml;schen. Die Rechtm&auml;&szlig;igkeit der Verarbeitung bis zum Widerruf bleibt unber&uuml;hrt.</p>
<p>Da bei Online-Marketing-Tools in der Regel Cookies zum Einsatz kommen k&ouml;nnen, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerkl&auml;rung &uuml;ber Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerkl&auml;rungen der jeweiligen Tools durchlesen.</p>
<h3>Rechtsgrundlage</h3>
<p>Wenn Sie eingewilligt haben, dass Drittanbieter eingesetzt werden d&uuml;rfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Online-Marketing-Tools vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Online-Marketing-Ma&szlig;nahmen in anonymisierter Form zu messen, um mithilfe der gewonnenen Daten unser Angebot und unsere Ma&szlig;nahmen zu optimieren. Die daf&uuml;r entsprechende Rechtsgrundlage ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen die Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<p>Informationen zu speziellen Online-Marketing-Tools erhalten Sie &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<h2>Userpilot Datenschutzrichtlinie</h2>
<p>Userpilot ist eine Plattform f&uuml;r Produkterlebnisse, die von Hunderten von Teams genutzt wird, um ihren Nutzern In-App-Erlebnisse zu bieten. Als ein Anbieter, der t&auml;glich Millionen von Datenpunkten verarbeitet, nehmen wir die Daten unserer Kunden und deren Nutzer sehr ernst. Userpilot &uuml;bernimmt die volle Verantwortung f&uuml;r alle Daten, die &uuml;ber die Plattform verarbeitet werden. Unsere Daten werden vollst&auml;ndig verschl&uuml;sselt, verwaltet und von SOC-konformen Anbietern wie Amazon AWS und Google Cloud gespeichert.</p>
<h3>Sicherheit</h3>
<p>Alle Daten, die &uuml;ber die Userpilot-Plattform gehandhabt und verarbeitet werden, sind vollst&auml;ndig gegen unbefugten Zugriff gesichert. Nur autorisierte Userpilot-Mitarbeiter d&uuml;rfen auf diese Daten zugreifen und m&uuml;ssen sich bei jedem Zugriff authentifizieren.</p>
<p>Dar&uuml;ber hinaus haben wir extreme Ma&szlig;nahmen ergriffen, um unbefugten Zugriff, Diebstahl oder Manipulation von Daten zu verhindern.</p>
<p><strong>- Interne Sicherheit</strong></p>
<p>o Datenverschl&uuml;sselung</p>
<p>Alle unsere Projekte werden routinem&auml;&szlig;ig durch Sicherheitstests und regelm&auml;&szlig;ige Penetrationstests mit Sicherheitsanbietern &uuml;berpr&uuml;ft.</p>
<p>Auth-Schl&uuml;ssel werden gehasht, und wir verwenden AWS-Tools zur Verwaltung von Produktionsgeheimnissen.</p>
<p>o Entwurf der Produktsicherheit</p>
<p>Alle unsere Projekte durchlaufen routinem&auml;&szlig;ig Sicherheitstests und regelm&auml;&szlig;ige Penetrationstests mit Hilfe von Sicherheitsanbietern.</p>
<p><strong>- Produktsicherheit</strong></p>
<p>o Kontozugriff, SSO f&uuml;r Unternehmen</p>
<p>o Transparente und feink&ouml;rnige Kontrolle &uuml;ber den Benutzerzugang</p>
<p>o Mehrfaktorielle Authentifizierung (MFA)</p>
<p>MFA bietet eine zus&auml;tzliche Sicherheitsebene. Wenn Sie sich bei Userpilot anmelden, geben Sie zus&auml;tzlich einen Code von Ihrem Mobiltelefon oder .</p>
<p>Dies kann auf Kontoebene f&uuml;r alle Teammitglieder durchgesetzt werden.</p>
<p><strong>- Netzwerk- und Anwendungssicherheit</strong></p>
<p>o Ausfallsicherung und DR</p>
<p>Unsere Infrastruktur und Daten sind &uuml;ber mehrere AWS-Verf&uuml;gbarkeitszonen verteilt und funktionieren auch dann noch, wenn eines dieser Rechenzentren ausf&auml;llt.</p>
<p>o Virtuelle private Cloud</p>
<p>Alle unsere Server befinden sich in unserer eigenen Virtual Private Cloud (VPC) mit Netzwerkzugriffskontrolllisten (ACLs).</p>
<p>o Berechtigungen und Authentifizierung</p>
<p>Der Zugriff auf Kundendaten ist auf autorisierte Mitarbeiter beschr&auml;nkt, die sie f&uuml;r ihre Arbeit ben&ouml;tigen.</p>
<p>o Reaktion auf Vorf&auml;lle</p>
<p>Userpilot implementiert ein Protokoll f&uuml;r den Umgang mit Sicherheitsvorf&auml;llen, das Eskalationsverfahren beinhaltet.</p>
<p><strong>- Zus&auml;tzliche Sicherheitsfunktionen</strong></p>
<p>o Schulung</p>
<p>Alle Mitarbeiter absolvieren Sicherheits- und Awareness-Schulungen.</p>
<p>o Vertraulichkeit</p>
<p>Alle Mitarbeitervertr&auml;ge enthalten eine Vertraulichkeitsvereinbarung.</p>
<p>o PCI-Verpflichtungen</p>
<p>Alle Zahlungen, die an Userpilot get&auml;tigt werden, laufen &uuml;ber Stripe. Details zu den Sicherheitseinstellungen und der PCI-Konformit&auml;t finden Sie auf der Sicherheitsseite von Stripe.</p>
<h3>Verf&uuml;gbarkeit</h3>
<p>Die Architektur der Userpilot-Plattform wurde entwickelt, um maximale Verf&uuml;gbarkeit und Betriebszeit zu gew&auml;hrleisten. Unsere APIs sind vollst&auml;ndig von unserem Produktserver getrennt, und es wurden redundante Failover-Server eingerichtet.</p>
<p>Unsere Infrastruktur wird gr&ouml;&szlig;tenteils &uuml;ber AWS bereitgestellt, mit einem kleinen Anteil in GCP (Google Cloud Platform), um kleinere Komponenten in unserer Userpilot-Anwendung zu betreiben. Unsere Amazon-Infrastruktur befindet sich in von Userpilot verwalteten VPCs (Virtual Private Clouds) und bietet vollst&auml;ndige Isolierung von anderen Instanzen im selben Rechenzentrum.</p>
<p>Au&szlig;erdem werden alle unsere Daten in Echtzeit mit mehreren t&auml;glichen Backups synchronisiert.</p>
<h3>Backups</h3>
<p>Wir f&uuml;hren t&auml;gliche Backups aller Anwendungsdaten an mehreren Standorten durch. Wir stellen sicher, dass jede Instanz vollst&auml;ndig verschl&uuml;sselt und gesichert ist.</p>
<h3>Datenschutz</h3>
<p>Wir verpflichten uns durch Initiativen zum Datenschutz:</p>
<p><strong>- Praxis der Daten&uuml;bertragung</strong></p>
<p>Wir bieten Standardvertragsklauseln f&uuml;r die rechtskonforme &Uuml;bertragung und Speicherung von Nutzerdaten au&szlig;erhalb der EU.</p>
<p><strong>- Datenschutz durch Design</strong></p>
<p>Userpilot verfolgt einen umfassenden Ansatz f&uuml;r Sicherheit und Datenschutz und verkauft keine Nutzerdaten unserer Kunden.</p>
<p><strong>- Aktualisierte Datenschutzrichtlinien</strong></p>
<p>Wir haben unsere Datenschutzrichtlinien aktualisiert, um unserem Engagement f&uuml;r den Datenschutz gerecht zu werden.</p>
<h3>GDPR-Konformit&auml;t mit Userpilot</h3>
<p><strong>- Das Recht auf L&ouml;schung.</strong></p>
<p>Verwenden Sie die HTTP-API von Userpilot, um die Daten eines bestimmten Nutzers oder Unternehmens oder einer Gruppe von Nutzern oder einer Gruppe von Unternehmen aus den Userpilot-Datens&auml;tzen zu l&ouml;schen.</p>
<p>Sehen Sie sich die API-Dokumente f&uuml;r HTTP DELETE [https://docs.userpilot.com/article/189-delete-users-and-companies] an.</p>
<p><strong>- &Uuml;berwachen des L&ouml;schstatus und der L&ouml;schanfragen</strong></p>
<p>Verfolgen Sie den Fortschritt von L&ouml;schanfragen, um zu best&auml;tigen, wann Daten endg&uuml;ltig gel&ouml;scht sind, damit Sie Ihre Benutzer aktualisieren k&ouml;nnen.</p>
<p>Sehen Sie sich die API-Dokumente f&uuml;r die Verfolgung von Hintergrundauftr&auml;gen an. [https://docs.userpilot.com/article/189-delete-users-and-companies]</p>
<p><strong>- Das Recht auf Zugang, &Uuml;bertragbarkeit und Berichtigung</strong></p>
<p>Zusammenstellen von Benutzerdaten f&uuml;r Zugriffs- und &Uuml;bertragbarkeitsanfragen</p>
<p>Exportieren Sie Benutzerdaten in ein offenes Format (CSV), um Daten &uuml;ber einen bestimmten Benutzer zu organisieren, so dass Sie sie auf Anfrage leicht weitergeben k&ouml;nnen.</p>
<h3>SOC2 Typ II-Zertifizierung</h3>
<p>Die SOC 2 Typ II-Zertifizierung wird von einer unabh&auml;ngigen Pr&uuml;fstelle vergeben, um die Sicherheit der Verarbeitung von Kundendaten durch Drittanbieter zu gew&auml;hrleisten. Die Zertifizierung basiert auf 5 "Vertrauensdienstprinzipien": Sicherheit, Verf&uuml;gbarkeit, Integrit&auml;t der Verarbeitung, Vertraulichkeit und Datenschutz.</p>
<p>Dank SOC2 Typ II k&ouml;nnen Sie nun sicher sein, dass Userpilot:</p>
<p>- Zugriffskontrolle durch Ende-zu-Ende-Verschl&uuml;sselung und Zwei-Faktor-Authentifizierung hat. Mehr &uuml;ber die eingesetzten Technologien zur Gew&auml;hrleistung der Datensicherheit erfahren Sie in einem Bericht, der von einem renommierten, unabh&auml;ngigen Wirtschaftspr&uuml;fer erstellt wurde.</p>
<p>- verwendet Netzwerk- und Anwendungs-Firewalls</p>
<p>- verf&uuml;gt &uuml;ber Mechanismen zur Erkennung von Eindringlingen</p>
<p>- Tools zur Leistungs&uuml;berwachung einsetzt</p>
<p>- Werkzeuge f&uuml;r die Notfallwiederherstellung verwendet</p>
<p>- &uuml;ber Verfahren zur Behandlung von Sicherheitsvorf&auml;llen verf&uuml;gt</p>
<p>- Qualit&auml;tssicherungs- und Prozess&uuml;berwachungsverfahren einsetzt</p>
<p>Aktuelle und potentielle Kunden von Userpilot k&ouml;nnen sich nun &uuml;ber die Qualit&auml;t der Datenschutzmechanismen sicher sein und erfahren alle Details aus dem SOC 2 Bericht, der auf Anfrage unter security@userpilot.co erh&auml;ltlich ist.</p>
<h3>Bug Bounty Programm</h3>
<p>Bei Userpilot nehmen wir User Experience und Sicherheit sehr ernst. Wir bieten finanzielle Belohnungen f&uuml;r Fehlerberichte, die uns helfen, unser Produkt zu verbessern. Wenn Sie Bugs, potenzielle Sicherheitsprobleme oder technische Fehler entdecken, wenden Sie sich bitte an security@userpilot.co. Die Meldungen werden individuell auf ihre Eignung f&uuml;r eine finanzielle Belohnung gepr&uuml;ft. Es gelten die allgemeinen Gesch&auml;ftsbedingungen.</p>
<h3>KONTAKT</h3>
<p>Um Probleme zu melden oder weitere Informationen anzufordern, senden Sie uns bitte eine E-Mail an security@userpilot.co.</p>
<h2>Userpilot Privacy Policy</h2>
<p>Userpilot is a product experience platform that is used by hundreds of teams to deliver in-app experiences to their users. As a vendor that processes millions of data points on a daily basis, we take our customers and their users&rsquo; data very seriously. Userpilot takes full responsibility for all data that's processed through the platform. Our data is fully encrypted, managed, and stored by SOC-compliant vendors such as Amazon AWS and Google Cloud.</p>
<h3>Security</h3>
<p>All data that is handled and processed through the Userpilot platform is fully secured against unauthorized access. Only authorized Userpilot personnel are allowed to access such data and are required to authenticate themselves whenever such data is accessed.</p>
<p>We have also taken extreme measures to prevent unauthorized access, theft or manipulation of data.</p>
<h5>&middot; Internal security</h5>
<ul>
<li>Data encryption
<ul>
<li>All our projects are run routinely through security tests and regular penetration tests using security vendors.</li>
<li>Auth keys are hashed, and we use AWS tools to manage production secrets</li>
</ul>
</li>
<li>Product security design
<ul>
<li>All our projects are run routinely through security tests and regular penetration tests using security vendors.</li>
</ul>
</li>
</ul>
<h5>&middot; Product security</h5>
<ul>
<li>Account access, SSO for enterprise</li>
<li>Transparent and fine grained control over user access</li>
<li>Multi Factor Authentication (MFA)
<ul>
<li>MFA provides an additional layer of security. When logging into Userpilot, you&rsquo;ll also enter a code from your mobile phone or .</li>
<li>Can be enforced on account level for all team members.</li>
</ul>
</li>
</ul>
<h5>&middot; Network and application security</h5>
<ul>
<li>Failover and DR
<ul>
<li>Our infrastructure and data are spread across Multi AWS availability zones and will continue to work should any one of those data centers fail.</li>
</ul>
</li>
<li>Virtual Private Cloud
<ul>
<li>All of our servers are within our own virtual private cloud (VPC) with network access control lists (ACLs).</li>
</ul>
</li>
<li>Permissions and Authentication
<ul>
<li>Access to customer data is limited to authorized employees who require it for their job.</li>
</ul>
</li>
<li>Incident Response
<ul>
<li>Userpilot implements a protocol for handling security events which includes escalation procedures.</li>
</ul>
</li>
</ul>
<h5>&middot; Additional Security features</h5>
<ul>
<li>Training
<ul>
<li>All employees complete Security and Awareness training.</li>
</ul>
</li>
<li>Confidentiality
<ul>
<li>All employee contracts include a confidentiality agreement.</li>
</ul>
</li>
<li>PCI Obligations
<ul>
<li>All payments made to Userpilot go through Stripe. Details about their security setup and PCI compliance can be found at Stripe&rsquo;s security page.</li>
</ul>
</li>
</ul>
<h3>Availability</h3>
<p>The Userpilot platform architecture was built to ensure maximum accessibility and uptime. Our APIs are fully separate from our product server, and redundant failover servers are set in place.</p>
<p>Our infrastructure is largely provisioned across AWS, with a small footprint in GCP (Google Cloud Platform), in order to power smaller components in our Userpilot application. Our Amazon infrastructure is contained within Userpilot managed VPCs (Virtual Private Clouds) and provides total isolation from other instances in the same datacenter.</p>
<p>Moreover, all of our data is synced in real-time with multiple backups on a daily basis.</p>
<h3>Backups</h3>
<p>We perform daily backups of all application data in multiple locations. We ensure every instance is fully encrypted and secured.</p>
<h3>Privacy</h3>
<p>Commitment to data privacy through initiatives:</p>
<h5>&middot; Data Transfer Practice</h5>
<p>We offer Standard Contractual Clauses for compliant user data transfer and storage outside of the EU.</p>
<h5>&middot; Privacy By Design</h5>
<p>Userpilot takes a comprehensive approach to security and privacy, and does not sell our customers' user data.</p>
<h5>&middot; Updated Privacy Policy</h5>
<p>We updated our Privacy Policy to honor our commitment to privacy.</p>
<h3>GDPR compliance with Userpilot</h3>
<h5>&middot; The right of erasure.</h5>
<p>Use Userpilot&rsquo;s HTTP API to delete a specified user&rsquo;s or company's data or a group of users, or a group of companies from Userpilot records.</p>
<p>View the API docs for HTTP DELETE [https://docs.userpilot.com/article/189-delete-users-and-companies].</p>
<h5>&middot; Monitor deletion status and requests</h5>
<p>Track the progress of deletion requests to confirm when data is finally deleted, so you can update your users.</p>
<p>View the API docs for background jobs tracking. [https://docs.userpilot.com/article/189-delete-users-and-companies]</p>
<h5>&middot; The rights of access, portability, and rectification</h5>
<p>Compile user data for access and portability requests</p>
<p>Export user data to open format (CSV) to organize data about a given user, so you can easily share it if requested.</p>
<h3>SOC2 Type II Certification</h3>
<p>SOC 2 Type II certification is awarded by an independent auditing body to ensure the security of client data processing by 3rd party service providers. The certification is based on 5 &ldquo;trust service principles&rdquo;: security, availability, processing integrity, confidentiality, and privacy</p>
<p>Thanks to SOC2 Type II, you can now be confident that Userpilot:</p>
<ul>
<li>has access control via end-to-end encryption and two-factor authentication. You can learn more about the technologies used to ensure data security in a report issued by a reputable, independent auditor.</li>
<li>uses network and application firewalls</li>
<li>has intrusion-detection mechanisms in place</li>
<li>uses performance monitoring tools</li>
<li>uses disaster recovery tools</li>
<li>has security incident handling procedures in place</li>
<li>uses quality assurance and process monitoring procedures</li>
</ul>
<p>Current and potential customers of Userpilot can now be sure about its data protection mechanisms quality and can learn all the details from the SOC 2 report, available upon request from security@userpilot.co</p>
<h3>Bug Bounty Program</h3>
<p>At Userpilot, we take user experience and security extremely seriously. We offer monetary rewards for bug reports that help us improve our product experience. If you spot any bugs, potential security issues, or technical errors - please contact security@userpilot.co. Reports will be investigated individually alongside their eligibility for the financial reward. Terms and conditions apply.</p>
<h4>CONTACT</h4>
<p>To report any issues or request more information, please drop us an email at security@userpilot.co.</p>
<h2>Appcues Datenschutzerkl&auml;rung</h2>
<p>Wir verwenden auf unserer Website Appcues, ein Personalisierungstool f&uuml;r Produkterfahrungen. Dienstanbieter ist das amerikanische Unternehmen Appcues, Inc., 177 Huntington Ave Ste 1703, PMB 94414, Boston, MA 02115, USA.</p>
<p>Appcues verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;&szlig;igkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (au&szlig;erhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Appcues sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Appcues, bei der Verarbeitung Ihrer relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Mehr Informationen zu den Standardvertragsklauseln und &uuml;ber die Daten, die durch die Verwendung von Appcues verarbeitet werden, erfahren Sie in der Privacy Policy auf <a href="https://www.appcues.com/privacy">https://www.appcues.com/privacy</a>.</p>
<h2>Google Ads (Google AdWords) Conversion-Tracking Datenschutzerkl&auml;rung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Google Ads (Google AdWords) Conversion-Tracking Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: wirtschaftlicher Erfolg und die Optimierung unserer Serviceleistung.<br /> 📓 Verarbeitete Daten: Zugriffsstatistiken, die Daten wie Standorte der Zugriffe, Ger&auml;tedaten, Zugriffsdauer und Zeitpunkt, Navigationsverhalten, Klickverhalten und IP-Adressen enthalten. Auch personenbezogene Daten wie Name oder E-Mail-Adresse k&ouml;nnen verarbeitet werden.<br /> 📅 Speicherdauer: Conversion-Cookies laufen in der Regel nach 30 Tagen ab und &uuml;bermitteln keine personenbezogenen Daten<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was ist Google Ads Conversion-Tracking?</h3>
<p>Wir verwenden als Online-Marketing-Ma&szlig;nahme Google Ads (fr&uuml;her Google AdWords), um unsere Produkte und Dienstleistungen zu bewerben. So wollen wir im Internet mehr Menschen auf die hohe Qualit&auml;t unserer Angebote aufmerksam machen. Im Rahmen unserer Werbe-Ma&szlig;nahmen durch Google Ads verwenden wir auf unserer Website das Conversion-Tracking der Firma Google Inc. In Europa ist allerdings f&uuml;r alle Google-Dienste das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Mithilfe dieses kostenlosen Tracking-Tools k&ouml;nnen wir unser Werbeangebot an Ihre Interessen und Bed&uuml;rfnisse besser anpassen. Im Folgenden Artikel wollen wir genauer darauf eingehen, warum wir Conversion-Tracking benutzen, welche Daten dabei gespeichert werden und wie Sie diese Datenspeicherung verhindern.</p>
<p>Google Ads (fr&uuml;her Google AdWords) ist das hauseigene Online-Werbesystem der Firma Google Inc. Wir sind von der Qualit&auml;t unseres Angebots &uuml;berzeugt und wollen, dass so viele Menschen wie m&ouml;glich unsere Webseite kennenlernen. Im Onlinebereich bietet Google Ads daf&uuml;r die beste Plattform. Nat&uuml;rlich wollen wir auch einen genauen &Uuml;berblick &uuml;ber den Kosten-Nutzen-Faktor unserer Werbeaktionen gewinnen. Darum verwenden wir das Conversion-Tracking-Tool von Google Ads.</p>
<p>Doch was ist eine Conversion eigentlich? Eine Conversion entsteht, wenn Sie von einem rein interessierten Webseitenbesucher zu einem handelnden Besucher werden. Dies passiert immer dann, wenn Sie auf unsere Anzeige klicken und im Anschluss eine andere Aktion ausf&uuml;hren, wie zum Beispiel unsere Webseite besuchen.&nbsp;Mit dem Conversion-Tracking-Tool von Google erfassen wir, was nach einem Klick eines Users auf unsere Google Ads-Anzeige geschieht. Zum Beispiel k&ouml;nnen wir so sehen, ob Produkte gekauft werden, Dienstleistungen in Anspruch genommen werden oder ob sich User f&uuml;r unseren Newsletter angemeldet haben.</p>
<h3>Warum verwenden wir Google Ads Conversion-Tracking auf unserer Website?</h3>
<p>Wir setzen Google Ads ein, um auch auf anderen Webseiten auf unser Angebot aufmerksam zu machen. Ziel ist es, dass unsere Werbekampagnen wirklich auch nur jene Menschen erreichen, die sich f&uuml;r unsere Angebote interessieren. Mit dem Conversion-Tracking Tool sehen wir welche Keywords, Anzeigen, Anzeigengruppen und Kampagnen zu den gew&uuml;nschten Kundenaktionen f&uuml;hren.&nbsp;Wir sehen wie viele Kunden mit unseren Anzeigen auf einem Ger&auml;t interagieren und dann eine Conversion durchf&uuml;hren. Durch diese Daten k&ouml;nnen wir unseren&nbsp;Kosten-Nutzen-Faktor berechnen,&nbsp;den Erfolg einzelner Werbema&szlig;nahmen messen und folglich unsere Online-Marketing-Ma&szlig;nahmen optimieren.&nbsp;Wir k&ouml;nnen weiters mithilfe der gewonnenen Daten unsere Webseite f&uuml;r Sie interessanter gestalten und unser Werbeangebot noch individueller an Ihre Bed&uuml;rfnisse anpassen.</p>
<h3>Welche Daten werden bei Google Ads Conversion-Tracking gespeichert?</h3>
<p>Wir haben ein Conversion-Tracking-Tag oder Code-Snippet auf unserer Webseite eingebunden, um gewisse User-Aktionen besser analysieren zu k&ouml;nnen. Wenn Sie nun eine unserer Google Ads-Anzeigen anklicken, wird auf Ihrem Computer (meist im Browser) oder Mobilger&auml;t das Cookie &bdquo;Conversion&ldquo; von einer Google-Domain gespeichert. Cookies sind kleine Textdateien, die Informationen auf Ihrem Computer speichern.</p>
<p>Hier die Daten der wichtigsten Cookies f&uuml;r das Conversion-Tracking von Google:</p>
<p><strong>Name:</strong> Conversion<br /><strong>Wert:</strong>&nbsp;EhMI_aySuoyv4gIVled3Ch0llweVGAEgt-mr6aXd7dYlSAGQ121865447-3<br /><strong>Verwendungszweck: </strong>Dieses Cookie speichert jede Conversion, die Sie auf unserer Seite machen, nachdem Sie &uuml;ber eine Google Ad zu uns gekommen sind.<br /><strong>Ablaufdatum:</strong> nach 3 Monaten</p>
<p><strong>Name:</strong> _gac<br /><strong>Wert:</strong>&nbsp;1.1558695989.EAIaIQobChMIiOmEgYO04gIVj5AYCh2CBAPrEAAYASAAEgIYQfD_BwE<br /><strong>Verwendungszweck:</strong>&nbsp;Dies ist ein klassisches Google Analytics-Cookie und dient dem Erfassen verschiedener Handlungen auf unserer Webseite.<br /><strong>Ablaufdatum:</strong> nach 3 Monaten</p>
<p><strong>Anmerkung:</strong> Das Cookie _gac scheint nur in Verbindung mit Google Analytics auf. Die oben angef&uuml;hrte Aufz&auml;hlung hat keinen Anspruch auf Vollst&auml;ndigkeit, da Google f&uuml;r analytische Auswertung immer wieder auch andere Cookies verwendet.</p>
<p>Sobald Sie eine Aktion auf unserer Webseite abschlie&szlig;en, erkennt Google das Cookie und speichert Ihre Handlung als sogenannte Conversion. Solange Sie auf unserer Webseite surfen und das Cookie noch nicht abgelaufen ist, erkennen wir und Google, dass Sie &uuml;ber unsere Google-Ads-Anzeige zu uns gefunden haben. Das Cookie wird ausgelesen und mit den Conversion-Daten zur&uuml;ck an Google Ads gesendet. Es ist auch m&ouml;glich, dass noch andere Cookies zur Messung von Conversions verwendet werden. Das Conversion-Tracking von Google Ads kann mithilfe von Google Analytics noch verfeinert und verbessert werden. Bei Anzeigen, die Google an verschiedenen Orten im Web anzeigt, werden unter unserer Domain m&ouml;glicherweise Cookies mit dem Namen &ldquo;__gads&rdquo; oder &ldquo;_gac&rdquo; gesetzt. Seit September 2017 werden diverse Kampagneninformationen von analytics.js mit dem _gac-Cookie gespeichert. Das Cookie speichert diese Daten, sobald Sie eine unserer Seiten aufrufen, f&uuml;r die die automatische Tag-Kennzeichnung von Google Ads eingerichtet wurde. Im Gegensatz zu Cookies, die f&uuml;r Google-Domains gesetzt werden, kann Google diese Conversion-Cookies nur lesen, wenn Sie sich auf unserer Webseite befinden. Wir erheben und erhalten keine personenbezogenen Daten. Wir bekommen von Google einen Bericht mit statistischen Auswertungen. So erfahren wir beispielsweise die Gesamtanzahl der User, die unsere Anzeige angeklickt haben und wir sehen, welche Werbema&szlig;nahmen gut ankamen.</p>
<h3>Wie lange und wo werden die Daten gespeichert?</h3>
<p>An dieser Stelle wollen wir darauf hinweisen, dass wir keinen Einfluss darauf haben, wie Google die erhobenen Daten weiterverwendet. Laut Google werden die Daten verschl&uuml;sselt und auf sicheren Servern gespeichert. In den meisten F&auml;llen laufen Conversion-Cookies nach 30 Tagen ab und &uuml;bermitteln keine personenbezogenen Daten. Die Cookies mit dem Namen &bdquo;Conversion&ldquo; und &bdquo;_gac&ldquo; (das in Verbindung mit Google Analytics zum Einsatz kommt) haben ein Ablaufdatum von 3 Monaten.</p>
<h3>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</h3>
<p>Sie haben die M&ouml;glichkeit am Conversion-Tracking von Google Ads nicht teilzunehmen. Wenn Sie das Cookie des Google Conversion-Trackings &uuml;ber Ihren Browser deaktivieren, blockieren Sie das Conversion-Tracking. In diesem Fall werden Sie in der Statistik des Tracking-Tools nicht ber&uuml;cksichtigt. Sie k&ouml;nnen die Cookie-Einstellungen in Ihrem Browser jederzeit ver&auml;ndern. Bei jedem Browser funktioniert dies etwas anders. Unter dem Abschnitt &bdquo;Cookies&ldquo; finden Sie die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<p>Falls Sie grunds&auml;tzlich keine Cookies haben wollen, k&ouml;nnen Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So k&ouml;nnen Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Durch das Herunterladen und Installieren dieses Browser-Plug-ins auf <a href="https://support.google.com/ads/answer/7395996">https://support.google.com/ads/answer/7395996</a> werden ebenfalls alle &bdquo;Werbecookies&ldquo; deaktiviert. Bedenken Sie, dass Sie durch das Deaktivieren dieser Cookies nicht die Werbeanzeigen verhindern, sondern nur die personalisierte Werbung.</p>
<h3>Rechtsgrundlage</h3>
<p>Wenn Sie eingewilligt haben, dass das Google Ads Conversion Tracking eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch das Google Ads Conversion Tracking vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, das Google Ads Conversion Tracking zu verwenden, um unser Online-Service und unsere Marketingma&szlig;nahmen zu optimieren. Die daf&uuml;r entsprechende Rechtsgrundlage ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen das Google Ads Conversion Tracking gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;&szlig;igkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (au&szlig;erhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Datenverarbeitungsbedingungen f&uuml;r Google Werbeprodukte (Google Ads Controller-Controller Data Protection Terms), welche den Standardvertragsklauseln entsprechen und auch f&uuml;r Google Ads geltend sind, finden Sie unter <a href="https://business.safety.google/adscontrollerterms/">https://business.safety.google/adscontrollerterms/</a>.</p>
<p>Wenn Sie N&auml;heres &uuml;ber den Datenschutz bei Google erfahren m&ouml;chten, empfehlen wir die allgemeine Datenschutzerkl&auml;rung von Google: <a href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>.</p>
<h2>HubSpot Datenschutzerkl&auml;rung</h2>
<p>Wir verwenden auf unserer Website HubSpot, ein Tool f&uuml;r digitales Marketing. Dienstanbieter ist das amerikanische Unternehmen HubSpot, Inc., 25 First St 2nd Floor Cambridge, MA, USA. Das Unternehmen hat unter anderem auch in Irland einen Firmensitz mit der Adresse 1 Sir John Rogerson&rsquo;s Quay, Dublin 2, Irland.</p>
<p>HubSpot verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;&szlig;igkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (au&szlig;erhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet HubSpot sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich HubSpot, bei der Verarbeitung Ihrer relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a></p>
<p>Die Datenverarbeitungsbedingung (Data Processing Agreement), welche den Standardvertragsklauseln entspricht, finden Sie unter <a href="https://legal.hubspot.com/dpa">https://legal.hubspot.com/dpa</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von HubSpot verarbeitet werden, erfahren Sie in der Privacy Policy auf <a href="https://legal.hubspot.com/de/privacy-policy">https://legal.hubspot.com/de/privacy-policy</a><u>. </u></p>
<h2>Auftragsverarbeitungsvertrag (AVV) HubSpot</h2>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit HubSpot einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, k&ouml;nnen Sie in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil HubSpot in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass HubSpot Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) finden Sie unter <a href="https://legal.hubspot.com/dpa">https://legal.hubspot.com/dpa</a>.</p>
<h2>OptiMonk Datenschutzerkl&auml;rung</h2>
<p>Wir verwenden auf unserer Website OptiMonk, ein Web-Optimierungstool f&uuml;r Onlineshops. Dienstanbieter ist das ungarische Unternehmen Optimonk International Zrt. Address. Kassai &uacute;t 129. Debrecen, 4028. Ungarn. Mehr &uuml;ber die Daten, die durch die Verwendung von OptiMonk verarbeitet werden, erfahren Sie in der Privacy Policy auf <a href="https://www.optimonk.com/privacy-policy/">https://www.optimonk.com/privacy-policy/</a>.</p>
<h2 id="content-delivery-networks-einleitung">Content Delivery Networks Einleitung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Content Delivery Networks Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung (um die Website schneller laden zu k&ouml;nnen)<br /> 📓 Verarbeitete Daten: Daten wie etwa Ihre IP-Adresse<br /> Mehr Details dazu finden Sie weiter unten und den einzelnen Datenschutztexten.<br /> 📅 Speicherdauer: meisten werden die Daten solange gespeichert, bis sie zur Erf&uuml;llung der Dienstleistung nicht mehr ben&ouml;tigt werden<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was ist ein Content Delivery Network?</h3>
<p>Wir nutzen auf unserer Website ein sogenanntes Content Delivery Network. Meistens wird ein solchen Netzwerk nur CDN genannt. Ein CDN hilft uns, unsere Website, unabh&auml;ngig von Ihrem Standort, schnell und problemlos zu laden. Dabei werden auch personenbezogene Daten von Ihnen auf den Servern des eingesetzten CDN-Anbieters gespeichert, verwaltet und verarbeitet. Nachfolgend gehen wir allgemein n&auml;her auf den Dienst und dessen Datenverarbeitung ein. Genaue Informationen &uuml;ber den Umgang mit Ihren Daten finden Sie in der jeweiligen Datenschutzerkl&auml;rung des Anbieters.</p>
<p>Jedes Content Delivery Network (CDN) ist ein Netzwerk regional verteilter Server, die alle &uuml;ber das Internet miteinander verbunden sind. &Uuml;ber dieses Netzwerk k&ouml;nnen Inhalte von Websites (speziell auch sehr gro&szlig;e Dateien) auch bei gro&szlig;en Lastspitzen schnell und reibungslos ausgeliefert werden. Das CDN legt daf&uuml;r eine Kopie unserer Website auf Ihren Servern an. Da diese Server weltweit verteilt sind, kann die Website schnell ausgeliefert werden. Die Daten&uuml;bertragung zu Ihrem Browser wird folglich durch das CDN deutlich verk&uuml;rzt.</p>
<h3>Warum verwenden wir ein Content Delivery Network f&uuml;r unsere Website?</h3>
<p>Eine schnell ladende Website ist Teil unserer Dienstleistung. Wir wissen nat&uuml;rlich, wie nervig es ist, wenn eine Website im Schneckentempo l&auml;dt. Meist verliert man sogar die Geduld und sucht das Weite, bevor die Website vollst&auml;ndig geladen ist. Das wollen wir nat&uuml;rlich vermeiden. Daher geh&ouml;rt eine schnell ladende Website ganz selbstverst&auml;ndlich zu unserem Websiteangebot. Mit einem Content Delivery Network wird in Ihrem Browser unsere Website deutlich schneller geladen. Besonders hilfreich ist der Einsatz des CDNs wenn Sie im Ausland sind, weil die Website von einem Server in Ihrer N&auml;he ausgeliefert wird.</p>
<h3>Welche Daten werden verarbeitet?</h3>
<p>Wenn Sie eine Website bzw. die Inhalte einer Website anfordern und diese in einem CDN zwischengespeichert sind, leitet das CDN die Anforderung an den von Ihnen am n&auml;chsten gelegenen Server und dieser liefert die Inhalte aus. Content Delivery Networks sind so aufgebaut, dass JavaScript-Bibliotheken heruntergeladen werden k&ouml;nnen und auf npm- und Github-Servern gehostet werden. Alternativ k&ouml;nnen bei den meisten CDNs auch WordPress-Plugins geladen werden, wenn diese auf <a href="https://wordpress.org/">WordPress.org</a> gehostet werden. Ihr Browser kann personenbezogene Daten an das von uns eingesetzte Content Delivery Network senden. Dabei handelt es sich etwa um Daten wie IP-Adresse, Browsertyp, Browserversion, welche Webseite geladen wird oder Uhrzeit und Datum des Seitenbesuchs. Diese Daten werden vom CDN gesammelt und auch gespeichert. Ob zur Datenspeicherung Cookies verwendet werden, h&auml;ngt von dem eingesetzten Network ab. Bitte lesen Sie sich daf&uuml;r die Datenschutztexte des jeweiligen Dienstes durch.</p>
<h3>Widerspruchsrecht</h3>
<p>Falls Sie diese Daten&uuml;bertragung vollkommen unterbinden wollen, k&ouml;nnen Sie einen JavaScript-Blocker (siehe beispielsweise <a href="https://noscript.net/">https://noscript.net/</a>) auf Ihrem PC installieren. Nat&uuml;rlich kann dann unsere Website nicht mehr das gewohnte Service (wie beispielsweise eine schnelle Ladegeschwindigkeit) bieten.</p>
<h3>Rechtsgrundlage</h3>
<p>Wenn Sie eingewilligt haben, dass ein Content Delivery Network eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut <strong>Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage f&uuml;r die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch ein Content Delivery Network vorkommen kann, dar.</p>
<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, ein Content Delivery Network zu verwenden, um unser Online-Service zu optimieren und sicherer zu machen. Die daf&uuml;r entsprechende Rechtsgrundlage ist <strong>Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen das Tool gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<p>Informationen zu speziellen Content Delivery Network erhalten Sie &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<h2>DigitalOcean CDN Datenschutzerkl&auml;rung</h2>
<p>Wir nutzen f&uuml;r unsere Website DigitalOcean CDN, unter anderem ein Content-Delivery-Network. Dienstanbieter ist das amerikanische Unternehmen DigitalOcean LLC, New York, NY, 101 6th Ave, USA.</p>
<p>DigitalOcean CDN verarbeitet Daten von Ihnen u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;&szlig;igkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (au&szlig;erhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet DigitalOcean CDN sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses &ndash; SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europ&auml;ischen Datenschutzstandards entsprechen, wenn diese in Drittl&auml;nder (wie beispielsweise in die USA) &uuml;berliefert und dort gespeichert werden. Durch diese Klauseln verpflichtet sich DigitalOcean CDN, bei der Verarbeitung Ihrer relevanten Daten, das europ&auml;ische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de.</a></p>
<p>Die DigitalOcean CDN Datenverarbeitungsbedingung (Data Protection Agreement), welche den Standardvertragsklauseln entspricht, finden Sie unter <a href="https://www.digitalocean.com/legal/data-processing-agreement">https://www.digitalocean.com/legal/data-processing-agreement</a>.</p>
<p>Mehr &uuml;ber die Daten, die durch die Verwendung von DigitalOcean CDN verarbeitet werden, erfahren Sie in der Datenschutzerkl&auml;rung auf <a href="https://www.digitalocean.com/legal">https://www.digitalocean.com/legal</a>.</p>
<h2>Auftragsverarbeitungsvertrag (AVV) DigitalOcean CDN</h2>
<p>Wir haben im Sinne des Artikels 28 der Datenschutz-Grundverordnung (DSGVO) mit DigitalOcean einen Auftragsverarbeitungsvertrag (AVV) abgeschlossen. Was ein AVV genau ist und vor allem was in einem AVV enthalten sein muss, k&ouml;nnen Sie in unserem allgemeinen Abschnitt &bdquo;Auftragsverarbeitungsvertrag (AVV)&ldquo; nachlesen.</p>
<p>Dieser Vertrag ist gesetzlich vorgeschrieben, weil DigitalOcean in unserem Auftrag personenbezogene Daten verarbeitet. Darin wird gekl&auml;rt, dass DigitalOcean Daten, die sie von uns erhalten, nur nach unserer Weisung verarbeiten darf und die DSGVO einhalten muss. Den Link zum Auftragsverarbeitungsvertrag (AVV) finden Sie unter <a href="https://www.digitalocean.com/legal/data-processing-agreement">https://www.digitalocean.com/legal/data-processing-agreement</a>.</p>
<h2 id="audio-video-einleitung">Audio &amp; Video Einleitung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Audio &amp; Video Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten zum Nutzerverhalten, Informationen zu Ihrem Ger&auml;t und Ihre IP-Adresse k&ouml;nnen gespeichert werden.<br /> Mehr Details dazu finden Sie weiter unten in den entsprechenden Datenschutztexten.<br /> 📅 Speicherdauer: Daten bleiben grunds&auml;tzlich gespeichert, solange sie f&uuml;r den Dienstzweck n&ouml;tig sind<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was sind Audio- und Videoelemente?</h3>
<p>Wir haben auf unsere Website Audio- bzw. Videoelemente eingebunden, damit Sie sich direkt &uuml;ber unsere Website etwa Videos ansehen oder Musik/Podcasts anh&ouml;ren k&ouml;nnen. Die Inhalte werden von Dienstanbietern zur Verf&uuml;gung gestellt. Alle Inhalte werden also auch von den entsprechenden Servern der Anbieter bezogen.</p>
<p>Es handelt sich dabei um eingebundene Funktionselemente von Plattformen wie etwa YouTube, Vimeo oder Spotify. Die Nutzung dieser Portale ist in der Regel kostenlos, es k&ouml;nnen aber auch kostenpflichtige Inhalte ver&ouml;ffentlicht werden. Mit Hilfe dieser eingebundenen Elemente k&ouml;nne Sie sich &uuml;ber unsere Website die jeweiligen Inhalte anh&ouml;ren oder ansehen.</p>
<p>Wenn Sie Audio- oder Videoelemente auf unsere Website verwenden, k&ouml;nnen auch personenbezogene Daten von Ihnen an die Dienstanbieter &uuml;bermittelt, verarbeitet und gespeichert werden.</p>
<h3>Warum verwenden wir Audio- &amp; Videoelemente auf unserer Website?</h3>
<p>Nat&uuml;rlich wollen wir Ihnen auf unserer Website das beste Angebot liefern. Und uns ist bewusst, dass Inhalte nicht mehr blo&szlig; in Text und statischem Bild vermittelt werden. Statt Ihnen einfach nur einen Link zu einem Video zu geben, bieten wir Ihnen direkt auf unserer Website Audio- und Videoformate, die unterhaltend oder informativ und im Idealfall sogar beides sind. Das erweitert unser Service und erleichtert Ihnen den Zugang zu interessanten Inhalten. Somit bieten wir neben unseren Texten und Bildern auch Video und/oder Audio-Inhalte an.</p>
<h3>Welche Daten werden durch Audio- &amp; Videoelemente gespeichert?</h3>
<p>Wenn Sie eine Seite auf unserer Website aufrufen, die beispielsweise ein eingebettetes Video hat, verbindet sich Ihr Server mit dem Server des Dienstanbieters. Dabei werden auch Daten von Ihnen an den Drittanbieter &uuml;bertragen und dort gespeichert. Manche Daten werden ganz unabh&auml;ngig davon, ob Sie bei dem Drittanbieter ein Konto haben oder nicht, gesammelt und gespeichert. Dazu z&auml;hlen meist Ihre IP-Adresse, Browsertyp, Betriebssystem, und weitere allgemeine Informationen zu Ihrem Endger&auml;t. Weiters werden von den meisten Anbietern auch Informationen &uuml;ber Ihre Webaktivit&auml;t eingeholt. Dazu z&auml;hlen etwa Sitzungsdauer, Absprungrate, auf welchen Button Sie geklickt haben oder &uuml;ber welche Website Sie den Dienst nutzen. All diese Informationen werden meist &uuml;ber Cookies oder Pixel-Tags (auch Web Beacon genannt) gespeichert. Pseudonymisierte Daten werden meist in Cookies in Ihrem Browser gespeichert. Welche Daten genau gespeichert und verarbeitet werden, erfahren Sie stets in der Datenschutzerkl&auml;rung des jeweiligen Anbieters.</p>
<h3>Dauer der Datenverarbeitung</h3>
<p>Wie lange die Daten auf den Servern der Drittanbieter genau gespeichert werden, erfahren Sie entweder weiter unten im Datenschutztext des jeweiligen Tools oder in der Datenschutzerkl&auml;rung des Anbieters. Grunds&auml;tzlich werden personenbezogene Daten immer nur so lange verarbeitet, wie es f&uuml;r die Bereitstellung unserer Dienstleistungen oder Produkte unbedingt n&ouml;tig wird. Dies gilt in der Regel auch f&uuml;r Drittanbieter. Meist k&ouml;nnen Sie davon ausgehen, dass gewisse Daten &uuml;ber mehrere Jahre auf den Servern der Drittanbieter gespeichert werden. Daten k&ouml;nnen speziell in Cookies unterschiedlich lange gespeichert werden. Manche Cookies werden bereits nach dem Verlassen der Website wieder gel&ouml;scht, anderen k&ouml;nnen &uuml;ber einige Jahre in Ihrem Browser gespeichert sein.</p>
<h3>Widerspruchsrecht</h3>
<p>Sie haben auch jederzeit das Recht und die M&ouml;glichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder &uuml;ber unser Cookie-Management-Tool oder &uuml;ber andere Opt-Out-Funktionen. Zum Bespiel k&ouml;nnen Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder l&ouml;schen. Die Rechtm&auml;&szlig;igkeit der Verarbeitung bis zum Widerruf bleibt unber&uuml;hrt.</p>
<p>Da durch die eingebundenen Audio- und Video-Funktionen auf unserer Seite meist auch Cookies verwendet werden, sollte Sie sich auch unsere allgemeine Datenschutzerkl&auml;rung &uuml;ber Cookies durchlesen. In den Datenschutzerkl&auml;rungen der jeweiligen Drittanbieter erfahren Sie genaueres &uuml;ber den Umgang und die Speicherung Ihrer Daten.</p>
<h3><strong>Rechtsgrundlage</strong></h3>
<p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Audio- und Video-Elemente verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grunds&auml;tzlich werden Ihre Daten auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet. Wir setzen die eingebundenen Audio- und Video-Elemente gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
<h2>YouTube Datenschutzerkl&auml;rung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>YouTube Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Besucher der Website<br /> 🤝 Zweck: Optimierung unserer Serviceleistung<br /> 📓 Verarbeitete Daten: Daten wie etwa Kontaktdaten, Daten zum Nutzerverhalten, Informationen zu Ihrem Ger&auml;t und Ihre IP-Adresse k&ouml;nnen gespeichert werden.<br /> Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerkl&auml;rung.<br /> 📅 Speicherdauer: Daten bleiben grunds&auml;tzlich gespeichert, solange sie f&uuml;r den Dienstzweck n&ouml;tig sind<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was ist YouTube?</h3>
<p>Wir haben auf unserer Website YouTube-Videos eingebaut. So k&ouml;nnen wir Ihnen interessante Videos direkt auf unserer Seite pr&auml;sentieren. YouTube ist ein Videoportal, das seit 2006 eine Tochterfirma von Google ist. Betrieben wird das Videoportal durch YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie auf unserer Website eine Seite aufrufen, die ein YouTube-Video eingebettet hat, verbindet sich Ihr Browser automatisch mit den Servern von YouTube bzw. Google. Dabei werden (je nach Einstellungen) verschiedene Daten &uuml;bertragen. F&uuml;r die gesamte Datenverarbeitung im europ&auml;ischen Raum ist Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich.</p>
<p>Im Folgenden wollen wir Ihnen genauer erkl&auml;ren, welche Daten verarbeitet werden, warum wir YouTube-Videos eingebunden haben und wie Sie Ihre Daten verwalten oder l&ouml;schen k&ouml;nnen.</p>
<p>Auf YouTube k&ouml;nnen die User kostenlos Videos ansehen, bewerten, kommentieren und selbst hochladen. &Uuml;ber die letzten Jahre wurde YouTube zu einem der wichtigsten Social-Media-Kan&auml;le weltweit. Damit wir Videos auf unserer Webseite anzeigen k&ouml;nnen, stellt YouTube einen Codeausschnitt zur Verf&uuml;gung, den wir auf unserer Seite eingebaut haben.</p>
<h3>Warum verwenden wir YouTube-Videos auf unserer Website?</h3>
<p>YouTube ist die Videoplattform mit den meisten Besuchern und dem besten Content. Wir sind bem&uuml;ht, Ihnen die bestm&ouml;gliche User-Erfahrung auf unserer Webseite zu bieten. Und nat&uuml;rlich d&uuml;rfen interessante Videos dabei nicht fehlen. Mithilfe unserer eingebetteten Videos stellen wir Ihnen neben unseren Texten und Bildern weiteren hilfreichen Content zur Verf&uuml;gung. Zudem wird unsere Webseite auf der Google-Suchmaschine durch die eingebetteten Videos leichter gefunden. Auch wenn wir &uuml;ber Google Ads Werbeanzeigen schalten, kann Google &ndash; dank der gesammelten Daten &ndash; diese Anzeigen wirklich nur Menschen zeigen, die sich f&uuml;r unsere Angebote interessieren.</p>
<h3>Welche Daten werden von YouTube gespeichert?</h3>
<p>Sobald Sie eine unserer Seiten besuchen, die ein YouTube-Video eingebaut hat, setzt YouTube zumindest ein Cookie, das Ihre IP-Adresse und unsere URL speichert. Wenn Sie in Ihrem YouTube-Konto eingeloggt sind, kann YouTube Ihre Interaktionen auf unserer Webseite meist mithilfe von Cookies Ihrem Profil zuordnen. Dazu z&auml;hlen Daten wie Sitzungsdauer, Absprungrate, ungef&auml;hrer Standort, technische Informationen wie Browsertyp, Bildschirmaufl&ouml;sung oder Ihr Internetanbieter. Weitere Daten k&ouml;nnen Kontaktdaten, etwaige Bewertungen, das Teilen von Inhalten &uuml;ber Social Media oder das Hinzuf&uuml;gen zu Ihren Favoriten auf YouTube sein.</p>
<p>Wenn Sie nicht in einem Google-Konto oder einem Youtube-Konto angemeldet sind, speichert Google Daten mit einer eindeutigen Kennung, die mit Ihrem Ger&auml;t, Browser oder App verkn&uuml;pft sind. So bleibt beispielsweise Ihre bevorzugte Spracheinstellung beibehalten. Aber viele Interaktionsdaten k&ouml;nnen nicht gespeichert werden, da weniger Cookies gesetzt werden.</p>
<p>In der folgenden Liste zeigen wir Cookies, die in einem Test im Browser gesetzt wurden. Wir zeigen einerseits Cookies, die ohne angemeldetes YouTube-Konto gesetzt werden. Andererseits zeigen wir Cookies, die mit angemeldetem Account gesetzt werden. Die Liste kann keinen Vollst&auml;ndigkeitsanspruch erheben, weil die Userdaten immer von den Interaktionen auf YouTube abh&auml;ngen.</p>
<p><strong>Name:</strong> YSC<br /><strong>Wert:</strong> b9-CV6ojI5Y121865447-1<br /><strong>Verwendungszweck:</strong> Dieses Cookie registriert eine eindeutige ID, um Statistiken des gesehenen Videos zu speichern.<br /><strong>Ablaufdatum:</strong> nach Sitzungsende</p>
<p><strong>Name:</strong> PREF<br /><strong>Wert:</strong> f1=50000000<br /><strong>Verwendungszweck:</strong> Dieses Cookie registriert ebenfalls Ihre eindeutige ID. Google bekommt &uuml;ber PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite verwenden.<br /><strong>Ablaufdatum:</strong> nach 8 Monaten</p>
<p><strong>Name:</strong> GPS<br /><strong>Wert:</strong> 1<br /><strong>Verwendungszweck:</strong> Dieses Cookie registriert Ihre eindeutige ID auf mobilen Ger&auml;ten, um den GPS-Standort zu tracken.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 30 Minuten</p>
<p><strong>Name:</strong> VISITOR_INFO1_LIVE<br /><strong>Wert:</strong> 95Chz8bagyU<br /><strong>Verwendungszweck:</strong> Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten (mit eingebautem YouTube-Video) zu sch&auml;tzen.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 8 Monaten</p>
<p>Weitere Cookies, die gesetzt werden, wenn Sie mit Ihrem YouTube-Konto angemeldet sind:</p>
<p><strong>Name:</strong> APISID<br /><strong>Wert:</strong> zILlvClZSkqGsSwI/AU1aZI6HY7121865447-<br /><strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein Profil &uuml;ber Ihre Interessen zu erstellen. Gen&uuml;tzt werden die Daten f&uuml;r personalisierte Werbeanzeigen.<br /><strong>Ablaufdatum:</strong> nach 2 Jahren</p>
<p><strong>Name:</strong> CONSENT<br /><strong>Wert:</strong> YES+AT.de+20150628-20-0<br /><strong>Verwendungszweck:</strong> Das Cookie speichert den Status der Zustimmung eines Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um User zu &uuml;berpr&uuml;fen und Userdaten vor unbefugten Angriffen zu sch&uuml;tzen.<br /><strong>Ablaufdatum: </strong>nach 19 Jahren</p>
<p><strong>Name:</strong> HSID<br /><strong>Wert:</strong> AcRwpgUik9Dveht0I<br /><strong>Verwendungszweck:</strong> Dieses Cookie wird verwendet, um ein Profil &uuml;ber Ihre Interessen zu erstellen. Diese Daten helfen personalisierte Werbung anzeigen zu k&ouml;nnen.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 2 Jahren</p>
<p><strong>Name:</strong> LOGIN_INFO<br /><strong>Wert:</strong> AFmmF2swRQIhALl6aL&hellip;<br /><strong>Verwendungszweck:</strong> In diesem Cookie werden Informationen &uuml;ber Ihre Login-Daten gespeichert.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 2 Jahren</p>
<p><strong>Name:</strong> SAPISID<br /><strong>Wert:</strong> 7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM<br /><strong>Verwendungszweck:</strong> Dieses Cookie funktioniert, indem es Ihren Browser und Ihr Ger&auml;t eindeutig identifiziert. Es wird verwendet, um ein Profil &uuml;ber Ihre Interessen zu erstellen.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 2 Jahren</p>
<p><strong>Name:</strong> SID<br /><strong>Wert:</strong> oQfNKjAsI121865447-<br /><strong>Verwendungszweck:</strong> Dieses Cookie speichert Ihre Google-Konto-ID und Ihren letzten Anmeldezeitpunkt in digital signierter und verschl&uuml;sselter Form.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 2 Jahren</p>
<p><strong>Name:</strong> SIDCC<br /><strong>Wert:</strong> AN0-TYuqub2JOcDTyL<br /><strong>Verwendungszweck:</strong> Dieses Cookie speichert Informationen, wie Sie die Webseite nutzen und welche Werbung Sie vor dem Besuch auf unserer Seite m&ouml;glicherweise gesehen haben.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 3 Monaten</p>
<h3>Wie lange und wo werden die Daten gespeichert?</h3>
<p>Die Daten, die YouTube von Ihnen erh&auml;lt und verarbeitet werden auf den Google-Servern gespeichert. Die meisten dieser Server befinden sich in Amerika. Unter <a href="https://www.google.com/about/datacenters/locations/?hl=de">https://www.google.com/about/datacenters/inside/locations/?hl=de</a> sehen Sie genau wo sich die Google-Rechenzentren befinden. Ihre Daten sind auf den Servern verteilt. So sind die Daten schneller abrufbar und vor Manipulation besser gesch&uuml;tzt.</p>
<p>Die erhobenen Daten speichert Google unterschiedlich lang. Manche Daten k&ouml;nnen Sie jederzeit l&ouml;schen, andere werden automatisch nach einer begrenzten Zeit gel&ouml;scht und wieder andere werden von Google &uuml;ber l&auml;ngere Zeit gespeichert. Einige Daten (wie Elemente aus &bdquo;Meine Aktivit&auml;t&ldquo;, Fotos oder Dokumente, Produkte), die in Ihrem Google-Konto gespeichert sind, bleiben so lange gespeichert, bis Sie sie l&ouml;schen. Auch wenn Sie nicht in einem Google-Konto angemeldet sind, k&ouml;nnen Sie einige Daten, die mit Ihrem Ger&auml;t, Browser oder App verkn&uuml;pft sind, l&ouml;schen.</p>
<h3>Wie kann ich meine Daten l&ouml;schen bzw. die Datenspeicherung verhindern?</h3>
<p>Grunds&auml;tzlich k&ouml;nnen Sie Daten im Google Konto manuell l&ouml;schen. Mit der 2019 eingef&uuml;hrten automatischen L&ouml;schfunktion von Standort- und Aktivit&auml;tsdaten werden Informationen abh&auml;ngig von Ihrer Entscheidung &ndash; entweder 3 oder 18 Monate gespeichert und dann gel&ouml;scht.</p>
<p>Unabh&auml;ngig, ob Sie ein Google-Konto haben oder nicht, k&ouml;nnen Sie Ihren Browser so konfigurieren, dass Cookies von Google gel&ouml;scht bzw. deaktiviert werden. Je nachdem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise. Unter dem Abschnitt &bdquo;Cookies&ldquo; finden Sie die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
<p>Falls Sie grunds&auml;tzlich keine Cookies haben wollen, k&ouml;nnen Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So k&ouml;nnen Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.</p>
<h3>Rechtsgrundlage</h3>
<p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene YouTube-Elemente verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grunds&auml;tzlich werden Ihre Daten auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet. Wir setzen die eingebundenen YouTube-Elemente gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. YouTube setzt auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>YouTube verarbeitet Daten u.a. auch in den USA. Wir weisen darauf hin, dass nach Meinung des Europ&auml;ischen Gerichtshofs derzeit kein angemessenes Schutzniveau f&uuml;r den Datentransfer in die USA besteht. Dies kann mit verschiedenen Risiken f&uuml;r die Rechtm&auml;&szlig;igkeit und Sicherheit der Datenverarbeitung einhergehen.</p>
<p>Als Grundlage der Datenverarbeitung bei Empf&auml;ngern mit Sitz in Drittstaaten (au&szlig;erhalb der Europ&auml;ischen Union, Island, Liechtenstein, Norwegen, also insbesondere in den USA) oder einer Datenweitergabe dorthin verwendet YouTube von der EU-Kommission genehmigte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Diese Klauseln verpflichten YouTube, das EU-Datenschutzniveau bei der Verarbeitung relevanter Daten auch au&szlig;erhalb der EU einzuhalten. Diese Klauseln basieren auf einem Durchf&uuml;hrungsbeschluss der EU-Kommission. Sie finden den Beschluss sowie die Klauseln u.a. hier: <a href="https://germany.representation.ec.europa.eu/index_de">https://germany.representation.ec.europa.eu/index_de</a>.</p>
<p>Da YouTube ein Tochterunternehmen von Google ist, gibt es eine gemeinsame Datenschutzerkl&auml;rung. Wenn Sie mehr &uuml;ber den Umgang mit Ihren Daten erfahren wollen, empfehlen wir Ihnen die Datenschutzerkl&auml;rung unter <a href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de.</a></p>
<h2>YouTube Abonnieren Button Datenschutzerkl&auml;rung</h2>
<p>Wir haben auf unserer Webseite den YouTube Abonnieren Button (engl. &bdquo;Subscribe-Button&ldquo;) eingebaut. Sie erkennen den Button meist am klassischen YouTube-Logo. Das Logo zeigt vor rotem Hintergrund in wei&szlig;er Schrift die W&ouml;rter &bdquo;Abonnieren&ldquo; oder &bdquo;YouTube&ldquo; und links davon das wei&szlig;e &bdquo;Play-Symbol&ldquo;. Der Button kann aber auch in einem anderen Design dargestellt sein.</p>
<p>Unser YouTube-Kanal bietet Ihnen immer wieder lustige, interessante oder spannende Videos. Mit dem eingebauten &bdquo;Abonnieren-Button&ldquo; k&ouml;nnen Sie unseren Kanal direkt von unserer Webseite aus abonnieren und m&uuml;ssen nicht eigens die YouTube-Webseite aufrufen. Wir wollen Ihnen somit den Zugang zu unserem umfassenden Content so einfach wie m&ouml;glich machen. Bitte beachten Sie, dass YouTube dadurch Daten von Ihnen speichern und verarbeiten kann.</p>
<p>Wenn Sie auf unserer Seite einen eingebauten Abo-Button sehen, setzt YouTube&nbsp;&ndash; laut Google &ndash; mindestens ein Cookie. Dieses Cookie speichert Ihre IP-Adresse und unsere URL. Auch Informationen &uuml;ber Ihren Browser, Ihren ungef&auml;hren Standort und Ihre voreingestellte Sprache kann YouTube so erfahren. Bei unserem Test wurden folgende vier Cookies gesetzt, ohne bei YouTube angemeldet zu sein:</p>
<p><strong>Name:</strong> YSC<br /><strong>Wert:</strong> b9-CV6ojI5121865447Y<br /><strong>Verwendungszweck:</strong> Dieses Cookie registriert eine eindeutige ID, um Statistiken des gesehenen Videos zu speichern.<br /><strong>Ablaufdatum:</strong> nach Sitzungsende</p>
<p><strong>Name:</strong> PREF<br /><strong>Wert:</strong> f1=50000000<br /><strong>Verwendungszweck:</strong> Dieses Cookie registriert ebenfalls Ihre eindeutige ID. Google bekommt &uuml;ber PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite verwenden.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 8 Monate</p>
<p><strong>Name:</strong> GPS<br /><strong>Wert:</strong> 1<br /><strong>Verwendungszweck:</strong> Dieses Cookie registriert Ihre eindeutige ID auf mobilen Ger&auml;ten, um den GPS-Standort zu tracken.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 30 Minuten</p>
<p><strong>Name:</strong> VISITOR_INFO1_LIVE<br /><strong>Wert:</strong> 12186544795Chz8bagyU<br /><strong>Verwendungszweck:</strong> Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten (mit eingebautem YouTube-Video) zu sch&auml;tzen.<br /><strong>Ablaufdatum:</strong>&nbsp;nach 8 Monaten</p>
<p><strong>Anmerkung:</strong> Diese Cookies wurden nach einem Test gesetzt und k&ouml;nnen nicht den Anspruch auf Vollst&auml;ndigkeit erheben.</p>
<p>Wenn Sie in Ihrem YouTube-Konto angemeldet sind, kann YouTube viele Ihrer Handlungen/Interaktionen auf unserer Webseite mit Hilfe von Cookies speichern und Ihrem YouTube-Konto zuordnen. YouTube bekommt dadurch zum Beispiel Informationen wie lange Sie auf unserer Seite surfen, welchen Browsertyp Sie verwenden, welche Bildschirmaufl&ouml;sung Sie bevorzugen oder welche Handlungen Sie ausf&uuml;hren.</p>
<p>YouTube verwendet diese Daten zum einen um die eigenen Dienstleistungen und Angebote zu verbessern, zum anderen um Analysen und Statistiken f&uuml;r Werbetreibende (die Google Ads verwenden) bereitzustellen.</p>
<h2 id="videokonferenzen-streaming-einleitung">Videokonferenzen &amp; Streaming Einleitung</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Videokonferenzen &amp; Streaming Datenschutzerkl&auml;rung Zusammenfassung</strong><br /> 👥 Betroffene: Nutzer, die unser Videokonferenzen- oder Streaming-Tool verwenden<br /> 🤝 Zweck: Kommunikation und Pr&auml;sentation von Inhalten<br /> 📓 Verarbeitete Daten: Zugriffsstatistiken die Daten wie etwa Name, Adresse, Kontaktdaten, E-Mail-Adresse, Telefonnummer oder Ihre IP-Adresse enthalten. Mehr Details dazu finden Sie beim jeweils eingesetzten Videokonferenz- oder Streaming-Tool.<br /> 📅 Speicherdauer: abh&auml;ngig vom eingesetzten Videokonferenzen- oder Streaming-Tool<br /> ⚖️ Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen), Art. 6 Abs. 1 lit. b DSGVO (Vertrag)</p>
</td>
</tr>
</tbody>
</table>
<h3>Was sind Videokonferenzen &amp; Streamings?</h3>
<p>Wir verwenden Softwareprogramme, die es uns erm&ouml;glichen Videokonferenzen, Onlinemeetings, Webinare, Display-Sharing und/oder Streamings abzuhalten. Bei einer Videokonferenz oder einem Streaming werden Informationen gleichzeitig &uuml;ber Ton und bewegtes Bild &uuml;bertragen. Mit Hilfe solcher Videokonferenz- oder Streaming-Tools k&ouml;nnen wir mit Kunden, Gesch&auml;ftspartnern, Klienten und auch Mitarbeitern schnell und einfach &uuml;ber das Internet kommunizieren. Nat&uuml;rlich achten wir bei der Auswahl des Dienstanbieters auf die vorgegebenen gesetzlichen Rahmenbedingungen.</p>
<p>Grunds&auml;tzlich k&ouml;nnen Drittanbieter Daten verarbeiten, sobald Sie mit dem Softwareprogramm interagieren. Drittanbieter der Videokonferenz bzw. Streaming-L&ouml;sungen verwenden Ihre Daten und Metadaten f&uuml;r unterschiedliche Zwecke. Die Daten helfen etwa, das Tool sicherer zu machen und das Service zu verbessern. Meistens d&uuml;rfen die Daten auch f&uuml;r eigene Marketingzwecke des Drittanbieters verwendet werden.</p>
<h3>Warum verwenden wir Videokonferenzen &amp; Streaming auf unserer Website?</h3>
<p>Wir wollen mit Ihnen, mit unseren Kunden und Gesch&auml;ftspartnern auch digital schnell, unkompliziert und sicher kommunizieren. Das funktioniert am besten mit Videokonferenzl&ouml;sungen, die ganz einfach zu bedienen sind. Die meisten Tools funktionieren auch direkt &uuml;ber Ihren Browser und nach nur wenigen Klicks sind Sie mittendrin in einem Videomeeting. Die Tools bieten auch hilfreiche Zusatz-Features wie etwa eine Chat- und Screensharing-Funktion oder die M&ouml;glichkeit Inhalte zwischen Meeting-Teilnehmern zu teilen.</p>
<h3>Welche Daten werden verarbeitet?</h3>
<p>Wenn Sie an unserer Videokonferenz bzw. an einem Streaming teilnehmen, werden auch Daten von Ihnen verarbeitet und auf den Servern des jeweiligen Dienstanbieters gespeichert.</p>
<p>Welche Daten genau gespeichert werden, h&auml;ngt von der verwendeten L&ouml;sungen ab. Jeder Anbieter speichert und verarbeitet unterschiedliche und unterschiedlich viele Daten. Doch in der Regel werden bei den meisten Anbietern Ihr Name, Ihre Adresse, Kontaktdaten wie zum Beispiel Ihre E-Mail-Adresse oder Ihre Telefonnummer und Ihre IP-Adresse gespeichert. Weiter k&ouml;nnen auch Informationen zu Ihrem verwendeten Ger&auml;t, Nutzungsdaten wie beispielsweise welche Webseiten Sie besuchen, wann Sie eine Webseite besuchen oder auf welche Buttons Sie klicken, gespeichert werden. Auch Daten, die innerhalb der Videokonferenz geteilt werden (Fotos, Videos, Texte), k&ouml;nnen gespeichert werden.</p>
<h3>Dauer der Datenverarbeitung</h3>
<p>&Uuml;ber die Dauer der Datenverarbeitung informieren wir Sie weiter unten im Zusammenhang mit dem verwendeten Dienst, sofern wir weitere Informationen dazu haben. Generell verarbeiten wir personenbezogene Daten nur so lange wie es f&uuml;r die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Es kann sein, dass der Anbieter nach eigenen Ma&szlig;gaben Daten von Ihnen speichert, worauf wir dann keinen Einfluss haben.</p>
<h3>Widerspruchsrecht</h3>
<p>Sie haben immer das Recht auf Auskunft, Berichtigung und L&ouml;schung Ihrer personenbezogenen Daten. Bei Fragen k&ouml;nnen Sie auch jederzeit Verantwortliche des verwendeten Videokonferenzen- bzw. Streamingtools kontaktieren. Kontaktdaten finden Sie entweder in unserer spezifischen Datenschutzerkl&auml;rung oder auf der Website des entsprechenden Anbieters.</p>
<p>Cookies, die Anbieter f&uuml;r ihre Funktionen verwenden, k&ouml;nnen Sie in Ihrem Browser l&ouml;schen, deaktivieren oder verwalten. Je nachdem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise. Bitte beachten Sie aber, dass dann eventuell nicht mehr alle Funktionen wie gewohnt funktionieren.</p>
<h3>Rechtsgrundlage</h3>
<p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch die Video bzw. Streaming-L&ouml;sung verarbeitet und gespeichert werden k&ouml;nnen, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong>(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Zudem k&ouml;nnen wir auch eine Videokonferenz als Teil unserer Services anbieten, wenn dies im Vorfeld mit Ihnen vertraglich vereinbart wurde <strong>(Art. 6 Abs. 1 lit. b DSGVO)</strong>. Grunds&auml;tzlich werden Ihre Daten auch auf Grundlage unseres berechtigten Interesses <strong>(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Gesch&auml;ftspartnern gespeichert und verarbeitet, jedoch nur, soweit Sie zumindest eingewilligt haben. Die meisten Video- bzw. Streaming-L&ouml;sungen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext &uuml;ber Cookies genau durchzulesen und die Datenschutzerkl&auml;rung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
<p>Informationen zu speziellen Videokonferenz- und Streaming-L&ouml;sungen, erfahren Sie &ndash; sofern vorhanden &ndash; in den folgenden Abschnitten.</p>
<p>Alle Texte sind urheberrechtlich gesch&uuml;tzt.</p>`
}