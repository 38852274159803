const orderFields = () => {
	return `
		id
		number
		date
		status
		title
		license
		amount
		link
		document_type
		payment_due_date
		company_id
		company {
			id
			name
		}
		project_id
		project {
			id
			name
		}
		user_id
		user {
			id
			first_name
			last_name
			email
		}
		service_ids
		service_period
		model_name
	`;
};

import helperFunctions from '@/helper/functions.js';

export default {
	getAllOrders() {
		return `
			{
				orders {
					${orderFields()}
				}
			}
		`;
	},
	getOrderById(orderId) {
		return `
			{
				orderById(id: ${orderId}) {
					${orderFields()}
				}
			}
		`;
	},
	createOrder(orderData, serviceIds) {
		return `
			mutation {
				createOrder(service_ids: "${JSON.stringify(serviceIds)}", ${helperFunctions.convertFieldsToString(orderData)}) {
					${orderFields()}
				}
			}
		`;
	},
	updateOrder(orderId, serviceIds, fields) {
		return `
			mutation {
				updateOrder(id: ${orderId}, service_ids: "${JSON.stringify(serviceIds)}", ${helperFunctions.convertFieldsToString(fields)}) {
					${orderFields()}
				}
			}
		`;
	},
	deleteOrder(orderId) {
		return `
			mutation {
				deleteOrder(id: ${orderId}) {
					${orderFields()}
				}
			}
		`;
	}
}
