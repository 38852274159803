import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from '@/router'

import auth from '@/auth/authService'

Vue.use(AclInstaller)

let initialRole = 'staff'

const userInfo = auth.getAuth()
if (userInfo && userInfo.userRole) initialRole = userInfo.userRole

Vue.prototype.$Gate = auth.getGate(userInfo)

const roleAdmin           = new AclRule('admin');
const roleCompanyAdmin    = roleAdmin.or('company_admin');
const roleOwner           = roleCompanyAdmin.or('owner');
const roleProjectManager  = roleOwner.or('project_manager').or('propster_finance').or('propster_manager');
const roleProjectAdmin    = roleProjectManager.or('project_admin');
const roleStaff           = roleProjectManager.or('staff');

let aclProcessor = new AclCreate({
  initial  : initialRole,
  notfound : '/pages/not-authorized',
  router,
  acceptLocalRules : true,
  globalRules: {
    admin           : roleAdmin.generate(),
    company_admin   : roleCompanyAdmin.generate(),
    owner           : roleOwner.generate(),
    project_admin   : roleProjectAdmin.generate(),
    project_manager : roleProjectManager.generate(),
    staff           : roleStaff.generate()
  }
})

export default aclProcessor
