/*=========================================================================================
  File Name: moduleOrdersMutations.js
  Description: Orders Module Mutations
==========================================================================================*/


export default {
  SET_ORDERS (state, orders) {
    state.orders = orders;
  },
  ADD_NEW_ORDER (state, order) {
    state.orders = [...state.orders, ...order];
  },
  UPDATE_ORDER (state, order) {
    const orderIndex = state.orders.findIndex((u) => u.id === order.id);
    state.orders[orderIndex] = order;
  },
  DELETE_ORDER (state, deletedOrder) {
    state.orders = state.orders.filter(order => {
      return order.id !== deletedOrder.id;
    });
  },
}
