export default class MarketplacePolicy
{
    /**
     * Determine whether the user can view any models.
     *
     * @param  {object}  user
     * @return mixed
     */
    viewAny(user)
    {
        return (user.role.key_name === 'admin')
    }

    /**
     * Determine whether the user can view the model.
     *
     * @param  {object}  user
     * @param  {object}  marketplace
     * @return {mixed}
     */
    view(user, marketplace)
    {
        return (user.role.key_name === 'admin')
    }

    /**
     * Determine whether the user can create models.
     *
     * @param  {object}  user
     * @return {mixed}
     */
    create(user)
    {
        return (user.role.key_name === 'admin')
    }

    /**
     * Determine whether the user can update the model.
     *
     * @param  {object}  user
     * @param  {object}  marketplace
     * @return {mixed}
     */
    update(user, marketplace)
    {
        return (user.role.key_name === 'admin')
    }

    /**
     * Determine whether the user can restore the model.
     *
     * @param  {object}  user
     * @param  {object}  marketplace
     * @return {mixed}
     */
    restore(user, marketplace)
    {
        return (user.role.key_name === 'admin')
    }

    /**
     * Determine whether the user can delete the model.
     *
     * @param  {object}  user
     * @param  {object}  marketplace
     * @return {mixed}
     */
    delete(user, marketplace)
    {
        return (user.role.key_name === 'admin')
    }

    /**
     * Determine whether the user can permanently delete the model.
     *
     * @param  {object}  user
     * @param  {object}  marketplace
     * @return {mixed}
     */
    forceDelete(user, marketplace)
    {
        return (user.role.key_name === 'admin')
    }

    /**
     * Determine whether the user can delete the model.
     *
     * @param  {object}  user
     * @return {mixed}
     */
    deleteAll(user)
    {
        return (user.role.key_name === 'admin')
    }
}
