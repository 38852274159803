const projectFields = (isFilesNeeded = 1) => {
	return `
		id
		model_name
		name
		company_id
		company {
			id
			name
			street
			house_number
			postcode
			city
			uid_number
			company_register
			website_url
			parentCompany {
				id
				name
			}
			created_at
			updated_at
			has_project_dashboard
		}
		address_1
		address_2
		postcode
		city
		country_id
		country {
			id
			name
		}
		clientCountry {
			id
			name
		}
		is_third_party_company
		client_name
		client_company_name
		client_address_1
		client_address_2
		client_postcode
		client_city
		client_country_id
		project_number
		icon
		key_visual
		propster_project_id
		status {
			id
			key_name
		}
		construction_type
		area_types
		residential_project_types
		residential_project_sales
		commercial_project_types
		other_commercial_project_type
		commercial_project_sales
		services {
			id
			project_id
			service_id
			license_id
			site_url
			gtm_url
			is_active
			service {
				id
				key_name
				name
			}
		}
		activeServices {
			id
			project_id
			service_id
			license_id
			site_url
			gtm_url
			service {
				id
				key_name
				name
			}
		}
		activeServicesWithGTM {
			id
			project_id
			service_id
			license_id
			site_url
			gtm_url
			service {
				id
				key_name
				name
			}
		}
		addons {
			id
			project_id
			addon_id
			license_id
			is_active
			addon {
				id
				key_name
				name
			}
		}
		logo_file_id
		user_behaviour_report_url
		residential_investment_project_area
		residential_investment_price_range_from
		residential_investment_price_range_to
		residential_investment_units
		residential_investment_notes
		residential_rent_project_area
		residential_rent_price_range_from
		residential_rent_price_range_to
		residential_rent_units
		residential_rent_notes
		commercial_investment_project_area
		commercial_investment_price_range_from
		commercial_investment_price_range_to
		commercial_investment_units
		commercial_investment_notes
		commercial_rent_project_area
		commercial_rent_price_range_from
		commercial_rent_price_range_to
		commercial_rent_units
		commercial_rent_notes
		project_style
		other_project_style
		handle_user_id
		deals_user_id
		project_url	
		${populateWithAdminFields()}
    ${populateWithFiles(isFilesNeeded)}
    created_at
    creator_id
    creator
    creatorUser {
			${userFields()}
		}
		projectManager {
			${userFields()}
		}
		handleUser {
			${userFields()}
		}
		dealsUser {
			${userFields()}
		}
		configurationProcessingContact {
			${userFields()}
		}
		productSelectionContact {
			${userFields()}
		}
		project_onboarding_overview_url
		interested_in_banner
        building_permit_issued
        not_issued_notes
        marketing_start
        notices
        project_url
        fields_visibility {
			field
			roles
		}
		fields_writeability {
			field
			roles
		}
	`;
};

const nineDotsMenuProjectFields = () => {
	return `
		id
		name
		company {
			id
			has_project_dashboard
		}
		icon
		services {
			id
			project_id
			service_id
			site_url
			is_active
			service {
				id
				key_name
				name
			}
		}
	`;
};

const populateWithFiles = (isFilesNeeded) => {
	if (isFilesNeeded) {
		return `
			files {
				${projectFileFields()}
			}
		`;
	}

	return ``;
};

const populateWithAdminFields = () => {
	const role = JSON.parse(localStorage.getItem('userInfo')).role;

	if (role && role.key_name == 'admin') {
		return `
			project_status_id
			stats_swm_wc_user_key
			stats_swm_wc_consumer_secret
			stats_swm_blog_id
			stats_swm_region
			project_manager_id
			stats_mmgmt_api_url
			stats_mmgmt_user_key
			stats_mmgmt_consumer_secret
			user_id
		`;
	}

	return ``;
};

const projectFileFields = () => {
	return `
		id
		name
		hash_name
		extension
		type
		company_id
		user_id
		url
		size
		download
		token
	`;
};

const userFields = () => {
	return `
		id
		first_name
		last_name
		email
		phone_number
		avatar_url
		linkedin_url
		website_url
		xing_url
		country {
			name
		}
		role {
			key_name
		}
	`;
};

const projectMemberFields = () => {
	return `
		id
		first_name
		last_name
		email
		phone_number
		avatar_url
		linkedin_url
		website_url
		xing_url
		country {
			name
		}
		role {
			key_name
		}
		project_member_role
	`;
};

const serviceFields = () => {
	return `
		id
		key_name
		name
		description
		icon_code
		order
		model_name
		serviceProjects {
			${projectServiceFields()}
		}
	`;
};

const projectServiceFields = () => {
	return `
		project_id
		service_id
		license_id
		site_url
		gtm_url
		is_active
	`;
};

const projectAddonFields = () => {
	return `
		project_id
		addon_id
		license_id
		is_active
	`;
};

const projectTypeFields = () => {
	return `
		id
		key_name
	`;
};

const projectStatusAllFields = () => {
	return `
		id
		key_name
		project
		model_name
	`;
};

const projectStatusListFields = () => {
	return `
		id
		key_name
		model_name
	`;
};

const addonFields = () => {
	return `
		id
		key_name
		name
		description
		icon_code
		order
		model_name
		projects {
			id
			name
		}
		services {
			id
			name
		}
	`;
};

import helperFunctions from '@/helper/functions.js';

export default {
	createProject(fields) {
		return `
			mutation($icon: Upload, $key_visual: Upload) {
				createProject(${helperFunctions.convertFieldsToString(fields)}, icon: $icon, key_visual: $key_visual) {
					${projectFields()}
				}
			}
		`;
	},
	updateProject(fields) {
		// console.log(fields);
		return `
			mutation($icon: Upload, $key_visual: Upload) {
				updateProject(${helperFunctions.convertFieldsToString(fields)}, icon: $icon, key_visual: $key_visual) {
					${projectFields()}
				}
			}
		`;
	},
	pappApiUpdate(projectId) {
		return `
			mutation {
				response: projectPappApiUpdate(id: ${projectId}) {
					${projectFields()}
				}
			}
		`;
	},
	getUserProjectsWithRoles(userId) {
		return `
			{
				projects: userProjectsWithRoles(id: ${userId}) {
					${nineDotsMenuProjectFields()}
				}
			}
		`;
	},
	getUserPMProjectsWithRoles(userId) {
		return `
			{
				projects: userPMProjectsWithRoles(id: ${userId}) {
					${nineDotsMenuProjectFields()}
				}
			}
		`;
	},
	createService(fields) {
		return `
			mutation {
				createService(name: """${JSON.stringify(fields.name)}""", description: """${JSON.stringify(fields.description)}""", ${helperFunctions.convertFieldsToString(fields)}) {
					${serviceFields()}
				}
			}
		`;
	},
	getServiceById(serviceId) {
		return `
			{
				serviceById(id: ${serviceId}) {
					${serviceFields()}
				}
			}
		`;
	},
	updateService(serviceId, fields) {
		let name = fields.name ? `, name: """${JSON.stringify(fields.name)}""",` : ``;
		let description = fields.description ? `, description: """${JSON.stringify(fields.description)}""",` : ``;

		return `
			mutation {
				updateService(id: ${serviceId} ${name} ${description} ${helperFunctions.convertFieldsToString(fields)}) {
					${serviceFields()}
				}
			}
		`;
	},
	updateProjectServices(projectId, serviceId, fields) {
		return `
			mutation {
				updateProjectServices(project_id: ${projectId}, service_id: ${serviceId}, ${helperFunctions.convertFieldsToString(fields)}) {
					${projectServiceFields()}
				}
			}
		`;
	},
	updateProjectAddons(projectId, addonId, fields) {
		return `
			mutation {
				updateProjectAddons(project_id: ${projectId}, addon_id: ${addonId}, ${helperFunctions.convertFieldsToString(fields)}) {
					${projectAddonFields()}
				}
			}
		`;
	},
	getPropsterProjectManagers() {
		return `
		{
			propsterProjectManagers {
				id
				first_name
				last_name
				email
				phone_number
				avatar_url
				linkedin_url
				website_url
				xing_url
				country {
					name
				}
				role {
					key_name
				}
			}
		}`;
	},
	getAllServices() {
		return `
			{
				services {
					${serviceFields()}
				}
			}
		`;
	},
	deleteService(serviceId) {
		return `
			mutation {
				deleteService(id: ${serviceId}) {
					${serviceFields()}
				}
			}
		`;
	},
	getAllProjectTypes() {
		return `
			{
				projectTypes {
					${projectTypeFields()}
				}
			}
		`;
	},
	// getAllProjects(role) {
	// 	return `
	// 		{
	// 			projects(orderBy: [
	// 				{
	// 					field: "id"
	// 					order: DESC
	// 				}
	// 			]) {
	// 				${projectFields(0)}
	// 			}
	// 		}
	// 	`;
	// },
	getAllProjectsByCompanyID(companyID, role) {
		return `
			{
				projectsByCompanyId(company_id: ${companyID}, orderBy: [
					{
						field: "id"
						order: DESC
					}
				]) {
					${projectFields()}
				}
			}
		`;
	},
	getProjectListByCompanies(companyIDs, role) {
		return `
			{
				projectListByCompanies(company_id: [${companyIDs.join(',')}]) {
					id
                    name
                    company_id
                    marketing_start
				}
			}
		`;
	},
	// getProject(projectId, role) {
	// 	return `
	// 		{
	// 			projectById(id: ${projectId}) {
	// 				${projectFields()}
	// 			}
	// 		}
	// 	`;
	// },
	getStatusesList() {
		return `
			{
				projectStatuses {
					${projectStatusListFields()}
				}
			}
		`
	},
	getProjectOrdersStats(projectId, run) {
		return `
			{
				stats: projectOrderStatsByProjectId(project_id: ${projectId}, run: ${run}) {
					status,
					count
				}
			}
		`;
	},
	getProjectSwmRequestsStats(projectId) {
		return `
			{
				stats: projectSwmRequestsStatsByProjectId(project_id: ${projectId}){
					status,
					status_name,
					count
				}
			}
		`;
	},
	getProjectSwmRequestsByCategoryStats(projectId) {
		return `
			{
				stats: projectSwmRequestsByCategoryStatsByProjectId(project_id: ${projectId}) {
					category,
					category_name,
					count
				}
			}
		`;
	},
	getProjectDefectsStats(projectId) {
		return `
			{
				stats: projectDefectsStatsByProjectId(project_id: ${projectId}) {
					status,
					status_name,
					count
				}
			}
		`;
	},
	getProjectDefectsByCategoryStats(projectId) {
		return `
			{
				stats: projectDefectsByCategoryStatsByProjectId(project_id: ${projectId}) {
					category,
					category_name,
					count
				}
			}
		`;
	},
	getProjectFileDownloadsStats(projectId, run) {
		return `
			{
				projectFileDownloadsStatsByProjectId(project_id: ${projectId}, run: ${run}) {
					date,
					count
				}
			}
		`;
	},
	getProjectTicketsStats(projectId) {
		return `
			{
				projectTicketsStatsByProjectId(project_id: ${projectId}) {
					status,
					status_name,
					count
				}
			}
		`;
	},
	fetchProjectMembers(projectId) {
		const q = `
			{
				projectMembers(id: ${projectId}) {
					${projectMemberFields()}
				}
			}
		`;
		return q;
	},
	fetchProjectNonMembers(projectId) {
		const q = `
			{
				projectNonMembers(id: ${projectId}) {
					${userFields()}
				}
			}
		`;
		return q;
	},
	updateProjectMemberRole(projectId, memberId, roleKeyName) {
		return `
			mutation {
				updateProjectMemberRole(project_id: ${projectId}, member_id: ${memberId}, role_key_name: "${roleKeyName}") {
					${projectMemberFields()}
				}
			}
		`;
	},
	updateProjectFiles(projectId) {
		return `
			mutation($floor_plan: [Upload], $equipment_description: [Upload], $visualization: [Upload]) {
				updateProjectFiles(id: ${projectId}, floor_plan: $floor_plan, equipment_description: $equipment_description, visualization: $visualization) {
					${projectFileFields()}
				}
			}
		`;
	},
	getProjectFiles(projectId) {
		return `
			{
				projectFiles(id: ${projectId}) {
					${projectFileFields()}
				}
			}
		`;
	},
	deleteFile(fileId) {
		return `
			mutation {
				deleteFileById(id: ${fileId}) {
					id
					name
					type
				}
			}
		`;
	},
	deleteProject(projectId) {
		return `
			mutation {
				deleteProject(id: ${projectId}) {
					${projectFields()}
				}
			}
		`;
	},
	getCompanyOrdersStats(companyId, run = false) {
		return `
			{
				stats: companyOrderStatsByCompanyId(company_id: ${companyId}, run: ${run}) {
					status,
					count
				}
			}
		`;
	},
	getCompanySwmRequestsStats(companyId) {
		return `
			{
				stats: companySwmRequestsStatsByCompanyId(company_id: ${companyId}) {
					status,
					status_name,
					count
				}
			}
		`;
	},
	getCompanySwmRequestsByCategoryStats(companyId) {
		return `
			{
				stats: companySwmRequestsByCategoryStatsByCompanyId(company_id: ${companyId}) {
					category,
					category_name,
					count
				}
			}
		`;
	},
	getCompanyDefectsStats(companyId, run = false) {
		return `
			{
				stats: companyDefectsStatsByCompanyId(company_id: ${companyId}, run: ${run}) {
					status,
					status_name,
					count
				}
			}
		`;
	},
	getCompanyDefectsByCategoryStats(companyId) {
		return `
			{
				stats: companyDefectsByCategoryStatsByCompanyId(company_id: ${companyId}) {
					category,
					category_name,
					count
				}
			}
		`;
	},
	getCompanyFileDownloadsStats(companyId, run = false) {
		return `
			{
				companyFileDownloadsStatsByCompanyId(company_id: ${companyId}, run: ${run}) {
					date,
					count
				}
			}
		`;
	},
	getCompanyTicketsStats(companyId) {
		return `
			{
				companyTicketsStatsByCompanyId(company_id: ${companyId}) {
					status,
					status_name,
					count
				}
			}
		`;
	},
	createAddon(fields) {
		return `
			mutation {
				createAddon(
					name: """${JSON.stringify(fields.name)}""", 
					description: """${JSON.stringify(fields.description)}""", 
					services: {sync:${JSON.stringify(fields['services'])}},
					${helperFunctions.convertFieldsToString(fields)}) {
						${addonFields()}
				}
			}
		`;
	},
	getAllAddons() {
		return `
			{
				addons {
					${addonFields()}
				}
			}
		`;
	},
	getAvailableAddons(projectId) {
		return `
			{
				availableAddonsByProjectId(project_id: ${projectId}) {
					${addonFields()}
				}
			}
		`;
	},
	deleteAddon(addonId) {
		return `
			mutation {
				deleteAddon(id: ${addonId}) {
					${addonFields()}
				}
			}
		`;
	},
	getAddonById(addonId) {
		return `
			{
				addonById(id: ${addonId}) {
					${addonFields()}
				}
			}
		`;
	},
	updateAddon(addonId, fields) {
		let name = fields.name ? `name: """${JSON.stringify(fields.name)}""",` : ``;
		let description = fields.description ? `description: """${JSON.stringify(fields.description)}""",` : ``;
		return `
			mutation {
				updateAddon(
					id: ${addonId}, 
					${name}
					${description}
					services: {sync:${JSON.stringify(fields['services'])}},
					${helperFunctions.convertFieldsToString(fields)}) {
						${addonFields()}
				}
			}
		`;
	},
	getProject(projectId) {
		return `
			{
				projectById(id: ${projectId}) {
					${projectFields()}
				}
			}
		`;
	},
	getPMProject(projectId) {
		return `
			{
				project: pmProjectById(id: ${projectId}) {
					${projectFields()}
					projectSettingsGeneral {
						model_name
						project_id
						created_at
						site_title
						use_custom_domain
						site_url
						language
						email
						admin_email
						signature
						clients_can_access
        		customers_can_access
					}
					projectSettingsHomepage {
						model_name
						project_id
						created_at
						block_1_image_file
						block_1_headline
						block_1_text
						block_2_name
						block_2_position
						block_2_photo_file
						block_2_email
						block_2_phone_number
						block_2_button_text
						block_2_button_url
						block_3_text
						block_3_link_text
						block_3_link_to
						block_3_custom_link
						block_3_image_file
						block_4_text
						block_4_link_text
						block_4_link_to
						block_4_custom_link
						block_4_image_file
					}
					projectSettingsDesign {
						model_name
						project_id
						created_at
						logo_file
						login_screen_image_file
						favicon_file
						primary_color
					}
					projectSettingsFeatures {
						model_name
						project_id
						created_at
						uses_deadlines
						uses_help_center
						uses_news
						uses_docubox
						communication_method
						project_mode
					}
				}
			}
		`;
	},
	getPMGeneralPreset(projectId) {
		return `
			{
				projectPreset: getPMGeneralPreset(id: ${projectId}) {
					site_title
					email
					signature
				}
			}
		`;
	},
	fetchPMGeneralConfig(projectId) {
		return `
			{
				config: pmGeneralConfig {
					default_domain
				}
			}
		`;
	},
	getPMEligibleProjects(page = null, items_per_page = null, search = '') {
		return `
			{
				projects: pmProjects(page: ${page}, items_per_page: ${items_per_page}, search: "${search}") {
					${projectFields(0)}
				}
			}
		`;
	},
	getPMEligibleProject() {
		return `
			{
				project: pmProjectById {
					${projectFields()}
				}
			}
		`;
	},
	getAllProjects() {
		return `
			{
				projects(orderBy: [
					{
						field: "id"
						order: DESC
					}
				]) {
					${projectFields(0)}
				}
			}
		`;
	},
	getAllReportProjects() {
		return `
			{
				projects(orderBy: [
					{
						field: "name"
						order: ASC
					}
				]) {
					${projectFields(0)}
				}
			}
		`;
	},
	// updateProjectSettingsGeneral(data) {
	// 	return `
	// 		mutation {
	// 			updateProjectSettingsGeneral(${helperFunctions.convertFieldsToString(data)}) {
	// 				status
	// 				message
	// 				projectSettingsGeneral {
	// 					model_name
	// 					project_id
	// 					created_at
	// 					site_title
	// 					site_url
	// 					language
	// 					email
	// 					signature
	// 				}
	// 			}
	// 		}
	// 	`;
	// },
	updateProjectSettingsGeneral(data, signature) {
		return `
			mutation {
				projectSettingsUpdate: updateProjectSettingsGeneral(${helperFunctions.convertFieldsToString(data)}, signature: """${signature}""") {
					model_name
					project_id
					created_at
					site_title
					site_url
					language
					email
					admin_email
					signature
				}
			}
		`;
	},
	updateProjectSettingsHomepage(data, block_1_text) {
		return `
			mutation(
				$block1ImageFile: [Upload]
				$block2PhotoFile: [Upload]
				$block3ImageFile: [Upload]
				$block4ImageFile: [Upload]
			) {
				projectSettingsUpdate: updateProjectSettingsHomepage(
					block1ImageFile: $block1ImageFile,
					block2PhotoFile: $block2PhotoFile,
					block3ImageFile: $block3ImageFile,
					block4ImageFile: $block4ImageFile,
					${helperFunctions.convertFieldsToString(data)},
					block_1_text: """${block_1_text}"""
				) {
					model_name
					project_id
					created_at
					block_1_image_file
					block_1_headline
					block_1_text
					block_2_name
					block_2_position
					block_2_photo_file
					block_2_email
					block_2_phone_number
					block_2_button_text
					block_2_button_url
					block_3_text
					block_3_link_text
					block_3_link_to
					block_3_custom_link
					block_3_image_file
					block_4_text
					block_4_link_text
					block_4_link_to
					block_4_custom_link
					block_4_image_file
				}
			}
		`;
	},
	updateProjectSettingsDesign(data) {
		return `
			mutation(
				$logoFile: [Upload]
				$loginScreenImageFile: [Upload]
				$faviconFile: [Upload]
			) {
				projectSettingsUpdate: updateProjectSettingsDesign(
					logoFile: $logoFile,
					loginScreenImageFile: $loginScreenImageFile,
					faviconFile: $faviconFile,
					${helperFunctions.convertFieldsToString(data)}
				) {
					model_name
					project_id
					created_at
					logo_file
					login_screen_image_file
					favicon_file
					primary_color
				}
			}
		`;
	},
	updateProjectSettingsFeatures(data) {
		return `
			mutation {
				projectSettingsUpdate: updateProjectSettingsFeatures(${helperFunctions.convertFieldsToString(data)}) {
					model_name
					project_id
					created_at
					uses_deadlines
					uses_help_center
					uses_news
					uses_docubox
					communication_method
					project_mode
				}
			}
		`;
	},
	getFilteredProjects(
		page,
		items_per_page = null,
		company_id = null,
		country_id = null,
		status_id = null,
		search = '',
		creator = '',
		date_time_from = '',
		date_time_to = ''
	) {
		return `
			{
				filteredProjects(
					page: ${page}, 
					items_per_page: ${items_per_page}, 
					company_id: "${company_id}", 
					country_id: "${country_id}", 
					status_id: "${status_id}", 
					search: "${search}", 
					creator: "${creator}", 
					date_time_from: "${date_time_from}", 
					date_time_to: "${date_time_to}"
				) {
					${projectFields(0)}
				}
			}
		`;
	},
	fetchAvailableProjectFiltersData(
		company_id = null,
		country_id = null,
		status_id = null,
		search = '',
		creator = '',
		date_time_from = '',
		date_time_to = ''
	) {
		return `
			{
				availableProjectFiltersData(
				company_id: "${company_id}", 
				country_id: "${country_id}", 
				status_id: "${status_id}", 
				search: "${search}", 
				creator: "${creator}", 
					date_time_from: "${date_time_from}", 
					date_time_to: "${date_time_to}"
				)
			}
		`;
	},
	getProjectUsersWithAppsMarketplacesRoles(projectId) {
		return `
			{
				projectUsersWithAppsMarketplacesRoles(id: ${projectId})
			}
		`;
	}
}
