export default class CompanyProjectPolicy
{
    projectPermission (user, project, permission) {

        if(user.role.key_name === 'admin') {
            return true
        }

        if (user.role.company_restricted && project !== null
         && user.user_companies.indexOf(parseInt(project.company_id)) == -1) {
            return false
        }

        if (!user.aclPermissions[permission]) {
            return false
        }

        return true;
    }
    /**
     * Determine whether the user can view any models.
     *
     * @param  {object}  user
     * @return mixed
     */
    viewAny(user)
    {
        return true
    }

    /**
     * Determine whether the user can view the model.
     *
     * @param  {object}  user
     * @param  {object}  project
     * @return {mixed}
     */
    view(user, project)
    {
        return this.projectPermission(user, project, 'view_projects')
    }

    /**
     * Determine whether the user can create models.
     *
     * @param  {object}  user
     * @return {mixed}
     */
    create(user)
    {
        if(user.role.key_name === 'admin') {
            return true
        }
        return user.aclPermissions.add_projects
    }

    /**
     * Determine whether the user can update the model.
     *
     * @param  {object}  user
     * @param  {object}  project
     * @return {mixed}
     */
    update(user, project)
    {
        return this.projectPermission(user, project, 'edit_projects')
    }

    /**
     * Determine whether the user can update the model.
     *
     * @param  {object}  user
     * @param  {object}  project
     * @return {mixed}
     */
    edit(user, project)
    {
        return this.projectPermission(user, project, 'edit_projects')
    }

    /**
     * Determine whether the user can restore the model.
     *
     * @param  {object}  user
     * @param  {object}  project
     * @return {mixed}
     */
    restore(user, project)
    {
        return this.projectPermission(user, project, 'delete_projects')
    }

    /**
     * Determine whether the user can delete the model.
     *
     * @param  {object}  user
     * @param  {object}  project
     * @return {mixed}
     */
    delete(user, project)
    {
        return this.projectPermission(user, project, 'delete_projects')
    }

    /**
     * Determine whether the user can permanently delete the model.
     *
     * @param  {object}  user
     * @param  {object}  project
     * @return {mixed}
     */
    forceDelete (user, project)
    {
        return this.projectPermission(user, project, 'delete_projects')
    }

    editProjectCompany (user, project)
    {
        return (user.role.key_name === 'admin')
    }

    editDashboardSettings (user, project)
    {
        return (user.role.key_name === 'admin')
    }

    editServices (user, project)
    {
        return !user.role.company_restricted && this.projectPermission(user, project, 'edit_projects')
    }

    seePropsterID (user, project)
    {
        return this.projectPermission(user, project, 'view_propster_id')
    }

    editPropsterID (user, project)
    {
        return this.projectPermission(user, project, 'edit_propster_id')
    }
}
