/*=========================================================================================
  File Name: moduleOrders.js
  Description: Orders Module
==========================================================================================*/


import state from './moduleOrdersState.js'
import mutations from './moduleOrdersMutations.js'
import actions from './moduleOrdersActions.js'
import getters from './moduleOrdersGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

