/*=========================================================================================
  File Name: moduleStream.js
  Description: Stream Module
==========================================================================================*/

import state from './moduleStreamState.js'
import mutations from './moduleStreamMutations.js'
import actions from './moduleStreamActions.js'
import getters from './moduleStreamGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

