<template>
  <div :id="idName" @click="generate" ref="generateCsv">
    <slot>Download {{name}}</slot>
  </div>
</template>

<script>

import mapKeys from "lodash.mapkeys";
import pickBy from "lodash.pickby";
import pick from "lodash.pick";
import get from "lodash.get";

import { saveAs } from "file-saver";
import { unparse } from "papaparse";

export const isType = (value, type) => typeof value === type;

export default {
  name: "JsonCSV",
  props: {
    /**
     * Json to download
     */
    data: {
      type: Array,
      required: true
    },
    /**
     * fields inside the Json Object that you want to export
     * if no given, all the properties in the Json are exported
     * Can either be an array or a function
     */
    exportFields: {
      required: false
    },
    /**
     * filename to export, default: data.csv
     */
    name: {
      type: String,
      default: "data.csv"
    },
    /**
     * Delimiter for the CSV file
     */
    delimiter: {
      type: String,
      default: ",",
      required: false
    },
    /**
     * Should the module add SEP={delimiter}
     *
     * Useful for opening file with Excel
     */
    separatorExcel: {
      type: Boolean,
      default: false
    },
    /**
     * What will be the encoding of the file
     */
    encoding: {
      type: String,
      default: "utf-8"
    },
    /**
     * Advanced options for Papaparse that is used to export to CSV
     */
    advancedOptions: {
      type: Object,
      default: () => {}
    },
    /**
     * Labels for columns
     *
     * Object or function
     */
    labels: {
      required: false
    },
    /**
     * Used only for testing purposes
     */
    testing: {
      required: false,
      default: false
    }
  },
  data () {
    return {
      parsedData: JSON.parse(JSON.stringify(this.data))
    };
  },
  computed: {
    // unique identifier
    idName() {
      const now = new Date().getTime();
      return "export_" + now;
    },
    exportableData() {
      const filteredData = this.cleaningData();

      if (!filteredData.length) {
        return null;
      }

      return filteredData;
    }
  },
  methods: {
    updateData (data) {
      this.parsedData = data;
    },
    labelsFunctionGenerator() {
      if (
        !isType(this.labels, "undefined") &&
        !isType(this.labels, "function") &&
        !isType(this.labels, "object")
      ) {
        throw new Error("Labels needs to be a function(value,key) or object.");
      }

      if (isType(this.labels, "function")) {
        return item => {
          let _mapKeys = mapKeys(item, this.labels);
          return _mapKeys;
        };
      }

      if (isType(this.labels, "object")) {
        return item => {
          return mapKeys(item, (item, key) => {
            return this.labels[key] || key;
          });
        };
      }

      return item => item;
    },

    fieldsFunctionGenerator() {
      if (
        !isType(this.exportFields, "undefined") &&
        !isType(this.exportFields, "function") &&
        !isType(this.exportFields, "object") &&
        !Array.isArray(this.exportFields)
      ) {
        throw new Error("Fields needs to be a function(value,key) or array.");
      }

      if (
        isType(this.exportFields, "function") ||
        (isType(this.exportFields, "object") && !Array.isArray(this.exportFields))
      ) {
        return item => {
          return pickBy(item, this.exportFields);
        };
      }

      if (Array.isArray(this.exportFields)) {
        return item => {
          let obj = {};

          this.exportFields.forEach(field => {
            // const value = get(item, field);
            // obj[field] = value ? value.replace(/(\r\n|\n|\r|")/gm, "") : value;
            obj[field] = get(item, field);
          });

          return obj;
          //return pick(item, this.exportFields);
        };
      }

      return item => item;
    },

    cleaningData() {
      if (
        isType(this.exportFields, "undefined") &&
        isType(this.labels, "undefined")
      ) {
        return this.parsedData;
      }

      const labels = this.labelsFunctionGenerator();
      const fields = this.fieldsFunctionGenerator();

      return this.parsedData.map(item => labels(fields(item)));
    },

    generate() {
      this.$emit("export-started");

      const dataExport = this.exportableData;

      if (!dataExport) {
        console.error("No data to export");
        return;
      }

      let csv = unparse(
        dataExport,
        Object.assign(
          {
            delimiter: this.delimiter,
            encoding: this.encoding
          },
          this.advancedOptions
        )
      );

      if (this.separatorExcel) {
        csv = "SEP=" + this.delimiter + "\r\n" + csv;
      }

      //Add BOM when UTF-8
      if (this.encoding === "utf-8") {
        csv = "\ufeff" + csv;
      }

      this.$emit("export-finished");

      if (!this.testing) {
        let blob = new Blob([csv], {
          type: "application/csvcharset=" + this.encoding
        });

        saveAs(blob, this.name);
      }
    }
  }
};

</script>