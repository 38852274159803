/*=========================================================================================
  File Name: moduleRolesPermissionsMutations.js
  Description: RolesPermissions Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_ROLES (state, roles) {
    state.roles = roles
  },
  SET_MARKETPLACE_ROLES (state, marketplaceRoles) {
    state.marketplaceRoles = marketplaceRoles
  },
  SET_USER_MARKETPLACE_ROLES_STRUCTURE (state, structure) {
    state.userMarketplaceRolesStructure = structure
  }
}
