/*=========================================================================================
  File Name: moduleCountries.js
  Description: Countries Module
==========================================================================================*/


import state from './moduleCountriesState.js'
import mutations from './moduleCountriesMutations.js'
import actions from './moduleCountriesActions.js'
import getters from './moduleCountriesGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

