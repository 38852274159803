export default {
	getAllCountries() {
		return `
			{
				countries {
					id
					name
				}
			}
		`;
	}
}
