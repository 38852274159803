/*=========================================================================================
  File Name: moduleProjectsMutations.js
  Description: Projects Module Mutations
==========================================================================================*/

export default {
  ADD_NOTIFICATION (state, notification) {
    state.notifications = [...state.notifications, ...notification]
  },
  APPEND_NOTIFICATIONS (state, fetchedNotifications) {
    state.notifications = [...state.notifications, ...fetchedNotifications]
  },
  CLEANUP_NOTIFICATIONS (state) {
    state.notifications = []
  },
  SET_NEW_NOTIFICATIONS_COUNT (state, newNotificationsCount) {
    state.newNotificationsCount = newNotificationsCount
  },
  SET_UNREAD_NOTIFICATIONS_COUNT (state, unreadNotificationsCount) {
    state.unreadNotificationsCount = unreadNotificationsCount
  },

}
