/*=========================================================================================
  File Name: moduleEventsActions.js
  Description: Events Module Actions
  Description: Events Module Actions
==========================================================================================*/

import axios from '@/axios.js';
import helperFunctions from '@/helper/functions.js';
import apiQueries from './api/queries.js';

export default {
  logEvent ({ commit }, data) {
    // console.log(data);
    const query = apiQueries.logEvent(data.app_key_name, data.object_key_name, data.event_key_name, data.context, data.project_id, data.company_id, data.user_id, data.marketplace_id, data.object_id);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.response;

          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchFilteredEvents ({ commit }, data) {
    const query = apiQueries.getFilteredEvents(data.page, data.stats_user_id, data.stats_company_id, data.app_id, data.role_id, data.instance_id, data.event_type_id, data.object_type_id,
      data.object_id, data.date_time_from, data.date_time_to, data.hide_propster);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }

        const responseData = response.data.data.filteredEvents;
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  },
  fetchAvailableFilters ({ commit }, data) {
    const query = apiQueries.getAvailableFilters(data.stats_user_id, data.stats_company_id, data.app_id, data.role_id, data.instance_id, data.event_type_id, data.object_type_id, data.object_id, data.date_time_from, data.date_time_to, data.hide_propster);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }

        const responseData = response.data.data.availableEventFilters;
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  },
}
