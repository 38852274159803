/*=========================================================================================
  File Name: moduleCompaniesMutations.js
  Description: Companies Module Mutations
==========================================================================================*/


export default {
  SET_COMPANIES (state, companies) {
    state.companies = companies;
  },
  SET_COMPANIES_LIST (state, companies) {
    state.companies = companies
  },
  SET_PARENT_COMPANIES_WITH_CHILDS (state, companies) {
    state.parentCompaniesWithChilds = companies
  },
  SET_PARENT_COMPANIES (state, companies) {
    state.parentCompanies = companies.filter(c => {
      return c.company_id === null
    }).map(company => {
      return {
        label: company.name,
        value: parseInt(company.id),
        identity_provider_id: company.identity_provider_id
      }
    });
  },
  ADD_NEW_COMPANY (state, company) {
    state.companies = [...state.companies, ...company];
  },
  DELETE_COMPANY (state, deletedCompany) {
    state.companies = state.companies.filter(company => {
      return company.id !== deletedCompany.id;
    });
  },
  BULK_DELETE_COMPANIES (state, deletedCompanies) {
    let companyIds = deletedCompanies.map(company => {
      return company.id;
    });

    state.companies = state.companies.filter(company => {
      return companyIds.indexOf(company.id) === -1;
    });
  }
}
