const roleFields = () => {
  return `
    id
    key_name
    name
    company_restricted
    license_restricted
    level
    userPermissions {
      id
      key_name
    }
   `
}

const identityProviderFields = () => {
  return `
    id
    identity_provider_type_id
    name
    azure_client_id
    azure_tenant_id
    azure_client_secret
    azure_certificate
    identityProviderType {
      id
      key_name
      name
    }
    companies {
      id
      name
    }
   `
}

const identityProviderTypeFields = () => {
  return `
    id
    name
    key_name
   `
}

const userFields = () => {
  return `
    id
    email
    first_name
    last_name
    avatar_url
    language_id
    user_companies
    language {
      name
      code
      icon_url
    }
    role {
      key_name
      company_restricted
      license_restricted
      permission {
        permissions {
          key_name
        }
      }
    }
    companies {
      id
      name
      marketplaces {
        name
        website
        is_private
      }
    }
  `
}

import helperFunctions from '@/helper/functions.js'

export default {
  login (email, password, remember_me) {
    return `mutation {
          login(input: {
            username: "${  email  }",
            password: "${  password  }"
            remember_me: ${  remember_me  }
          }) {
            access_token
            refresh_token
            expires_in
            token_type
            user {
              ${userFields()}
            }
            sso {
              swm_sso_access_token
              expires
            }
          }
        }`
  },
  ssoLogin (email, code) {
    return `mutation {
          ssoLogin(input: {
            email: "${  email  }"
            adcode: "${  code  }"
          }) {
            access_token
            refresh_token
            expires_in
            token_type
            user {
              ${userFields()}
            }
            sso {
              swm_sso_access_token
              expires
            }
          }
        }`
  },
  logout () {
    return `mutation {
          logout {
            status
            message
          }
        }`
  },
  refreshToken (refreshToken) {
    return `mutation {
          refreshToken(input: {
            refresh_token: "${  refreshToken  }"
          }) {
            access_token
            refresh_token
            expires_in
            token_type
          }
        }`
  },
  passwordReset (token, newPassword, newPasswordRepeat) {
    return `mutation {
          login(input: {
            username: "${  email  }",
              password: "${  password  }"
          }) {
            access_token
            refresh_token
            expires_in
            token_type
            user {
              id
              email
              first_name
              last_name
            }
          }
        }`
  },
  passwordChange (oldPassword, newPassword, newPasswordRepeat) {
    return `mutation {
          login(input: {
            username: "${  email  }",
              password: "${  password  }"
          }) {
            access_token
            refresh_token
            expires_in
            token_type
            user {
              id
              email
              first_name
              last_name
            }
          }
        }`
  },
  forgotPassword (email) {
    return `mutation {
          forgotPassword(input: {
            email: "${  email  }"
          }) {
            status
            message
          }
        }`
  },
  setPassword (email, token, password, password_confirmation) {
    return `mutation {
          updateForgottenPassword(input: {
            email: "${  email  }",
            token: "${  token  }",
            password: "${  password  }",
            password_confirmation: "${  password_confirmation  }"
          }) {
            status
            message
          }
        }`
  },
  fetchRolesList () {
    return `{
        userRoles {
          value: key_name,
          label: key_name
        }
      }`
  },
  fetchRoles () {
    return `{
        userRoles {
          id,
          key_name,
          name,
          company_restricted,
          license_restricted,
          level
          userPermissions {
            id
            key_name
          }
        }
      }`
  },
  fetchPermissionsList () {
    return `{
        userPermissions {
          id
          key_name
        }
      }`
  },
  createRole (roleData) {
    const userPermissionsSync = `, userPermissions: {sync:${JSON.stringify(roleData.userPermissions)}}`
    let createName = roleData.name ? `name:${roleData.name},` : ``;
    return `
         mutation {
          createUserRole(key_name:"${roleData.key_name}", ${createName}, company_restricted: ${roleData.company_restricted}, license_restricted: ${roleData.license_restricted}, level: ${roleData.level}  ${userPermissionsSync}) {
           ${roleFields()}
          }
         }
        `
  },
  updateRole (roleData) {
    const userPermissionsSync = `, userPermissions: {sync:${JSON.stringify(roleData.userPermissions)}}`
    let updateName = roleData.name ? `name:${roleData.name},` : ``;
    return `
         mutation {
          updateUserRole(id:${roleData.id}, key_name:"${roleData.key_name}", ${updateName}, company_restricted: ${roleData.company_restricted}, license_restricted: ${roleData.license_restricted}, level: ${roleData.level} ${userPermissionsSync}) {
           ${roleFields()}
          }
         }
        `
  },
  deleteRole (roleId) {
    return `
         mutation {
          deleteUserRole(id: ${roleId}) {
           ${roleFields()}
          }
         }
        `
  },
  getResetTokenStatus (token, email) {
    return `
     {
      checkResetToken(token: "${token}", email: "${email}") {
       status,
       message
      }
     }`
  },
  getUserById(userId) {
    return `
      {
        userById(id: ${userId}) {
          ${userFields()}
        }
      }
    `;
  },
  fetchIdentityProviders () {
    return `{
        list: identityProviders {
          ${identityProviderFields()}
        }
      }`
  },
  fetchIdentityProviderTypes () {
    return `{
        list: identityProviderTypes {
          ${identityProviderTypeFields()}
        }
      }`
  },
  createIdentityProvider (data) {
    return `
         mutation {
          create: createIdentityProvider(
            identity_provider_type_id:${data.identity_provider_type_id}, 
            name:"${data.name}", 
            azure_client_id:"${data.azure_client_id}",
            azure_tenant_id:"${data.azure_tenant_id}",
            azure_client_secret:"${data.azure_client_secret}",
            azure_certificate:"${data.azure_certificate}"
          ) {
           ${identityProviderFields()}
          }
         }
        `
  },
  updateIdentityProvider (data) {
    const azure_client_secret = data.azure_client_secret ? `azure_client_secret:"${data.azure_client_secret}"` : '';

    return `
         mutation {
          update: updateIdentityProvider(
            id:${data.id}, 
            identity_provider_type_id:${data.identity_provider_type_id}, 
            name:"${data.name}", 
            azure_client_id:"${data.azure_client_id}",
            azure_tenant_id:"${data.azure_tenant_id}",
            ${azure_client_secret}
            azure_certificate:"${data.azure_certificate}"
          ) {
           ${identityProviderFields()}
          }
         }
        `
  },
  deleteIdentityProvider (id) {
    return `
         mutation {
          delete: deleteIdentityProvider(id: ${id}) {
           ${identityProviderFields()}
          }
         }
        `
  },
  checkIdentityProvider (email) {
    return `
        mutation {
          checkIdentityProvider(input: {
            email: "${email}"
          }) {
           key_name
           azure_client_id
           azure_tenant_id
           redirect_uri
           authority
           scope
          }
        }`;
  },
  identityProviderLogout (email) {
    return `
        mutation {
          identityProviderLogout {
           key_name
           azure_tenant_id
           logout_uri
           authority
          }
        }`;
  },
}
