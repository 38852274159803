const appServiceFields = () => {
	return `
		id
		key_name
		created_at
		updated_at
	`;
};

const mpRoleListFields = () => {
	return `
		id
		title
		key_name
		company_restricted
		is_marketplace_level
		is_company_level
		is_project_level
		created_at
		updated_at
	`;
};

const mpRoleFields = () => {
	return `
		id
		title
		key_name
		company_restricted
		is_marketplace_level
		is_company_level
		is_project_level
		created_at
		updated_at
		ssoMarketplaceRoles {
			marketplace_id
			sso_mp_role_key_name
			user_id
		}
		ssoCompanyMarketplaceRoles {
			company_id
			marketplace_id
			sso_mp_role_key_name
			user_id
		}
		ssoMarketplaceProjectRoles {
			project_id
			company_id
			marketplace_id
			sso_mp_role_key_name
			user_id
		}
	`;
};

import helperFunctions from '@/helper/functions.js';

export default {
	getAllRoles() {
		return `
			{
				ssoMpRoles {
					${mpRoleListFields()}
				}
			}
		`;
	},
	getAllDetailedRoles() {
		return `
			{
				ssoMpRoles {
					${mpRoleFields()}
				}
			}
		`;
	},
	createMpRole (roleData) {
		return `
         mutation {
          createSsoMpRole(key_name:"${roleData.key_name}", title:${roleData.title}, company_restricted: ${roleData.company_restricted}) {
           ${mpRoleFields()}
          }
         }
        `
	},
	updateMpRole (roleData) {
		let updateTitle = roleData.title ? `title:${roleData.title},` : ``;

		return `
         mutation {
          updateSsoMpRole(id:${roleData.id}, ${updateTitle} company_restricted: ${roleData.company_restricted}, ${helperFunctions.convertFieldsToString(roleData.fields)}) {
           ${mpRoleFields()}
          }
         }
        `
	},
	deleteMpRole (roleId) {
		return `
         mutation {
          deleteSsoMpRole(id: ${roleId}) {
           ${mpRoleFields()}
          }
         }
        `
	},
}
