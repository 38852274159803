/*=========================================================================================
  File Name: moduleMarketplaces.js
  Description: Marketplaces Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleSsoAppState.js'
import mutations from './moduleSsoAppMutations.js'
import actions from './moduleSsoAppActions.js'
import getters from './moduleSsoAppGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

