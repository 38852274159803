/*=========================================================================================
  File Name: moduleMarketplaces.js
  Description: Marketplaces Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleSsoState.js'
import mutations from './moduleSsoMutations.js'
import actions from './moduleSsoActions.js'
import getters from './moduleSsoGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

