/*=========================================================================================
  File Name: moduleProjectsActions.js
  Description: Projects Module Actions
==========================================================================================*/

import axios from '@/axios.js';
import helperFunctions from '@/helper/functions.js';
import apiQueries from './api/queries.js';

export default {
  createProject ({ commit }, projectData) {
    const query = apiQueries.createProject(projectData.data);
    let files = {};
    if(projectData.data.icon) {
      files.icon = projectData.data.icon;
    }
    if(projectData.data.key_visual) {
      files.key_visual = projectData.data.key_visual;
    }
    const data = helperFunctions.makeFormDataIfFileExists(query, files);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', data)
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.parseGraphQLErrorDebugMessages(response.data.errors));
          }

          let responseData = response.data.data.createProject;
          responseData.icon = null;
          responseData.removeIcon = null;
          commit('ADD_NEW_PROJECT', [responseData]);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchUserProjectsWithRoles (context, userId) {
    const query = apiQueries.getUserProjectsWithRoles(userId);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.projects;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchUserPMProjectsWithRoles (context, userId) {
    const query = apiQueries.getUserPMProjectsWithRoles(userId);
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }
        const responseData = response.data.data.projects;
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  },
  fetchFilteredProjects ({ commit }, data) {
    const query = apiQueries.getFilteredProjects(
      data.page,
      data.items_per_page,
      data.company_id,
      data.country_id,
      data.status_id,
      data.search,
      data.creator,
      data.date_time_from,
      data.date_time_to
    );

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.filteredProjects;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchAvailableProjectFiltersData ({ commit }, data) {
    const query = apiQueries.fetchAvailableProjectFiltersData(
      data.company_id,
      data.country_id,
      data.status_id,
      data.search,
      data.creator,
      data.date_time_from,
      data.date_time_to
    );

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.availableProjectFiltersData;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  updateProjectServices ({ commit }, projectData) {
    const query = apiQueries.updateProjectServices(projectData.projectId, projectData.serviceId, projectData.updatedFields);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.parseGraphQLErrorValidatorMessages(response.data.errors));
          }

          const responseData = response.data.data.updateProjectServices;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  updateProjectAddons ({ commit }, projectData) {
    const query = apiQueries.updateProjectAddons(projectData.projectId, projectData.addonId, projectData.updatedFields);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.parseGraphQLErrorValidatorMessages(response.data.errors));
          }

          const responseData = response.data.data.updateProjectAddons;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchProjectTypes ({ commit }) {
    const query = apiQueries.getAllProjectTypes();

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.projectTypes;
          commit('SET_PROJECT_TYPES', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchProjects ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getAllProjects()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }

          const responseData = response.data.data.projects;
          commit('SET_PROJECTS', responseData)
          resolve(responseData)
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchReportProjects ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getAllReportProjects()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }

          const responseData = response.data.data.projects;
          commit('SET_PROJECTS', responseData)
          resolve(responseData)
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchProject ({commit}, projectId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getProject(projectId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          commit('SET_PROJECT', response.data.data.projectById)
          resolve(response.data.data.projectById)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchPMGeneralConfig ({commit}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.fetchPMGeneralConfig()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.config)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchPMProject ({commit}, projectId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getPMProject(projectId)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        commit('SET_PROJECT', response.data.data.project)
        resolve(response.data.data.project)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  fetchPMGeneralPreset ({commit}, projectId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getPMGeneralPreset(projectId)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        resolve(response.data.data.projectPreset)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  fetchPMEligibleProjects ({ commit }, data) {
    const query = apiQueries.getPMEligibleProjects(data.page, data.items_per_page, data.search);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        resolve(response.data.data.projects)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  fetchPMEligibleProject ({commit}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getPMEligibleProject()
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        resolve(response.data.data.project)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  updateProjectSettingsGeneral ({ commit }, data) {
    const preparedData = JSON.parse(JSON.stringify(data));
    const signature = preparedData.signature
    delete preparedData.signature;
    const query = apiQueries.updateProjectSettingsGeneral(preparedData, signature);
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.parseGraphQLErrorValidatorMessages(response.data.errors));
        }
        resolve(response.data.data.projectSettingsUpdate);
      })
      .catch((error) => { reject(error) })
    });
  },
  updateProjectSettingsHomepage ({ commit }, data) {
    const block_1_text = data.block_1_text
    delete data.block_1_text;
    const query = apiQueries.updateProjectSettingsHomepage(data, block_1_text);
    const queryData = helperFunctions.makeFormDataIfFileExists(query, {
      'block1ImageFile': data.block1ImageFile,
      'block2PhotoFile': data.block2PhotoFile,
      'block3ImageFile': data.block3ImageFile,
      'block4ImageFile': data.block4ImageFile,
    });
    return new Promise((resolve, reject) => {
      axios.post('/graphql', queryData)
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.parseGraphQLErrorValidatorMessages(response.data.errors));
        }
        resolve(response.data.data.projectSettingsUpdate);
      })
      .catch((error) => { reject(error) })
    });
  },
  updateProjectSettingsDesign ({ commit }, data) {
    const query = apiQueries.updateProjectSettingsDesign(data);
    const queryData = helperFunctions.makeFormDataIfFileExists(query, {
      'logoFile': data.logoFile,
      'loginScreenImageFile': data.loginScreenImageFile,
      'faviconFile': data.faviconFile
    });
    return new Promise((resolve, reject) => {
      axios.post('/graphql', queryData)
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.parseGraphQLErrorValidatorMessages(response.data.errors));
        }
        resolve(response.data.data.projectSettingsUpdate);
      })
      .catch((error) => { reject(error) })
    });
  },
  updateProjectSettingsFeatures ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.updateProjectSettingsFeatures(data)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.parseGraphQLErrorValidatorMessages(response.data.errors));
        }
        resolve(response.data.data.projectSettingsUpdate);
      })
      .catch((error) => { reject(error) })
    });
  },
  updateProjectData ({ commit }, data) {
    commit('SET_PROJECT', data);
  },
  fetchProjectUsersWithAppsMarketplacesRoles ({commit}, projectId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getProjectUsersWithAppsMarketplacesRoles(projectId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors);
          }

          const responseData = response.data.data.projectUsersWithAppsMarketplacesRoles;
          commit('SET_PROJECT_USERS_WITH_APPS_MARKETPLACES_ROLES', responseData);
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        })
    })
  },
  fetchProjectsByCompanyID ({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getAllProjectsByCompanyID(data.companyID, data.role)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        const responseData = response.data.data.projectsByCompanyId.map((proj) => {
          proj.price_range_txt = (proj.price_range_from + '') + '-' + (proj.price_range_to + '')
          return proj
        })
        resolve(responseData)
      })
      .catch((error) => { reject(error) })
    });
  },
  fetchProjectListByCompanies ({commit}, data) {
      return new Promise((resolve, reject) => {
          axios.post('/graphql', {
              query: apiQueries.getProjectListByCompanies(data.companyIDs, data.role)
          })
              .then((response) => {
                  if (helperFunctions.checkForApiErrors(response.data)) {
                      return reject(response.data.errors)
                  }
                  const responseData = response.data.data.projectListByCompanies;
                  resolve(responseData)
              })
              .catch((error) => { reject(error) })
      });
  },
  fetchProjectFiles ({commit}, projectId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getProjectFiles(projectId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors);
          }

          const responseData = response.data.data.projectFiles;
          commit('SET_PROJECT_FILES', responseData);
          resolve(responseData);
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateProjectFiles ({ commit }, filesData) {
    const query = apiQueries.updateProjectFiles(filesData.projectId);
    const files = filesData.files;
    const data = helperFunctions.makeFormDataIfFileExists(query, files);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', data)
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.parseGraphQLErrorDebugMessages(response.data.errors));
          }

          const responseData = response.data.data.updateProjectFiles;
          commit('SET_PROJECT_FILES', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchProjectMembers ({commit}, projectId) {
    commit('SET_PROJECT_MEMBERS', []);
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.fetchProjectMembers(projectId)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors);
        }
        const responseData = response.data.data.projectMembers;
        commit('SET_PROJECT_MEMBERS', responseData);
        resolve(responseData);
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  fetchProjectNonMembers ({commit}, projectId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.fetchProjectNonMembers(projectId)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors);
        }
        const responseData = response.data.data.projectNonMembers;
        commit('SET_PROJECT_NON_MEMBERS', responseData);
        resolve(responseData);
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  updateProjectMemberRole ({ commit }, data) {
    const query = apiQueries.updateProjectMemberRole(data.projectId, data.memberId, data.roleKeyName);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.parseGraphQLErrorValidatorMessages(response.data.errors));
        }
        const responseData = response.data.data.updateProjectMemberRole;
        commit('SET_PROJECT_MEMBERS', responseData);
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  },
  fetchProjectStatusesList ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getStatusesList()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors))
          }
          const responseData = response.data.data.projectStatuses
          commit('SET_PROJECT_STATUSES_LIST', responseData)
          resolve(responseData)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchPropsterProjectManagersList ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getPropsterProjectManagers()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors))
          }
          const responseData = response.data.data.propsterProjectManagers
          commit('SET_PROPSTER_PROJECT_MANAGERS', responseData)
          resolve(responseData)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateProject ({ commit }, projectData) {
    const query = apiQueries.updateProject(projectData.data);
    let files = {};
    if(projectData.data.icon) {
      files.icon = projectData.data.icon;
    }
    if(projectData.data.key_visual) {
      files.key_visual = projectData.data.key_visual;
    }
    const data = helperFunctions.makeFormDataIfFileExists(query, files);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', data)
        .then((response) => {
          // console.log(response);
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.parseGraphQLErrorDebugMessages(response.data.errors));
          }
          const responseData = response.data.data.updateProject;
          // commit('SET_PROJECT', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  pappApiUpdate ({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.pappApiUpdate(projectId)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }
        const responseData = response.data.data.response;
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  },
  deleteFile ({ commit }, fileId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.deleteFile(fileId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.deleteFileById;
          commit('DELETE_FILE', responseData);
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        })
    })
  },
  deleteProject ({ commit }, projectId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.deleteProject(projectId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.deleteProject;
          commit('DELETE_PROJECT', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchProjectOrdersStats ({commit}, {projectId, run}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getProjectOrdersStats(projectId, run)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.stats)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchProjectSwmRequestsStats ({commit}, {projectId}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getProjectSwmRequestsStats(projectId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.stats)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchProjectSwmRequestsByCategoryStats ({commit}, {projectId}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getProjectSwmRequestsByCategoryStats(projectId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.stats)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchProjectFileDownloadsStats ({commit}, {projectId, run}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getProjectFileDownloadsStats(projectId, run)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.projectFileDownloadsStatsByProjectId)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchProjectTicketsStats ({commit}, projectId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getProjectTicketsStats(projectId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.projectTicketsStatsByProjectId)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchProjectDefectsStats ({commit}, {projectId}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getProjectDefectsStats(projectId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.stats)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchProjectDefectsByCategoryStats ({commit}, {projectId}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getProjectDefectsByCategoryStats(projectId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.stats)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCompanyOrdersStats ({commit}, {companyId, run}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getCompanyOrdersStats(companyId, run)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.stats)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCompanySwmRequestsStats ({commit}, {companyId}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getCompanySwmRequestsStats(companyId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.stats)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCompanySwmRequestsByCategoryStats ({commit}, {companyId}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getCompanySwmRequestsByCategoryStats(companyId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.stats)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCompanyFileDownloadsStats ({commit}, {companyId, run}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getCompanyFileDownloadsStats(companyId, run)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.companyFileDownloadsStatsByCompanyId)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCompanyTicketsStats ({commit}, companyId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getCompanyTicketsStats(companyId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.companyTicketsStatsByCompanyId)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCompanyDefectsStats ({commit}, {companyId, run}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getCompanyDefectsStats(companyId, run)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.stats)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchCompanyDefectsByCategoryStats ({commit}, {companyId}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getCompanyDefectsByCategoryStats(companyId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.stats)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createService ({ commit }, serviceData) {
    const query = apiQueries.createService(serviceData);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.parseGraphQLErrorValidatorMessages(response.data.errors));
          }

          const responseData = response.data.data.createService;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchService (context, serviceId) {
    const query = apiQueries.getServiceById(serviceId);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.serviceById;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  updateService({ commit }, serviceData) {
    const query = apiQueries.updateService(serviceData.id, serviceData.updatedFields);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.parseGraphQLErrorValidatorMessages(response.data.errors));
          }

          const responseData = response.data.data.updateService;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchServices ({ commit }) {
    const query = apiQueries.getAllServices();
    commit('SET_SERVICES', []);
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }
          const responseData = response.data.data.services.map(item => {
            return {
              key_name: item.key_name,
              name: item.name,
              description: item.description,
              icon_code: item.icon_code,
              order: item.order,
              serviceProjects: item.serviceProjects,
              model_name: item.model_name,
              id: parseInt(item.id)
            }
          });
          commit('SET_SERVICES', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  deleteService ({ commit }, serviceId) {
    const query = apiQueries.deleteService(serviceId);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.deleteService;
          commit('DELETE_SERVICE', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  createAddon ({ commit }, addonData) {
    const query = apiQueries.createAddon(addonData);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.parseGraphQLErrorValidatorMessages(response.data.errors));
          }
          const responseData = response.data.data.createAddon;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchAddon (context, addonId) {
    const query = apiQueries.getAddonById(addonId);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.addonById;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  updateAddon({ commit }, addonData) {
    const query = apiQueries.updateAddon(addonData.id, addonData.updatedFields);
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.parseGraphQLErrorValidatorMessages(response.data.errors));
          }

          const responseData = response.data.data.updateAddon;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchAddons ({ commit }) {
    const query = apiQueries.getAllAddons();

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }
          const responseData = response.data.data.addons.map(item => {
            return {
              key_name: item.key_name,
              name: item.name,
              icon_code: item.icon_code,
              order: item.order,
              model_name: item.model_name,
              projects: item.projects,
              id: parseInt(item.id)
            }
          });
          commit('SET_ADDONS', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchAvailableAddons ({ commit }, projectId) {
    const query = apiQueries.getAvailableAddons(projectId);
    commit('SET_PROJECT_ADDONS', []);
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }
          const responseData = response.data.data.availableAddonsByProjectId.map(item => {
            return {
              key_name: item.key_name,
              name: item.name,
              description: item.description,
              icon_code: item.icon_code,
              order: item.order,
              model_name: item.model_name,
              projects: item.projects,
              id: parseInt(item.id)
            }
          });
          commit('SET_PROJECT_ADDONS', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  deleteAddon ({ commit }, addonId) {
    const query = apiQueries.deleteAddon(addonId);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }
          const responseData = response.data.data.deleteAddon;
          commit('DELETE_ADDON', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  }
}
