/*=========================================================================================
  File Name: moduleCountriesActions.js
  Description: Countries Module Actions
==========================================================================================*/

import axios from '@/axios.js';
import helperFunctions from '@/helper/functions.js';
import apiQueries from './api/queries.js';

export default {
  fetchCountries ({ commit }) {
    const query = apiQueries.getAllCountries();

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.countries;
          commit('SET_COUNTRIES', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    })
  }
}
