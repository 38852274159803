const userRolesFields = () => {
	return `
		id
		key_name
		name
		level
		permission {
			user_role_id
			user_permission_id
			permissions {
				id
				key_name
			}
		}
	`;
};

const marketplaceRolesFields = () => {
	return `
		id
		key_name
		title
		company_restricted
		is_marketplace_level
		is_company_level
		is_project_level
	`;
};

export default {
	getAllRolesPermissions() {
		return `
			{
				userRoles {
					${userRolesFields()}
				}
			}
		`;
	},
	getMarketplaceRoles() {
		return `
			{
				marketplaceRoles {
					${marketplaceRolesFields()}
				}
			}
		`;
	},
	getUserMarketplaceRolesStructure(userId) {
		return `
			{
				userMarketplaceRolesStructure(id: ${userId})
			}
		`;
	},
	updateUserMarketplaceRolesStructure(userId, rolesStructure) {
		return `
			mutation {
				updateUserMarketplaceRolesStructure(id: ${userId}, rolesStructure: """${JSON.stringify(rolesStructure)}""")
			}
		`;
	}
}
