/*=========================================================================================
  File Name: moduleMarketplacesActions.js
  Description: Marketplaces Module Actions
==========================================================================================*/

import axios from '@/axios.js';
import helperFunctions from '@/helper/functions.js';
import apiQueries from './api/queries.js';

export default {
  fetchMarketplaces ({ commit }) {
    const query = apiQueries.getAllMarketplaces();

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.marketplaces;
          commit('SET_MARKETPLACES', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchActiveMarketplaces ({ commit }) {
    const query = apiQueries.getActiveMarketplaces();

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }

        const responseData = response.data.data.marketplaces;
        commit('SET_MARKETPLACES', responseData);
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  },
  fetchFilteredMarketplaces ({ commit }, data) {
    const query = apiQueries.getFilteredMarketplaces(data.page, data.search);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }

        const responseData = response.data.data.filteredMarketplaces;
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  },
  fetchMarketplace (context, marketplaceId) {
    const query = apiQueries.getMarketplaceById(marketplaceId);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }

        const responseData = response.data.data.marketplaceById;
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  },
  fetchUserMarketplacesWithRoles (context, userId) {
    const query = apiQueries.getUserMarketplacesWithRoles(userId);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }

        const responseData = response.data.data.userMarketplacesWithRoles;
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  },
  createMarketplace({ commit }, marketplaceData) {
    const query = apiQueries.createMarketplace(marketplaceData);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.parseGraphQLErrorValidatorMessages(response.data.errors));
        }

        const responseData = response.data.data.createMarketplace;
        commit('ADD_NEW_MARKETPLACE', [responseData]);
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  },
  updateMarketplace({ commit }, marketplaceData) {
    const query = apiQueries.updateMarketplace(marketplaceData.id, marketplaceData.updatedFields);
    const files = marketplaceData.updatedFields.icon ? {icon: marketplaceData.updatedFields.icon} : {};
    const data = helperFunctions.makeFormDataIfFileExists(query, files);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', data)
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.parseGraphQLErrorValidatorMessages(response.data.errors));
        }

        const responseData = response.data.data.updateMarketplace;
        commit('UPDATE_MARKETPLACE', responseData);
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  },
  deleteMarketplace({commit}, marketplaceId) {
    const query = apiQueries.deleteMarketplace(marketplaceId);
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }
        const responseData = response.data.data.deleteMarketplace;
        commit('DELETE_MARKETPLACE', responseData);
        resolve(responseData);
      })
      .catch((error) => {
        reject(error)
      })
    });
  },
  associateCompaniesToMarketplace ({ commit }, marketplaceData) {
    const query = apiQueries.associateCompaniesToMarketplace(marketplaceData.ids, marketplaceData.companyIds);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }

        const responseData = response.data.data.associateCompaniesToMarketplaces;
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  }
}
