const appServiceFields = () => {
	return `
		id
		key_name
		created_at
		updated_at
	`;
};

const appRoleListFields = () => {
	return `
		id
		title
		key_name
		sso_app_service_id
		company_restricted
		is_service_level
		is_company_level
		is_project_level
		created_at
		updated_at
	`;
};

const appRoleFields = () => {
	return `
		id
		title
		key_name
		sso_app_service_id
		company_restricted
		is_service_level
		is_company_level
		is_project_level
		created_at
		updated_at
		ssoServiceRoles {
			sso_app_service_id
			sso_app_role_key_name
			user_id
		}
		ssoCompanyServiceRoles {
			company_id
			sso_app_service_id
			sso_app_role_key_name
			user_id
		}
		ssoProjectRoles {
			project_id
			company_id
			sso_app_service_id
			sso_app_role_key_name
			user_id
		}
	`;
};

import helperFunctions from '@/helper/functions.js';

export default {
	getAllServices() {
		return `
			{
				ssoAppServices {
					${appServiceFields()}
				}
			}
		`;
	},
	getAllRoles() {
		return `
			{
				ssoAppRoles {
					${appRoleListFields()}
				}
			}
		`;
	},
	getAllDetailedRoles() {
		return `
			{
				ssoAppRoles {
					${appRoleFields()}
				}
			}
		`;
	},
	getSsoRolesByService(ssoServiceID) {
		return `
			{
				ssoAppRolesByServiceId (${ssoServiceID}) {
					${appRoleListFields()}
				}
			}
		`;
	},
	createAppRole (roleData) {
		return `
         mutation {
          createSsoAppRole(key_name:"${roleData.key_name}", title:${roleData.title}, company_restricted: ${roleData.company_restricted}, sso_app_service_id: ${roleData.sso_app_service_id}) {
           ${appRoleFields()}
          }
         }
        `
	},
	updateAppRole (roleData) {
		let updateTitle = roleData.title ? `title:${roleData.title},` : ``;

		return `
         mutation {
          updateSsoAppRole(id:${roleData.id}, ${updateTitle} company_restricted: ${roleData.company_restricted}, sso_app_service_id: ${roleData.sso_app_service_id}, ${helperFunctions.convertFieldsToString(roleData.fields)}) {
           ${appRoleFields()}
          }
         }
        `
	},
	deleteAppRole (roleId) {
		return `
         mutation {
          deleteSsoAppRole(id: ${roleId}) {
           ${appRoleFields()}
          }
         }
        `
	},
}
