/*=========================================================================================
  File Name: moduleSsoAppActions.js
  Description: SSO App Module Actions
==========================================================================================*/

import axios from '@/axios.js';
import helperFunctions from '@/helper/functions.js';
import apiQueries from './api/queries.js';

export default {

  fetchFilteredRequestStatusList ({ commit }, data) {
    const query = apiQueries.fetchFilteredRequestStatusList(data.page, data.search);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.filteredStatsRequestStatuses;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },

  fetchDetailedRequestStatusList ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getAllDetailedRequestStatuses()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }
          const responseData = response.data.data.statsRequestStatuses;
          commit('SET_DETAILED_REQUEST_STATUS_LIST', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },

  createRequestStatus ({ commit }, itemData) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.createRequestStatus(itemData)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        commit('ADD_NEW_REQUEST_STATUS', [response.data.data.createRequestStatus])
        resolve(response.data.data.createRequestStatus)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },

  updateRequestStatus ({ commit }, itemData) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.updateRequestStatus(itemData)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        resolve(response.data.data.updateRequestStatus)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },

  deleteRequestStatus ({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.deleteRequestStatus(itemId)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        commit('DELETE_REQUEST_STATUS', response.data.data.deleteRequestStatus)
        resolve(response.data.data.deleteRequestStatus)
      })
      .catch((error) => { reject(error) })
    })
  },

  fetchFilteredDefectStatusList ({ commit }, data) {
    const query = apiQueries.fetchFilteredDefectStatusList(data.page, data.search);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.filteredStatsDefectStatuses;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },

  fetchDetailedDefectStatusList ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getAllDetailedDefectStatuses()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }
          const responseData = response.data.data.statsDefectStatuses;
          commit('SET_DETAILED_DEFECT_STATUS_LIST', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },

  createDefectStatus ({ commit }, itemData) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.createDefectStatus(itemData)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          commit('ADD_NEW_DEFECT_STATUS', [response.data.data.createDefectStatus])
          resolve(response.data.data.createDefectStatus)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateDefectStatus ({ commit }, itemData) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.updateDefectStatus(itemData)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.updateDefectStatus)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  deleteDefectStatus ({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.deleteDefectStatus(itemId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          commit('DELETE_DEFECT_STATUS', response.data.data.deleteDefectStatus)
          resolve(response.data.data.deleteDefectItemCategory)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchFilteredDefectItemCategoryList ({ commit }, data) {
    const query = apiQueries.fetchFilteredDefectItemCategoryList(data.page, data.search);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.filteredStatsDefectItemCategories;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },

  fetchDetailedDefectItemCategoryList ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getAllDetailedDefectItemCategories()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }
          const responseData = response.data.data.statsDefectItemCategories;
          commit('SET_DETAILED_DEFECT_ITEM_CATEGORY_LIST', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },

  createDefectItemCategory ({ commit }, itemData) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.createDefectItemCategory(itemData)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          commit('ADD_NEW_DEFECT_ITEM_CATEGORY', [response.data.data.createDefectItemCategory])
          resolve(response.data.data.createDefectItemCategory)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateDefectItemCategory ({ commit }, itemData) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.updateDefectItemCategory(itemData)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.updateDefectItemCategory)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  deleteDefectItemCategory ({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.deleteDefectItemCategory(itemId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          commit('DELETE_DEFECT_ITEM_CATEGORY', response.data.data.deleteDefectItemCategory)
          resolve(response.data.data.deleteDefectItemCategory)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchFilteredSwmProductCategoryList ({ commit }, data) {
    const query = apiQueries.fetchFilteredSwmProductCategoryList(data.page, data.search);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.filteredStatsSwmProductCategories;
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },

  fetchDetailedSwmProductCategoryList ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getAllDetailedSwmProductCategories()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }
          const responseData = response.data.data.statsSwmProductCategories;
          commit('SET_DETAILED_REQUEST_PRODUCT_CATEGORY_LIST', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },

  createSwmProductCategory ({ commit }, itemData) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.createSwmProductCategory(itemData)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          commit('ADD_NEW_REQUEST_PRODUCT_CATEGORY', [response.data.data.createSwmProductCategory])
          resolve(response.data.data.createSwmProductCategory)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateSwmProductCategory ({ commit }, itemData) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.updateSwmProductCategory(itemData)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.updateSwmProductCategory)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  deleteSwmProductCategory ({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.deleteSwmProductCategory(itemId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          commit('DELETE_REQUEST_PRODUCT_CATEGORY', response.data.data.deleteSwmProductCategory)
          resolve(response.data.data.deleteSwmProductCategory)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchFilteredTicketStatusList ({ commit }, data) {
    const query = apiQueries.fetchFilteredTicketStatusList(data.page, data.search);

    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }

          const responseData = response.data.data.items;
          // commit('SET_DETAILED_TICKET_STATUS_LIST', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },

  fetchDetailedTicketStatusList ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getAllDetailedTicketStatuses()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }
          const responseData = response.data.data.items;
          commit('SET_DETAILED_TICKET_STATUS_LIST', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },

  createTicketStatus ({ commit }, itemData) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.createTicketStatus(itemData)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          commit('ADD_NEW_TICKET_STATUS', [response.data.data.createTicketStatus])
          resolve(response.data.data.createTicketStatus)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateTicketStatus ({ commit }, itemData) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.updateTicketStatus(itemData)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.updateTicketStatus)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  deleteTicketStatus ({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.deleteTicketStatus(itemId)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          commit('DELETE_TICKET_STATUS', response.data.data.deleteTicketStatus)
          resolve(response.data.data.deleteTicketStatus)
        })
        .catch((error) => { reject(error) })
    })
  },
}
