/*=========================================================================================
  File Name: moduleProjectsState.js
  Description: Projects Module State
==========================================================================================*/

export default {
  sent_email: {},
  sent_emails: [],
  activities: []
}
