/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '../../http/axios/index.js'

export default {
  SET_AUTH (state, loginCredentials) {
    localStorage.setItem('accessToken', loginCredentials.access_token)
    // Set refresh token - just in case
    localStorage.setItem('refreshToken', loginCredentials.refresh_token)
    //Set token expire time
    const tokenExpiry = new Date(Date.now() + (loginCredentials.expires_in * 1000))
    localStorage.setItem('tokenExpiry', tokenExpiry)
    //Set logged in flag
    localStorage.setItem('loggedIn', 'true')
  },
  UNSET_AUTH (state) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('tokenExpiry')
    localStorage.removeItem('loggedIn')
    delete axios.defaults.headers.common["Authorization"]
  },
  SET_ROLES_LIST (state, rolesList) {
    state.rolesList = rolesList
  },
  SET_ROLES (state, roles) {
    state.roles = roles
  },
  ADD_NEW_ROLE (state, role) {
    state.roles = [...state.roles, ...role]
  },
  DELETE_ROLE (state, deletedRole) {
    state.roles = state.roles.filter(roles => {
      return roles.id !== deletedRole.id
    })
  },
  SET_PERMISSIONS_LIST (state, permissionsList) {
    state.permissionsList = permissionsList
  },
  SET_AUTOLOGIN_INTERVAL (state, interval) {
    // console.log('setting interval');
    state.autologinInterval = interval;
  },
  RESET_AUTOLOGIN_INTERVAL (state) {
    // console.log('resetting interval');
    clearInterval(state.autologinInterval);
    state.autologinInterval = false;
  },
  SET_IDENTITY_PROVIDERS (state, identityProviders) {
    state.identityProviders = identityProviders
  },
  SET_IDENTITY_PROVIDER_TYPES (state, identityProviderTypes) {
    state.identityProviderTypes = identityProviderTypes
  },
  ADD_NEW_IDENTITY_PROVIDER (state, identityProvider) {
    state.identityProviders = [...state.identityProviders, ...identityProvider]
  },
  DELETE_IDENTITY_PROVIDER (state, identityProvider) {
    state.identityProviders = state.identityProviders.filter(item => {
      return item.id !== identityProvider.id
    })
  },
  LOGIN_NEXT (state) {
    state.loginStep = 2;
  },
  LOGIN_BACK (state) {
    state.loginStep = 1;
  },
}
