/*=========================================================================================
  File Name: moduleMarketplacesMutations.js
  Description: Marketplaces Module Mutations
==========================================================================================*/


export default {
  SET_MARKETPLACES (state, marketplaces) {
    state.marketplaces = marketplaces.sort((a, b) => a.name.localeCompare(b.name));
  },
  ADD_NEW_MARKETPLACE (state, marketplace) {
    state.marketplaces = [...state.marketplaces, ...marketplace].sort((a, b) => a.name.localeCompare(b.name));
  },
  UPDATE_MARKETPLACE (state, marketplace) {
    const marketplaceIndex = state.marketplaces.findIndex((u) => u.id === marketplace.id);
    state.marketplaces[marketplaceIndex] = marketplace;
  },
  DELETE_MARKETPLACE (state, deletedMarketplace) {
    state.marketplaces = state.marketplaces.filter(marketplace => {
      return marketplace.id != deletedMarketplace.id;
    });
  },
}
