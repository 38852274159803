export default {
	privacyPolicy: `<h1>Legal Notice</h1>
<p>Information in accordance with &sect;5 of the E-Commerce Act, &sect;14 of the Unternehmensgesetzbuch, &sect;63 of the Commercial Code and disclosure requirements under &sect;25 of the Media Act.</p>
<p>PROPSTER Sonderwunsch Meister GmbH<br /> Mariahilferstra&szlig;e 37-39, DG<br /> 1060 Wien, <br /> &Ouml;sterreich</p>
<p><strong>Object of the company:</strong> IT Dienstleistungen<br /><strong>VAT-Number:</strong> ATU72559689<br /><strong>Corporate register number:</strong> FN 477854 b<br /><strong>Corporate register court:</strong> Handelsgericht Wien</p>
<p><strong>Phone:</strong> +4313610101<br /><strong>Email: </strong><a href="mailto:office@propster.tech">office@propster.tech</a></p>
<p><strong>Member of:</strong> Wirtschaftskammer Wien</p>
<p><strong>Awarding country:</strong> &Ouml;sterreich</p>
<p><strong>Chief executive</strong><br /> Milan Zahradnik</p>
<p><strong>Contact details of the data protection controller</strong><br /> If you have any question about data protection, please find the contact details of the body or person responsible for data protection below:<br /> PROPSTER &ndash; Sonderwunsch Meister GmbH<br /> Mariahilferstra&szlig;e 37-39, DG<br /> 1060 Wien<br /> &Ouml;sterreich<br /> Authorised to represent: Milan Zahradnik<br /> E-Mail: <a href="mailto:office@propster.tech">office@propster.tech</a><br /> Phone: +4313610101<br /> Company details: <a href="https://www.propster.tech/impressum/">https://www.propster.tech/impressum/</a></p>
<h2>EU Dispute Resolution</h2>
<p>We would like to inform you about the Online Dispute Resolution platform (ODR platform) in accordance with the regulation on Online Dispute Resolution in consumer matters (ODR Regulation).<br /> Consumers have the option of submitting complaints to the European Commission&rsquo;s Online Dispute Resolution platform at <a href="https://ec.europa.eu/consumers/odr/main/?event=main.home2.show">https://ec.europa.eu/consumers/odr/main/?event=main.home2.show</a>. You will find the necessary contact details in our imprint above.</p>
<p>However, we would like to note, that we are not willing or obliged to participate in dispute settlement procedures before a consumer arbitration board.</p>
<h2>Liability for the Contents of this Website</h2>
<p>We are constantly developing the content of this website and strive to provide correct and up-to-date information. Unfortunately, we cannot accept liability for the accuracy of any content on this website. This especially includes content provided by third parties. As a service provider, we are neither obliged to monitor any information you transmit or store, nor to investigate any circumstances that indicate illegal activity.</p>
<p>Due to court- or official orders under the general law, our obligations to remove information or to block the use of information remain unaffected, even if we are not responsible.</p>
<p>If you notice any problematic or illegal content, please contact us immediately so we can remove the illegal content. You will find our contact details in the imprint.</p>
<h2>Liability for Links on this Website</h2>
<p>Our website contains links to other websites for which we are not responsible. We are not liable for any linked websites, since we have had no knowledge of illegal activities. If we ever become aware of any illegal activity, we will remove any links in question immediately.</p>
<p>If you notice illegal links on our website, please contact us. You will find our contact details in the imprint.</p>
<h2>Copyright Notice</h2>
<p>All contents of this website (pictures, images, photos, texts, videos) are subject to copyright. Please ask us before distributing, reproducing or using the contents of this website &ndash; such as republishing them on other websites. If necessary, we will prosecute the unauthorised use of our website&rsquo;s content.</p>
<p>If you find content on this website that violates copyright, please contact us.</p>
<h2>Picture Credits</h2>
<p>The pictures, images and graphics on this website are protected by copyright.</p>
<p>The image rights are with:</p>
<p>Fotograf Markus Schieder<br /> Fotografin Flo Hanatschek</p>
<p>All texts are copyrighted.</p>
<h1>Privacy Policy</h1>
<h2>Table of contents</h2>
<ul>
<li><a href="#privacy-policy">Privacy Policy</a></li>
<li><a href="#scope">Scope</a></li>
<li><a href="#legal-bases">Legal bases</a></li>
<li><a href="#contact-details">Contact details of the data protection controller</a></li>
<li><a href="#storage-period">Storage Period</a></li>
<li><a href="#rights-in-accordance">Rights in accordance with the General Data Protection Regulation</a></li>
<li><a href="#data-transfer">Data transfer to third countries</a></li>
<li><a href="#security-of-data">Security of data processing operations</a></li>
<li><a href="#communications">Communications</a></li>
<li><a href="#cookies">Cookies</a></li>
<li><a href="#web-hosting">Web hosting</a></li>
<li><a href="#web-analytics">Web Analytics</a></li>
<li><a href="#email-marketing">Email-Marketing</a></li>
<li><a href="#push-messages">Push Messages</a></li>
<li><a href="#social-media">Social Media</a></li>
<li><a href="#online-marketing">Online Marketing</a></li>
<li><a href="#content-delivery-networks">Content Delivery Networks</a></li>
<li><a href="#audio-and-video">Audio &amp; Video</a></li>
<li><a href="#video-streaming">Video Conferencing &amp; Streaming</a></li>
</ul>
<h2 id="privacy-policy">Privacy Policy</h2>
<p>We have written this privacy policy (version 19.04.2022-121865447) in order to explain to you, in accordance with the provisions of the <a href="https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=celex%3A32016R0679&amp;amp%3Btid=%5Badsimpletextid%5d">General Data Protection Regulation (EU) 2016/679</a> and applicable national laws, which personal data (data for short) we as the controller &ndash; and the processors commissioned by us (e.g. providers) &ndash; process, will process in the future and what legal options you have. The terms used are to be considered as gender-neutral.<br /><strong>In short:</strong> We provide you with comprehensive information about any personal data we process about you.</p>
<p>Privacy policies usually sound very technical and use legal terminology. However, this privacy policy is intended to describe the most important things to you as simply and transparently as possible. So long as it aids transparency, technical <strong>terms are explained in a reader-friendly manner, links</strong> to further information are provided and <strong>graphics</strong> are used. We are thus informing in clear and simple language that we only process personal data in the context of our business activities if there is a legal basis for it. This is certainly not possible with brief, unclear and legal-technical statements, as is often standard on the Internet when it comes to data protection. I hope you find the following explanations interesting and informative. Maybe you will also find some information that you have not been familiar with.<br /> If you still have questions, we would like to ask you to contact the responsible body named below or in the imprint, to follow the existing links and to look at further information on third-party sites. You can of course also find our contact details in the imprint.</p>
<h2 id="scope">Scope</h2>
<p>This privacy policy applies to all personal data processed by our company and to all personal data processed by companies commissioned by us (processors). With the term personal data, we refer to information within the meaning of Article 4 No. 1 GDPR, such as the name, email address and postal address of a person. The processing of personal data ensures that we can offer and invoice our services and products, be it online or offline. The scope of this privacy policy includes:</p>
<ul>
<li>all online presences (websites, online shops) that we operate</li>
<li>Social media presences and email communication</li>
<li>mobile apps for smartphones and other devices</li>
</ul>
<p><strong>In short:</strong> This privacy policy applies to all areas in which personal data is processed in a structured manner by the company via the channels mentioned. Should we enter into legal relations with you outside of these channels, we will inform you separately if necessary.</p>
<h2 id="legal-bases">Legal bases</h2>
<p>In the following privacy policy, we provide you with transparent information on the legal principles and regulations, i.e. the legal bases of the General Data Protection Regulation, which enable us to process personal data.<br /> Whenever EU law is concerned, we refer to REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL of April 27, 2016. You can of course access the General Data Protection Regulation of the EU online at EUR-Lex, the gateway to EU law, at <a href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=121865447">https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32016R0679</a>.</p>
<p>We only process your data if at least one of the following conditions applies:</p>
<ol>
<li><strong>Consent</strong> (Article 6 Paragraph 1 lit. a GDPR): You have given us your consent to process data for a specific purpose. An example would be the storage of data you entered into a contact form.</li>
<li><strong>Contract</strong> (Article 6 Paragraph 1 lit. b GDPR): We process your data in order to fulfill a contract or pre-contractual obligations with you. For example, if we conclude a sales contract with you, we need personal information in advance.</li>
<li><strong>Legal obligation</strong> (Article 6 Paragraph 1 lit. c GDPR): If we are subject to a legal obligation, we will process your data. For example, we are legally required to keep invoices for our bookkeeping. These usually contain personal data.</li>
<li><strong>Legitimate interests</strong> (Article 6 Paragraph 1 lit. f GDPR): In the case of legitimate interests that do not restrict your basic rights, we reserve the right to process personal data. For example, we have to process certain data in order to be able to operate our website securely and economically. Therefore, the processing is a legitimate interest.</li>
</ol>
<p>Other conditions such as making recordings in the interest of the public, the exercise of official authority as well as the protection of vital interests do not usually occur with us. Should such a legal basis be relevant, it will be disclosed in the appropriate place.</p>
<p>In addition to the EU regulation, national laws also apply:</p>
<ul>
<li>In <strong>Austria</strong> this is the Austrian Data Protection Act (<strong>Datenschutzgesetz</strong>), in short <strong>DSG</strong>.</li>
<li>In <strong>Germany</strong> this is the Federal Data Protection Act (<strong>Bundesdatenschutzgesetz</strong>), in short <strong>BDSG</strong>.</li>
</ul>
<p>Should other regional or national laws apply, we will inform you about them in the following sections.</p>
<h2 id="contact-details">Contact details of the data protection controller</h2>
<p>If you have any questions about data protection, you will find the contact details of the responsible person or controller below:<br /> PROPSTER &ndash; Sonderwunsch Meister GmbH<br /> Mariahilferstra&szlig;e 37-39, DG<br /> 1060 Wien<br /> &Ouml;sterreich<br /> Authorised to represent: Milan Zahradnik <br /> E-Mail: <a href="mailto:office@propster.tech">office@propster.tech</a><br /> Phone: <a href="tel:+4313610101">+4313610101</a><br /> Company details: <a href="https://www.propster.tech/impressum/">https://www.propster.tech/impressum/</a></p>
<h2 id="storage-period">Storage Period</h2>
<p>It is a general criterion for us to store personal data only for as long as is absolutely necessary for the provision of our services and products. This means that we delete personal data as soon as any reason for the data processing no longer exists. In some cases, we are legally obliged to keep certain data stored even after the original purpose no longer exists, such as for accounting purposes.</p>
<p>If you want your data to be deleted or if you want to revoke your consent to data processing, the data will be deleted as soon as possible, provided there is no obligation to continue its storage.</p>
<p>We will inform you below about the specific duration of the respective data processing, provided we have further information.</p>
<h2 id="rights-in-accordance">Rights in accordance with the General Data Protection Regulation</h2>
<p>You are granted the following rights in accordance with the provisions of the <a href="https://gdpr-info.eu/">GDPR</a> (General Data Protection Regulation) and the Austrian <a href="https://www.ris.bka.gv.at/Dokumente/Erv/ERV_1999_1_165/ERV_1999_1_165.html">Data Protection Act (DSG)</a>:</p>
<ul>
<li>right to rectification (article 16 GDPR)</li>
<li>right to erasure (&ldquo;right to be forgotten&ldquo;) (article 17 GDPR)</li>
<li>right to restrict processing (article 18 GDPR)</li>
<li>righ to notification &ndash; notification obligation regarding rectification or erasure of personal data or restriction of processing (article 19 GDPR)</li>
<li>right to data portability (article 20 GDPR)</li>
<li>Right to object (article 21 GDPR)</li>
<li>right not to be subject to a decision based solely on automated processing &ndash; including profiling &ndash; (article 22 GDPR)</li>
</ul>
<p>If you think that the processing of your data violates the data protection law, or that your data protection rights have been infringed in any other way, you can lodge a complaint with your respective regulatory authority. For Austria this is the data protection authority, whose website you can access at <a href="https://www.data-protection-authority.gv.at/?tid=121865447">https://www.data-protection-authority.gv.at/</a>.</p>
<h2>Austria Data protection authority</h2>
<p><strong>Manager: </strong>Mag. Dr. Andrea Jelinek <strong><br /><strong>Address: </strong></strong>Barichgasse 40-42, 1030 Wien <strong><br /><strong>Phone number.: </strong></strong>+43 1 52&nbsp;152-0 <strong><br /><strong>E-mail address: </strong></strong><a href="mailto:dsb@dsb.gv.at">dsb@dsb.gv.at</a><strong><br /><strong>Website: </strong></strong><a href="https://www.dsb.gv.at/">https://www.dsb.gv.at/</a></p>
<h2 id="data-transfer">Data transfer to third countries</h2>
<p>We only transfer or process data to countries outside the EU (third countries) if you consent to this processing, if this is required by law or if it is contractually necessary. In any case, we generally only do so to the permitted extent. In most cases, your consent is the most important reason for data being processed in third countries. When personal data is being processed in third countries such as the USA, where many software manufacturers offer their services and have their servers located, your personal data may be processed and stored in unexpected ways.</p>
<p>We want to expressly point out, that according to the European Court of Justice, there is currently no adequate level of protection for data transfer to the USA. Data processing by US services (such as Google Analytics) may result in data processing and retention without the data having undergone anonymisation processes. Furthermore, US government authorities may be able to access individual data. The collected data may also get linked to data from other services of the same provider, should you have a user account with the respective provider. We try to use server locations within the EU, whenever this is offered and possible.</p>
<p>We will provide you with more details about data transfer to third countries in the appropriate sections of this privacy policy, whenever applicable.</p>
<h2 id="security-of-data">Security of data processing operations</h2>
<p>In order to protect personal data, we have implemented both technical and organisational measures. We encrypt or pseudonymise personal data wherever this is possible. Thus, we make it as difficult as we can for third parties to extract personal information from our data.</p>
<p>Article 25 of the GDPR refers to &ldquo;data protection by technical design and by data protection-friendly default&rdquo; which means that both software (e.g. forms) and hardware (e.g. access to server rooms) appropriate safeguards and security measures shall always be placed. If applicable, we will outline the specific measures below.</p>
<h2>TLS encryption with https</h2>
<p>The terms TLS, encryption and https sound very technical, which they are indeed. We use HTTPS (Hypertext Transfer Protocol Secure) to securely transfer data on the Internet.<br /> This means that the entire transmission of all data from your browser to our web server is secured &ndash; nobody can &ldquo;listen in&rdquo;.</p>
<p>We have thus introduced an additional layer of security and meet privacy requirements through technology design <a href="https://eur-lex.europa.eu/legal-content/en/TXT/HTML/?uri=CELEX:32016R0679&amp;from=EN&amp;tid=121865447">Article 25 Section 1 GDPR</a>). With the use of TLS (Transport Layer Security), which is an encryption protocol for safe data transfer on the internet, we can ensure the protection of confidential information.<br /> You can recognise the use of this safeguarding tool by the little lock-symbol , which is situated in your browser&rsquo;s top left corner in the left of the internet address (e.g. examplepage.uk), as well as by the display of the letters https (instead of http) as a part of our web address.<br /> If you want to know more about encryption, we recommend you to do a Google search for &ldquo;Hypertext Transfer Protocol Secure wiki&rdquo; to find good links to further information.</p>
<h2 id="communications">Communications</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Communications Overview</strong><br /> 👥 Affected parties: Anyone who communicates with us via phone, email or online form<br /> 📓 Processed data: e. g. telephone number, name, email address or data entered in forms. You can find more details on this under the respective form of contact<br /> 🤝 Purpose: handling communication with customers, business partners, etc.<br /> 📅 Storage duration: for the duration of the business case and the legal requirements<br /> ⚖️ Legal basis: Article 6 (1) (a) GDPR (consent), Article 6 (1) (b) GDPR (contract), Article 6 (1) (f) GDPR (legitimate interests)</p>
</td>
</tr>
</tbody>
</table>
<p>If you contact us and communicate with us via phone, email or online form, your personal data may be processed.</p>
<p>The data will be processed for handling and processing your request and for the related business transaction. The data is stored for this period of time or for as long as is legally required.</p>
<h3>Affected persons</h3>
<p>The above-mentioned processes affect all those who seek contact with us via the communication channels we provide.</p>
<h3>Telephone</h3>
<p>When you call us, the call data is stored in a pseudonymised form on the respective terminal device, as well as by the telecommunications provider that is being used. In addition, data such as your name and telephone number may be sent via email and stored for answering your inquiries. The data will be erased as soon as the business case has ended and the legal requirements allow for its erasure.</p>
<h3>Email</h3>
<p>If you communicate with us via email, your data is stored on the respective terminal device (computer, laptop, smartphone, &hellip;) as well as on the email server. The data will be deleted as soon as the business case has ended and the legal requirements allow for its erasure.</p>
<h3>Online forms</h3>
<p>If you communicate with us using an online form, your data is stored on our web server and, if necessary, forwarded to our email address. The data will be erased as soon as the business case has ended and the legal requirements allow for its erasure.</p>
<h3>Legal bases</h3>
<p>Data processing is based on the following legal bases:</p>
<ul>
<li>6 para. 1 lit. a GDPR (consent): You give us your consent to store your data and to continue to use it for the purposes of the business case;</li>
<li>6 para. 1 lit. b GDPR (contract): For the performance of a contract with you or a processor such as a telephone provider, or if we have to process the data for pre-contractual activities, such as preparing an offer;</li>
<li>6 para. 1 lit. f GDPR (legitimate interests): We want to conduct our customer inquiries and business communication in a professional manner. Thus, certain technical facilities such email programs, Exchange servers and mobile network operators are necessary to efficiently operate our communications.</li>
</ul>
<h2 id="cookies">Cookies</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Cookies Overview </strong><br /> 👥 Affected parties: visitors to the website<br /> 🤝 Purpose: depending on the respective cookie. You can find out more details below or from the software manufacturer that sets the cookie.<br /> 📓 Processed data: Depending on the cookie used. More details can be found below or from the manufacturer of the software that sets the cookie.<br /> 📅 Storage duration: can vary from hours to years, depending on the respective cookie<br /> ⚖️ Legal basis: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit. f GDPR (legitimate interests)</p>
</td>
</tr>
</tbody>
</table>
<h3>What are cookies?</h3>
<p>Our website uses HTTP-cookies to store user-specific data.<br /> In the following we explain what cookies are and why they are used, so that you can better understand the following privacy policy.</p>
<p>Whenever you surf the Internet, you are using a browser. Common browsers are for example, Chrome, Safari, Firefox, Internet Explorer and Microsoft Edge. Most websites store small text-files in your browser. These files are called cookies.</p>
<p>It is important to note that cookies are very useful little helpers. Almost every website uses cookies. More precisely, these are HTTP cookies, as there are also other cookies for other uses. HTTP cookies are small files that our website stores on your computer. These cookie files are automatically placed into the cookie-folder, which is the &ldquo;brain&rdquo; of your browser. A cookie consists of a name and a value. Moreover, to define a cookie, one or multiple attributes must be specified.</p>
<p>Cookies store certain user data about you, such as language or personal page settings. When you re-open our website to visit again, your browser submits these &ldquo;user-related&rdquo; information back to our site. Thanks to cookies, our website knows who you are and offers you the settings you are familiar to. In some browsers, each cookie has its own file, while in others, such as Firefox, all cookies are stored in one single file.</p>
<p>The following graphic shows a possible interaction between a web browser such as Chrome and the web server. The web browser requests a website and receives a cookie back from the server. The browser then uses this again as soon as another page is requested.</p>
<p>There are both first-party cookies and third-party cookies. First-party cookies are created directly by our site, while third-party cookies are created by partner-websites (e.g. Google Analytics). Each cookie must be evaluated individually, as each cookie stores different data. The expiry time of a cookie also varies from a few minutes to a few years. Cookies are not software programs and do not contain viruses, trojans or other malware. Cookies also cannot access your PC&rsquo;s information.</p>
<p>This is an example of how cookie-files can look:</p>
<p><strong>Name:</strong> _ga<br /><strong>Value:</strong> GA1.2.1326744211.152121865447-9<br /><strong>Purpose:</strong> Differentiation between website visitors<br /><strong>Expiry date:</strong> after 2 years</p>
<p>A browser should support these minimum sizes:</p>
<ul>
<li>At least 4096 bytes per cookie</li>
<li>At least 50 cookies per domain</li>
<li>At least 3000 cookies in total</li>
</ul>
<h3>Which types of cookies are there?</h3>
<p>The exact cookies that we use, depend on the used services, which will be outlined in the following sections of this privacy policy. Firstly, we will briefly focus on the different types of HTTP-cookies.</p>
<p>There are 4 different types of cookies:</p>
<p><strong>Essential cookies</strong><strong><br /></strong>These cookies are necessary to ensure the basic functions of a website. They are needed when a user for example puts a product into their shopping cart, then continues surfing on different websites and comes back later in order to proceed to the checkout. These cookies ensure the shopping cart does not get deleted, even if the user closes their browser window.</p>
<p><strong>Purposive cookies</strong><strong><br /></strong>These cookies collect information about user behaviour and whether the user receives any error messages. Furthermore, these cookies record the website&rsquo;s loading time as well as its behaviour in different browsers.</p>
<p><strong>Target-orientated cookies</strong><strong><br /></strong>These cookies ensure better user-friendliness. Thus, information such as previously entered locations, fonts sizes or data in forms stay stored.</p>
<p><strong>Advertising cookies</strong><strong><br /></strong>These cookies are also known as targeting cookies. They serve the purpose of delivering customised advertisements to the user. This can be very practical, but also rather annoying.</p>
<p>Upon your first visit to a website you are usually asked which of these cookie-types you want to accept. Furthermore, this decision will of course also be stored in a cookie.</p>
<p>If you want to learn more about cookies and do not mind technical documentation, we recommend <a href="https://tools.ietf.org/html/rfc6265">https://tools.ietf.org/html/rfc6265</a>, the Request for Comments of the Internet Engineering Task Force (IETF) called &ldquo;HTTP State Management Mechanism&rdquo;.</p>
<h3>Purpose of processing via cookies</h3>
<p>The purpose ultimately depends on the respective cookie. You can find out more details below or from the software manufacturer that sets the cookie.</p>
<h3>Which data are processed?</h3>
<p>Cookies are little helpers for a wide variety of tasks. Unfortunately, it is not possible to tell which data is generally stored in cookies, but in the privacy policy below we will inform you on what data is processed or stored.</p>
<h3>Storage period of cookies</h3>
<p>The storage period depends on the respective cookie and is further specified below. Some cookies are erased after less than an hour, while others can remain on a computer for several years.</p>
<p>You can also influence the storage duration yourself. You can manually erase all cookies at any time in your browser (also see &ldquo;Right of objection&rdquo; below). Furthermore, the latest instance cookies based on consent will be erased is after you withdraw your consent. The legality of storage will remain unaffected until then.</p>
<h3>Right of objection &ndash; how can I erase cookies?</h3>
<p>You can decide for yourself how and whether you want to use cookies. Regardless of which service or website the cookies originate from, you always have the option of erasing, deactivating or only partially accepting cookies. You can for example block third-party cookies but allow all other cookies.</p>
<p>If you want to find out which cookies have been stored in your browser, or if you want to change or erase cookie settings, you can find this option in your browser settings:</p>
<p><a href="https://support.google.com/chrome/answer/95647?tid=121865447">Chrome: Clear, enable and manage cookies in Chrome </a></p>
<p><a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac?tid=121865447">Safari: Manage cookies and website data in Safari </a></p>
<p><a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?tid=121865447">Firefox: Clear cookies and site data in Firefox </a></p>
<p><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121865447">Internet Explorer: Delete and manage cookies </a></p>
<p><a href="https://support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies?tid=121865447">Microsoft Edge: Delete cookies in Microsoft Edge </a></p>
<p>If you generally do not want cookies, you can set up your browser in a way to notify you whenever a cookie is about to be set. This gives you the opportunity to manually decide to either permit or deny the placement of every single cookie. This procedure varies depending on the browser. Therefore, it might be best for you to search for the instructions in Google. If you are using Chrome, you could for example put the search term &ldquo;delete cookies Chrome&rdquo; or &ldquo;deactivate cookies Chrome&rdquo; into Google.</p>
<h3>Legal basis</h3>
<p>The so-called &ldquo;cookie directive&rdquo; has existed since 2009. It states that the storage of cookies requires your <strong>consent</strong> (Article 6 Paragraph 1 lit. a GDPR). Within countries of the EU, however, the reactions to these guidelines still vary greatly. In Austria, however, this directive was implemented in Section 96 (3) of the Telecommunications Act (TKG). In Germany, the cookie guidelines have not been implemented as national law. Instead, this guideline was largely implemented in Section 15 (3) of the Telemedia Act (TMG).</p>
<p>For absolutely necessary cookies, even if no consent has been given, there are legitimate interests (Article 6 (1) (f) GDPR), which in most cases are of an economic nature. We want to offer our visitors a pleasant user experience on our website. For this, certain cookies often are absolutely necessary.</p>
<p>This is exclusively done with your consent, unless absolutely necessary cookies are used. The legal basis for this is Article 6 (1) (a) of the GDPR.</p>
<p>In the following sections you will find more detail on the use of cookies, provided the used software does use cookies.</p>
<h2 id="web-hosting">Web hosting</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Web hosting Overview</strong><br /> 👥 Affected parties: visitors to the website<br /> 🤝 Purpose: professional hosting of the website and security of operations<br /> 📓 Processed data: IP address, time of website visit, browser used and other data. You can find more details on this below or at the respective web hosting provider.<br /> 📅 Storage period: dependent on the respective provider, but usually 2 weeks<br /> ⚖️ Legal basis: Art. 6 para. 1 lit. f GDPR (legitimate interests)</p>
</td>
</tr>
</tbody>
</table>
<h3>What is web hosting?</h3>
<p>Every time you visit a website nowadays, certain information &ndash; including personal data &ndash; is automatically created and stored, including on this website. This data should be processed as sparingly as possible, and only with good reason. By website, we mean the entirety of all websites on your domain, i.e. everything from the homepage to the very last subpage (like this one here). By domain we mean example.uk or examplepage.com.</p>
<p>When you want to view a website on a screen, you use a program called a web browser. You probably know the names of some web browsers: Google Chrome, Microsoft Edge, Mozilla Firefox, and Apple Safari.</p>
<p>The web browser has to connect to another computer which stores the website&rsquo;s code: the web server. Operating a web server is complicated and time-consuming, which is why this is usually done by professional providers. They offer web hosting and thus ensure the reliable and flawless storage of website data.</p>
<p>Whenever the browser on your computer establishes a connection (desktop, laptop, smartphone) and whenever data is being transferred to and from the web server, personal data may be processed. After all, your computer stores data, and the web server also has to retain the data for a period of time in order to ensure it can operate properly.</p>
<p>Illustration:</p>
<h3>Why do we process personal data?</h3>
<p>The purposes of data processing are:</p>
<ol>
<li>Professional hosting of the website and operational security</li>
<li>To maintain the operational as well as IT security</li>
<li>Anonymous evaluation of access patterns to improve our offer, and if necessary, for prosecution or the pursuit of claims.li&gt;</li>
</ol>
<h3>Which data are processed?</h3>
<p>Even while you are visiting our website, our web server, that is the computer on which this website is saved, usually automatically saves data such as</p>
<ul>
<li>the full address (URL) of the accessed website (e. g. https://www.examplepage.uk/examplesubpage.html?tid=121865447)</li>
<li>browser and browser version (e.g. Chrome 87)</li>
<li>the operating system used (e.g. Windows 10)</li>
<li>the address (URL) of the previously visited page (referrer URL) (e. g. https://www.examplepage.uk/icamefromhere.html/)</li>
<li>the host name and the IP address of the device from the website is being accessed from (e.g. COMPUTERNAME and 194.23.43.121)</li>
<li>date and time</li>
<li>in so-called web server log files</li>
</ul>
<h3>How long is the data stored?</h3>
<p>Generally, the data mentioned above are stored for two weeks and are then automatically deleted. We do not pass these data on to others, but we cannot rule out the possibility that this data may be viewed by the authorities in the event of illegal conduct.</p>
<p><strong>In short:</strong> Your visit is logged by our provider (company that runs our website on special computers (servers)), but we do not pass on your data without your consent!</p>
<h3>Legal basis</h3>
<p>The lawfulness of processing personal data in the context of web hosting is justified in Art. 6 para. 1 lit. f GDPR (safeguarding of legitimate interests), as the use of professional hosting with a provider is necessary to present the company in a safe and user-friendly manner on the internet, as well as to have the ability to track any attacks and claims, if necessary.</p>
<h2>Hetzner Privacy Policy</h2>
<p>We use Hetzner for our website, which is a web hosting provider, among other things. The provider of this service is the German company Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen, Germany. You can find out more about the data that is processed through the use of Hetzner in their Privacy Policy at <a href="https://www.hetzner.com/de/rechtliches/datenschutz">https://www.hetzner.com/de/rechtliches/datenschutz</a>.</p>
<h2>WordPress.com Privacy Policy</h2>
<p>We use WordPress.com for our website, which is a modular website system. The provider of this service is the American company Automattic Inc., 60 29th Street #343, San Francisco, CA 94110, USA.</p>
<p>WordPress.com also processes data in the USA, among other countries. We would like to note, that according to the European Court of Justice, there is currently no adequate level of protection for data transfers to the USA. This can be associated with various risks to the legality and security of data processing.</p>
<p>WordPress.com uses standard contractual clauses approved by the EU Commission as the basis for data processing by recipients based in third countries (outside the European Union, Iceland, Liechtenstein, Norway and especially in the USA) or data transfer there (= Art. 46, paragraphs 2 and 3 of the GDPR). These clauses oblige WordPress.com to comply with the EU&lsquo;s level of data protection when processing relevant data outside the EU. These clauses are based on an implementing order by the EU Commission. You can find the order and the clauses here: <a href="https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847">https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847</a>.</p>
<p>You can find out more about the data that is processed by using WordPress.com in their Privacy Policy at <a href="https://automattic.com/privacy/">https://automattic.com/privacy/</a>.</p>
<h2 id="web-analytics">Web Analytics</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Web Analytics Privacy Policy Overview </strong><br /> 👥 Affected parties: visitors to the website<br /> 🤝 Purpose: Evaluation of visitor information to optimise the website.<br /> 📓 Processed data: Access statistics that contain data such as access location, device data, access duration and time, navigation behaviour, click behaviour and IP addresses. You can find more details on this from the respective web analytics tool directly.<br /> 📅 Storage period: depending on the respective web analytics tool used<br /> ⚖️ Legal basis: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit. f GDPR (legitimate interests)</p>
</td>
</tr>
</tbody>
</table>
<h3>What is Web Analytics?</h3>
<p>We use software on our website, which is known as web analytics, in order to evaluate website visitor behaviour. Thus, data is collected, which the analytic tool provider (also called tracking tool) stores, manages and processes. Analyses of user behaviour on our website are created with this data, which we as the website operator receive. Most tools also offer various testing options. These enable us, to for example test which offers or content our visitors prefer. For this, we may show you two different offers for a limited period of time. After the test (a so-called A/B test) we know which product or content our website visitors find more interesting. For such testing as well as for various other analyses, user profiles are created and the respective data is stored in cookies.</p>
<h3>Why do we run Web Analytics?</h3>
<p>We have a clear goal in mind when it comes to our website: we want to offer our industry&rsquo;s best website on the market. Therefore, we want to give you both, the best and most interesting offer as well as comfort when you visit our website. With web analysis tools, we can observe the behaviour of our website visitors, and then improve our website accordingly for you and for us. For example, we can see the average age of our visitors, where they come from, the times our website gets visited the most, and which content or products are particularly popular. All this information helps us to optimise our website and adapt it to your needs, interests and wishes.</p>
<h3>Which data are processed?</h3>
<p>The exact data that is stored depends on the analysis tools that are being used. But generally, data such as the content you view on our website are stored, as well as e. g. which buttons or links you click, when you open a page, which browser you use, which device (PC, tablet, smartphone, etc.) you visit the website with, or which computer system you use. If you have agreed that location data may also be collected, this data may also be processed by the provider of the web analysis tool.</p>
<p>Moreover, your IP address is also stored. According to the General Data Protection Regulation (GDPR), IP addresses are personal data. However, your IP address is usually stored in a pseudonymised form (i.e. in an unrecognisable and abbreviated form). No directly linkable data such as your name, age, address or email address are stored for testing purposes, web analyses and web optimisations. If this data is collected, it is retained in a pseudonymised form. Therefore, it cannot be used to identify you as a person.</p>
<p>The following example shows Google Analytics&rsquo; functionality as an example for client-based web tracking with JavaScript code.</p>
<p>The storage period of the respective data always depends on the provider. Some cookies only retain data for a few minutes or until you leave the website, while other cookies can store data for several years.</p>
<h3>Duration of data processing</h3>
<p>If we have any further information on the duration of data processing, you will find it below. We generally only process personal data for as long as is absolutely necessary to provide products and services. The storage period may be extended if it is required by law, such as for accounting purposes for example for accounting.</p>
<h3>Right to object</h3>
<p>You also have the option and the right to revoke your consent to the use of cookies or third-party providers at any time. This works either via our cookie management tool or via other opt-out functions. For example, you can also prevent data processing by cookies by managing, deactivating or erasing cookies in your browser.</p>
<h3>Legal basis</h3>
<p>The use of Web Analytics requires your consent, which we obtained with our cookie popup. According to <strong>Art. 6 para. 1 lit. a of the GDPR (consent)</strong>, this consent represents the legal basis for the processing of personal data, such as by collection through Web Analytics tools.</p>
<p>In addition to consent, we have a legitimate interest in analysing the behaviour of website visitors, which enables us to technically and economically improve our offer. With Web Analytics, we can recognise website errors, identify attacks and improve profitability. The legal basis for this is <strong>Art. 6 para. 1 lit. f of the GDPR (legitimate interests)</strong>. Nevertheless, we only use these tools if you have given your consent.</p>
<p>Since Web Analytics tools use cookies, we recommend you to read our privacy policy on cookies. If you want to find out which of your data are stored and processed, you should read the privacy policies of the respective tools.</p>
<p>If available, information on special Web Analytics tools can be found in the following sections.</p>
<h2>Google Analytics Privacy Policy</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Google Analytics Privacy Policy Overview </strong><br /> 👥 Affected parties: website visitors<br /> 🤝 Purpose: Evaluation of visitor information to optimise the website.<br /> 📓 Processed data: Access statistics that contain data such as the location of access, device data, access duration and time, navigation behaviour, click behaviour and IP addresses. You can find more details on this in the privacy policy below.<br /> 📅 Storage period: depending on the properties used<br /> ⚖️ Legal basis: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit. f GDPR (legitimate interests)</p>
</td>
</tr>
</tbody>
</table>
<h3>What is Google Analytics?</h3>
<p>We use the tracking and analysis tool Google Analytics (GA) of the US-American company Google LLC (1600 Amphitheatre Parkway Mountain View, CA 94043, USA). Google Analytics collects data on your actions on our website. Whenever you click a link for example, this action is saved in a cookie and transferred to Google Analytics. With the help of reports which we receive from Google Analytics, we can adapt our website and our services better to your wishes. In the following, we will explain the tracking tool in more detail, and most of all, we will inform you what data is saved and how you can prevent this.</p>
<p>Google Analytics is a tracking tool with the purpose of conducting data traffic analysis of our website. For Google Analytics to work, there is a tracking code integrated to our website. Upon your visit to our website, this code records various actions you perform on your website. As soon as you leave our website, this data is sent to the Google Analytics server, where it is stored.</p>
<p>Google processes this data and we then receive reports on your user behaviour. These reports can be one of the following:</p>
<ul>
<li>Target audience reports: With the help of target audience reports we can get to know our users better and can therefore better understand who is interested in our service.</li>
<li>Advertising reports: Through advertising reports we can analyse our online advertising better and hence improve it.</li>
<li>Acquisition reports: Acquisition reports provide us helpful information on how we can get more people enthusiastic about our service.</li>
<li>Behaviour reports: With these reports, we can find out how you interact with our website. By the means of behaviour reports, we can understand what path you go on our website and what links you click.</li>
<li>Conversion reports: A conversion is the process of leading you to carry out a desired action due to a marketing message. An example of this would be transforming you from a mere website visitor into a buyer or a newsletter subscriber. Hence, with the help of these reports we can see in more detail, if our marketing measures are successful with you. Our aim is to increase our conversion rate.</li>
<li>Real time reports: With the help of these reports we can see in real time, what happens on our website. It makes us for example see, we can see how many users are reading this text right now.</li>
</ul>
<h3>Why do we use Google Analytics on our website?</h3>
<p>The objective of our website is clear: We want to offer you the best possible service. Google Analytics&rsquo; statistics and data help us with reaching this goal.</p>
<p>Statistically evaluated data give us a clear picture of the strengths and weaknesses of our website. On the one hand, we can optimise our page in a way, that makes it easier to be found by interested people on Google. On the other hand, the data helps us to get a better understanding of you as our visitor. Therefore, we can very accurately find out what we must improve on our website, in order to offer you the best possible service. The analysis of that data also enables us to carry out our advertising and marketing measures in a more individual and more cost-effective way. After all, it only makes sense to show our products and services exclusively to people who are interested in them.</p>
<h3>What data is stored by Google Analytics?</h3>
<p>With the aid of a tracking code, Google Analytics creates a random, unique ID which is connected to your browser cookie. That way, Google Analytics recognises you as a new user. The next time you visit our site, you will be recognised as a &ldquo;recurring&rdquo; user. All data that is collected gets saved together with this very user ID. Only this is how it is made possible for us to evaluate and analyse pseudonymous user profiles.</p>
<p>To analyse our website with Google Analytics, a property ID must be inserted into the tracking code. The data is then stored in the corresponding property. Google Analytics 4-property is standard for every newly created property. An alternative however, is the Universal Analytics Property. Depending on the property that is being used, data are stored for different periods of time.</p>
<p>Your interactions on our website are measured by tags such as cookies and app instance IDs. Interactions are all kinds of actions that you perform on our website. If you are also using other Google systems (such as a Google Account), data generated by Google Analytics can be linked with third-party cookies. Google does not pass on any Google Analytics data, unless we as the website owners authorise it. In case it is required by law, exceptions can occur.</p>
<p>The following cookies are used by Google Analytics:</p>
<p><strong>Name:</strong> _ga<br /><strong>Value:</strong>2.1326744211.152121865447-5<br /><strong>Purpose:</strong> By deafault, analytics.js uses the cookie _ga, to save the user ID. It generally serves the purpose of differentiating between website visitors.<br /><strong>Expiration date:</strong> After 2 years</p>
<p><strong>Name:</strong> _gid<br /><strong>Value:</strong>2.1687193234.152121865447-1<br /><strong>Purpose:</strong> This cookie also serves the purpose of differentiating between website users<br /><strong>Expiration date:</strong> After 24 hours</p>
<p><strong>Name:</strong> _gat_gtag_UA_&lt;property-id&gt;<br /><strong>Value:</strong> 1<br /><strong>Verwendungszweck:</strong> It is used for decreasing the demand rate. If Google Analytics is provided via Google Tag Manager, this cookie gets the name _dc_gtm_ &lt;property-id&gt;.<br /><strong>Expiration date: </strong>After 1 minute</p>
<p><strong>Name:</strong> AMP_TOKEN<br /><strong>Value:</strong> No information<br /><strong>Purpose:</strong> This cookie has a token which is used to retrieve the user ID by the AMP Client ID Service. Other possible values suggest a logoff, a request or an error.<br /><strong>Expiration date:</strong> After 30 seconds up to one year</p>
<p><strong>Name:</strong> __utma<br /><strong>Value:</strong>1564498958.1564498958.1564498958.1<br /><strong>Purpose:</strong> With this cookie your behaviour on the website can be tracked and the site performance can be measured. The cookie is updated every time the information is sent to Google Analytics.<br /><strong>Expiration date:</strong> After 2 years</p>
<p><strong>Name:</strong> __utmt<br /><strong>Value:</strong> 1<br /><strong>Purpose:</strong> Just like _gat_gtag_UA_&lt;property-id&gt; this cookie is used for keeping the requirement rate in check.<br /><strong>Expiration date:</strong> Afer 10 minutes</p>
<p><strong>Name:</strong> __utmb<br /><strong>Value:</strong>3.10.1564498958<br /><strong>Purpose:</strong> This cookie is used to determine new sessions. It is updated every time new data or information gets sent to Google Analytics.<br /><strong>Expiration date:</strong> After 30 minutes</p>
<p><strong>Name:</strong> __utmc<br /><strong>Value:</strong> 167421564<br /><strong>Purpose:</strong> This cookie is used to determine new sessions for recurring visitors. It is therefore a session cookie, and only stays stored until you close the browser again.<br /><strong>Expiration date:</strong> After closing the browser</p>
<p><strong>Name:</strong> __utmz<br /><strong>Value:</strong> m|utmccn=(referral)|utmcmd=referral|utmcct=/<br /><strong>Purpose:</strong> This cookie is used to identify the source of the number of visitors to our website. This means, that the cookie stored information on where you came to our website from. This could be another site or an advertisement.<br /><strong>Expiration date:</strong> After 6 months</p>
<p><strong>Name:</strong> __utmv<br /><strong>Value:</strong> No information<br /><strong>Purpose:</strong> The cookie is used to store custom user data. It gets updated whenever information is sent to Google Analytics.<br /><strong>Expiration date:</strong> After 2 years</p>
<p><strong>Note:</strong> This list is by no means exhaustive, since Google are repeatedly changing the use of their cookies.</p>
<p>Below we will give you an overview of the most important data that can be evaluated by Google Analytics:</p>
<p><strong>Heatmaps:</strong> Google creates so-called Heatmaps an. These Heatmaps make it possible to see the exact areas you click on, so we can get information on what routes you make on our website.</p>
<p><strong>Session duration:</strong> Google calls the time you spend on our website without leaving it session duration. Whenever you are inactive for 20 minutes, the session ends automatically.</p>
<p><strong>Bounce rate</strong> If you only look at one page of our website and then leave our website again, it is called a bounce.</p>
<p><strong>Account creation:</strong> If you create an account or make an order on our website, Google Analytics collects this data.</p>
<p><strong>IP-Address:</strong> The IP address is only shown in a shortened form, to make it impossible to clearly allocate it.</p>
<p><strong>Location:</strong> Your approximate location and the country you are in can be defined by the IP address. This process is called IP location determination.</p>
<p><strong>Technical information:</strong> Information about your browser type, your internet provider and your screen resolution are called technical information.</p>
<p><strong>Source:</strong> Both, Google Analytics as well as ourselves, are interested what website or what advertisement led you to our site.</p>
<p>Further possibly stored data include contact data, potential reviews, playing media (e.g. when you play a video on our site), sharing of contents via social media or adding our site to your favourites. This list is not exhaustive and only serves as general guidance on Google Analytics&rsquo; data retention.</p>
<h3>How long and where is the data stored?</h3>
<p>Google has servers across the globe. Most of them are in America and therefore your data is mainly saved on American servers. Here you can read detailed information on where Google&rsquo;s data centres are located: <a href="https://www.google.com/about/datacenters/inside/locations/?hl=en">https://www.google.com/about/datacenters/inside/locations/?hl=en</a></p>
<p>Your data is allocated to various physical data mediums. This has the advantage of allowing to retrieve the data faster, and of protecting it better from manipulation. Every Google data centre has respective emergency programs for your data. Hence, in case of a hardware failure at Google or a server error due to natural disasters, the risk for a service interruption stays relatively low.</p>
<p>The data retention period depends on the properties used. When using the newer Google Analytics 4-properties, the retention period of your user data is set to 14 months. For so-called event data, we have the option of choosing a retention period of either 2 months or 14 months.</p>
<p>Google Analytics has a 26 months standardised period of retaining your user data. After this time, your user data is deleted. However, we have the possibility to choose the retention period of user data ourselves. There are the following five options:</p>
<ul>
<li>Deletion after 14 months</li>
<li>Deletion after 26 months</li>
<li>Deletion after 38 months</li>
<li>Deletion after 50 months</li>
<li>No automatical deletion</li>
</ul>
<p>Additionally, there is the option for data to be deleted only if you no longer visit our website within a period determined by us. In this case, the retention period will be reset every time you revisit our website within the specified period.</p>
<p>As soon as the chosen period is expired, the data is deleted once a month. This retention period applies to any of your data which is linked to cookies, user identification and advertisement IDs (e.g. cookies of the DoubleClick domain). Any report results are based on aggregated information and are stored independently of any user data. Aggregated information is a merge of individual data into a single and bigger unit.</p>
<h3>How can I delete my data or prevent data retention?</h3>
<p>Under the provisions of the European Union&rsquo;s data protection law, you have the right to obtain information on your data and to update, delete or restrict it. With the help of a browser add on that can deactivate Google Analytics&rsquo; JavaScript (ga.js, analytics.js, dc.js), you can prevent Google Analytics from using your data. You can download this add on at <a href="https://tools.google.com/dlpage/gaoptout?hl=en-GB">https://tools.google.com/dlpage/gaoptout?hl=en-GB</a>. Please consider that this add on can only deactivate any data collection by Google Analytics.</p>
<p>If you generally want to deactivate, delete or manage all cookies (independently of Google Analytics), you can use one of the guides that are available for any browser:</p>
<p><a href="https://support.google.com/chrome/answer/95647?tid=121865447">Chrome: Clear, enable and manage cookies in Chrome </a></p>
<p><a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac?tid=121865447">Safari: Manage cookies and website data in Safari </a></p>
<p><a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?tid=121865447">Firefox: Clear cookies and site data in Firefox </a></p>
<p><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121865447">Internet Explorer: Delete and manage cookies </a></p>
<p><a href="https://support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies?tid=121865447">Microsoft Edge: Delete cookies in Microsoft Edge </a></p>
<h3>Legal basis</h3>
<p>The use of Google Analytics requires your consent, which we obtained via our cookie popup. According to <strong>Art. 6 para. 1 lit. a of the GDPR (consent) </strong>, this is the legal basis for the processing of personal data when collected via web analytics tools.</p>
<p>In addition to consent, we have legitimate interest in analysing the behaviour of website visitors, in order to technically and economically improve our offer. With Google Analytics, we can recognise website errors, identify attacks and improve profitability. The legal basis for this is <strong>Art. 6 para. 1 lit. f of the GDPR (legitimate interests) </strong>. Nevertheless, we only use Google Analytics if you have given your consent.</p>
<p>Google also processes data in the USA, among other countries. We would like to note, that according to the European Court of Justice, there is currently no adequate level of protection for data transfers to the USA. This can be associated with various risks to the legality and security of data processing.</p>
<p>Google uses standard contractual clauses approved by the EU Commission as basis for data processing by recipients based in third countries (outside the European Union, Iceland, Liechtenstein, Norway and especially in the USA) or data transfer there (= Art. 46, paragraphs 2 and 3 of the GDPR). These clauses oblige Google to comply with the EU&lsquo;s level of data protection when processing relevant data outside the EU. These clauses are based on an implementing order by the EU Commission. You can find the order and the clauses here: <a href="https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847">https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847</a></p>
<p>We hope we could provide you with the most important information about data processing by Google Analytics. If you want to find out more on the tracking service, we recommend these two links: <a href="https://marketingplatform.google.com/about/analytics/terms/gb/">https://marketingplatform.google.com/about/analytics/terms/gb/</a> and <a href="https://support.google.com/analytics/answer/6004245?hl=en">https://support.google.com/analytics/answer/6004245?hl=en</a>.</p>
<h2>Google Analytics Reports on demographic characteristics and interests</h2>
<p>We have turned on Google Analytics&rsquo; functions for advertising reports. These reports on demographic characteristics and interests contain details about age, gender and interests. Through them we can get a better picture of our users &ndash; without being able to allocate any data to individual persons. You can learn more about advertising functions at <a href="https://support.google.com/analytics/answer/3450482?hl=en&amp;amp%3Butm_id=ad">auf https://support.google.com/analytics/answer/3450482?hl=en&amp;amp%3Butm_id=ad</a>.</p>
<p>You can terminate the use of your Google Account&rsquo;s activities and information in &ldquo;Ads Settings&rdquo; at <a href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a> via a checkbox.</p>
<h2>Google Analytics IP Anonymisation</h2>
<p>We implemented Google Analytics&rsquo; IP address anonymisation to this website. Google developed this function, so this website can comply with the applicable privacy laws and the local data protection authorities&rsquo; recommendations, should they prohibit the retention of any full IP addresses.<br /> The anonymisation or masking of IP addresses takes place, as soon as they reach Google Analytics&rsquo; data collection network, but before the data would be saved or processed.</p>
<p>You can find more information on IP anonymisation at <a href="https://support.google.com/analytics/answer/2763052?hl=en">https://support.google.com/analytics/answer/2763052?hl=en</a>.</p>
<h2>Google Tag Manager Privacy Policy</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Google Tag Manager Privacy Policy Overview</strong><br /> 👥 Affected parties: website visitors<br /> 🤝 Purpose: Organisation of individual tracking tools<br /> 📓 Processed data: Google Tag Manager itself does not store any data. The data record tags of the web analytics tools used.<br /> 📅 Storage period: depending on the web analytics tool used<br /> ⚖️ Legal bases: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit. f GDPR (legitimate interests)</p>
</td>
</tr>
</tbody>
</table>
<h3>What is Google Tag Manager?</h3>
<p>We use Google Tag Manager by the company Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) for our website.<br /> This Tag Manager is one of Google&rsquo;s many helpful marketing products. With it, we can centrally integrate and manage code sections of various tracking tools, that we use on our website.</p>
<p>In this privacy statement we will explain in more detail, what Google Tag Manager does, why we use it and to what extent your data is processed.</p>
<p>Google Tag Manager is an organising tool with which we can integrate and manage website tags centrally and via a user interface. Tags are little code sections which e.g. track your activities on our website. For this, segments of JavaScript code are integrated to our site&rsquo;s source text. The tags often come from Google&rsquo;s intern products, such as Google Ads or Google Analytics, but tags from other companies can also be integrated and managed via the manager. Since the tags have different tasks, they can collect browser data, feed marketing tools with data, embed buttons, set cookies and track users across several websites.</p>
<h3>Why do we use Google Tag Manager for our website?</h3>
<p>Everybody knows: Being organised is important! Of course, this also applies to maintenance of our website. In order to organise and design our website as well as possible for you and anyone who is interested in our products and services, we rely on various tracking tools, such as Google Analytics.<br /> The collected data shows us what interests you most, which of our services we should improve, and which other persons we should also display our services to. Furthermore, for this tracking to work, we must implement relevant JavaScript Codes to our website. While we could theoretically integrate every code section of every tracking tool separately into our source text, this would take too much time and we would lose overview. This is the reason why we use Google Tag Manager. We can easily integrate the necessary scripts and manage them from one place. Additionally, Google Tag Manager&rsquo;s user interface is easy to operate, and requires no programming skills. Therefore, we can easily keep order in our jungle of tags.</p>
<h3>What data is stored by Google Tag Manager?</h3>
<p>Tag Manager itself is a domain that neither uses cookies nor stores data. It merely functions as an &ldquo;administrator&ldquo; of implemented tags. Data is collected by the individual tags of the different web analysis tools. Therefore, in Google Tag Manager the data is sent to the individual tracking tools and does not get saved.</p>
<p>However, with the integrated tags of different web analysis tools such as Google Analytics, this is quite different. Depending on the analysis tool used, various data on your internet behaviour is collected, stored and processed with the help of cookies. Please read our texts on data protection for more information on the articular analysis and tracking tools we use on our website.</p>
<p>We allowed Google via the account settings for the Tag Manager to receive anonymised data from us. However, this exclusively refers to the use of our Tag Manager and not to your data, which are saved via code sections. We allow Google and others, to receive selected data in anonymous form. Therefore, we agree to the anonymised transfer of our website data. However, even after extensive research we could not find out what summarised and anonymous data it is exactly that gets transmitted. What we do know is that Google deleted any info that could identify our website. Google combines the data with hundreds of other anonymous website data and creates user trends as part of benchmarking measures. Benchmarking is a process of comparing a company&rsquo;s results with the ones of competitors. As a result, processes can be optimised based on the collected information.</p>
<h3>How long and where is the data stored?</h3>
<p>When Google stores data, this is done on Google&rsquo;s own servers. These servers are located all over the world, with most of them being in America. At <a href="https://www.google.com/about/datacenters/inside/locations/?hl=en">https://www.google.com/about/datacenters/inside/locations/?hl=en</a> you can read in detail where Google&rsquo;s servers are.</p>
<p>In our individual data protection texts on the different tools you can find out how long the respective tracking tools store your data.</p>
<h3>How can I delete my data or prevent data retention?</h3>
<p>Google Tag Manager itself does not set any cookies but manages different tracking websites&rsquo; tags. In our data protection texts on the different tracking tools you can find detailed information on how you can delete or manage your data.</p>
<p>Please note that when using this tool, your data may also be stored and processed outside the EU. Most third countries (including the USA) are not considered secure under current European data protection law. Data must not be transferred, stored and processed to insecure third countries, unless there are suitable guarantees (such as EU standard contractual clauses) between us and the non-European service provider.</p>
<h3>Legal basis</h3>
<p>The use of the Google Tag Manager requires your consent, which we obtained via our cookie popup. According to <strong>Art. 6 para. 1 lit. a GDPR (consent)</strong>, this consent is the legal basis for personal data processing, such as when it is collected by web analytics tools.</p>
<p>In addition to consent, we have a legitimate interest in analysing the behaviour of website visitors and thus technically and economically improving our offer. With the help of Google Tag Managers we can also improve profitability. The legal basis for this is <strong>Art. 6 para. 1 lit. f GDPR (legitimate interests)</strong>. We only use Google Tag Manager if you have given us your consent.</p>
<p>Google also processes data in the USA, among other countries. We would like to note, that according to the European Court of Justice, there is currently no adequate level of protection for data transfers to the USA. This can be associated with various risks to the legality and security of data processing.</p>
<p>Google uses standard contractual clauses approved by the EU Commission as basis for data processing by recipients based in third countries (outside the European Union, Iceland, Liechtenstein, Norway and especially in the USA) or data transfer there (= Art. 46, paragraphs 2 and 3 of the GDPR). These clauses oblige Google to comply with the EU&lsquo;s level of data protection when processing relevant data outside the EU. These clauses are based on an implementing order by the EU Commission. You can find the order and the clauses here: <a href="https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847">https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847</a></p>
<p>If you want to learn more about Google Tag Manager, we recommend their FAQs at <a href="https://support.google.com/tagmanager/?hl=en#topic=3441530">https://support.google.com/tagmanager/?hl=en#topic=3441530</a>.</p>
<h2>Hotjar Privacy Policy</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Hotjar Privacy Policy Overview</strong><br /> 👥 Affected parties: website visitors<br /> 🤝 Purpose: To evaluate visitor information for optimising user experience.<br /> 📓 Processed data: Access statistics that contain data such as access location, device data, access duration and time, navigation behaviour, click behaviour and IP addresses.<br /> 📅 Storage period: the data will be deleted after one year<br /> ⚖️ Legal basis: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit. f GDPR (legitimate interests)</p>
</td>
</tr>
</tbody>
</table>
<h3>What is Hotjar?</h3>
<p>We use Hotjar of the company Hotjar Limited (Level 2, St Julian&rsquo;s Business Centre, 3, Elia Zammit Street, St Julian&rsquo;s STJ 1000, Malta) on our website, to statistically evaluate visitor data. Hotjar is a service which analyses the behaviour and feedback of you as a user of our website by combining analysis and feedback tools. We receive reports as well as visual displays from Hotjar, which show us how you move on our site. Personal data is anonymised automatically and never reaches Hotjar&rsquo;s servers. This means you as the website user are not personally identified, while we can still learn much about your user behaviour.</p>
<p>As mentioned in the above paragraph, Hotjar helps us analyse the behaviour of our site visitors. Some of the tools Hotjar offers are e.g. heatmaps, conversion funnels, visitor recording, incoming feedback, feedback polls and surveys (you can find more information about it at <a href="https://www.hotjar.com?tid=121865447/">https://www.hotjar.com/</a>). Therewith, Hotjar helps us to provide you a better user experience as well as an improved service. On the one hand it offers good analysis of online behaviour and on the other hand it gives us good feedback on our website&rsquo;s quality. Besides the analytical aspects we of course also want to know your opinion about our website. This is possible thanks to the feedback tool.</p>
<h3>Why do we use Hotjar on our website?</h3>
<p>Within the last years the importance of websites&rsquo; user experience has gained in importance. And justifiably so &ndash; a website should be structured in a way that makes the user feel comfortable and is easy to navigate. Thanks to Hotjar&rsquo;s analysis and the feedback tools, we can make our website and our offer more attractive. To us, Hotjar&rsquo;s Heatmaps has proven particularly valuable, as it helps with presenting and visualising data. In that sense, Hotjar&rsquo;s Heatmaps e.g. helps us see what you like to click on and where you scroll to.</p>
<h3>What data is stored by Hotjar?</h3>
<p>Hotjar automatically collects information on your user behaviour while you surf our website. In order to be able to collect this information, we implemented a tracking code. We integrated a tracking code, to be able to collect this information. The following data can be gathered via your computer or your browser:</p>
<ul>
<li>Your computer&rsquo;s IP address (is collected and saved in an anonymous format)</li>
<li>Screen size</li>
<li>Browser information (which browser and version etc.)</li>
<li>Your location (but only the country)</li>
<li>Your language preference</li>
<li>Visited websites (subpages)</li>
<li>Date and time of access to one of our subpages (websites)</li>
</ul>
<p>Moreover, cookies also save data that have been placed on your computer (mostly your browser), although no personal data is collected. Generally, Hotjar does not pass collected data to third parties. However, Hotjar explicitly emphasises that it is sometimes necessary to share data with Amazon Web Services. _ parts of your information is saved on its servers. Nonetheless, Amazon is bound to a confidentiality obligation and cannot disclose these data.</p>
<p>Only a limited number of people (employees of Hotjar) have access to the stored information. Furthermore, Hotjar&rsquo;s servers are protected by firewalls and IP restrictions (only authorised IP addresses have access). Firewalls are security systems which protect computers from unwanted network accesses. They serve as barriers between Hotjar&rsquo;s secure internal network and the internet. Moreover, Hotjar also uses third-party companies for their services, such as Google Analytics or Optimizely. These firms can also save information that your browser sends to our website.</p>
<p>The following cookies are used by Hotjar. Since we refer to the cookie list in Hotjar&rsquo;s privacy statement at <a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://www.hotjar.com/legal/policies/cookie-information</a>, not every cookie has a sample value. The list shows examples of utilised Hotjar cookies and does not claim to be exhaustive.</p>
<p><strong>Name</strong>: ajs_anonymous_id<br /><strong>Value: </strong>%2258832463-7cee-48ee-b346-a195f18b06c3%22121865447-5<br /><strong>Purpose:</strong> This cookie is generally used for analysis purposes and helps with counting our website&rsquo;s visitors by tracking whether they have been to the website before.<br /><strong>Expiry date:</strong> after one year</p>
<p><strong>Name</strong>: ajs_group_id<br /><strong>Value: </strong>0<br /><strong>Purpose: </strong>This cookie collects data on user behaviour. Based on the similarities between website visitors, the data can then be assigned to a specific visitor group.<br /><strong>Expiry date:</strong> after one year</p>
<p><strong>Name</strong>: _hjid<br /><strong>Value: </strong>699ffb1c-4bfb-483f-bde1-22cfa0b59c6c<br /><strong>Purpose: </strong>This cookie is used to maintain a Hotjar user ID, which is unique for the website in the browser. That way, upon the next website visits, the user behaviour can be assigned to the same user ID.<br /><strong>Expiry date:</strong> after one year</p>
<p><strong>Name:</strong> _hjMinimizedPolls<br /><strong>Value:</strong> 462568121865447-8<br /><strong>Verwendungszweck:</strong> Every time you minimise a feedback poll widget, Hotjar sets this cookie. It ensures that the widget stays minimised when you surf our sites.<br /><strong>Expiry date:</strong> after one year</p>
<p><strong>Name:</strong> _hjIncludedInSample<br /><strong>Value:</strong> 1<br /><strong>Purpose:</strong> This session cookie is used to inform Hotjar if you are part of the selected individuals (sample), who are used for the creation of funnels.<br /><strong>Expiry date:</strong> after one year</p>
<p><strong>Name</strong>: _hjClosedSurveyInvites<br /><strong>Purpose:</strong> This cookie is set when you see an invitation to a feedback poll in a popup window. It is used to ensure that this invitation appears to you only once.<br /><strong>Expiry date:</strong> after one year</p>
<p><strong>Name:</strong> _hjDonePolls<br /><strong>Purpose:</strong> This cookie is set in your browser whenever you finish a round of questions for feedback in a poll widget. Therewith, Hotjar prevents you from receiving the same polls in the future.<br /><strong>Expiry date:</strong> after one year</p>
<p><strong>Name:</strong> _hjDoneTestersWidgets <strong><br /><strong>Purpose:</strong></strong> This cookie is used when you enter your data in the &ldquo;recruit user tester&rdquo; widget. With this widget we want to engage you as a tester. The cookie is used to prevent the form from reappearing repeatedly.<br /><strong>Expiry date:</strong> after one year</p>
<p><strong>Name:</strong> _hjMinimizedTestersWidgets<br /><strong>Purpose:</strong> This cookie is set to keep the &ldquo;recruit user tester&ldquo; widget minimised accross all our pages. The cookie is set upon you minimising this widget once.<br /><strong>Expiry date:</strong> after one year</p>
<p><strong>Name:</strong> _hjShownFeedbackMessage<br /><strong>Purpose:</strong> This cookie is set if you minimise or amend the given feedback. This is done so the feedback is instantly loaded as minimised when you navigate to another page, on which it is displayed.<br /><strong>Expiry date:</strong> after one year</p>
<h3>How long and where is the data stored?</h3>
<p>We integrated a tracking code to our website, which is transmitted to Hotjar&rsquo;s servers in Ireland (EU). This tracking code contacts Hotjar&rsquo;s servers and sends a script to your computer or any terminal device with which you are accessing our website. The script collects certain data concerning your interaction with our website. Then, the data is sent to Hotjar&rsquo;s servers for processing. Moreover, Hotjar imposed a limit of retaining data for up to 365 days on itself. This means that all data collected by Hotjar which is over one year old are deleted automatically.</p>
<h3>How can I erase my data or prevent data retention?</h3>
<p>Hotjar saves none of your personal data for its analysis. The company even advertises with the slogan &ldquo;We track behaviour, not individuals&ldquo;. In addition, it is always possible for you to prevent the collection of your data. For this you simply need to visit Hotjar&rsquo;s &ldquo;<a href="https://www.hotjar.com/legal/compliance/opt-out">Opt-out page</a>&ldquo; and click on &ldquo;deactivate Hotjar&rdquo;. Please note that deleting cookies, using your browser&rsquo;s private mode or utilising a different browser will result in the collection of data again. Furthermore, you can activate the &ldquo;Do Not Track&rdquo; button in your browser. To do this in Chrome for example, you must click on the three bars and select &ldquo;Settings&rdquo;. In the section &ldquo;Data Protection&ldquo; you will find the option &ldquo;Send a &lsquo;Do Not Track&rsquo; request with your browsing traffic&rdquo;. Finally, you must click on this button and no data will be collected by Hotjar.</p>
<h3>Legal basis</h3>
<p>The use of Hotjar requires your consent, which we obtained via our cookie popup. According to <strong>Art. 6 para. 1 lit. a GDPR (consent) </strong>, this consent represents the legal basis for personal data processing , such as when it is collected by web analytics tools.</p>
<p>In addition to consent, we have legitimate interest in analysing the behaviour of website visitors, and thus technically and economically improving our offer. With the help of Hotjar, we can recognise website errors, identify attacks and improve profitability. The legal basis for this is <strong>Art. 6 para. 1 lit.f GDPR (legitimate interests)</strong>. Nevertheless, we only use Hotjar if you have given us your consent.</p>
<p>You can find more details on the privacy policy and on what data Hotjar uses and how it is utilised at <a href="https://www.hotjar.com/legal/policies/privacy?tid=121865447">https://www.hotjar.com/legal/policies/privacy?tid=121865447</a>.</p>
<h2 id="email-marketing">Email-Marketing</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Email Marketing Overview</strong><br /> 👥 Affected parties: newsletter subscribers<br /> 🤝 Purpose: direct marketing via email, notification of events that are relevant to the system<br /> 📓 Processed data: data entered during registration, but at least the email address. You can find more details on this in the respective email marketing tool used.<br /> 📅 Storage duration: for the duration of the subscription<br /> ⚖️ Legal bases: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit. f GDPR (legitimate interests)</p>
</td>
</tr>
</tbody>
</table>
<h3>What is Email-Marketing?</h3>
<p>We use email marketing to keep you up to date. If you have agreed to receive our emails or newsletters, your data will be processed and stored. Email marketing is a part of online marketing. In this type of marketing, news or general information about a company, product or service are emailed to a specific group of people who are interested in it.</p>
<p>If you want to participate in our email marketing (usually via newsletter), you usually just have to register with your email address. To do this, you have to fill in and submit an online form. However, we may also ask you for your title and name, so we can address you personally in our emails.</p>
<p>The registration for newsletters generally works with the help of the so-called &ldquo;double opt-in procedure&rdquo;. After you have registered for our newsletter on our website, you will receive an email, via which you can confirm the newsletter registration. This ensures that you own the email address you signed up with, and prevents anyone to register with a third-party email address. We or a notification tool we use, will log every single registration. This is necessary so we can ensure and prove, that registration processes are done legally and correctly. In general, the time of registration and registration confirmation are stored, as well as your IP address. Moreover, any change you make to your data that we have on file is also logged.</p>
<h3>Why do we use Email-Marketing?</h3>
<p>Of course, we want to stay in contact with you and keep you in the loop of the most important news about our company. For this, we use email marketing &ndash; often just referred to as &ldquo;newsletters&rdquo; &ndash; as an essential part of our online marketing. If you agree to this or if it is permitted by law, we will send you newsletters, system emails or other notifications via email. Whenever the term &ldquo;newsletter&rdquo; is used in the following text, it mainly refers to emails that are sent regularly. We of course don&rsquo;t want to bother you with our newsletter in any way. Thus, we genuinely strive to offer only relevant and interesting content. In our emails you can e.g. find out more about our company and our services or products. Since we are continuously improving our offer, our newsletter will always give you the latest news, or special, lucrative promotions. Should we commission a service provider for our email marketing, who offers a professional mailing tool, we do this in order to offer you fast and secure newsletters. The purpose of our email marketing is to inform you about new offers and also to get closer to our business goals.</p>
<h3>Which data are processed?</h3>
<p>If you subscribe to our newsletter via our website, you then have to confirm your membership in our email list via an email that we will send to you. In addition to your IP and email address, your name, address and telephone number may also be stored. However, this will only be done if you agree to this data retention. Any data marked as such are necessary so you can participate in the offered service. Giving this information is voluntary, but failure to provide it will prevent you from using this service. Moreover, information about your device or the type of content you prefer on our website may also be stored. In the section &ldquo;Automatic data storage&rdquo; you can find out more about how your data is stored when you visit a website.&nbsp;We record your informed consent, so we can always prove that it complies with our laws.</p>
<h3>Duration of data processing</h3>
<p>If you unsubscribe from our e-mail/newsletter distribution list, we may store your address for up to three years on the basis of our legitimate interests, so we can keep proof your consent at the time. We are only allowed to process this data if we have to defend ourselves against any claims.</p>
<p>However, if you confirm that you have given us your consent to subscribe to the newsletter, you can submit an individual request for erasure at any time. Furthermore, if you permanently object to your consent, we reserve the right to store your email address in a blacklist. But as long as you have voluntarily subscribed to our newsletter, we will of course keep your email address on file.</p>
<h3>Withdrawal &ndash; how can I cancel my subscription?</h3>
<p>You have the option to cancel your newsletter subscription at any time. All you have to do is revoke your consent to the newsletter subscription. This usually only takes a few seconds or a few clicks. Most of the time you will find a link at the end of every email, via which you will be able to cancel the subscription. Should you not be able to find the link in the newsletter, you can contact us by email and we will immediately cancel your newsletter subscription for you.</p>
<h3>Legal basis</h3>
<p>Our newsletter is sent on the basis of your <strong>consent </strong>(Article 6 (1) (a) GDPR). This means that we are only allowed to send you a newsletter if you have actively registered for it beforehand. Moreover, we may also send you advertising messages on the basis of Section 7 (3) UWG (Unfair Competition Act), provided you have become our customer and have not objected to the use of your email address for direct mail.</p>
<p>If available &ndash; you can find information on special email marketing services and how they process personal data, in the following sections.</p>
<h2 id="push-messages">Push Messages</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Push Messages Overview</strong><br /> 👥 Affected parties: subscribers to push messages<br /> 🤝 Purpose: to notify you about interesting events that are relevant to the system<br /> 📓 Processed data: data that was entered during registration, usually also location data. You can find more details on this at the respective push notification tool.<br /> 📅 Storage period: data are usually stored for as long as is necessary for the service provision.<br /> ⚖️ Legal bases: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit. b GDPR (contract)</p>
</td>
</tr>
</tbody>
</table>
<h3>What are push notifications?</h3>
<p>We use so-called push notification services on our website, which help us to keep our users up to date. Thus, if you have agreed to the use of such push notifications, we can send you short news using a software tool. Push messages are text messages that appear on your smartphone or on other devices such as tablets or PCs, provided you have registered for them. You can also receive these messages if you are not on our website at this point in time, or are not actively using our offer. Moreover, data about your location and your user behaviour may also be collected and stored.</p>
<h3>Why do we use push notifications?</h3>
<p>On the one hand, we use push notifications to be able to fully provide the services we have contractually agreed to with you. On the other hand, they are helpful to our online marketing. This is because we can show you our services or products better with them. Thus, we can inform you immediately if there is e.g. any news in our company. We also want to get to know our users&rsquo; preferences and habits as well as possible, in order to continuously improve our offer.</p>
<h3>Which data are processed?</h3>
<p>For you to receive push notifications, you must confirm that you want to receive them. Any data accumulated during the process of confirming your consent is stored, managed and processed. This is necessary to recognise and proof users&rsquo; consent to receiving push notifications. Hence, a so-called device token or push token is stored in your browser. Along with this, your location data or the location of your device are usually also retained.</p>
<p>We also statistically evaluate how you handle our messages, so we can always send you push messages that are important and interesting. Thus, we can e.g. see when you open a message. With this knowledge we can adapt our communication strategy to your wishes and interests. Although this stored data could be assigned to you, we do not want to screen you as an individual. Rather, we are interested in the collected data from all our users so we can optimise further. In the privacy policies of the respective service providers, you can find out which data is stored exactly.</p>
<h3>Duration of data processing</h3>
<p>The duration of data processing and retention depends primarily on the tool we use. Below you can find out more about data processing of the individual tools. The providers&rsquo; privacy policies usually state exactly which data is stored and processed and for how long. Generally, personal data is only processed for as long as is necessary for the provision of our services. If data is stored in cookies, the storage duration can vary greatly. On the one hand, this data may be deleted immediately after leaving a website, and on the other hand it may get stored for several years. Therefore, if you want to know more about data storage, you should look at each individual cookie in detail. In most cases, you will also find informative information on individual cookies in the privacy policies of the respective providers.</p>
<h3>Legal basis</h3>
<p>It may be possible, that push messages may be necessary for us to be able to fulfill certain obligations in a contract. An example would be us notifying you about technical or organisational news. In this case, the legal basis is Art. 6 paragraph 1 lit. b of the GDPR.</p>
<p>Should this not be the case, push notifications will only be sent on the basis of your consent. Our push messages may particularly include advertising content. Furthermore, push notifications may also be sent depending on your location displayed on your device. The above-mentioned analytical evaluations are also based on your consent to their receipt. The legal basis for this is Article 6 (1) (a) of the GDPR. Of course, you can either revoke your consent at any time in the settings, or change various settings.</p>
<h2>Zendesk Privacy Policy</h2>
<p>We also use the customer service software Zendesk. The provider of this service is the American company Zendesk, Inc., 989 Market St, San Francisco, CA 94103, USA.</p>
<p>Zendesk also processes data in the USA, among other&nbsp;countries. We would like to&nbsp;note, that according to&nbsp;the European Court of Justice, there is currently no adequate level of protection for data transfers&nbsp;to the USA. This can be associated with various risks to the legality and security of data processing.</p>
<p>Zendesk uses standard contractual clauses approved by the EU Commission as the basis for data processing by recipients based in third countries (i. e. outside the European Union, Iceland, Liechtenstein, Norway, and thus especially in the USA) or data transfer there (= Art. 46, paragraphs 2 and 3 of the GDPR). Standard Contractual Clauses (SCC) are legal templates provided by the EU Commission. Their purpose is to ensure that your data complies with European data privacy standards, even if your data is transferred to and stored in third countries (such as the USA). With these clauses, Zendesk commits to comply with the EU&lsquo;s level of data protection when processing relevant data, even if it is stored, processed and managed in the USA. These clauses are based on an implementing order by the EU Commission. You can find the order and the standard contractual clauses here: <a href="https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847">https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847</a>.</p>
<p>Further information on data processing and the standard contractual clauses at Zendesk can be found at <a href="https://www.zendesk.co.uk/company/privacy-and-data-protection/">https://www.zendesk.co.uk/company/privacy-and-data-protection/</a>.</p>
<p>You can find out more about the data that is processed by Zendesk in their Privacy Policy at <a href="https://www.zendesk.co.uk/company/agreements-and-terms/privacy-policy/">https://www.zendesk.co.uk/company/agreements-and-terms/privacy-policy/</a><u>.</u></p>
<h2>HubSpot (Chatbot) Privacy Policy</h2>
<p>We also use the chatbot function HubSpot. The provider of this service is the American company HubSpot, Inc., 25 First St., 2nd floor, Cambridge, Massachusetts 02141, USA.</p>
<p>HubSpot also processes data in the USA, among other countries. We would like to note, that according to the European Court of Justice, there is currently no adequate level of protection for data transfers to the USA. This can be associated with various risks to the legality and security of data processing.</p>
<p>HubSpot uses standard contractual clauses approved by the EU Commission as the basis for data processing by recipients based in third countries (i. e. outside the European Union, Iceland, Liechtenstein, Norway, and thus especially in the USA) or data transfer there (= Art. 46, paragraphs 2 and 3 of the GDPR). Standard Contractual Clauses (SCC) are legal templates provided by the EU Commission. Their purpose is to ensure that your data complies with European data privacy standards, even if your data is transferred to and stored in third countries (such as the USA). With these clauses, HubSpot commits to comply with the EU&lsquo;s level of data protection when processing relevant data, even if it is stored, processed and managed in the USA. These clauses are based on an implementing order by the EU Commission. You can find the order and the standard contractual clauses here: <a href="https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847">https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847</a>.</p>
<p>You can find HubSpot&rsquo;s Data Processing Agreement, which corresponds to the standard contractual clauses at <a href="https://legal.hubspot.com/dpa">https://legal.hubspot.com/dpa</a>.</p>
<p>You can find out more about the data that is processed by using HubSpot in their Privacy Policy at <a href="https://legal.hubspot.com/privacy-policy">https://legal.hubspot.com/privacy-policy</a>.</p>
<h2 id="social-media">Social Media</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Social Media Privacy Policy Overview</strong><br /> 👥 Affected parties: website visitors<br /> 🤝 Purpose: Service presentation and optimisation, staying in contact with visitors, interested parties, etc. as well as advertising<br /> 📓 Processed data: data such as telephone numbers, email addresses, contact data, data on user behaviour, information about your device and your IP address.<br /> You can find more details on this directly at the respective social media tool used.<br /> 📅 Storage period: depending on the social media platforms used<br /> ⚖️ Legal bases: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit. f GDPR (legitimate interests)</p>
</td>
</tr>
</tbody>
</table>
<h3>What is Social Media?</h3>
<p>In addition to our website, we are also active on various social media platforms. For us to be able to target interested users via social networks, user data may be processed. Additionally, elements of social media platforms may be embedded directly in our website. This is e.g. the case if you click a so-called social button on our website and are forwarded directly to our social media presence. So-called social media are websites and apps on which registered members can produce and exchange content with other members, be it openly or in certain groups and networks.</p>
<h3>Why do we use Social Media?</h3>
<p>For years, social media platforms have been the place where people communicate and get into contact online. With our social media presence, we can familiarise interested people better with our products and services. The social media elements integrated on our website help you switch to our social media content quickly and hassle free.</p>
<p>The data that is retained and processed when you use a social media channel is primarily used to conduct web analyses. The aim of these analyses is to be able to develop more precise and personal marketing and advertising strategies. The evaluated data on your behaviour on any social media platform can help to draw appropriate conclusions about your interests. Moreover, so-called user profiles can be created. Thus, the platforms may also to present you with customised advertisements. For this, cookies are usually placed in your browser, which store data on your user behaviour.</p>
<p>We generally assume that we will continue to be responsible under Data Protection Law, even when using the services of a social media platform. However, the European Court of Justice has ruled that, within the meaning of Art. 26 GDPR, in certain cases the operator of the social media platform can be jointly responsible with us. Should this be the case, we will point it out separately and work on the basis of a related agreement. You will then find the essence of the agreement for the concerned platform below.</p>
<p>Please note that when you use social media platforms or our built-in elements, your data may also be processed outside the European Union, as many social media channels, such as Facebook or Twitter, are American companies. As a result, you may no longer be able to easily claim or enforce your rights regarding your personal data.</p>
<h3>Which data are processed?</h3>
<p>Exactly which data are stored and processed depends on the respective provider of the social media platform. But usually it is data such as telephone numbers, email addresses, data you enter in contact forms, user data such as which buttons you click, what you like or who you follow, when you visited which pages, as well as information about your device and IP address. Most of this data is stored in cookies. Should you have a profile on the social media channel you are visiting and are logged in, data may be linked to your profile.</p>
<p>All data that are collected via social media platforms are also stored on the providers&rsquo; servers. This means that only the providers have access to the data and can provide you with appropriate information or make changes for you.</p>
<p>If you want to know exactly which data is stored and processed by social media providers and how you can object to the data processing, we recommend you to carefully read the privacy policy of the respective company. We also recommend you to contact the provider directly if you have any questions about data storage and data processing or if you want to assert any corresponding rights.</p>
<h3>Duration of data processing</h3>
<p>Provided we have any further information on this, we will inform you about the duration of the data processing below. The social media platform Facebook example stores data until they are no longer needed for the company&rsquo;s own purposes. However, customer data that is synchronised with your own user data is erased within two days. Generally, we only process personal data for as long as is absolutely necessary for the provision of our services and products. This storage period can also be exceeded however, if it is required by law, such as e.g. in the case of accounting.</p>
<h3>Right to object</h3>
<p>You also retain the right and the option to revoke your consent to the use of cookies or third-party providers such as embedded social media elements at any time. This can be done either via our cookie management tool or via other opt-out functions. You can e.g. also prevent data collection via cookies by managing, deactivating or erasing cookies in your browser.</p>
<p>Since cookies may be used with social media tools, we also recommend you to read our privacy policy on cookies. If you want to find out which of your data is stored and processed, we advise you to read the privacy policies of the respective tools.</p>
<h3>Legal basis</h3>
<p>If you have consented to the processing and storage of your data by integrated social media elements, this consent serves as the legal basis for data processing <strong>(Art. 6 para. 1 lit. a GDPR)</strong>. Generally, provided you have given your consent, your data will also be stored and processed on the basis of our legitimate interest <strong>(Art. 6 para. 1 lit. f GDPR) </strong>in maintaining fast and good communication with you and other customers and business partners. Nevertheless, we only use the tools if you have consented. Most social media platforms also set cookies on your browser to store data. We therefore recommend you to read our privacy policy on cookies carefully and to take a look at the privacy policy or cookie policy of the respective service provider.</p>
<p>in the following section you can find information on special social media platforms &ndash; provided this information is available.</p>
<h2>WordPress-Emojis Privacy Policy</h2>
<p>In our blog, we also use emojis and smilies. We most probably don&rsquo;t need to explain in more detail what emojis are. After all, you know those smiling, angry or sad faces. They are graphic elements or files that we make available, which are loaded from another server. The service provider for WordPress emojis and smilies is Automattic Inc., 60 29th Street #343, San Francisco, CA 94110, USA. This third-party provider stores your IP address in order to be able to transmit the emoji files to your browser.</p>
<p>Automattic also processes data in the USA, among other countries. We would like to note, that according to the European Court of Justice, there is currently no adequate level of protection for data transfers to the USA. This can be associated with various risks to the legality and security of data processing.</p>
<p>Automattic uses standard contractual clauses approved by the EU Commission as the basis for data processing by recipients based in third countries (outside the European Union, Iceland, Liechtenstein, Norway and especially in the USA) or data transfer there (= Art. 46, paragraphs 2 and 3 of the GDPR). These clauses oblige Automattic to comply with the EU&lsquo;s level of data protection when processing relevant data outside the EU. These clauses are based on an implementing order by the EU Commission. You can find the order and the clauses here: <a href="https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847">https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847</a>.</p>
<p>You can find out more about the data that is processed by using Automattic in their Privacy Policy at <a href="https://automattic.com/privacy/">https: //automattic.com/privacy/</a>.</p>
<h2 id="online-marketing">Online Marketing</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Online Marketing Privacy Policy Overview </strong><br /> 👥 Affected parties: visitors to the website<br /> 🤝 Purpose: Evaluation of visitor information for website optimisation<br /> 📓 Processed data: Access statistics containing data such as access location, device data, access duration and time, navigation behaviour, click behaviour and IP addresses. Personal data such as name or email address may also be processed. You can find more details on this from the respective Online Marketing tool.<br /> 📅 Storage period: depending on the Online Marketing tools used<br /> ⚖️ Legal basis: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit. f GDPR (legitimate interests)</p>
</td>
</tr>
</tbody>
</table>
<h3>What is Online Marketing?</h3>
<p>Online Marketing refers to all measures that are carried out online to achieve marketing goals, such as increasing brand awareness or doing business transactions. Furthermore, our Online Marketing measures aim to draw people&rsquo;s attention to our website. In order to be able to show our offer to many interested people, we do Online Marketing. It mostly is about online advertising, content marketing or search engine optimisation. For this, personal data is also stored and processed, to enable us to use Online Marketing efficiently and targeted. On the one hand, the data help us to only show our content to people who are interested in it. On the other hand, it helps us to measure the advertising success of our Online Marketing measures.</p>
<h3>Why do we use Online Marketing tools?</h3>
<p>We want to show our website to everyone who is interested in our offer. We are aware that this is not possible without conscious measures being taken. That is why we do Online Marketing. There are various tools that make working on our Online Marketing measures easier for us. These also provide suggestions for improvement via data. Thus, we can target our campaigns more precisely to our target group. The ultimate purpose of these Online Marketing tools is to optimise our offer.</p>
<h3>Which data are processed?</h3>
<p>For our Online Marketing to work and to measure its success, user profiles are created and data are e.g. stored in cookies (small text files). With the help of this data, we can not only advertise in the traditional way, but also present our content directly on our website in the way you prefer. There are various third-party tools that offer these functions and thus collect and store your data accordingly. The aforementioned cookies e.g. store the pages you visit on our website, how long you view these pages, which links or buttons you click or which website you came from. What is more, technical information may also be stored. This may include e.g. your IP address, the browser and device you use to visit our website or the time you accessed our website as well as the time you left. If you have agreed for us to determine your location, we can also store and process it.</p>
<p>Your IP address is stored in pseudonymised form (i.e. shortened). What is more, distinct data that directly identify you as a person, such as your name, address or email address, are only stored in pseudonymised for advertising and Online Marketing purposes. With this data we cannot identify you as a person and only retain the pseudonymised information that is stored in your user profile.</p>
<p>Under certain circumstances, cookies may also be utilised, analysed and used for advertising purposes on other websites that use the same advertising tools. Thus, your data may then also be stored on the servers of the respective provider of the advertising tool.</p>
<p>In rare exceptions, unique data (name, email address, etc.) may also be stored in the user profiles. This can happen, if you are for example a member of a social media channel that we use for our Online Marketing measures and if the network connects previously received data with the user profile.</p>
<p>We only ever receive summarised information from the advertising tools we use that do store data on their servers. We never receive data that can be used to identify you as an individual. What is more, the data only shows how well-placed advertising measures have worked. For example, we can see what measures have caused you or other users to visit our website and purchase a service or product. Based on these analyses we can improve our advertising offer in the future and adapt it more precisely to the needs and wishes of people who are interested.</p>
<h3>Duration of data processing</h3>
<p>Below we will inform you on the duration of data processing, provided we have this information. In general, we only process personal data for as long as is absolutely necessary to provide our services and products. Data stored in cookies are retained for different lengths of time. Some cookies are deleted after you leave a website, while others may be stored in your browser for a number of years. However, in the respective privacy policies of the respective provider, you will usually find detailed information on the individual cookies this provider uses.</p>
<h3>Right of withdrawal</h3>
<p>You also retain the right and the option to revoke your consent to the use of cookies or third-party providers at any time. This can be done either via our cookie management tool or via other opt-out functions. You can for example also prevent data collection by cookies if you manage, deactivate or erase cookies in your browser. The legality of the processing remains unaffected to the point of revocation.</p>
<p>Since Online Marketing tools usually use cookies, we also recommend you to read our privacy policy on cookies. If you want to find out which of your data is stored and processed, you should read the privacy policies of the respective tools.</p>
<h3>Legal basis</h3>
<p>If you have consented to the use of third-party providers, then this consent is the legal basis for the corresponding data processing. According to <strong>Art. 6 para. 1 lit. a GDPR (consent) </strong>, this consent is the legal basis for personal data processing, as may be done when data is collected by online marketing tools.</p>
<p>Moreover, we have a legitimate interest in measuring our online marketing activities in anonymised form, in order to use this data for optimising our offer and our Marketing. The corresponding legal basis for this is <strong>Art. 6 para. 1 lit. f GDPR (legitimate interests) </strong>. Nevertheless, we only use these tools if you have given your consent.</p>
<p>Information on special online marketing tools can be found in the following sections, provided this information is available.</p>
<h2>Userpilot Privacy Policy</h2>
<p>Userpilot is a product experience platform that is used by hundreds of teams to deliver in-app experiences to their users. As a vendor that processes millions of data points on a daily basis, we take our customers and their users&rsquo; data very seriously. Userpilot takes full responsibility for all data that's processed through the platform. Our data is fully encrypted, managed, and stored by SOC-compliant vendors such as Amazon AWS and Google Cloud.</p>
<h3>Security</h3>
<p>All data that is handled and processed through the Userpilot platform is fully secured against unauthorized access. Only authorized Userpilot personnel are allowed to access such data and are required to authenticate themselves whenever such data is accessed.</p>
<p>We have also taken extreme measures to prevent unauthorized access, theft or manipulation of data.</p>
<h5>&middot; Internal security</h5>
<ul>
<li>Data encryption
<ul>
<li>All our projects are run routinely through security tests and regular penetration tests using security vendors.</li>
<li>Auth keys are hashed, and we use AWS tools to manage production secrets</li>
</ul>
</li>
<li>Product security design
<ul>
<li>All our projects are run routinely through security tests and regular penetration tests using security vendors.</li>
</ul>
</li>
</ul>
<h5>&middot; Product security</h5>
<ul>
<li>Account access, SSO for enterprise</li>
<li>Transparent and fine grained control over user access</li>
<li>Multi Factor Authentication (MFA)
<ul>
<li>MFA provides an additional layer of security. When logging into Userpilot, you&rsquo;ll also enter a code from your mobile phone or .</li>
<li>Can be enforced on account level for all team members.</li>
</ul>
</li>
</ul>
<h5>&middot; Network and application security</h5>
<ul>
<li>Failover and DR
<ul>
<li>Our infrastructure and data are spread across Multi AWS availability zones and will continue to work should any one of those data centers fail.</li>
</ul>
</li>
<li>Virtual Private Cloud
<ul>
<li>All of our servers are within our own virtual private cloud (VPC) with network access control lists (ACLs).</li>
</ul>
</li>
<li>Permissions and Authentication
<ul>
<li>Access to customer data is limited to authorized employees who require it for their job.</li>
</ul>
</li>
<li>Incident Response
<ul>
<li>Userpilot implements a protocol for handling security events which includes escalation procedures.</li>
</ul>
</li>
</ul>
<h5>&middot; Additional Security features</h5>
<ul>
<li>Training
<ul>
<li>All employees complete Security and Awareness training.</li>
</ul>
</li>
<li>Confidentiality
<ul>
<li>All employee contracts include a confidentiality agreement.</li>
</ul>
</li>
<li>PCI Obligations
<ul>
<li>All payments made to Userpilot go through Stripe. Details about their security setup and PCI compliance can be found at Stripe&rsquo;s security page.</li>
</ul>
</li>
</ul>
<h3>Availability</h3>
<p>The Userpilot platform architecture was built to ensure maximum accessibility and uptime. Our APIs are fully separate from our product server, and redundant failover servers are set in place.</p>
<p>Our infrastructure is largely provisioned across AWS, with a small footprint in GCP (Google Cloud Platform), in order to power smaller components in our Userpilot application. Our Amazon infrastructure is contained within Userpilot managed VPCs (Virtual Private Clouds) and provides total isolation from other instances in the same datacenter.</p>
<p>Moreover, all of our data is synced in real-time with multiple backups on a daily basis.</p>
<h3>Backups</h3>
<p>We perform daily backups of all application data in multiple locations. We ensure every instance is fully encrypted and secured.</p>
<h3>Privacy</h3>
<p>Commitment to data privacy through initiatives:</p>
<h5>&middot; Data Transfer Practice</h5>
<p>We offer Standard Contractual Clauses for compliant user data transfer and storage outside of the EU.</p>
<h5>&middot; Privacy By Design</h5>
<p>Userpilot takes a comprehensive approach to security and privacy, and does not sell our customers' user data.</p>
<h5>&middot; Updated Privacy Policy</h5>
<p>We updated our Privacy Policy to honor our commitment to privacy.</p>
<h3>GDPR compliance with Userpilot</h3>
<h5>&middot; The right of erasure.</h5>
<p>Use Userpilot&rsquo;s HTTP API to delete a specified user&rsquo;s or company's data or a group of users, or a group of companies from Userpilot records.</p>
<p>View the API docs for HTTP DELETE [https://docs.userpilot.com/article/189-delete-users-and-companies].</p>
<h5>&middot; Monitor deletion status and requests</h5>
<p>Track the progress of deletion requests to confirm when data is finally deleted, so you can update your users.</p>
<p>View the API docs for background jobs tracking. [https://docs.userpilot.com/article/189-delete-users-and-companies]</p>
<h5>&middot; The rights of access, portability, and rectification</h5>
<p>Compile user data for access and portability requests</p>
<p>Export user data to open format (CSV) to organize data about a given user, so you can easily share it if requested.</p>
<h3>SOC2 Type II Certification</h3>
<p>SOC 2 Type II certification is awarded by an independent auditing body to ensure the security of client data processing by 3rd party service providers. The certification is based on 5 &ldquo;trust service principles&rdquo;: security, availability, processing integrity, confidentiality, and privacy</p>
<p>Thanks to SOC2 Type II, you can now be confident that Userpilot:</p>
<ul>
<li>has access control via end-to-end encryption and two-factor authentication. You can learn more about the technologies used to ensure data security in a report issued by a reputable, independent auditor.</li>
<li>uses network and application firewalls</li>
<li>has intrusion-detection mechanisms in place</li>
<li>uses performance monitoring tools</li>
<li>uses disaster recovery tools</li>
<li>has security incident handling procedures in place</li>
<li>uses quality assurance and process monitoring procedures</li>
</ul>
<p>Current and potential customers of Userpilot can now be sure about its data protection mechanisms quality and can learn all the details from the SOC 2 report, available upon request from security@userpilot.co</p>
<h3>Bug Bounty Program</h3>
<p>At Userpilot, we take user experience and security extremely seriously. We offer monetary rewards for bug reports that help us improve our product experience. If you spot any bugs, potential security issues, or technical errors - please contact security@userpilot.co. Reports will be investigated individually alongside their eligibility for the financial reward. Terms and conditions apply.</p>
<h4>CONTACT</h4>
<p>To report any issues or request more information, please drop us an email at security@userpilot.co.</p>
<h2>Appcues Privacy Policy</h2>
<p>We use Appcues on our website, which is a personalisation tool for product experiences. The provider of this service is the American company Appcues , Inc., 177 Huntington Ave Ste 1703, PMB 94414, Boston, MA 02115, USA.</p>
<p>Appcues also processes data in the USA, among other countries. We would like to note, that according to the European Court of Justice, there is currently no adequate level of protection for data transfers to the USA. This can be associated with various risks to the legality and security of data processing.</p>
<p>Appcues uses standard contractual clauses approved by the EU Commission as the basis for data processing by recipients based in third countries (i. e. outside the European Union, Iceland, Liechtenstein, Norway, and thus especially in the USA) or data transfer there (= Art. 46, paragraphs 2 and 3 of the GDPR). Standard Contractual Clauses (SCC) are legal templates provided by the EU Commission. Their purpose is to ensure that your data complies with European data privacy standards, even if your data is transferred to and stored in third countries (such as the USA). With these clauses, Appcues commits to comply with the EU&lsquo;s level of data protection when processing relevant data, even if it is stored, processed and managed in the USA. These clauses are based on an implementing order by the EU Commission. You can find the order and the standard contractual clauses here: <a href="https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847">https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847</a>.</p>
<p>You can find out more about the data that is processed through the use of Appcues in their Privacy Policy at <a href="https://www.appcues.com/privacy">https://www.appcues.com/privacy</a>.</p>
<h2>Google Ads (Google AdWords) Conversion Tracking Privacy Policy</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Google Ads (Google AdWords) Conversion Tracking Privacy Policy Overview</strong><br /> 👥 Affected parties: visitors to the website<br /> 🤝 Purpose: economic success and service optimisation.<br /> 📓 Processed data: Access statistics that contain data such as access location, device data, access duration and time, navigation behaviour, click behaviour and IP addresses. Personal data such as name or email address may also be processed.<br /> 📅 Storage period: Conversion cookies usually expire after 30 days and do not transmit any personal data<br /> ⚖️ Legal bases: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit.f GDPR (legitimate interests)</p>
</td>
</tr>
</tbody>
</table>
<h3>What is Google Ads conversion tracking?</h3>
<p>We use Google Ads (previously Google AdWords) as an online marketing measure, to advertise our products and services. Thus, we want to draw more people&rsquo;s attention on the internet to the high quality of our offers. As part of our advertising measures with Google Ads, we use the conversion tracking of Google LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (&ldquo;Google&rdquo;) on our website. With the aid of this free tracking tool we can tailor our advertising offer better to your interests and needs. In the following article we will explain, why we use conversion tracking, what data gets saved and how you can prevent this data retention.</p>
<p>Google Ads (previously Google AdWords) is the internal online advertising sxstem of the company Google LLC. We are convinced of our offer&lsquo;s quality and would like as many people as possible to discover our website. For this, Google Ads offers the best platform within the online environment. Of course, we also want to get an overview of the cost-benefit factor of our advertising campaigns. Thence, we use Google Ads&rsquo; conversion tracking tool.</p>
<p>But what is a conversion actually? A conversion occurs, when you turn from an interested visitor into an acting website visitor. This happens every time you click on our ad and then make another action, such as paying a visit to our website. With Google&rsquo;s conversion tracking tool, we can understand what happens after a user clicks our Google ad. It shows us for instance if products get bought, services are used or whether users have subscribed to our newsletter.</p>
<h3>Why do we use Google Ads conversion tracking on our website?</h3>
<p>We use Google Ads to show our offer also across other websites. Our aim is for our advertising campaigns to reach only those people, who are interested in our offers. With the conversion tracking tool, we see what keywords, ads, ad groups and campaigns lead to the desired customer actions. We see how many customers interact with our ads on a device, to then convert. With this data we can calculate our cost-benefit-factor, measure the success of individual ad campaigns and therefore optimise our online marketing measures. With the help of the obtained data we can give our website a more interesting design and customise our advertising offer better to your needs.</p>
<h3>What data is stored with Google Ads conversion tracking?</h3>
<p>For a better analysis of certain user actions, we have integrated a conversion tracking tag, or code snippet to our website. Therefore, if you click one of our Google ads, a Google domain stores the cookie &ldquo;conversion&rdquo; on your computer (usually in the browser) or on your mobile device. Cookies are little text files that save information on your computer.</p>
<p>Here are data of the most significant cookies for Google&rsquo;s conversion tracking:</p>
<p><strong>Name:</strong> Conversion<br /><strong>Value:</strong> EhMI_aySuoyv4gIVled3Ch0llweVGAEgt-mr6aXd7dYlSAGQ121865447-3<br /><strong>Purpose: </strong>This cookie saves every conversion you make on our website after you came to us via a Google ad.<br /><strong>Expiry date:</strong> after 3 months</p>
<p><strong>Name:</strong> _gac<br /><strong>Value:</strong> 1.1558695989.EAIaIQobChMIiOmEgYO04gIVj5AYCh2CBAPrEAAYASAAEgIYQfD_BwE<br /><strong>Purpose:</strong> This is a classic Google Analytics Cookie that records various actions on our website.<br /><strong>Expiry date:</strong> after 3 months</p>
<p><strong>Note:</strong> The cookie _gac only appears in connection with Google Analytics. The above list does not claim to be exhaustive, as Google repeatedly change the cookies they use for analytical evaluation.</p>
<p>As soon as you complete an action on our website, Google identifies the cookie and saves your action as a so-called conversion. For as long as you surf our website, provided the cookie has not expired, both Google and us can determine that you found your way to us via a Google ad. Then, the cookie is read and sent back to Google Ads, together with the conversion data. Moreover, other cookies may also be used for measuring conversions. Google Ads&lsquo; conversion tracking can be fine-tuned and improved with the aid of Google Analytics. Furthermore, ads which Google displays in various places across the web, might be placed under our domain with the name &ldquo;__gads&rdquo; or &ldquo;_gac&rdquo;.<br /> Since September 2017, analytics.js retains various campaign information with the _gac cookie. This cookie stores data, as soon as you open one of our sites that has been set up for Google Ads&rsquo; auto-tagging. In contrast to cookies that are placed for Google domains, Google can only read these conversion cookies when you are on our website. We do neither collect nor receive any personal data. We do obtain a report with statistical evaluations by Google. With the help thereof, we can not only see the total number of users who clicked our ad, but also what advertising measures were well received.</p>
<h3>How long and where is the data stored?</h3>
<p>At this point we want to reiterate, that we have no influence on how Google use the collected data. According to Google, the data are encrypted and stored on a secure server. In most cases, conversion cookies expire after 30 days, and do not transmit any personalised data. The cookies named &ldquo;conversion&ldquo; and &ldquo;_gac&ldquo; (which is used with Google Analytics) have an expiry date of 3 months.</p>
<h3>How can I erase my data or prevent data retention?</h3>
<p>You have the possibility to opt out of Google Ads&rsquo; conversion tracking. The conversion tracking can be blocked by deactivating the conversion tracking cookie via your browser. If you do this, you will not be considered for the statistic of the tracking tool. You can change the cookie settings in your browser anytime. Doing so, works a little different in every browser. Hence, in the following you will find an instruction on how to manage cookies in your browser:</p>
<p><a href="https://support.google.com/chrome/answer/95647?tid=121865447">Chrome: Clear, enable and manage cookies in Chrome </a></p>
<p><a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac?tid=121865447">Safari: Manage cookies and website data in Safari </a></p>
<p><a href="%20https:/support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?tid=121865447">Firefox: Clear cookies and site data in Firefox </a></p>
<p><a href="%20https:/support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121865447">Internet Explorer: Delete and manage cookies </a></p>
<p><a href="%20https:/support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies?tid=121865447">Microsoft Edge: Delete cookies in Microsoft Edge </a></p>
<p>If you generally do not want to allow any cookies at all, you can set up your browser to notify you whenever a potential cookie is about to be set. This lets you decide upon permitting or denying the cookie&rsquo;s placement. By downloading and installing the browser plugin at <a href="https://support.google.com/ads/answer/7395996">https://support.google.com/ads/answer/7395996</a> you can also deactivate all &ldquo;advertising cookies&rdquo;. Please consider that by deactivating these cookies, you cannot prevent all advertisements, only personalised ads.</p>
<h3>Legal basis</h3>
<p>If you have consented to the use of Google Ads Conversion Tracking, your consent is the legal basis for the corresponding data processing. According to <strong>Art. 6 para. 1 lit. a GDPR (consent)</strong>, this consent is the legal basis for personal data processing, as may be done when collected by Google Ads Conversion Tracking.</p>
<p>We also have legitimate interest in using Google Ads Conversion Tracking to optimise our online service and marketing measures. The corresponding legal basis for this is <strong>Art. 6 para. 1 lit. f GDPR (legitimate interests)</strong>. Nevertheless, we only use Google Ads Conversion Tracking if you have consented to it.</p>
<p>Google also processes data in the USA, among other countries. We would like to note, that according to the European Court of Justice, there is currently no adequate level of protection for data transfers to the USA. This can be associated with various risks to the legality and security of data processing.</p>
<p>Google uses standard contractual clauses approved by the EU Commission as basis for data processing by recipients based in third countries (outside the European Union, Iceland, Liechtenstein, Norway and especially in the USA) or data transfer there (= Art. 46, paragraphs 2 and 3 of the GDPR). These clauses oblige Google to comply with the EU&lsquo;s level of data protection when processing relevant data outside the EU. These clauses are based on an implementing order by the EU Commission. You can find the order and the clauses here: <a href="https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847">https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847</a></p>
<p>If you would like to find out more about data protection at Google, we recommend Google&rsquo;s privacy policy at: <a href="https://policies.google.com/privacy?hl=en-GB">https://policies.google.com/privacy?hl=en-GB</a>.</p>
<h2>HubSpot privacy policy</h2>
<p>On our website, we use HubSpot, which is a tool for digital marketing. The provider of this service is the American company HubSpot Inc.. The responsible entity for the European region is the Irish company HubSpot (1 Sir John Rogerson&rsquo;s Quay, Dublin 2, Ireland).</p>
<p>HubSpot also processes data in the USA, among other countries. We would like to note, that according to the European Court of Justice, there is currently no adequate level of protection for data transfers to the USA. This can be associated with various risks to the legality and security of data processing.</p>
<p>HubSpot uses standard contractual clauses approved by the EU Commission as basis for data processing by recipients based in third countries (outside the European Union, Iceland, Liechtenstein, Norway, and especially in the USA) or data transfer there (= Art. 46, paragraphs 2 and 3 of the GDPR). These clauses oblige HubSpot to comply with the EU&lsquo;s level of data protection when processing relevant data outside the EU. These clauses are based on an implementing order by the EU Commission. You can find the order and the clauses here: <a href="https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847">https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847</a></p>
<p>You can find out more about HubSpot&rsquo;s data processing in their privacy policy at <a href="https://legal.hubspot.com/de/privacy-policy">https://legal.hubspot.com/de/privacy-policy</a>.</p>
<h2>OptiMonk Privacy Policy</h2>
<p>On our website we use OptiMonk, a web optimization tool for onlineshops. The provider of this service is the Hungarian company Optimonk International Zrt. Address. Kassai &uacute;t 129. Debrecen, 4028. Hungary. You can find out more on the data processed by using OptiMonk in their Privacy Policy at <a href="https://www.optimonk.com/privacy-policy/">https://www.optimonk.com/privacy-policy/</a>.</p>
<h2 id="content-delivery-networks">Content Delivery Networks</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Content Delivery Networks Privacy Policy Overview</strong><br /> 👥 Affected parties: website visitors<br /> 🤝 Purpose: Service performance optimisation (to increase website loading speeds)<br /> 📓 Processed data: data such as your IP address<br /> You can find more details on this below as well as in the individual Privacy Policies.<br /> 📅 Storage period: most data is stored until it is no longer needed for the provision of the service.<br /> ⚖️ Legal bases: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit. f GDPR (legitimate interests)</p>
</td>
</tr>
</tbody>
</table>
<h3>What is a Content Delivery Network?</h3>
<p>On our website we use a so-called content delivery network or CDN. This helps to load our website quickly and easily, regardless of your location. Moreover, your personal data will also be stored, managed and processed on the respective CDN provider&rsquo;s servers. In the following, we will go into more general detail on this service and the data processing associated with it. You can find detailed information on how your data is handled in the provider&rsquo;s Privacy Policy.</p>
<p>Each content delivery network (CDN) is a network of regionally distributed servers that are connected to each other via the internet. Through this network, website content (especially very large files) can be delivered quickly and smoothly, even when large loading peaks occur. To make this possible, CDNs create a copy of our website on their servers. The website can be delivered quickly because these servers are distributed all around the world. Any data transfer to your browser is therefore significantly shortened by the CDN.</p>
<h3>Why do we use a Content Delivery Network for our website?</h3>
<p>A fast loading website is part of our service. Of course, we know how annoying it is when a website loads at a snail&rsquo;s pace. Most of the time, you lose your patience and click away before the website is fully loaded. But of course we want to avoid that. Therefore, to us a fast loading website is an obligatory part of our website offer. With the use of a content delivery network, our website loads significantly faster in your browser. Furthermore, CDNs are particularly helpful when you are abroad, as the website is always delivered from a server in your area.</p>
<h3>Which data are processed?</h3>
<p>If you access a website or its content and it gets cached in a CDN, the CDN forwards the request to the server closest to you which then delivers the content. Content delivery networks are built in a way that JavaScript libraries can be downloaded and hosted on npm and Github servers. Alternatively, WordPress plugins can also be loaded on most CDNs, provided they are hosted on <a href="https://wordpress.org/">WordPress.org</a>. Moreover, your browser can send personal data to the content delivery network we use. This includes data such as IP addresses, browser type, browser version, the accessed website or the time and date of the page visit. This data is collected and stored by the CDN. Whether cookies are used for data storage depends on the network that is being used. For more information on this, please read the Privacy Policy of the respective service.</p>
<h3>Right to object</h3>
<p>If you want to prevent this data transfer altogether, you can use a JavaScript blocker (see for example <a href="https://noscript.net/">https://noscript.net/</a>) on your computer. However, our website can then of course no longer offer its usual service (such as a fast loading speeds).</p>
<h3>Legal basis</h3>
<p>If you have consented to the use of a content delivery network, your consent represents the the legal basis for the corresponding data processing. According to <strong>Art. 6 paragraph 1 lit. a (consent)</strong> your consent represents the legal basis for the processing of personal data, as it can occur when collected by a content delivery network.</p>
<p>We also have a legitimate interest in using a content delivery network to optimise our online service and make it more secure. The corresponding legal basis for this is <strong>Art. 6 para. 1 lit. f GDPR (legitimate interests)</strong>. Nevertheless, we only use the tool if you have consented to it.</p>
<p>Provided this information is available, you can find out more about the particular content delivery networks in the following sections.</p>
<h2>DigitalOcean CDN Privacy Policy</h2>
<p>For our website we use DigitalOcean CDN for our website, which is a a content delivery network, among other things. The provider of this service is the American company DigitalOcean LLC, New York, NY, 101 6th Ave, USA.</p>
<p>DigitalOcean also processes data in the USA, among other countries. We would like to note, that according to the European Court of Justice, there is currently no adequate level of protection for data transfers to the USA. This can be associated with various risks to the legality and security of data processing.</p>
<p>DigitalOcean uses standard contractual clauses approved by the EU Commission as basis for data processing by recipients based in third countries (outside the European Union, Iceland, Liechtenstein, Norway and especially in the USA) or data transfer there (= Art. 46, paragraphs 2 and 3 of the GDPR). These clauses oblige DigitalOcean to comply with the EU&lsquo;s level of data protection when processing relevant data outside the EU. These clauses are based on an implementing order by the EU Commission. You can find the order and the clauses here: <a href="https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847">https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847</a></p>
<p>You can find out more about the data processed by the use of DigitalOcean CDN in the privacy policy at <a href="https://www.digitalocean.com/legal/">https://www.digitalocean.com/legal/</a><u>.</u></p>
<h2 id="audio-and-video">Audio &amp; Video</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Audio &amp; Video Privacy Policy Overview</strong><br /> 👥 Affected parties: website visitors<br /> 🤝 Purpose: service optimisation<br /> 📓 Processed data: Data such as contact details, user behaviour, device information and IP addresses can be stored.<br /> You can find more details in the Privacy Policy below.<br /> 📅 Storage period: data are retained for as long as necessary for the provision of the service<br /> ⚖️ Legal bases: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit. f GDPR (legitimate interests)</p>
</td>
</tr>
</tbody>
</table>
<h3>What are audio and video elements?</h3>
<p>We have integrated audio and video elements to our website. Therefore, you can watch videos or listen to music/podcasts directly via our website. This content is delivered by service providers and is obtained from the respective providers&rsquo; servers.</p>
<p>Audio and video elements are integrated functional elements of platforms such as YouTube, Vimeo or Spotify. It is usually free of charge to use these portals, but they can also contain paid content. With the integrated elements, you can listen to or view any of their content on our website.</p>
<p>If you use audio or video elements on our website, your personal data may get transmitted to as well as processed and retained by service providers.</p>
<h3>Why do we use audio &amp; video elements on our website?</h3>
<p>We of course want to provide you with the best offer on our website. And we are aware that content is no longer just conveyed in text and static images. Instead of just giving you a link to a video, we offer you audio and video formats directly on our website. These are entertaining or informative, but ideally they are both. Our service therefore gets expanded and it gets easier for you to access interesting content. In addition to our texts and images, we thus also offer video and/or audio content.</p>
<h3>Which data are retained by audio &amp; video elements?</h3>
<p>When you visit a page on our website with e.g. an embedded video, your server connects to the service provider&rsquo;s server. Thus, your data will also be transferred to the third-party provider, where it will be stored. Certain data is collected and stored regardless of whether you have an account with the third party provider or not. This usually includes your IP address, browser type, operating system and other general information about your device. Most providers also collect information on your web activity. This e.g. includes the session duration, bounce rate, the buttons you clicked or information about the website you are using the service on. This data is mostly stored via cookies or pixel tags (also known as web beacons). Any data that is pseudonymised usually gets stored in your browser via cookies. In the respective provider&rsquo;s Privacy Policy, you can always find more information on the data that is stored and processed.</p>
<h3>Duration of data processing</h3>
<p>You can find out exactly how long the data is stored on the third-party provider&rsquo;s servers either in a lower point of the respective tool&rsquo;s Privacy Policy or in the provider&rsquo;s Privacy Policy. Generally, personal data is only processed for as long as is absolutely necessary for the provision of our services or products. This usually also applies to third-party providers. In most cases, you can assume that certain data will be stored on third-party providers&rsquo; servers for several years. Data can be retained for different amounts of time, especially when stored in cookies. Some cookies are deleted after you leave a website, while others may be stored in your browser for a few years.</p>
<h3>Right to object</h3>
<p>You also retain the right and the option to revoke your consent to the use of cookies or third-party providers at any time. This can be done either via our cookie management tool or via other opt-out functions. You can e.g. also prevent data retention via cookies by managing, deactivating or erasing cookies in your browser. The legality of the processing up to the point of revocation remains unaffected.</p>
<p>Since the integrated audio and video functions on our site usually also use cookies, we recommend you to also read our general Privacy Policy on cookies. You can find out more about the handling and storage of your data in the Privacy Policies of the respective third party providers.</p>
<h3><strong>Legal basis </strong></h3>
<p>If you have consented to the processing and storage of your data by integrated audio and video elements, your consent is considered the legal basis for data processing <strong>(Art. 6 Para. 1 lit. a GDPR)</strong>. Generally, your data is also stored and processed on the basis of our legitimate interest <strong>(Art. 6 Para. 1 lit. f GDPR) </strong>in maintaining fast and good communication with you or other customers and business partners. We only use the integrated audio and video elements if you have consented to it.</p>
<h2>YouTube Privacy Policy</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>YouTube Privacy Policy Overview</strong><br /> 👥 Affected parties: website visitors<br /> 🤝 Purpose: optimising our service<br /> 📓 Processed data: Data such as contact details, data on user behaviour, information about your device and IP address may be stored.<br /> You can find more details on this in the privacy policy below.<br /> 📅 Storage period: data are generally stored for as long as is necessary for the purpose of the service<br /> ⚖️ Legal bases: Art. 6 para. 1 lit. a GDPR (consent), Art. 6 para. 1 lit. f GDPR (legitimate interests)</p>
</td>
</tr>
</tbody>
</table>
<h3>What is YouTube?</h3>
<p>We have integrated YouTube videos to our website. Therefore, we can show you interesting videos directly on our site. YouTube is a video portal, which has been a subsidiary company of Google LLC since 2006. The video portal is operated by YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. When you visit a page on our website that contains an embedded YouTube video, your browser automatically connects to the servers of YouTube or Google. Thereby, certain data are transferred (depending on the settings). Google is responsible for YouTube&rsquo;s data processing and therefore Google&rsquo;s data protection applies.</p>
<p>In the following we will explain in more detail which data is processed, why we have integrated YouTube videos and how you can manage or clear your data.</p>
<p>On YouTube, users can watch, rate, comment or upload videos for free. Over the past few years, YouTube has become one of the most important social media channels worldwide. For us to be able to display videos on our website, YouTube provides a code snippet that we have integrated to our website.</p>
<h3>Why do we use YouTube videos on our website?</h3>
<p>YouTube is the video platform with the most visitors and best content. We strive to offer you the best possible user experience on our website, which of course includes interesting videos. With the help of our embedded videos, we can provide you other helpful content in addition to our texts and images. Additionally, embedded videos make it easier for our website to be found on the Google search engine. Moreover, if we place ads via Google Ads, Google only shows these ads to people who are interested in our offers, thanks to the collected data.</p>
<h3>What data is stored by YouTube?</h3>
<p>As soon as you visit one of our pages with an integrated YouTube, YouTube places at least one cookie that stores your IP address and our URL. If you are logged into your YouTube account, by using cookies YouTube can usually associate your interactions on our website with your profile. This includes data such as session duration, bounce rate, approximate location, technical information such as browser type, screen resolution or your Internet provider. Additional data can include contact details, potential ratings, shared content via social media or YouTube videos you added to your favourites.</p>
<p>If you are not logged in to a Google or YouTube account, Google stores data with a unique identifier linked to your device, browser or app. Thereby, e.g. your preferred language setting is maintained. However, many interaction data cannot be saved since less cookies are set.</p>
<p>In the following list we show you cookies that were placed in the browser during a test. On the one hand, we show cookies that were set without being logged into a YouTube account. On the other hand, we show you what cookies were placed while being logged in. We do not claim for this list to be exhaustive, as user data always depend on how you interact with YouTube.</p>
<p><strong>Name:</strong> YSC<br /><strong>Value:</strong> b9-CV6ojI5Y121865447-1<br /><strong>Purpose:</strong> This cookie registers a unique ID to store statistics of the video that was viewed.<br /><strong>Expiry date:</strong> after end of session</p>
<p><strong>Name:</strong> PREF<br /><strong>Value:</strong> f1=50000000<br /><strong>Purpose:</strong> This cookie also registers your unique ID. Google receives statistics via PREF on how you use YouTube videos on our website.<br /><strong>Expiry date:</strong> after 8 months</p>
<p><strong>Name:</strong> GPS<br /><strong>Value:</strong> 1<br /><strong>Purpose:</strong> This cookie registers your unique ID on mobile devices to track GPS locations.<br /><strong>Expiry date:</strong> after 30 minutes</p>
<p><strong>Name:</strong> VISITOR_INFO1_LIVE<br /><strong>Value:</strong> 95Chz8bagyU<br /><strong>Purpose:</strong> This cookie tries to estimate the user&rsquo;s internet bandwith on our sites (that have built-in YouTube videos).<br /><strong>Expiry date:</strong> after 8 months</p>
<p>Further cookies that are placed when you are logged into your YouTube account:</p>
<p><strong>Name:</strong> APISID<br /><strong>Value:</strong> zILlvClZSkqGsSwI/AU1aZI6HY7121865447-<br /><strong>Purpose:</strong> This cookie is used to create a profile on your interests. This data is then used for personalised advertisements.<br /><strong>Expiry date:</strong> after 2 years</p>
<p><strong>Name:</strong> CONSENT<br /><strong>Value:</strong> YES+AT.de+20150628-20-0<br /><strong>Purpose:</strong> The cookie stores the status of a user&rsquo;s consent to the use of various Google services. CONSENT also provides safety measures to protect users from unauthorised attacks.<br /><strong>Expiry date: </strong>after 19 years</p>
<p><strong>Name:</strong> HSID<br /><strong>Value:</strong> AcRwpgUik9Dveht0I<br /><strong>Purpose:</strong> This cookie is used to create a profile on your interests. This data helps to display customised ads.<br /><strong>Expiry date:</strong> after 2 years</p>
<p><strong>Name:</strong> LOGIN_INFO<br /><strong>Value:</strong> AFmmF2swRQIhALl6aL&hellip;<br /><strong>Purpose:</strong> This cookie stores information on your login data.<br /><strong>Expiry date:</strong> after 2 years</p>
<p><strong>Name:</strong> SAPISID<br /><strong>Value:</strong> 7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM<br /><strong>Purpose:</strong> This cookie identifies your browser and device. It is used to create a profile on your interests.<br /><strong>Expiry date:</strong> after 2 years</p>
<p><strong>Name:</strong> SID<br /><strong>Value:</strong> oQfNKjAsI121865447-<br /><strong>Purpose:</strong> This cookie stores your Google Account ID and your last login time, in a digitally signed and encrypted form.<br /><strong>Expiry date:</strong> after 2 years</p>
<p><strong>Name:</strong> SIDCC<br /><strong>Value:</strong> AN0-TYuqub2JOcDTyL<br /><strong>Purpose:</strong> This cookie stores information on how you use the website and on what advertisements you may have seen before visiting our website.<br /><strong>Expiry date:</strong> after 3 months</p>
<h3>How long and where is the data stored?</h3>
<p>The data YouTube receive and process on you are stored on Google&rsquo;s servers. Most of these servers are in America. At <a href="https://www.google.com/about/datacenters/inside/locations/?hl=en">https://www.google.com/about/datacenters/inside/locations/?hl=en</a> you can see where Google&rsquo;s data centres are located. Your data is distributed across the servers. Therefore, the data can be retrieved quicker and is better protected against manipulation.</p>
<p>Google stores collected data for different periods of time. You can delete some data anytime, while other data are automatically deleted after a certain time, and still other data are stored by Google for a long time. Some data (such as elements on &ldquo;My activity&rdquo;, photos, documents or products) that are saved in your Google account are stored until you delete them. Moreover, you can delete some data associated with your device, browser, or app, even if you are not signed into a Google Account.</p>
<h3>How can I erase my data or prevent data retention?</h3>
<p>Generally, you can delete data manually in your Google account. Furthermore, in 2019 an automatic deletion of location and activity data was introduced. Depending on what you decide on, it deletes stored information either after 3 or 18 months.</p>
<p>Regardless of whether you have a Google account or not, you can set your browser to delete or deactivate cookies placed by Google. These settings vary depending on the browser you use. The following instructions will show how to manage cookies in your browser:</p>
<p><a href="https://support.google.com/chrome/answer/95647?tid=121865447">Chrome: Clear, enable and manage cookies in Chrome </a></p>
<p><a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac?tid=121865447">Safari: Manage cookies and website data in Safari </a></p>
<p><a href="%20https:/support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?tid=121865447">Firefox: Clear cookies and site data in Firefox </a></p>
<p><a href="%20https:/support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies?tid=121865447">Internet Explorer: Delete and manage cookies </a></p>
<p><a href="%20https:/support.microsoft.com/en-gb/help/4027947/microsoft-edge-delete-cookies?tid=121865447">Microsoft Edge: Delete cookies in Microsoft Edge </a></p>
<p>If you generally do not want to allow any cookies, you can set your browser to always notify you when a cookie is about to be set. This will enable you to decide to either allow or permit each individual cookie.</p>
<h3>Legal basis</h3>
<p>If you have consented processing and storage of your data by integrated YouTube elements, this consent is the legal basis for data processing <strong>(Art. 6 para. 1 lit. a GDPR)</strong>. Generally, your data is also stored and processed on the basis of our legitimate interest <strong>(Art. 6 para. 1 lit. f GDPR) </strong>to maintain fast and good communication with you or other customers and business partners. Nevertheless, we only use integrated YouTube elements if you have given your consent. YouTube also sets cookies in your browser to store data. We therefore recommend you to read our privacy policy on cookies carefully and to take a look at the privacy policy or the cookie policy of the respective service provider.</p>
<p>YouTube also processes data in the USA, among other countries. We would like to note, that according to the European Court of Justice, there is currently no adequate level of protection for data transfers to the USA. This can be associated with various risks to the legality and security of the data processing.</p>
<p>YouTube uses standard contractual clauses approved by the EU Commission (= Art. 46, paragraphs 2 and 3 of the GDPR) as basis for data processing by recipients based in third countries (which are outside the European Union, Iceland, Liechtenstein and Norway) or for data transfer there. These clauses oblige YouTube to comply with the EU&lsquo;s level of data protection when processing relevant data outside the EU. These clauses are based on an implementing order by the EU Commission. You can find the order and the clauses here: <a href="https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847">https://ec.europa.eu/commission/presscorner/detail/en/ip_21_2847</a></p>
<p>Since YouTube is a subsidiary company of Google, Google&rsquo;s privacy statement applies to both. If you want to learn more about how your data is handled, we recommend the privacy policy at <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>.</p>
<h2>YouTube Subscribe Button Privacy Policy</h2>
<p>We have integrated the YouTube subscribe button to our website, which you can recognise by the classic YouTube logo. The logo shows the words &ldquo;Subscribe&rdquo; or &ldquo;YouTube&rdquo; in white letters against a red background, with a white &ldquo;Play&rdquo; symbol on the left. The button may also be displayed in a different design.</p>
<p>Our YouTube channel consistently offers you funny, interesting or exciting videos. With the built-in &ldquo;Subscribe&rdquo; button you can subscribe to our channel directly via our website and do not need to go to YouTube&rsquo;s website for it. With this feature, we want to make it as easy as possible for you to access our comprehensive content. Please note that YouTube may save and process your data.</p>
<p>If you see a built-in subscription button on our page, YouTube sets at least one cookie, according to Google. This cookie stores your IP address and our URL. It also allows YouTube to receive information about your browser, your approximate location and your default language. In our test the following four cookies were placed, without us being logged into YouTube:</p>
<p><strong>Name:</strong> YSC<br /><strong>Value:</strong> b9-CV6ojI5121865447Y<br /><strong>Purpose:</strong> This cookie registers a unique ID, which stores statistics of the viewed video.<br /><strong>Expiry date:</strong> after end of session</p>
<p><strong>Name:</strong> PREF<br /><strong>Value:</strong> f1=50000000<br /><strong>Purpose:</strong>This cookie also registers your unique ID. Google uses PREF to get statistics on how you interact with YouTube videos on our website.<br /><strong>Expiry date:</strong> after 8 months</p>
<p><strong>Name:</strong> GPS<br /><strong>Value:</strong> 1<br /><strong>Purpose:</strong>This cookie registers your unique ID on mobile devices to track your GPS location.<br /><strong>Expiry date:</strong> after 30 minutes</p>
<p><strong>Name:</strong> VISITOR_INFO1_LIVE<br /><strong>Value:</strong> 12186544795Chz8bagyU<br /><strong>Purpose:</strong> This cookie tries to estimate the user&rsquo;s internet bandwith on our website (that contain built-in YouTube video).<br /><strong>Expiry date:</strong> after 8 months</p>
<p><strong>Note: </strong>These cookies were set after a test, thus we do not claim for the list to be exhaustive.</p>
<p>If you are logged into your YouTube account, YouTube may store many of the actions and interactions you make on our website via cookies, to then assign them to your YouTube account. This gives YouTube information on e.g. how long you have been browsing our website, which browser type you use, which screen resolution you prefer or what actions you take.</p>
<p>On the one hand, YouTube uses this data to improve its own services and offers, and on the other hand to provide analyses and statistics for advertisers (who use Google Ads).</p>
<h2 id="video-streaming">Video Conferencing &amp; Streaming</h2>
<table>
<tbody>
<tr>
<td>
<p><strong>Video Conferencing &amp; Streaming Privacy Policy Overview</strong><br /> 👥 Affected parties: users of our video conferencing or streaming tools<br /> 🤝 Purpose: communication and presentation of content<br /> 📓 Processed data: Access statistics such as your name, address, contact details, email address, telephone number or IP address. You can find more details on this directly at the video conference or streaming tool that is being used.<br /> 📅 Storage duration: depending on the video conferencing or streaming tool<br /> ⚖️ Legal bases: Article 6 (1) (a) GDPR (consent), Article 6 (1) (f) GDPR (legitimate interests), Article 6 (1) (b) GDPR (contract)</p>
</td>
</tr>
</tbody>
</table>
<h3>What are video conferences &amp; streamings?</h3>
<p>We use software programs that enable us to hold video conferences, online meetings, webinars and to utilise display sharing and/or streaming. In a video conference or a streaming, information is transmitted simultaneously via sound and moving images. With such video conference or streaming tools, we can communicate quickly and easily with customers, business partners, clients and employees via the Internet. Of course, when selecting the service provider, we consider the given legal framework.</p>
<p>Generally, third-party providers can process data as soon as you interact with the software program. Third-party video conference providers or streaming solutions use your data and metadata for different purposes. The data helps to e.g. make the tool more secure and to improve the service. Most of the time, the data may also be used for the respective third-party provider&rsquo;s own marketing purposes.</p>
<h3>Why do we use video conferencing &amp; streaming on our website?</h3>
<p>We want to communicate digitally, quickly, easily and securely with you, our customers and our business partners. This works best with video conferencing solutions that are very easy to use. Most tools also work directly in your browser and with just a few clicks you can get right to your video meeting. The tools also offer helpful additional features such as chat and screen sharing functions or the possibility of sharing content between meeting participants.</p>
<h3>Which data are processed?</h3>
<p>If you join our video conference or streaming, your data will also be processed and stored on the servers of the respective service provider.</p>
<p>The exact data that gets stored depends on the respective software. Each provider stores and processes data differently. Generally, however, most providers store your name, address, contact details such as your email address or telephone number and your IP address. Information about the device you are using may also get stored, along with usage data, such as which websites you visit, when you visit a website or which buttons you click. Data that is shared within the video conference (photos, videos, texts) may also be retained.</p>
<h3>Duration of data processing</h3>
<p>Below we will inform you about the duration of the data processing of the service that is being used, provided we have further information on this. In general, we only process personal data for as long as is absolutely necessary to provide our services and products. Moreover, it is possible that the provider may store your data according to their own requirements, which we have no influence on.</p>
<h3>Right to object</h3>
<p>You always have the right to information, rectification and erasure of your personal data. If you have any questions, you can always contact the person responsible for the respective video conferencing or streaming tool. You can find contact details either in our specific privacy policy or on the website of the relevant provider.</p>
<p>In your browser you can erase, deactivate or manage cookies that providers use for their functions. This works slightly different, depending on which browser you are using. Please note, however, that functions may not keep working as usual after doing so.</p>
<h3>Legal basis</h3>
<p>If you have consented to the processing and storage of your data by the respective video or streaming solution, this consent is the legal basis for data processing <strong>(Art. 6 para. 1 lit. a GDPR)</strong>. We may also offer a video conference as part of our services, if there has been a contractual agreement with you in advance <strong>(Art. 6 para. 1 lit. b GDPR)</strong>. Generally, your data is also stored and processed on the basis of our legitimate interest <strong>(Art. 6 para. 1 lit. f GDPR)</strong> to maintain fast and good communication with you or other customers and business partners, provided you have priorly given consent. Most video or streaming solutions also place cookies in your browser to store data. We therefore recommend you to read our privacy policy about cookies carefully and to take a look at the privacy policy or the cookie policy of the respective service provider.</p>
<p>Provided it is available, you can find information on special video conference and streaming solutions in the following sections.</p>
<p>All texts are copyrighted.</p>`
}