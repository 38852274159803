/*=========================================================================================
  File Name: moduleProjectsState.js
  Description: Projects Module State
==========================================================================================*/

export default {
  project: {},
  allProjectsFetched: false,
  projects: [],
  services: [],
  addons: [],
  projectAvailableAddons: [],
  projectTypes: [],
  projectStatusesList: [],
  propsterProjectManagersList: [],
  projectFiles: [],
  projectMembers: [],
  projectNonMembers: [],
  projectUsersWithAppsMarketplacesRoles: []
}
