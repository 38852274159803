/*=========================================================================================
  File Name: moduleSsoAppActions.js
  Description: SSO App Module Actions
==========================================================================================*/

import axios from '@/axios.js';
import helperFunctions from '@/helper/functions.js';
import apiQueries from './api/queries.js';

export default {
  syncCompanies ({commit}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.syncCompanies()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.ssoSyncCompanies)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  syncProjects ({commit}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.syncProjects()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.ssoSyncProjects)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  syncUsers ({commit}) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.syncUsers()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          resolve(response.data.data.ssoSyncUsers)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
