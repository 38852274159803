/*=========================================================================================
  File Name: moduleSsoAppActions.js
  Description: SSO App Module Actions
==========================================================================================*/

import axios from '@/axios.js';
import helperFunctions from '@/helper/functions.js';
import apiQueries from './api/queries.js';

export default {
  fetchAppServices ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getAllServices()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(helperFunctions.getApiErrorMessages(response.data.errors));
          }
          const responseData = response.data.data.ssoAppServices;
          commit('SET_APP_SERVICES', responseData);
          resolve(responseData);
        })
        .catch((error) => { reject(error) })
    });
  },
  fetchAppRoles ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getAllRoles()
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }
        const responseData = response.data.data.ssoAppRoles;
        commit('SET_APP_ROLES', responseData);
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  },
  fetchDetailedAppRoles ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getAllDetailedRoles()
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }
        const responseData = response.data.data.ssoAppRoles;
        commit('SET_APP_ROLES', responseData);
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  },
  fetchAppRolesByService (context, ssoServiceId) {
    const query = apiQueries.getSsoRolesByService(ssoServiceId);
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: query
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }
        const responseData = response.data.data.ssoAppRolesByServiceId;
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  },
  createAppRole ({ commit }, roleData) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.createAppRole(roleData)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        commit('ADD_NEW_APP_ROLE', [response.data.data.createSsoAppRole])
        resolve(response.data.data.createSsoAppRole)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  updateAppRole ({ commit }, roleData) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.updateAppRole(roleData)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        resolve(response.data.data.updateSsoAppRole)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  deleteAppRole ({ commit }, roleId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.deleteAppRole(roleId)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        commit('DELETE_APP_ROLE', response.data.data.deleteSsoAppRole)
        resolve(response.data.data.deleteSsoAppRole)
      })
      .catch((error) => { reject(error) })
    })
  },
}
