/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'

Vue.use(Vuesax)

import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

// axios
import axios from './axios.js'
Vue.prototype.$http = axios

// API Calls
import './http/requests'

// Theme Configurations
import '../themeConfig.js'

import helperFunctions from '@/helper/functions.js';
Vue.prototype.$swmHelper = helperFunctions

// Auth0 Plugin
import AuthPlugin from './plugins/auth'
Vue.use(AuthPlugin)


// ACL
import acl from './acl/acl'


// Globally Registered Components
import './globalComponents.js'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// i18n
import i18n from './i18n/i18n'


// Vuexy Admin Filters
import './filters/filters'


// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


// Tour
import VueTour from 'vue-tour'
Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')


// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)

import validationMessagesEN from 'vee-validate/dist/locale/en';
import validationMessagesDE from 'vee-validate/dist/locale/de';
import {Validator} from 'vee-validate'

Validator.localize('en', validationMessagesEN);
Validator.localize('de', validationMessagesDE);


// Tooltip
import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

require('@sass/dashboard/tooltip/style.scss')

import Vuetify from 'vuetify'
Vue.use(Vuetify)

// Google Maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: 'AIzaSyB4DDathvvwuwlwnUu7F4Sow3oU22y5T1Y',
    libraries: 'places' // This is required if you use the Auto complete plug-in
  }
})

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// Sentry
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  Vue,
  dsn: process.env.MIX_SENTRY_VUE_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.MIX_SENTRY_ENVIRONMENT,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(process.env.MIX_SENTRY_VUE_TRACES_SAMPLE_RATE),
  logErrors: true
})

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('@assets/css/iconfont.css')

import '@fortawesome/fontawesome-free/css/all.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faHome, faFolder, faFolderOpen, faUserFriends,
  faListUl, faQuestionCircle, faExclamationCircle, faGlobe,
  faPen, faTrash, faChartBar, faExternalLinkAlt,
  faThumbsUp, faFileInvoice, faEye, faFileAlt, faEnvelope, faHistory,
  faToggleOn, faToggleOff, faInfoCircle, faCog, faBook, faRobot
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faHome, faFolder, faFolderOpen, faUserFriends,
  faListUl, faQuestionCircle, faExclamationCircle, faGlobe,
  faPen, faTrash, faChartBar, faExternalLinkAlt,
  faThumbsUp, faFileInvoice, faEye, faFileAlt, faEnvelope, faHistory,
  faToggleOn, faToggleOff, faInfoCircle, faCog, faBook, faRobot
)

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';


Vue.config.productionTip = false

new Vue({
  vuetify: new Vuetify(),
  router,
  store,
  i18n,
  acl,
  render: h => h(App)
}).$mount('#app')
