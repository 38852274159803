/*=========================================================================================
  File Name: moduleSsoAppMutations.js
  Description: SSO App Module Mutations
==========================================================================================*/


export default {
 
 SET_DETAILED_REQUEST_STATUS_LIST (state, items) {
  state.RequestStatusDetailedList = items;
 },
 ADD_NEW_REQUEST_STATUS (state, item) {
  state.RequestStatusDetailedList = [...state.RequestStatusDetailedList, ...item]
 },
 DELETE_REQUEST_STATUS (state, deletedItem) {
  state.RequestStatusDetailedList = state.RequestStatusDetailedList.filter(item => {
   return item.id !== deletedItem.id
  })
 },

 SET_DETAILED_DEFECT_STATUS_LIST (state, items) {
  state.DefectStatusDetailedList = items;
 },
 ADD_NEW_DEFECT_STATUS (state, item) {
  state.DefectStatusDetailedList = [...state.DefectStatusDetailedList, ...item]
 },
 DELETE_DEFECT_STATUS (state, deletedItem) {
  state.DefectStatusDetailedList = state.DefectStatusDetailedList.filter(item => {
   return item.id !== deletedItem.id
  })
 },

 SET_DETAILED_DEFECT_ITEM_CATEGORY_LIST (state, items) {
  state.DefectItemCategoryDetailedList = items;
 },
 ADD_NEW_DEFECT_ITEM_CATEGORY (state, item) {
  state.DefectItemCategoryDetailedList = [...state.DefectItemCategoryDetailedList, ...item]
 },
 DELETE_DEFECT_ITEM_CATEGORY (state, deletedItem) {
  state.DefectItemCategoryDetailedList = state.DefectItemCategoryDetailedList.filter(item => {
   return item.id !== deletedItem.id
  })
 },

 SET_DETAILED_REQUEST_PRODUCT_CATEGORY_LIST (state, items) {
  state.SwmProductCategoryDetailedList = items;
 },
 ADD_NEW_REQUEST_PRODUCT_CATEGORY (state, item) {
  state.SwmProductCategoryDetailedList = [...state.SwmProductCategoryDetailedList, ...item]
 },
 DELETE_REQUEST_PRODUCT_CATEGORY (state, deletedItem) {
  state.SwmProductCategoryDetailedList = state.SwmProductCategoryDetailedList.filter(item => {
   return item.id !== deletedItem.id
  })
 },

 SET_DETAILED_TICKET_STATUS_LIST (state, items) {
  state.TicketStatusDetailedList = items;
 },
 ADD_NEW_TICKET_STATUS (state, item) {
  state.TicketStatusDetailedList = [...state.TicketStatusDetailedList, ...item]
 },
 DELETE_TICKET_STATUS (state, deletedItem) {
  state.TicketStatusDetailedList = state.TicketStatusDetailedList.filter(item => {
   return item.id !== deletedItem.id
  })
 }
 
}
