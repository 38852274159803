/*=========================================================================================
  File Name: moduleCompanies.js
  Description: Companies Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleCompaniesState.js'
import mutations from './moduleCompaniesMutations.js'
import actions from './moduleCompaniesActions.js'
import getters from './moduleCompaniesGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

