export default {
	sendWelcomeEmail(userId) {
		return `
			mutation {
				sendWelcomeEmail(input: {
					id: ${userId}
				}) {
					id
				}
			}
		`;
	}
}
