/*=========================================================================================
  File Name: moduleProjectsMutations.js
  Description: Projects Module Mutations
==========================================================================================*/

export default {
  ADD_NEWS_ITEM (state, newsItem) {
    state.news = [...state.news, ...newsItem];
  },
  SET_NEWS_STATUSES (state, newsStatuses) {
    state.newsStatuses = newsStatuses
  },
  SET_NEWS_CATEGORIES (state, newsCategories) {
    state.newsCategories = newsCategories
  },
  ADD_NEWS_CATEGORY (state, newsCategory) {
    state.newsCategories.push(newsCategory)
  },
  SET_NEWS_TAGS (state, newsTags) {
    state.newsTags = newsTags
  },
  SET_NEWS (state, news) {
    state.news = news
  },
  SET_LATEST_NEWS (state, news) {
    state.latestNews = news
  },
  SET_NEWS_ITEM (state, newsItem) {
    state.newsItem = newsItem
    state.newsAuthor = newsItem.author
    state.newsItemTags = newsItem.newsTags
  },
  DELETE_NEWS_ITEM (state, deletedProject) {
    state.projects = state.projects.filter(proj => {
      return proj.id !== deletedProject.id;
    });
  },
  DELETE_NEWS_CATEGORY (state, deletedNewsCategory) {
    state.newsCategories = state.newsCategories.filter(cat => {
      return cat.id !== deletedNewsCategory.id;
    });
  }
}
