const sentEmailFields = () => {
	return `
				id
				hash
				headers
				sender_name
				sender_email
				recipient_name
				recipient_email
				subject
				content
				opens
				clicks
				created_at
				updated_at
				opened_at
				clicked_at
				meta
				model_name
			`
}

const activityFields = () => {
	return `
				id
				action
				time
				user {
								id
								model_name
								first_name
								last_name
								email
								phone_number
								is_active
								avatar_url
								position
								department
								gender
								birthday_date
								address_1
								address_2
								postcode
								city
								country_id
								linkedin_url
								website_url
								xing_url
								user_role_id
								user_companies
								role {
									id
									key_name
									level
									userPermissions {
										key_name
									}
								}
								country_id
								country {
									id
									name
								}
								language_id
								language {
									id
									name
									code
									icon_url
								}
								companies {
									id
									name
								}
								remember_token
								created_at
								updated_at
				}
				object {
								id
								type
								name
								body
				}
				company {
								id
								name
				}
				model_name
			`
}

import helperFunctions from '@/helper/functions.js';

export default {
	getAllSentEmails() {
		return `
			{
				sentEmails(orderBy: [
					{
						field: "id"
						order: DESC
					}
				]) {
					${sentEmailFields()}
				}
			}
		`;
	},
	getFilteredSentEmails(page, items_per_page = null, search = '') {
		return `
			{
				filteredSentEmails(page: ${page}, items_per_page: ${items_per_page}, search: "${search}") {
					${sentEmailFields()}
				}
			}
		`;
	},
	getSentEmail(projectId) {
		return `
			{
				sentEmailById(id: ${projectId}) {
					${sentEmailFields()}
				}
			}
		`;
	},
	getFilteredSentEmails(page, search = '') {
		return `
			{
				filteredSentEmails(page: ${page}, search: "${search}") {
					${sentEmailFields()}
				}
			}
		`;
	},
	getAllActivities() {
		return `
			{
				streamEvents(orderBy: [
					{
						field: "id"
						order: DESC
					}
				]) {
					${activityFields()}
				}
			}
		`;
	},
	getFilteredActivities(lastItem, search = '') {
		return `
			{
				filteredStreamEvents(lastItem: "${lastItem}", search: "${search}") {
					${activityFields()}
				}
			}
		`;
	}
}
