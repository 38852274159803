/*=========================================================================================
  File Name: moduleNewsActions.js
  Description: News Module Actions
==========================================================================================*/

import axios from '@/axios.js';
import helperFunctions from '@/helper/functions.js';
import apiQueries from './api/queries.js';

export default {
  fetchNewNotificationsCount ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.fetchNotificationsCounts()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          const responseData = response.data.data.userStatus
          commit('SET_NEW_NOTIFICATIONS_COUNT', responseData.new_notifications)
          commit('SET_UNREAD_NOTIFICATIONS_COUNT', responseData.unread_notifications)
          resolve(responseData)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    });
  },
  fetchNotificationsList ({ commit }, notificationSettings) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.fetchNotifications(notificationSettings.perPage, notificationSettings.lastID)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          const responseData = response.data.data.notifications
          commit('APPEND_NOTIFICATIONS', responseData.notifications)
          resolve(responseData)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    });
  },
  readNotification ({ commit }, notificationID) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.readNotification(notificationID)
      })
      .then((response) => {
        resolve(response.data.data.readNotification)
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
    });
  },
  cleanupNotificationsList ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('CLEANUP_NOTIFICATIONS')
    });
  },
  fetchEmailNotificationsData ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.fetchEmailNotificationsData()
      })
      .then((response) => {
        resolve(response.data.data.emailNotificationsData)
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
    });
  },
  updateEmailNotificationsData ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.updateEmailNotificationsData(data)
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          const responseData = response.data.data.updateEmailNotificationsData
          resolve(responseData)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    });
  }
}
