/*=========================================================================================
  File Name: moduleNewsActions.js
  Description: News Module Actions
==========================================================================================*/

import axios from '@/axios.js';
import helperFunctions from '@/helper/functions.js';
import apiQueries from './api/queries.js';

export default {
  createNewsItem ({ commit }, newsData) {
    let newsDataLoc = JSON.parse(JSON.stringify(newsData))

    newsDataLoc.title = JSON.stringify(newsDataLoc.title)
    newsDataLoc.title = newsDataLoc.title.substring(1, newsDataLoc.title.length-1);
    newsDataLoc.title = JSON.stringify(newsDataLoc.title)
    newsDataLoc.title = newsDataLoc.title.substring(1, newsDataLoc.title.length-1);

    newsDataLoc.excerpt = JSON.stringify(newsDataLoc.excerpt)
    newsDataLoc.excerpt = newsDataLoc.excerpt.substring(1, newsDataLoc.excerpt.length-1);
    newsDataLoc.excerpt = JSON.stringify(newsDataLoc.excerpt)
    newsDataLoc.excerpt = newsDataLoc.excerpt.substring(1, newsDataLoc.excerpt.length-1);

    newsDataLoc.content = JSON.stringify(newsDataLoc.content)
    newsDataLoc.content = newsDataLoc.content.substring(1, newsDataLoc.content.length-1);
    newsDataLoc.content = JSON.stringify(newsDataLoc.content)
    newsDataLoc.content = newsDataLoc.content.substring(1, newsDataLoc.content.length-1);

    const query = apiQueries.createNewsItem(newsDataLoc, newsDataLoc.tags, newsDataLoc.categories, newsDataLoc.roles)
    const data = helperFunctions.makeFormDataIfFileExists(query, {
      'previewFile': newsData.previewFile
    });
    return new Promise((resolve, reject) => {
      axios.post('/graphql', data)
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors))
        }
        const responseData = response.data.data.createNewsItem
        commit('ADD_NEWS_ITEM', [responseData])
        resolve(responseData)
      })
      .catch((error) => { reject(error) })
    });
  },
  updateNewsItem ({ commit }, newsData) {
    let newsDataLoc = JSON.parse(JSON.stringify(newsData))

    newsDataLoc.title = JSON.stringify(newsDataLoc.title)
    newsDataLoc.title = newsDataLoc.title.substring(1, newsDataLoc.title.length-1);
    newsDataLoc.title = JSON.stringify(newsDataLoc.title)
    newsDataLoc.title = newsDataLoc.title.substring(1, newsDataLoc.title.length-1);

    newsDataLoc.excerpt = JSON.stringify(newsDataLoc.excerpt)
    newsDataLoc.excerpt = newsDataLoc.excerpt.substring(1, newsDataLoc.excerpt.length-1);
    newsDataLoc.excerpt = JSON.stringify(newsDataLoc.excerpt)
    newsDataLoc.excerpt = newsDataLoc.excerpt.substring(1, newsDataLoc.excerpt.length-1);

    newsDataLoc.content = JSON.stringify(newsDataLoc.content)
    newsDataLoc.content = newsDataLoc.content.substring(1, newsDataLoc.content.length-1);
    newsDataLoc.content = JSON.stringify(newsDataLoc.content)
    newsDataLoc.content = newsDataLoc.content.substring(1, newsDataLoc.content.length-1);

    const query = apiQueries.updateNewsItem(newsDataLoc, newsDataLoc.tags, newsDataLoc.newsCategories.map((c)=>{
      return c.id
    }), newsDataLoc.userRoles.map((c)=>{
      return c.id
    }))
    const data = helperFunctions.makeFormDataIfFileExists(query, {
      'previewFile': newsData.previewFile
    });
    return new Promise((resolve, reject) => {
      axios.post('/graphql', data)
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors))
        }
        const responseData = response.data.data.updateNewsItem
        resolve(responseData)
      })
      .catch((error) => { reject(error) })
    });
  },
  fetchTags ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getTagsList()
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors))
        }
        const responseData = response.data.data.newsTags;
        commit('SET_NEWS_TAGS', responseData)
        resolve(responseData)
      })
      .catch((error) => { reject(error) })
    });
  },
  fetchStatuses ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getStatusesList()
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors))
        }
        const responseData = response.data.data.newsStatuses
        commit('SET_NEWS_STATUSES', responseData)
        resolve(responseData)
      })
      .catch((error) => { reject(error) })
    });
  },
  fetchCategories ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getCategoriesList()
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors))
        }
        const responseData = response.data.data.newsCategories
        commit('SET_NEWS_CATEGORIES', responseData)
        resolve(responseData)
      })
      .catch((error) => { reject(error) })
    });
  },
  fetchNews ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getAllNews()
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          const responseData = response.data.data.news
          commit('SET_NEWS', responseData)
          resolve(responseData)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    });
  },
  fetchLatestNews ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.getFreshNews()
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        const responseData = response.data.data.news
        commit('SET_LATEST_NEWS', responseData)
        resolve(responseData)
      })
      .catch((error) => {
        console.log(error)
        reject(error)
      })
    });
  },
  fetchNewsItem ({commit}, newsItemId) {
    return new Promise((resolve, reject) => {
      const query = apiQueries.getNewsItem(newsItemId)
      axios.post('/graphql', {
        query: query
      })
        .then((response) => {
          if (helperFunctions.checkForApiErrors(response.data)) {
            return reject(response.data.errors)
          }
          commit('SET_NEWS_ITEM', response.data.data.newsItemById)
          resolve(response.data.data.newsItemById)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  /**
   * @todo take care of news delete
   * @param commit
   * @param categoryData
   * @returns {Promise<unknown>}
   */
  // deleteNewsItem ({ commit }, projectId) {
  //   return new Promise((resolve, reject) => {
  //     axios.post('/graphql', {
  //       query: apiQueries.deleteProject(projectId)
  //     })
  //     .then((response) => {
  //       if (helperFunctions.checkForApiErrors(response.data)) {
  //         return reject(helperFunctions.getApiErrorMessages(response.data.errors));
  //       }
  //       const responseData = response.data.data.deleteProject;
  //       commit('DELETE_PROJECT', responseData);
  //       resolve(responseData);
  //     })
  //     .catch((error) => { reject(error) })
  //   });
  // },
  updateCategory ({ commit }, categoryData) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.updateCategory(categoryData)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        resolve(response.data.data.updateNewsCategory)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  createCategory ({ commit }, categoryData) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.createCategory(categoryData)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(response.data.errors)
        }
        commit('ADD_NEWS_CATEGORY', response.data.data.createNewsCategory);
        resolve(response.data.data.createNewsCategory)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  deleteNewsCategory ({ commit }, newsCategoryId) {
    return new Promise((resolve, reject) => {
      axios.post('/graphql', {
        query: apiQueries.deleteNewsCategory(newsCategoryId)
      })
      .then((response) => {
        if (helperFunctions.checkForApiErrors(response.data)) {
          return reject(helperFunctions.getApiErrorMessages(response.data.errors));
        }
        const responseData = response.data.data.deleteNewsCategoryById;
        commit('DELETE_NEWS_CATEGORY', responseData);
        resolve(responseData);
      })
      .catch((error) => { reject(error) })
    });
  }
}
