const newsItemFields = () => {
	return `
	 id
	 model_name
		title
		excerpt
		content
		preview_file
		published
		published_date
		news_status_id
		newsStatus {
			id
			title
		}
		newsCategories {
			id
			title
		}
		newsTags {
			id
			title
		}
		userRoles {
			id
			name
		}
		author {
			id
			first_name
			last_name
			email
			phone_number
			avatar_url
			linkedin_url
			website_url
			xing_url
			country {
				name
			}
			role {
				key_name
			}
		}
	`;
};

const serviceFields = () => {
	return `
		id
		key_name
	`;
};

const projectTypeFields = () => {
	return `
		id
		key_name
	`;
};

const newsStatusAllFields = () => {
  return `
		id
		title
		created_at
		updated_at
	`;
};

const newsStatusListFields = () => {
  return `
		id
		title
	`;
};

const newsTagAllFields = () => {
	return `
		id
		title
		description
		created_at
		updated_at
	`;
};

const newsTagListFields = () => {
	return `
		id
		title
	`;
};

const newsCategoryAllFields = () => {
	return `
		id
		title
		icon
		color
		description
		created_at
		updated_at
		model_name
	`;
};

const newsCategoryListFields = () => {
	return `
		id
		title
		icon
		color
		description
		model_name
	`;
};

import helperFunctions from '@/helper/functions.js';

export default {
	createNewsItem(fields, newsTags, newsCategories, userRoles) {
		return `
			mutation($previewFile: [Upload]) {
				createNewsItem(
					previewFile: $previewFile,
					newsTags: ${JSON.stringify(newsTags)}, 
					newsCategories: ${JSON.stringify(newsCategories)},
					userRoles: ${JSON.stringify(userRoles)}
					${helperFunctions.convertFieldsToString(fields)}
				) {
					${newsItemFields()}
				}
			}
		`;
	},
	updateNewsItem(fields, newsTags, newsCategories, userRoles) {
		return `
			mutation($previewFile: [Upload]) {
				updateNewsItem(
					previewFile: $previewFile,
					newsTags: ${JSON.stringify(newsTags)}, 
					newsCategories: ${JSON.stringify(newsCategories)},
					userRoles: ${JSON.stringify(userRoles)}
					${helperFunctions.convertFieldsToString(fields)}
				) {
					${newsItemFields()}
				}
			}
		`;
	},
	getAllNews() {
		return `
			{
				news(orderBy: [
					{
						field: "published"
						order: DESC
					}
				]) {
					${newsItemFields()}
				}
			}
		`;
	},
	getFreshNews() {
		return `
			{
				news(orderBy: [
					{
						field: "id"
						order: DESC
					}
				]) {
					${newsItemFields()}
				}
			}
		`;
	},
	getNewsItem(newsItemId) {
		return `
			{
				newsItemById(id: ${newsItemId}) {
					${newsItemFields()}
				}
			}
		`;
	},
	getStatusesList() {
		return `
			{
				newsStatuses {
					${newsStatusListFields()}
				}
			}
		`
	},
	getTagsList() {
		return `
			{
				newsTags {
					${newsTagListFields()}
				}
			}
		`
	},
	getCategoriesList() {
		return `
			{
				newsCategories {
					${newsCategoryListFields()}
				}
			}
		`
	},
	updateCategory (categoryData) {
		return `
         mutation {
          updateNewsCategory(id:${categoryData.id}, title:"${categoryData.title}", icon:"${categoryData.icon}", color: "${categoryData.color}", description: "${categoryData.description}") {
           ${newsCategoryAllFields()}
          }
         }
        `
	},
	createCategory (categoryData) {
		return `
         mutation {
          createNewsCategory(title:"${categoryData.title}", icon:"${categoryData.icon}", color: "${categoryData.color}", description: "${categoryData.description}") {
           ${newsCategoryAllFields()}
          }
         }
        `
	},
	deleteNewsCategory(newsCategoryId) {
		return `
			mutation {
				deleteNewsCategoryById(id: ${newsCategoryId}) {
					id
					title
					icon
				}
			}
		`;
	}

}
