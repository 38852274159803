/*=========================================================================================
  File Name: moduleProjectsMutations.js
  Description: Projects Module Mutations
==========================================================================================*/

export default {
  ADD_NEW_PROJECT (state, project) {
    state.projects = [...state.projects, ...project];
  },
  SET_SERVICES (state, services) {
    state.services = services;
  },
  DELETE_SERVICE (state, deletedService) {
    state.services = state.services.filter(service => {
      return service.id != deletedService.id;
    });
  },
  SET_PROJECT_TYPES (state, projectTypes) {
    state.projectTypes = projectTypes;
  },
  SET_PROJECT_STATUSES_LIST (state, projectStatuses) {
    state.projectStatusesList = projectStatuses
  },
  SET_PROJECTS (state, projects) {
    state.allProjectsFetched = true;
    state.projects = projects;
  },
  SET_PROJECT (state, project) {
    state.project = project
  },
  SET_PROJECT_FILES (state, projectFiles) {
    state.projectFiles = projectFiles;
  },
  SET_PROJECT_MEMBERS (state, projectMembers) {
    state.projectMembers = projectMembers;
  },
  SET_PROJECT_NON_MEMBERS (state, projectMembers) {
    state.projectNonMembers = projectMembers;
  },
  DELETE_FILE (state, file) {
    state.projectFiles.splice(state.projectFiles.findIndex((f) => f.id === file.id), 1);
  },
  SET_PROPSTER_PROJECT_MANAGERS (state, propsterProjectManagers) {
    state.propsterProjectManagersList = propsterProjectManagers
  },
  SET_PROJECT_USERS_WITH_APPS_MARKETPLACES_ROLES (state, projectUsersWithAppsMarketplacesRoles) {
    if (typeof projectUsersWithAppsMarketplacesRoles === 'string') {
      projectUsersWithAppsMarketplacesRoles = JSON.parse(projectUsersWithAppsMarketplacesRoles);
    }

    state.projectUsersWithAppsMarketplacesRoles = projectUsersWithAppsMarketplacesRoles;
  },
  DELETE_PROJECT (state, deletedProject) {
    state.projects = state.projects.filter(proj => {
      return proj.id !== deletedProject.id;
    });
  },
  SET_ADDONS (state, addons) {
    state.addons = addons;
  },
  SET_PROJECT_ADDONS(state, projectAvailableAddons) {
    state.projectAvailableAddons = projectAvailableAddons;
  },
  DELETE_ADDON (state, deletedAddon) {
    state.addons = state.addons.filter(addon => {
      return addon.id != deletedAddon.id;
    });
  },
}
