const eventFields = () => {
	return `
		id
		created_at
		updated_at
		deleted_at
		stats_event_app_id
		stats_event_type_id
		stats_event_object_type_id
		stats_object_id
		company_id
		project_id
		marketplace_id
		event_datetime
		user_email
		user_role_key_name
		context
		statsCompany {
			id
			name
		}
		marketplace {
			id
			name
		}
		project {
			id
			name
		}
		statsUser {
			id
			email
			first_name
			last_name
		}
		app {
			id
			key_name
			name
		}
		type {
			id
			key_name
			name
			stats_event_app_id
			stats_event_object_type_id
		}
		objectType {
			id
			key_name
			name
			object_model_name
		}
		object
	`;
};

export default {
	logEvent(app_key_name, object_key_name, event_key_name, context, project_id = '', company_id = '', user_id = '', marketplace_id = '', object_id = '') {
		return `
			mutation {
				response: logEvent(app_key_name: "${app_key_name}", object_key_name: "${object_key_name}", event_key_name: "${event_key_name}", project_id: "${project_id}", company_id: "${company_id}", user_id: "${user_id}", marketplace_id: "${marketplace_id}", object_id: "${object_id}", context: """${JSON.stringify(context)}""") {
					id
				}
			}
		`;
	},
	getFilteredEvents(page, statsUserId, statsCompanyId, appId, roleId, instanceId, eventTypeId, objectTypeId, objectId, dateTimeFrom, dateTimeTo, hidePropster) {
		return `
			{
				filteredEvents(
					page: ${page},
					stats_user_id: "${statsUserId}",
					stats_company_id: "${statsCompanyId}",
					app_id: "${appId}",
					role_id: "${roleId}",
					instance_id: "${instanceId}",
					event_type_id: "${eventTypeId}",
					object_type_id: "${objectTypeId}",
					object_id: "${objectId}",
					${dateTimeFrom ? `date_time_from: "${dateTimeFrom}"` : ''},
					${dateTimeTo ? `date_time_to: "${dateTimeTo}"` : ''},
					hide_propster: "${hidePropster}"
				) {
					${eventFields()}
				}
			}
		`;
	},
	getAvailableFilters(statsUserId, statsCompanyId, appId, roleId, instanceId, eventTypeId, objectTypeId, objectId, dateTimeFrom, dateTimeTo, hidePropster) {
		return `
			{
				availableEventFilters(
					stats_user_id: "${statsUserId}",
					stats_company_id: "${statsCompanyId}",
					app_id: "${appId}",
					role_id: "${roleId}",
					instance_id: "${instanceId}",
					event_type_id: "${eventTypeId}",
					object_type_id: "${objectTypeId}",
					object_id: "${objectId}",
					${dateTimeFrom ? `date_time_from: "${dateTimeFrom}"` : ''},
					${dateTimeTo ? `date_time_to: "${dateTimeTo}"` : ''},
					hide_propster: "${hidePropster}"
				)
			}
		`;
	},
}
